import React from 'react';
import { TableProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import useTableCommon from 'src/hooks/useTable';
import modalConfirm from 'src/utils/modalConfirm';
import { usePluginRegistration } from 'src/queries/usePluginRegistration';
import { FLAG_PLUGIN } from 'src/constants/app';
import useForm from './useForm';
import { FLAG } from 'src/constants/optionSelect/pluginManagement';
import { RadioGroupField } from 'src/components/form';
import { getNameTypePlugin } from 'src/utils/helper';

const useTable = () => {
  const { t } = useTranslation();
  const { handleSubmit, getValues, control } = useForm();
  const { getListPlugins, update } = usePluginRegistration({
    setRowKeysSelected: undefined,
  });
  const { rowSelection, ...rest } = useTableCommon({
    pagination: false,
    hasSelect: true,
  });

  const onChangeRadio = (event, record) => {
    // Update post flag data when change radio.
    if (event.target.value == FLAG_PLUGIN.STOP) {
      modalConfirm({
        t,
        title: t('message.C000002'),
        okText: t('basic_setting.button_confirm'),
        cancelText: t('basic_setting.button_cancel'),
        okButtonProps: {
          type: 'primary',
        },
        onOk: () => {
          update.mutate({
            seqno: record.seqno,
            unyouFlg: event.target.value,
          });
        },
      });
    } else {
      update.mutate({
        seqno: record.seqno,
        unyouFlg: event.target.value,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t('plugin_management.item_number'),
      dataIndex: 'index',
      render(value, item, index) {
        return index + 1;
      },
    },
    {
      title: t('plugin_management.plugin_name'),
      dataIndex: 'pluginNm',
    },
    {
      title: t('plugin_management.type'),
      dataIndex: 'pluginType',
      render(status, record) {
        return getNameTypePlugin(record?.pluginType);
      },
    },
    {
      title: t('plugin_management.operation_stop_lag'),
      dataIndex: 'unyouFlg',
      render(value, record) {
        return (
          <RadioGroupField
            controller={{
              name: 'flag',
              control,
            }}
            radioGroupProps={{
              value: record.unyouFlg,
              options: FLAG(t),
              onChange: (event) => onChangeRadio(event, record),
            }}
            labelCol={{ xs: 3, md: 2 }}
            wrapperCol={{ xs: 21, md: 22 }}
          />
        );
      },
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: getListPlugins?.data?.data?.items,
    loading: {
      spinning: getListPlugins.isLoading,
      indicator: <LoadingOutlined />,
    },
    rowClassName: 'table_row_status',
    rowKey: 'seqno',
    size: 'middle',
    pagination: false,
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
  };

  return {
    tableProps,
    ...rest,
    getValues,
    handleSubmit,
    rowSelection,
  };
};

export default useTable;
