import axios from '../libs/axios';

const copyProfitMargin = (url, data: Payload.CopyProfitMarginRequest) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

export default {
  copyProfitMargin,
};
