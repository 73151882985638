import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useQueryUrl from 'src/hooks/useQueryUrl';

import customerDeliveryRegistration from 'src/repositories/customerDeliveryRegistration';

const apiPath = '/delivery/address';

const useCustomerDeliveryRegistration = (onClose) => {
  const queryClient = useQueryClient();

  const query = useQueryUrl<SearchParams.CustomerDeliverySettings>();

  const createCustomerDelivery = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.createCustomerDelivery
  >({
    mutationKey: [apiPath],
    mutationFn: (args) =>
      customerDeliveryRegistration.createCustomerDelivery(apiPath, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([
        `${apiPath}/${query.customer_code}`,
      ]);
      onClose();
    },
    onError(error) {
      console.log(error.response);
    },
  });
  return {
    createCustomerDelivery,
  };
};

export default useCustomerDeliveryRegistration;
