import { Button, Col, Row, Typography } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import classNames from 'classnames';

import {
  NumberOnlyField,
  NumberOnlyRangeField,
  SelectField,
} from 'src/components/form';
import { FormValue } from '../../useForm';
import { TANI_TYPE } from 'src/constants/optionSelect/profitMarginSetting';
import { ApiList, ApiTaniType } from 'src/enums/profitMargin';
import { handleUnitPrices } from 'src/utils/helper';

type Props = {
  apiFieldArray: UseFieldArrayReturn<FormValue, 'api_setting', 'id'>;
  formInfo: UseFormReturn<FormValue, any>;
  setHasErrorTaniType: React.Dispatch<React.SetStateAction<boolean>>;
  dataApiList;
  datacurrentCurrencyUnit;
};

type RenderSettingInputParams = {
  item_index: number;
  isInput4: boolean;
  title: string;
  input_label: string;
  input_name_from;
  input_name_to?;
};

const ApiSetting: React.FC<Props> = ({
  apiFieldArray,
  formInfo,
  setHasErrorTaniType,
  dataApiList,
  datacurrentCurrencyUnit,
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = formInfo;
  const { fields, append } = apiFieldArray;

  const [errorsSetting, setErrorsSetting] = useState<string[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handleAddRow = () => {
    append({
      api_select: dataApiList?.find(
        (item) => item?.isDefault === ApiList.IS_DEFAULT,
      )?.seqno,
      tani_type_select: undefined,
      shiire_kingaku_from_1: null,
      shiire_kingaku_to_1: null,
      shiire_kingaku_from_2: null,
      shiire_kingaku_to_2: null,
      shiire_kingaku_from_3: null,
      shiire_kingaku_to_3: null,
      shiire_kingaku_from_4: null,
    });
  };

  const dataApiListOption = useMemo(() => {
    return dataApiList?.map((item) => ({
      label: item?.name,
      value: item?.seqno,
    }));
  }, [dataApiList]);

  const checkShowInput = (index) => {
    const watchTaniTypes = watch(`api_setting.${index}.tani_type_select`);
    return (
      watchTaniTypes && watchTaniTypes !== ApiTaniType.EXCLUDED_PROFIT_RATE
    );
  };

  const handleChangeTaniType = (index) => {
    setValue(`api_setting.${index}.shiire_kingaku_from_1`, null);
    setValue(`api_setting.${index}.shiire_kingaku_to_1`, null);
    setValue(`api_setting.${index}.shiire_kingaku_from_2`, null);
    setValue(`api_setting.${index}.shiire_kingaku_to_2`, null);
    setValue(`api_setting.${index}.shiire_kingaku_from_3`, null);
    setValue(`api_setting.${index}.shiire_kingaku_to_3`, null);
    setValue(`api_setting.${index}.shiire_kingaku_from_4`, null);
  };

  const renderSettingInput = (params: RenderSettingInputParams) => {
    const {
      item_index,
      isInput4 = false,
      title,
      input_label,
      input_name_from,
      input_name_to,
    } = params;
    const watchTaniTypes = watch(`api_setting.${item_index}.tani_type_select`);

    if (checkShowInput(item_index)) {
      return (
        <>
          <Typography.Title level={5} className="api_setting_input_title">
            {title}
          </Typography.Title>
          {watchTaniTypes === ApiTaniType.PURCHASE_AMOUNT ? (
            isInput4 ? (
              <Row gutter={8} justify="space-between" className="w-full">
                <Col span={11} className="number_only_field">
                  <NumberOnlyField<FormValue>
                    controller={{
                      control,
                      name: input_name_from,
                    }}
                    isOnlyInteger
                  />
                </Col>
                <Col span={2} className="api_setting_input_icon">
                  <Typography.Text>{'~'}</Typography.Text>
                </Col>
                <Col span={11} />
              </Row>
            ) : (
              <NumberOnlyRangeField<FormValue>
                controllerFrom={{
                  name: input_name_from,
                  control: control,
                }}
                controllerTo={{
                  name: input_name_to,
                  control: control,
                }}
                iconBetween={'~'}
                isOnlyInteger
              />
            )
          ) : (
            <Typography.Text className="api_setting_input_text">
              {input_label}
            </Typography.Text>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    let newArr: string[] = [];
    errors?.api_setting?.map((item) => {
      if (item) {
        Object.values(item).map((mess) => {
          mess?.message && newArr.push(mess.message);
        });
      }
    });
    const uniqueErrors = [...new Set(newArr)];
    setErrorsSetting(uniqueErrors);
  }, [errors?.api_setting]);

  useEffect(() => {
    setHasErrorTaniType(errorsSetting.includes(t('message.E000067')));
  }, [errorsSetting]);

  useEffect(() => {
    if (fields?.length === dataApiList?.length) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [fields?.length, dataApiList?.length]);

  return (
    <Row className="api_setting">
      <div className="api_setting_fields">
        <Row className="api_setting_label">
          <Typography.Text>
            {t('profit_margin_setting.api_integrated_products')}
          </Typography.Text>
        </Row>
        {fields.map((item, index) => (
          <Row className="api_setting_row" key={item.id}>
            <Col xs={24} md={7} className="api_setting_select">
              <Row gutter={8}>
                <Col span={10}>
                  <SelectField<FormValue>
                    controller={{
                      control,
                      name: `api_setting.${index}.api_select`,
                    }}
                    selectProps={{
                      options: dataApiListOption,
                      showSearch: true,
                      filterOption: (inputValue, option) =>
                        ((option && option.label) || '')
                          .toString()
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1,
                    }}
                    className="auto_field setting_select_api"
                  />
                </Col>
                <Col span={4} className="api_setting_select_currency">
                  <Typography.Text>
                    {handleUnitPrices(
                      datacurrentCurrencyUnit?.currentKawaseSymbol,
                    )}
                  </Typography.Text>
                </Col>
                <Col span={10}>
                  <SelectField<FormValue>
                    controller={{
                      control,
                      name: `api_setting.${index}.tani_type_select`,
                    }}
                    selectProps={{
                      options: TANI_TYPE(t),
                      showSearch: true,
                      filterOption: (inputValue, option) =>
                        ((option && option.label) || '')
                          .toString()
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1,
                      onChange: () => handleChangeTaniType(index),
                    }}
                    className="auto_field"
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={4}
              xl={4}
              className={classNames('api_setting_input', {
                hide_input_mobile: !checkShowInput(index),
              })}
            >
              {renderSettingInput({
                item_index: index,
                input_label: '0~299',
                isInput4: false,
                title: '①',
                input_name_from: `api_setting.${index}.shiire_kingaku_from_1`,
                input_name_to: `api_setting.${index}.shiire_kingaku_to_1`,
              })}
            </Col>
            <Col
              xs={24}
              md={4}
              xl={4}
              className={classNames('api_setting_input', {
                hide_input_mobile: !checkShowInput(index),
              })}
            >
              {renderSettingInput({
                item_index: index,
                input_label: '300~749',
                isInput4: false,
                title: '②',
                input_name_from: `api_setting.${index}.shiire_kingaku_from_2`,
                input_name_to: `api_setting.${index}.shiire_kingaku_to_2`,
              })}
            </Col>
            <Col
              xs={24}
              md={4}
              xl={4}
              className={classNames('api_setting_input', {
                hide_input_mobile: !checkShowInput(index),
              })}
            >
              {renderSettingInput({
                item_index: index,
                input_label: '750~1499',
                isInput4: false,
                title: '③',
                input_name_from: `api_setting.${index}.shiire_kingaku_from_3`,
                input_name_to: `api_setting.${index}.shiire_kingaku_to_3`,
              })}
            </Col>
            <Col
              xs={24}
              md={4}
              xl={4}
              className={classNames('api_setting_input', {
                hide_input_mobile: !checkShowInput(index),
              })}
            >
              {renderSettingInput({
                item_index: index,
                input_label: '1500~',
                isInput4: true,
                title: '④',
                input_name_from: `api_setting.${index}.shiire_kingaku_from_4`,
              })}
            </Col>
          </Row>
        ))}
      </div>
      {errorsSetting &&
        errorsSetting.map((error, index) => (
          <Row key={index}>
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {error}
            </Typography.Text>
          </Row>
        ))}
      <Row className="setting_action">
        <Button
          className="setting_action_add"
          type="primary"
          onClick={handleAddRow}
          icon={<PlusSquareOutlined />}
          disabled={disableButton}
        >
          {t('profit_margin_setting.add_line')}
        </Button>
      </Row>
    </Row>
  );
};

export default ApiSetting;
