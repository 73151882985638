import React, { useState } from 'react';
import { Button, Layout, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';

import LangSelect from 'src/components/layout/TopNav/LangSelect';
import history from 'src/libs/history';
import useLogo from 'src/queries/useLogo';
import useCurrentAccount from 'src/queries/useCurrentAccount';
import { PATH } from 'src/pages/auth';
import LocalStorage from 'src/utils/LocalStorage';
import AppMenuMobile from 'src/components/layout/MenuMobile';
import { ENV } from 'src/constants/app';

const TopNav: React.FC = () => {
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenuMobile] = useState(false);
  const { data } = useLogo();
  const { name } = useCurrentAccount();
  const headerColor = process.env.REACT_APP_HEADER_COLOR;

  const handleLogoClick = () => {
    history.push('/');
  };

  const onLogout = () => {
    LocalStorage.removeInfo();
    history.push(PATH.SIGN_IN);
  };

  return (
    <Layout className={'top-nav'} style={{ backgroundColor: headerColor }}>
      <Layout
        className={'container-lg'}
        style={{ backgroundColor: headerColor }}
      >
        <Layout.Header style={{ backgroundColor: headerColor }}>
          <a href={ENV.FRONTSITE_URL}>
            {data?.ecsiteLogoDir && (
              <img
                src={data?.ecsiteLogoDir}
                alt="logo"
                className="object-contain logo-header"
                onClick={handleLogoClick}
              />
            )}
            <Button type="link" className="ml-5 text-black name-header">
              {data?.netSbsName || t('header.home')}
            </Button>
          </a>
          <div className="lang-select-topnav">
            {name && (
              <Typography.Text className="mr-12">{name}</Typography.Text>
            )}
            <Button
              type="primary"
              className="mr-20 lang-select_logout"
              icon={<LogoutOutlined />}
              onClick={onLogout}
            >
              {t('header.logout')}
            </Button>
            <div className="mobile_responsive">
              <LangSelect />
            </div>
          </div>
          {/** toggle search and menu mobile*/}
          <div className="toggle-mobile">
            <Button
              type="primary"
              size="small"
              className="w-full toggle-mobile-button"
              onClick={() => {
                setToggleMenuMobile(!toggleMenu);
              }}
            >
              <MenuOutlined />
            </Button>
          </div>
          {toggleMenu && (
            <AppMenuMobile setToggleMenuMobile={setToggleMenuMobile} />
          )}
        </Layout.Header>
      </Layout>
    </Layout>
  );
};

export default TopNav;
