import React, { useEffect, useState } from 'react';
import { Button, TableProps } from 'antd';
import {
  EditOutlined,
  LoadingOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';

import { t } from 'src/libs/i18n';
import useTableCommon from 'src/hooks/useTable';
import useUserRegistrationChange from 'src/queries/useUserRegistrationChange';
import {
  DATE_TIME_HOUR_MIN,
  INIT_PASS_SEND_FLAG,
  LOCK_FLAG,
} from 'src/constants/app';
import { Modal } from 'src/components/custom';
import CountDownButton from 'src/components/common/CountDownButton';
import LocalStorage from 'src/utils/LocalStorage';
import UserRegistrationChangeModal from '../UserRegistrationChangeModal';
import { SCREENS } from 'src/constants/screens';

const useTable = () => {
  const [errorResetPass, setErrorResetPass] = useState<string>('');
  const [currentRecord, setCurrentRecord] = useState<number>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { queryAllFrontAccount, sendMailResetPassword } =
    useUserRegistrationChange({
      setRowKeysSelected: undefined,
      setErrorTable: setErrorResetPass,
    });
  const {
    pagination: paginationCommon,
    rowSelection,
    ...rest
  } = useTableCommon({
    hasSelect: true,
    maxPageSize: 500,
  });

  const allFrontAccountData = queryAllFrontAccount?.data?.data?.items;

  const handleResetPassword = (record) => {
    setErrorResetPass('');
    const email = allFrontAccountData?.find(
      (row) => row.seqno === record.seqno,
    )?.email;
    setCurrentRecord(record.seqno);
    email &&
      sendMailResetPassword.mutate({
        emails: [email],
        gengoCd: LocalStorage.lang,
        customerMailType: 'RESET_PASSWORD',
      });
  };

  useEffect(() => {
    if (sendMailResetPassword?.isSuccess === true) {
      //start running countdown
      setIsSuccess(true);
      //stop running countdown again
      setTimeout(() => {
        setIsSuccess(false);
      });
    }
  }, [sendMailResetPassword?.isSuccess]);

  const columns: ColumnsType<Response.NotificationEntity> = [
    {
      title: t('user_registration_change.email_label'),
      width: 300,
      dataIndex: 'email',
      render: (text) => {
        return text;
      },
    },
    {
      title: t('user_registration_change.name'),
      dataIndex: 'smei',
      render: (text) => {
        return text;
      },
    },
    {
      title: t('user_registration_change.initial_password'),
      dataIndex: 'initPassSendFlg',
      render: (text) => {
        return text === INIT_PASS_SEND_FLAG.TRUE
          ? t('user_registration_change.unsent')
          : t('user_registration_change.sent');
      },
    },
    {
      title: t('user_registration_change.last_logged_in'),
      dataIndex: 'lastLoginDate',
      render: (text) => {
        return text && dayjs(text).format(DATE_TIME_HOUR_MIN);
      },
    },
    {
      title: t('user_registration_change.locked_state'),
      dataIndex: 'lockFlg',
      render: (text) => {
        return text === LOCK_FLAG.UNLOCK
          ? t('user_registration_change.unlocked')
          : t('user_registration_change.locked');
      },
    },
    {
      width: 170,
      render(record) {
        return (
          <React.Fragment>
            <CountDownButton
              onClick={() => handleResetPassword(record)}
              waitSeconds={60}
              label={t('user_registration_change.password_reset')}
              icon={<SettingOutlined />}
              isSuccess={isSuccess}
              index={record.seqno}
              currentButton={currentRecord}
              disable={record.lockFlg === LOCK_FLAG.LOCK}
            />
          </React.Fragment>
        );
      },
    },
    {
      render(record) {
        return (
          <Modal
            modalProps={{ width: 525 }}
            title={t('user_registration_change.title')}
            content={({ onClose }) => (
              <UserRegistrationChangeModal
                onClose={onClose}
                userInfo={record}
                screenId={SCREENS.R2505}
              />
            )}
          >
            <Button className="button_edit ml-20" icon={<EditOutlined />}>
              {t('user_registration_change.edit')}
            </Button>
          </Modal>
        );
      },
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    loading: {
      spinning: queryAllFrontAccount.isFetching,
      indicator: <LoadingOutlined />,
    },
    dataSource: allFrontAccountData,
    rowKey: 'seqno',
    size: 'middle',
    pagination: {
      ...paginationCommon,
      total: queryAllFrontAccount?.data?.data?.total,
      current: queryAllFrontAccount?.data?.data?.page,
      pageSize: queryAllFrontAccount?.data?.data?.offset,
    },
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
    locale: {
      emptyText: t('message.E000024'),
    },
  };

  return {
    tableProps,
    errorResetPass,
    ...rest,
  };
};

export default useTable;
