import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { AxiosError, AxiosResponse } from 'axios';

import plugins from 'src/repositories/pluginRegistration';
import pluginRegistration from 'src/repositories/pluginRegistration';
import { FormValue } from 'src/containers/PluginManagement/CreatePlugin/useForm';
import modalConfirm from 'src/utils/modalConfirm';
import { EXIST_PLUGIN } from 'src/constants/app';

export const apiKey = '/plugin' as const;

type usePlugin = {
  setError?: UseFormSetError<FormValue>;
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
};

export const usePluginRegistration = ({
  setError,
  setRowKeysSelected,
}: usePlugin) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const getListPlugins = useQuery<
    AxiosResponse<Response.listPlugins>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiKey],
    cacheTime: 0,
    queryFn: plugins.getListPlugins,
    onError(err) {
      console.log(err);
    },
  });

  const registration = useMutation<
    AxiosResponse<Response.createPlugin>,
    AxiosError<Response.Error>,
    Payload.registrationPlugin
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => {
      return pluginRegistration.registration(apiKey, args);
    },
    onSuccess: (data) => {
      if (data?.data?.item == EXIST_PLUGIN) {
        modalConfirm({
          t,
          title: t('plugin_management.stop_plugin'),
          okText: t('basic_setting.button_confirm'),
          cancelButtonProps: { style: { display: 'none' } },
        });
      }

      queryClient.invalidateQueries([apiKey]).then();
    },
    onError: (error) => {
      setError &&
        setError('script', {
          type: 'custom',
          message: t('message.E000066', { 0: t('plugin_management.fill') }),
        });
    },
  });

  const update = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.updatePlugin
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => {
      return pluginRegistration.update(`${apiKey}/${args.seqno}`, args);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([apiKey]).then();
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const deletePlugin = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeletePlugin
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => {
      return pluginRegistration.deletePlugin(apiKey, args);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([apiKey]).then();
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return { getListPlugins, registration, update, deletePlugin };
};
