import { Col, Row, Typography } from 'antd';
import React from 'react';

import { InputField } from 'src/components/form';
import { useTranslation } from 'react-i18next';
import { FormValue } from '../useForm';
import { UseFormReturn } from 'react-hook-form';
import PhoneNumberField from 'src/components/form/PhoneNumberField';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
};

const CustomerAddress: React.FC<Props> = ({ formInfo }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = formInfo;
  return (
    <React.Fragment>
      <Row className="mt-20 section_title">
        <Col xs={24} md={24} xl={24}>
          <Typography.Title level={5}>
            {t('customer_registration.address_info_title')}
          </Typography.Title>
          <Typography.Paragraph className="mt-10">
            {t('customer_registration.note_4')}
          </Typography.Paragraph>
          <Typography.Paragraph className="mt-10">
            {t('customer_registration.note_5')}
          </Typography.Paragraph>
          {errors && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error address_error"
              type="danger"
            >
              {errors?.address_recieve?.message ||
                errors?.address_recieve_code?.message ||
                errors?.address_recieve_name?.message ||
                errors?.address_recieve_phone?.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <div className="infomation_input">
        <Row className="infomation_input_address">
          <Col xs={24} md={24} xl={24}>
            <InputField
              controller={{ control, name: 'address_recieve_name' }}
              inputProps={{
                placeholder: t('customer_registration.address_recieve_name'),
                maxLength: 90,
              }}
              label={t('customer_registration.address_recieve_name')}
              labelCol={{ xs: 24, md: 7, xl: 4 }}
              wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            />
          </Col>
          <Col xs={24} md={24} xl={24}>
            <InputField
              controller={{ control, name: 'address_recieve_code' }}
              label={t('customer_registration.address_recieve_code')}
              inputProps={{
                placeholder: t('customer_registration.address_recieve_code'),
                maxLength: 10,
              }}
              labelCol={{ xs: 24, md: 7, xl: 4 }}
              wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            />
          </Col>
          <Col xs={24} md={24} xl={24}>
            <InputField
              controller={{ control, name: 'address_recieve' }}
              inputProps={{
                placeholder: t('customer_registration.address_recieve'),
                maxLength: 100,
              }}
              label={t('customer_registration.address_recieve')}
              labelCol={{ xs: 24, md: 7, xl: 4 }}
              wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            />
          </Col>
          <Col xs={24} md={24} xl={24}>
            <PhoneNumberField
              controller={{ control, name: 'address_recieve_phone' }}
              inputProps={{
                placeholder: t('customer_registration.address_recieve_phone'),
                maxLength: 13,
              }}
              label={t('customer_registration.address_recieve_phone')}
              labelCol={{ xs: 24, md: 7, xl: 4 }}
              wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CustomerAddress;
