import React from 'react';
import _ from 'lodash';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import useForm from './useForm';
import Note from './Note';
import RegisterInfo from './RegisterInfo';
import CustomerAddress from './CustomerAddress';
import CustomerInfo from './CustomerInfo';
import useCustomerRegistration from 'src/queries/useCustomerRegistration';

const CustomerRegistration: React.FC = () => {
  const { t } = useTranslation();
  const { formInfo } = useForm();
  const { handleSubmit, setError } = formInfo;
  const { saveCustomerRegistration } = useCustomerRegistration(setError);

  const handleRegister = (values) => {
    const payload: Payload.CustomerRegistration = {
      kokyakuMei: values.customer_org_name,
      jushoCd: values.customer_address_code,
      jusho: values.customer_address,
      telno: values.customer_phone,
      faxno: values.customer_fax,
      name: values.customer_staff,
      kokyakuKbnFlg: _.get(values, 'customer_type[0]') ? '1' : '0',
      jogenKingaku: parseInt(values.order_limit_price),
      kessaiJoken: values.order_condition,
      hanyomRan: values.note,
      otokesakiNm: values.address_recieve_name,
      otodokesakiJushoCd: values.address_recieve_code,
      otodokesakiJusho: values.address_recieve,
      telnoOtokesaki: values.address_recieve_phone,
      email: values.customer_email,
      smei: values.customer_name,
      gyoushuId: values.job_id,
      shozaichiId: values.region_id,
    };
    const dataOmit: any = _.omitBy(
      payload,
      (v) => _.isUndefined(v) || _.isNull(v) || v === '',
    );
    saveCustomerRegistration.mutate(dataOmit);
  };

  return (
    <div className="customer_registration">
      <Note />
      <RegisterInfo formInfo={formInfo} />
      <CustomerAddress formInfo={formInfo} />
      <CustomerInfo formInfo={formInfo} />
      <Row justify="center" className="mt-30 mb-60">
        <Col xs={12} md={4}>
          <Button
            type="primary"
            className="w-full submit_button"
            loading={saveCustomerRegistration.isLoading}
            onClick={handleSubmit(handleRegister)}
          >
            {t('customer_registration.button_add')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerRegistration;
