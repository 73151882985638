import { Button, Col, Divider, Row } from 'antd';
import React from 'react';
import qs from 'query-string';

import { CheckboxGroupField, InputField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import history from 'src/libs/history';
import useQueryUrl from 'src/hooks/useQueryUrl';
import {
  INITIAL_PASSWORD,
  LOCKED_STATE,
} from 'src/constants/optionSelect/userRegistrationChange';
import { useTranslation } from 'react-i18next';

const Search: React.FC = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();
  const query = useQueryUrl();

  const handleSearch = (values) => {
    history.push({
      search: qs.stringify(
        {
          ...query,
          ...values,
          page: 1,
          search: true,
        },
        {
          skipNull: true,
          skipEmptyString: true,
          arrayFormatSeparator: '|',
          arrayFormat: 'bracket-separator',
        },
      ),
    });
  };

  return (
    <div className="user_registration_search">
      <Row>
        <Col xs={24} md={9} xl={10}>
          <InputField<FormValue>
            label={t('user_registration_change.email')}
            controller={{
              control,
              name: 'email',
            }}
            inputProps={{
              maxLength: 80,
              placeholder: t('user_registration_change.email'),
            }}
            labelCol={{ xs: 24, md: 12, xl: 6 }}
            wrapperCol={{ xs: 24, md: 11, xl: 17 }}
          />
        </Col>
        <Col xs={24} md={3} xl={2} className="user_registration_search_note">
          {t('user_registration_change.partial_match')}
        </Col>
        <Col xs={24} md={9} xl={10}>
          <InputField<FormValue>
            label={t('user_registration_change.name')}
            controller={{
              control,
              name: 'name',
            }}
            inputProps={{
              maxLength: 30,
              placeholder: t('user_registration_change.name'),
            }}
            labelCol={{ xs: 24, md: 8, xl: 6 }}
            wrapperCol={{ xs: 24, md: 13, xl: 17 }}
          />
        </Col>
        <Col xs={24} md={3} xl={2} className="user_registration_search_note">
          {t('user_registration_change.partial_match')}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={24} md={12} xl={12}>
          <CheckboxGroupField<FormValue>
            label={t('user_registration_change.initial_password')}
            controller={{ name: 'initial_password', control }}
            checkboxGroupProps={{ options: INITIAL_PASSWORD(t) }}
            labelCol={{ xs: 8, md: 9, xl: 5 }}
            wrapperCol={{ xs: 16, md: 15, xl: 19 }}
            className="user_registration_search_checkbox"
          />
        </Col>
        <Col xs={24} md={12} xl={12}>
          <CheckboxGroupField<FormValue>
            label={t('user_registration_change.locked_state')}
            controller={{ name: 'locked_state', control }}
            checkboxGroupProps={{ options: LOCKED_STATE(t) }}
            labelCol={{ xs: 8, md: 6, xl: 5 }}
            wrapperCol={{ xs: 16, md: 18, xl: 19 }}
            className="user_registration_search_checkbox"
          />
        </Col>
      </Row>
      <Divider className="divider mt-20 mb-30" />
      <Row justify="center" className="mt-12">
        <Col xs={12} md={3}>
          <Button
            type="primary"
            className="w-full button"
            onClick={handleSubmit(handleSearch)}
          >
            {t('button.search')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Search;
