import React from 'react';

import CustomerRegistrationContainer from 'src/containers/CustomerRegistration';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const CustomerRegistrationPage: React.FC = () => {
  useTitle(t('customer_registration.page_title'));
  return <CustomerRegistrationContainer />;
};

export const PATH = '/customer_registration' as const;
export default CustomerRegistrationPage;
