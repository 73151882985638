import React from 'react';
import { Row, Button, Divider, Table as AntTable } from 'antd';

import useTable from './useTable';
import { useTranslation } from 'react-i18next';
import modalConfirm from 'src/utils/modalConfirm';
import { usePluginRegistration } from 'src/queries/usePluginRegistration';
import { DeleteOutlined } from '@ant-design/icons';

const PluginTable: React.FC = () => {
  const { tableProps, rowSelected, setRowKeysSelected } = useTable();
  const { t } = useTranslation();
  const { deletePlugin } = usePluginRegistration({ setRowKeysSelected });

  const submitForm = () => {
    modalConfirm({
      t,
      title: t('message.C000002'),
      cancelText: t('basic_setting.button_cancel'),
      okText: t('basic_setting.button_confirm'),
      okButtonProps: {
        type: 'primary',
      },
      onOk: () => {
        rowSelected &&
          deletePlugin.mutate({
            seqnos: rowSelected,
          });
      },
    });
  };

  return (
    <React.Fragment>
      <div className="table">
        <AntTable {...tableProps} />
        <Row className="contain-button-delete">
          <Button
            icon={<DeleteOutlined />}
            className="btn-update"
            type="primary"
            disabled={rowSelected && !rowSelected.length}
            onClick={submitForm}
          >
            {t('user_registration_change.delete')}
          </Button>
        </Row>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default PluginTable;
