import { Col, Row, Typography } from 'antd';
import React from 'react';
import { t } from 'src/libs/i18n';
import useTaxExChangeSetting from 'src/queries/useTaxExChangeSetting';

import CurrencyTransfers from './CurrencyTransfers';
import CurrencyUnit from './CurrencyUnit';
import TaxExchange from './TaxExchange';
import { formatCurrency } from 'src/utils/helper';

const TaxExchangeSetting: React.FC = () => {
  const { getSymbolsHanyomCd1, getSymbolsHanyomCd2 } = useTaxExChangeSetting(
    {},
  );

  return (
    <div className="tax_exchange_setting">
      <Typography.Text className="title_text fw-medium my-20">
        {t('tax_exchange_setting.multiply_each_by_the_selling_price')}
      </Typography.Text>
      <TaxExchange
        symbols={formatCurrency(getSymbolsHanyomCd1?.data?.data?.items)}
      />
      <Row className="mb-30">
        <Col xl={14} lg={14} md={14} sm={24} xs={24}>
          <CurrencyTransfers
            symbols={formatCurrency(getSymbolsHanyomCd2?.data?.data?.items)}
          />
        </Col>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 8, offset: 2 }}
          sm={24}
          xs={24}
        >
          <CurrencyUnit
            symbols={formatCurrency(getSymbolsHanyomCd2?.data?.data?.items)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaxExchangeSetting;
