import i18n from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';
import { setLocale } from 'yup';

import en from 'src/locales/en.json';
import ja from 'src/locales/ja.json';
import th from 'src/locales/th.json';
import ko from 'src/locales/ko.json';
import vi from 'src/locales/vi.json';
import zh from 'src/locales/zh.json';
import tw from 'src/locales/tw.json';
import LocalStorage from 'src/utils/LocalStorage';

export const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: ja,
  },
  th: {
    translation: th,
  },
  tw: {
    translation: tw,
  },
  zh: {
    translation: zh,
  },
  ko: {
    translation: ko,
  },
  vi: {
    translation: vi,
  },
} as const;

export function buildYupLocale(_: unknown, t): void {
  setLocale({
    mixed: {
      required: ({ path }) => {
        return t('message.E000003', { 0: t(`label.${path}`) });
      },
    },
    string: {
      email: ({ path }) => {
        return t('message.E000033', { 0: t(`label.email`) });
      },
    },
  });
}

i18n.use(initReactI18next).init(
  {
    interpolation: {
      escapeValue: false, // escapeValue is false to prevent i18next from automatically escaping values
      format: function (value) {
        if (typeof value === 'string') {
          return value.replace(/&quot;/g, '"');
        }
        return value;
      },
    },
    fallbackLng: 'en',
    debug: false,
    lng: i18n.options.lng || LocalStorage.lang,
    resources,
  },
  buildYupLocale,
);

const I18n = i18n;
const t: TFunction = (...args: Parameters<TFunction>) => I18n.t(...args);

export { I18n, t };
