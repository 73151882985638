import axiosBase, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';

import { configBatch, HTTP_CODE, STATUS_CODE } from 'src/constants/app';
import LocalStorage from 'src/utils/LocalStorage';
import authLocked from 'src/utils/authLocked';
import authorization from 'src/utils/authorization';

const axios = <T = any>(requestConfig: AxiosRequestConfig<T>) => {
  const instance: AxiosInstance = axiosBase.create(configBatch);
  instance.interceptors.request.use(
    (config) => {
      const accessToken = LocalStorage.accessToken;

      if (accessToken) {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        config.headers = Object.assign(config.headers || {}, headers);
      }

      const lang = LocalStorage.lang;
      if (lang) {
        config.params = {
          ...config.params,
          gengoCd: lang,
        };
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const httpCode = error.response && error.response.status;
      const statusCode = error.response && error.response?.data?.code;

      switch (httpCode) {
        case HTTP_CODE.UNAUTHORIZED:
          if (statusCode === STATUS_CODE.USER_LOCKED) {
            return authLocked();
          }
          authorization();
          break;
        default:
          break;
      }

      return Promise.reject(error);
    },
  );

  return instance(requestConfig);
};

export default axios;
