import React from 'react';

import TaxExchangeSetting from 'src/containers/TaxExchangeSetting';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const TaxExchangeSettingPage: React.FC = () => {
  useTitle(t('menu_home.tax_exchange_setting'));
  return <TaxExchangeSetting />;
};

export const PATH = '/tax_exchange_setting' as const;
export default TaxExchangeSettingPage;
