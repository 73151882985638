import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useQueryUrl from 'src/hooks/useQueryUrl';
import dayjs from 'dayjs';
import { t } from 'src/libs/i18n';
import { REGEX } from 'src/constants/app';

export type FormValue = {
  quote_order_flag: string;
  customer_distinction: boolean;
  customer_name: string;
  delivery_address: string;
  delivery_date_from: string;
  delivery_date_to: string;
  quotation_number_express: string;
  product_name: string;
  order_date_from: string;
  order_date_to: string;
  selling_price_from: string;
  selling_price_to: string;
  order_number_from: string;
  order_number_to: string;
  finished_dimensions: string;
  customer: string;
  weight_from: string;
  weight_to: string;
  maximum_search_count: string;
  quotation_number_from: string;
  quotation_number_to: string;
  internal_person: string;
  product_classification: string;
  offset: string;
  delivery_today: boolean;
  order_today: boolean;
  status?: number;
};

const useForm = () => {
  const query = useQueryUrl();

  const defaultValues: FormValue = {
    quote_order_flag: query.quote_order_flag,
    customer_distinction: query.customer_distinction,
    customer_name: query.customer_name,
    delivery_address: query.delivery_address,
    delivery_date_from: query.delivery_date_from,
    delivery_date_to: query.delivery_date_to,
    quotation_number_express: query.quotation_number_express || 'desc',
    product_name: query.product_name,
    internal_person: query.internal_person,
    order_date_from: query.order_date_from,
    order_date_to: query.order_date_to,
    selling_price_from: query.selling_price_from,
    selling_price_to: query.selling_price_to,
    order_number_from: query.order_number_from,
    order_number_to: query.order_number_to,
    finished_dimensions: query.finished_dimensions,
    customer: query.customer,
    weight_from: query.weight_from,
    weight_to: query.weight_to,
    maximum_search_count: query.maximum_search_count || '100',
    quotation_number_from: query.quotation_number_from,
    quotation_number_to: query.quotation_number_to,
    product_classification: query.product_classification,
    offset: query.offset || '30',
    delivery_today: query.delivery_today,
    order_today: query.order_today,
  };

  const schema = Yup.object().shape({
    delivery_date_from: Yup.date()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr)),
    delivery_date_to: Yup.date()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('delivery_date_from', (date_from, schema) => {
        return schema.test({
          test: function (date_to) {
            if (date_to && date_from) {
              if (dayjs(date_to).isBefore(dayjs(date_from))) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('quotation_page.delivery_date_error'),
                  }),
                  path: 'delivery_date_from',
                });
              }
            }
            return true;
          },
        });
      }),
    selling_price_from: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_0_TO_99999999999999,
        t('message.E000006', {
          0: t('quotation_page.selling_price_from'),
          1: t('quotation_page.half_width_number_0_to_99999999999999'),
        }),
      ),

    selling_price_to: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_0_TO_99999999999999,
        t('message.E000006', {
          0: t('quotation_page.selling_price_to'),
          1: t('quotation_page.half_width_number_0_to_99999999999999'),
        }),
      )
      .when('selling_price_from', (price_from, schema) => {
        return schema.test({
          test: function (price_to) {
            if (price_to && price_from) {
              if (Number(price_from) > Number(price_to)) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('quotation_page.selling_price_error'),
                  }),
                  path: 'selling_price_from',
                });
              }
            }
            return true;
          },
        });
      }),
    order_date_from: Yup.date()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr)),
    order_date_to: Yup.date()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('order_date_from', (date_from, schema) => {
        return schema.test({
          test: function (date_to) {
            if (date_to && date_from) {
              if (dayjs(date_to).isBefore(dayjs(date_from))) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('quotation_page.order_date_error'),
                    interpolation: {
                      escapeValue: false,
                    },
                  }),
                  path: 'order_date_from',
                });
              }
            }
            return true;
          },
        });
      }),
    quotation_number_from: Yup.string().nullable(true),
    quotation_number_to: Yup.string()
      .nullable(true)
      .when('quotation_number_from', (number_from, schema) => {
        return schema.test({
          test: function (number_to) {
            if (number_to && number_from) {
              if (Number(number_from) > Number(number_to)) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('quotation_page.quotation_number_error'),
                  }),
                  path: 'quotation_number_from',
                });
              }
            }
            return true;
          },
        });
      }),
    order_number_from: Yup.string().nullable(true),
    order_number_to: Yup.string()
      .nullable(true)
      .when('order_number_from', (number_from, schema) => {
        return schema.test({
          test: function (number_to) {
            if (number_to && number_from) {
              if (Number(number_from) > Number(number_to)) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('quotation_page.order_number_error'),
                  }),
                  path: 'order_number_from',
                });
              }
            }
            return true;
          },
        });
      }),
    finished_dimensions: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_DIMENSIONS,
        t('message.E000005', {
          0: t('quotation_page.finished_dimensions_error'),
        }),
      ),
    weight_from: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_0_TO_999999999,
        t('message.E000006', {
          0: t('quotation_page.weight_from'),
          1: t('quotation_page.half_width_number_0_to_999999999'),
        }),
      ),

    weight_to: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_0_TO_999999999,
        t('message.E000006', {
          0: t('quotation_page.weight_to'),
          1: t('quotation_page.half_width_number_0_to_999999999'),
        }),
      )
      .when('weight_from', (weight_from, schema) => {
        return schema.test({
          test: function (weight_to) {
            if (weight_to && weight_from) {
              if (Number(weight_from) > Number(weight_to)) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('quotation_page.weight_error'),
                  }),
                  path: 'weight_from',
                });
              }
            }
            return true;
          },
        });
      }),
  });
  const formInfo = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { ...formInfo };
};

export default useForm;
