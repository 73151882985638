import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { t } from 'src/libs/i18n';

export type FormValue = {
  delivery_name: string;
  address_code: number;
  shipping_address: string;
  phone_number: string;
};

const useForm = () => {
  const schema = Yup.object().shape({
    delivery_name: Yup.string().required(
      t('message.E000003', {
        0: t('customer_delivery_registration_modal.delivery_name'),
      }),
    ),
    address_code: Yup.string().required(
      t('message.E000003', {
        0: t('customer_delivery_registration_modal.delivery_address_code'),
      }),
    ),
    shipping_address: Yup.string().required(
      t('message.E000003', {
        0: t('customer_delivery_registration_modal.delivery_address'),
      }),
    ),
    phone_number: Yup.string().required(
      t('message.E000003', {
        0: t('customer_delivery_registration_modal.phone_number'),
      }),
    ),
  });

  const form = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  return { ...form };
};

export default useForm;
