import { useEffect } from 'react';
import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import useLanguage from 'src/queries/useLanguage';
import { DisplayCondition } from 'src/enums/notification';
import dayjs from 'dayjs';
import { REGEX } from 'src/constants/app';

export type rmOsiraseContentItem = {
  title: string;
  osirase: string;
  upload_file: string;
  fileName: string;
  dispFileName: string;
  gengoCd: string;
  langNm: string;
  seqno: number;
};

export type FormValue = {
  postedFrom: string;
  postedTo: string;
  hyoujiBasho: string[];
  postedFlg: string;
  rmOsiraseContents: rmOsiraseContentItem[];
};

const useForm = (id, queryNotificationSettingDetail) => {
  const { t } = useTranslation();
  const { data } = useLanguage();
  const schema = Yup.object().shape({
    postedTo: Yup.date()
      .required(
        t('message.E000003', { 0: t('notification_setting.publish_date_to') }),
      )
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('postedFrom', (from_date, schema) => {
        return schema.test({
          test: function (to_date) {
            if (to_date && from_date) {
              if (dayjs(to_date).isBefore(dayjs(from_date))) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('notification_setting.publish_date_from_to'),
                  }),
                  path: 'postedFrom',
                });
              }
            }
            return true;
          },
        });
      }),
    postedFrom: Yup.string().required(
      t('message.E000003', { 0: t('notification_setting.publish_date_from') }),
    ),
    hyoujiBasho: Yup.array()
      .min(
        1,
        t('message.E000008', {
          0: t('notification_setting.display_condition'),
        }),
      )
      .required(
        t('message.E000008', {
          0: t('notification_setting.display_condition'),
        }),
      ),
    rmOsiraseContents: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string().max(
            200,
            t('message.E000002', {
              0: t('notification_setting.title'),
              1: 200,
            }),
          ),
          upload_file: Yup.mixed()
            .test('fileSize', t('message.E000028', { size: 5 }), (file) => {
              if (
                typeof file[1] !== 'undefined' &&
                file[1] != null &&
                file[1] != ''
              ) {
                return (
                  file[0].size <= 5 * 1024 * 1024 ||
                  file[1].size <= 5 * 1024 * 1024
                );
              } else {
                return true;
              }
            })
            .test('fileSize', t('message.E000076'), (file) => {
              if (
                typeof file[1] !== 'undefined' &&
                file[1] != null &&
                file[1] != ''
              ) {
                return !(file[0].size === 0 || file[1].size === 0);
              } else {
                return true;
              }
            }),

          osirase: Yup.mixed().test(
            'maxLength',
            t('message.E000002', {
              0: t('notification_setting.text'),
              1: 2000,
            }),
            (value) => {
              if (typeof value !== 'undefined') {
                const length = value
                  .replace(REGEX.REGEX_CHARACTER_CKEDITOR, '')
                  .replace('&nbsp;', ' ').length;
                if (length > 2000) {
                  return false;
                }
                return true;
              } else {
                return false;
              }
            },
          ),
        }),
      )
      .test(
        'text',
        t('message.E000003', {
          0: t('notification_setting.title'),
        }),
        (rmOsiraseContents = [], { createError }) => {
          let checkAllrmOsiraseContentEmpty = 0;
          const errors = rmOsiraseContents
            .map((rmOsiraseContent, key) => {
              if (
                rmOsiraseContent.title === '' &&
                rmOsiraseContent.upload_file === '' &&
                rmOsiraseContent.osirase === ''
              ) {
                checkAllrmOsiraseContentEmpty++;
                if (
                  checkAllrmOsiraseContentEmpty === rmOsiraseContents.length
                ) {
                  return new ValidationError(
                    t('message.E000003', {
                      0: t('notification_setting.title'),
                    }),
                    rmOsiraseContent[key],
                    `rmOsiraseContents.0.title`,
                  );
                }
              }
              if (
                (rmOsiraseContent.title === '' &&
                  rmOsiraseContent.upload_file) ||
                (rmOsiraseContent.osirase && rmOsiraseContent.title === '')
              ) {
                return new ValidationError(
                  t('message.E000003', {
                    0: t('notification_setting.title'),
                  }),
                  rmOsiraseContent[key],
                  `rmOsiraseContents.${key}.title`,
                );
              }
            })
            .filter(Boolean);
          if (errors.length === 0) {
            return true;
          }

          return createError({
            message: () => errors,
          });
        },
      ),
  });

  const form = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onChange',
  });

  const fieldArray = useFieldArray({
    name: 'rmOsiraseContents',
    control: form.control,
  });

  const { fields, append, remove } = fieldArray;
  useEffect(() => {
    data?.forEach((item) => {
      append({
        title: '',
        osirase: '',
        upload_file: '',
        fileName: '',
        dispFileName: '',
        gengoCd: item.gengoCd,
        langNm: item.gengoNm,
      });
    });
    return () => {
      remove();
    };
  }, [data && data.length]);

  const formatValuehyoujiBasho = (value) => {
    switch (value) {
      case 1:
        return [DisplayCondition.LOGIN_PAGE];
      case 2:
        return [DisplayCondition.FRONT_SITE];
      case 3:
        return [DisplayCondition.LOGIN_PAGE, DisplayCondition.FRONT_SITE];
      default:
        return [DisplayCondition.LOGIN_PAGE];
    }
  };

  useEffect(() => {
    if (id) {
      // @ts-ignore
      const dataEditNotification: Response.rmOsiraseContentItemDetail =
        queryNotificationSettingDetail?.data?.data?.item;
      form.setValue('postedFlg', dataEditNotification?.postedFlg ?? '0');
      form.setValue('postedFrom', dataEditNotification?.postedFrom);
      form.setValue(
        'hyoujiBasho',
        formatValuehyoujiBasho(dataEditNotification?.hyoujiBasho),
      );
      form.setValue('postedTo', dataEditNotification?.postedTo);
      dataEditNotification?.rmOsiraseContents &&
        dataEditNotification?.rmOsiraseContents.map((rmOsiraseContent, key) => {
          const indexAppend = fields.findIndex(
            (item) => item.gengoCd == rmOsiraseContent.gengoCd,
          );
          form.setValue(
            `rmOsiraseContents.${indexAppend}.title`,
            rmOsiraseContent?.title,
          );
          form.setValue(
            `rmOsiraseContents.${indexAppend}.seqno`,
            rmOsiraseContent?.seqno,
          );
          form.setValue(
            `rmOsiraseContents.${indexAppend}.osirase`,
            rmOsiraseContent?.osirase,
          );
          form.setValue(
            `rmOsiraseContents.${indexAppend}.fileName`,
            rmOsiraseContent?.fileName,
          );
          form.setValue(
            `rmOsiraseContents.${indexAppend}.dispFileName`,
            rmOsiraseContent?.dispFileName,
          );
        });
    }
  }, [id, JSON.stringify(queryNotificationSettingDetail), fields]);

  return {
    ...form,
    fieldArray,
  };
};

export default useForm;
