import { Col, Divider, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/form';
import {
  HASUTANI_TYPE,
  MARUME_HOHO,
} from 'src/constants/optionSelect/profitMarginSetting';
import useProfitMarginSetting from 'src/queries/useProfitMarginSetting';
// import { handleUnitPrices } from 'src/utils/helper';
import { FormValue } from '../useForm';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
};

const Profit: React.FC<Props> = ({ formInfo }) => {
  const { t } = useTranslation();
  const { control, setValue } = formInfo;
  const { queryInitDataProfitMargin } = useProfitMarginSetting({});

  const dataMinimumGrossRes =
    queryInitDataProfitMargin?.data?.data?.item?.minimumGrossRes;

  useEffect(() => {
    if (dataMinimumGrossRes) {
      // if (dataMinimumGrossRes?.saiteiTankaRes?.saiteTanka === 0) {
      //   setValue('minimum_gross_profit', null);
      // } else {
      //   setValue(
      //     'minimum_gross_profit',
      //     dataMinimumGrossRes?.saiteiTankaRes?.saiteTanka,
      //   );
      // }
      // setValue(
      //   'saitei_tanka_seqno',
      //   dataMinimumGrossRes?.saiteiTankaRes?.seqno,
      // );
      setValue('hasu_settei_seqno', dataMinimumGrossRes?.hasuSetteiRes.seqno);
      setValue(
        'last_digit',
        dataMinimumGrossRes?.hasuSetteiRes?.hasuTani ||
          HASUTANI_TYPE(t)[0].value,
      );
      setValue(
        'round_down',
        dataMinimumGrossRes?.hasuSetteiRes?.marumeHoho ||
          MARUME_HOHO(t)[0].value,
      );
    }
  }, [dataMinimumGrossRes]);

  return (
    <div className="profit">
      {/* <Row className="profit_row">
        <Col xs={24} md={18} xl={18} className="profit_title">
          <Typography.Title level={5}>
            {t('profit_margin_setting.minimum_gross_profit')}
          </Typography.Title>
        </Col>
        <Col xs={24} md={6} xl={6} className="profit_input">
          <NumberOnlyField<FormValue>
            controller={{
              control,
              name: 'minimum_gross_profit',
            }}
            suffix={handleUnitPrices(
              dataMinimumGrossRes?.saiteiTankaRes?.currentKawase,
            )}
            isOnlyInteger
          />
          <Typography.Text className="profit_note">
            {t('profit_margin_setting.standard_yen')}
          </Typography.Text>
        </Col>
      </Row> */}
      <Row className="profit_row">
        <Col xs={24} md={12} xl={12} className="profit_title">
          <Typography.Title level={5}>
            {t('profit_margin_setting.fraction_setting_of_sales_price')}
          </Typography.Title>
        </Col>
        <Col xs={24} md={6} xl={6} className="profit_select">
          <SelectField<FormValue>
            controller={{
              control,
              name: 'last_digit',
            }}
            selectProps={{
              options: HASUTANI_TYPE(t),
            }}
          />
        </Col>
        <Col xs={24} md={6} xl={6} className="profit_select">
          <SelectField<FormValue>
            controller={{
              control,
              name: 'round_down',
            }}
            selectProps={{
              options: MARUME_HOHO(t),
            }}
          />
        </Col>
      </Row>
      <Divider className="profit_divider" />
    </div>
  );
};

export default Profit;
