import { Button, Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  InputField,
  CheckboxGroupField,
  NumberOnlyField,
  PhoneNumberField,
  SelectField,
} from 'src/components/form';
import { FormValue } from '../useForm';
import { Modal } from 'src/components/custom';
import { InternalPICModal } from 'src/components/common';
import useCustomers from 'src/queries/useCustomers';
import useCustomerCurrency from 'src/queries/useCustomerCurrency';
import useOccupationAndLocation from 'src/queries/useOccupationAndLocation';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
};

const RegisterInfo: React.FC<Props> = ({ formInfo }) => {
  const { t } = useTranslation();
  const { control, setValue } = formInfo;
  const { data } = useCustomers();
  const { currency } = useCustomerCurrency();
  const { dataLocation, dataOccupation } = useOccupationAndLocation();

  useEffect(() => {
    dataOccupation && setValue('job_id', 1); //Initial default: gyoushu_list.seqno = 1
  }, [dataOccupation]);

  useEffect(() => {
    dataLocation && setValue('region_id', 1); //Initial default: shozaichi_list.seqno = 1
  }, [dataLocation]);

  return (
    <div className="infomation_input">
      <Row>
        <Col xs={24} md={24} xl={24}>
          <InputField
            controller={{ control, name: 'customer_org_name' }}
            inputProps={{
              placeholder: t('customer_registration.customer_org_name'),
              maxLength: 90,
            }}
            label={t('customer_registration.customer_org_name')}
            labelCol={{ xs: 24, md: 7, xl: 4 }}
            wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            required
          />
        </Col>
        <Col xs={24} md={24} xl={24}>
          <PhoneNumberField
            controller={{ control, name: 'customer_address_code' }}
            inputProps={{
              placeholder: t('customer_registration.customer_address_code'),
              maxLength: 10,
            }}
            label={t('customer_registration.customer_address_code')}
            labelCol={{ xs: 24, md: 7, xl: 4 }}
            wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            required
          />
        </Col>
        <Col xs={24} md={24} xl={24}>
          <InputField
            controller={{ control, name: 'customer_address' }}
            inputProps={{
              placeholder: t('customer_registration.customer_address'),
              maxLength: 100,
            }}
            label={t('customer_registration.customer_address')}
            labelCol={{ xs: 24, md: 7, xl: 4 }}
            wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            required
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={11} md={14} xl={12}>
          <PhoneNumberField
            controller={{ control, name: 'customer_phone' }}
            inputProps={{
              placeholder: t('customer_registration.customer_phone'),
              maxLength: 13,
            }}
            label={t('customer_registration.customer_phone')}
            labelCol={{ xs: 24, md: 12, xl: 8 }}
            wrapperCol={{ xs: 24, md: 11, xl: 16 }}
            required
          />
        </Col>
        <Col xs={11} md={10} xl={10}>
          <PhoneNumberField
            controller={{ control, name: 'customer_fax' }}
            inputProps={{
              placeholder: t('customer_registration.customer_fax'),
              maxLength: 13,
            }}
            label={t('customer_registration.customer_fax')}
            labelCol={{ xs: 24, md: 6, xl: 6 }}
            wrapperCol={{ xs: 24, md: 18, xl: 18 }}
          />
        </Col>
      </Row>
      <Row className="internal_pic">
        <Col xs={24} md={7} xl={4}>
          <Typography.Title level={5} className="form_label_required">
            {t('customer_registration.customer_staff')}
          </Typography.Title>
        </Col>
        <Col xs={12} md={11} lg={12} xl={15}>
          <InputField
            controller={{ control, name: 'customer_staff' }}
            inputProps={{
              placeholder: t('customer_registration.customer_staff'),
              maxLength: 30,
            }}
            required
          />
        </Col>
        <Col
          xs={{ span: 11, offset: 1 }}
          md={{ span: 5, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
          xl={{ span: 4, offset: 1 }}
          className="internal_pic_modal"
        >
          <Modal
            modalProps={{ width: 840 }}
            title={t('internal_pic.title')}
            content={({ onClose }) => (
              <InternalPICModal
                onClose={onClose}
                onSelected={(value) => setValue('customer_staff', value?.name)}
                screenId="R1512"
                kokyakuCd="2"
              />
            )}
          >
            <Button type="primary" className="w-full internal_pic_modal_button">
              {t('customer_registration.customer_staff_button')}
            </Button>
          </Modal>
        </Col>
      </Row>
      {data?.tokuteiKokyakuKbn && (
        <Row>
          <Col xs={24} md={24} xl={24}>
            <CheckboxGroupField
              label={t('customer_registration.customer_type')}
              controller={{ name: 'customer_type', control }}
              checkboxGroupProps={{
                options: [{ label: data?.tokuteiKokyakuKbn, value: '1' }],
              }}
              labelCol={{ xs: 13, md: 7, xl: 4 }}
              wrapperCol={{ xs: 11, md: 17, xl: 20 }}
              className="input_any"
            />
          </Col>
        </Row>
      )}
      <Row className="job_and_region">
        <Col xs={24} md={7} xl={4}>
          <Typography.Title level={5} className="form_label_required">
            {t('customer_registration.customer_category')}
          </Typography.Title>
        </Col>
        <Col xs={11} md={8} xl={8}>
          <SelectField<FormValue>
            controller={{
              control,
              name: `job_id`,
            }}
            selectProps={{
              options: dataOccupation,
              placeholder: t('customer_registration.industry_classification'),
            }}
            label={t('customer_registration.industry_classification')}
            labelCol={{ xs: 24, md: 11, xl: 10 }}
            wrapperCol={{ xs: 24, md: 13, xl: 14 }}
            className="auto_field"
          />
        </Col>
        <Col
          xs={{ span: 11, offset: 2 }}
          md={{ span: 8, offset: 1 }}
          xl={{ span: 8, offset: 1 }}
        >
          <SelectField<FormValue>
            controller={{
              control,
              name: `region_id`,
            }}
            selectProps={{
              options: dataLocation,
              placeholder: t('customer_registration.region'),
            }}
            label={t('customer_registration.region')}
            labelCol={{ xs: 24, md: 7, xl: 6 }}
            wrapperCol={{ xs: 24, md: 17, xl: 16 }}
            className="auto_field"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={21} md={21} xl={12}>
          <NumberOnlyField
            controller={{ control, name: 'order_limit_price' }}
            inputProps={{
              placeholder: t('customer_registration.order_limit_price'),
              maxLength: 14,
            }}
            label={t('customer_registration.order_limit_price')}
            labelCol={{ xs: 24, md: 8, xl: 8 }}
            wrapperCol={{ xs: 24, md: 16, xl: 16 }}
            className="input_any"
          />
        </Col>
        <Col xs={3} md={2} xl={1} className="price_unit">
          <Typography.Paragraph>{`(${currency?.currentKawase})`}</Typography.Paragraph>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} xl={24}>
          <InputField
            controller={{ control, name: 'order_condition' }}
            inputProps={{
              placeholder: t('customer_registration.order_condition'),
              maxLength: 50,
            }}
            label={t('customer_registration.order_condition')}
            labelCol={{ xs: 24, md: 7, xl: 4 }}
            wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            className="input_any"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} xl={24}>
          <InputField
            controller={{ control, name: 'note' }}
            inputProps={{
              placeholder: t('customer_registration.note'),
              maxLength: 90,
            }}
            label={t('customer_registration.note')}
            labelCol={{ xs: 24, md: 7, xl: 4 }}
            wrapperCol={{ xs: 24, md: 17, xl: 20 }}
            className="input_any"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RegisterInfo;
