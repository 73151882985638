import { useState } from 'react';

const useSelectRow = () => {
  const [selectedRecord, setSelectedRecord] = useState<number[]>([]);
  const [selectedSeqno, setSelectedSeqno] = useState<number[]>([]);

  const handleSelectRow = (event, index, seqno) => {
    if (event.target.checked && !selectedRecord.includes(index)) {
      setSelectedRecord([...selectedRecord, index]);
      if (seqno && !selectedSeqno.includes(seqno)) {
        setSelectedSeqno([...selectedSeqno, seqno]);
      }
    }
    if (!event.target.checked) {
      setSelectedRecord(
        selectedRecord.filter((i) => {
          return i !== index;
        }),
      );
      setSelectedSeqno(
        selectedSeqno.filter((i) => {
          return i !== seqno;
        }),
      );
    }
  };

  return {
    handleSelectRow,
    selectedRecord,
    setSelectedRecord,
    selectedSeqno,
    setSelectedSeqno,
  };
};

export default useSelectRow;
