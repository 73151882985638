import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getCompanyInfo = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const updateCompanyInfo = (url, data: Payload.CreateAndUpdateCompanyInfo) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const createCompanyInfo = (url, data: Payload.CreateAndUpdateCompanyInfo) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const createEmployeeInfo = (url, data: Payload.EmployeeEntity) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const updateEmployeeInfo = (url, data: Payload.EmployeeEntity) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const deleteEmployeeInfo = (url) => {
  return axios({
    method: 'delete',
    url,
  });
};

const getApiList = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

export default {
  getCompanyInfo,
  updateCompanyInfo,
  createCompanyInfo,
  createEmployeeInfo,
  updateEmployeeInfo,
  deleteEmployeeInfo,
  getApiList,
};
