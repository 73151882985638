import React, { useState } from 'react';
import { Row, Col, Button, Typography, Divider, Tabs, Form } from 'antd';
import useForm from './useForm';
import { t } from 'src/libs/i18n';
import { FormValue } from './useForm';
import { QueryCache } from '@tanstack/react-query';

import {
  CheckboxGroupField,
  DateRangePickerField,
  RadioGroupField,
} from 'src/components/form';
import {
  DISPLAY_CONDITION,
  PUBLISH_SETTING,
} from 'src/constants/optionSelect/notification';
import useCreateNotification from 'src/queries/useCreateNotification';
import { useDetailNotificationSetting } from 'src/queries/useDetailNotification';
import LanguageItem from './LanguageItem';
import useUpdateNotification from 'src/queries/useUpdateNotification';
import { DisplayCondition } from 'src/enums/notification';
import { formatFileName } from 'src/utils/helper';
import LocalStorage from 'src/utils/LocalStorage';

type Props = {
  onClose: () => void;
  id: number;
  mode: 'CREATE' | 'UPDATE';
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  screenId?: string;
};
const { TabPane } = Tabs;

const ModalNotification: React.FC<Props> = ({
  onClose,
  id,
  mode,
  setOpenModal,
}) => {
  const { queryNotificationSettingDetail } = useDetailNotificationSetting(
    id,
    setOpenModal,
  );
  const queryCache = new QueryCache();
  const { control, handleSubmit, setError, fieldArray, watch } = useForm(
    id,
    queryNotificationSettingDetail,
  );
  const { fields } = fieldArray;

  const handleSubmitForm = (val) => {
    const valueForm = formatData(val);
    if (mode === 'CREATE') {
      mutation.mutate(valueForm);
    } else {
      mutationUpdate.mutate(valueForm);
      queryCache.clear();
    }
    onClose();
  };

  const formatData = (value) => {
    let hyoujiBasho = DisplayCondition.LOGIN_PAGE;
    if (value?.hyoujiBasho.length == 2) {
      hyoujiBasho = DisplayCondition.BOTH;
    } else {
      hyoujiBasho =
        value?.hyoujiBasho[0] == DisplayCondition.LOGIN_PAGE
          ? DisplayCondition.LOGIN_PAGE
          : DisplayCondition.FRONT_SITE;
    }
    const rmOsiraseContents = formatRmOsiraseContents(value);
    const postedFrom = checkFormatDate(value?.postedFrom)
      ? value?.postedFrom?.concat(' 00:00:00')
      : value?.postedFrom;
    const postedTo = formatDateTo(value?.postedTo);

    return { ...value, hyoujiBasho, rmOsiraseContents, postedFrom, postedTo };
  };

  const formatDateTo = (dateTo) => {
    const date = new Date(dateTo),
      month = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return dateTo?.length === 19
      ? dateTo
      : [date.getFullYear(), month, day].join('/').concat(' 23:59:59');
  };

  const checkFormatDate = (date) => {
    return date?.length === 10;
  };

  const formatRmOsiraseContents = (val) => {
    const rmOsiraseContents = val?.rmOsiraseContents.filter(function (item) {
      return item?.title !== '';
    });

    return rmOsiraseContents.map(function (item) {
      const fileName = item?.upload_file[1]?.id
        ? formatFileName(item?.upload_file[1]?.id)
        : formatFileName(item?.fileName);
      const dispFileName =
        item?.upload_file[1]?.displayName ?? item?.dispFileName;

      return { ...item, fileName, dispFileName };
    });
  };

  const mutation = useCreateNotification({ setError });
  const mutationUpdate = useUpdateNotification({
    setError,
    id: id,
  });

  const notificationDetailData =
    queryNotificationSettingDetail?.data?.data?.item;

  const [activeTabKey, setActiveTabKey] = useState<string>(LocalStorage.lang);

  const onChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <>
      <div className="p-20 modal-notification">
        <Form onFinish={handleSubmit((val) => handleSubmitForm(val))}>
          <Row gutter={[0, 24]}>
            <Col lg={16} xs={24} sm={24}>
              <Row gutter={[0, 10]}>
                <Col xs={24} sm={24} md={24} lg={24} className="group-input">
                  <CheckboxGroupField<FormValue>
                    className="mb-0"
                    label={t('notification_setting.display_conditions')}
                    controller={{
                      name: 'hyoujiBasho',
                      control,
                    }}
                    checkboxGroupProps={{
                      options: DISPLAY_CONDITION(t),
                      defaultValue: [DisplayCondition.LOGIN_PAGE],
                    }}
                    labelCol={{ xs: 24, md: 5 }}
                    wrapperCol={{ xs: 24, md: 19 }}
                    required
                  />
                </Col>
                <Col xs={24} sm={24} className="group-label mt-10">
                  <DateRangePickerField<FormValue>
                    controllerFrom={{
                      control,
                      name: 'postedFrom',
                    }}
                    label={t('notification_setting.publish_date_period')}
                    controllerTo={{
                      control,
                      name: 'postedTo',
                    }}
                    datePickerPropsFrom={{
                      placeholder: t('start'),
                    }}
                    datePickerPropsTo={{
                      placeholder: t('end'),
                    }}
                    labelCol={{ xs: 24, md: 5 }}
                    wrapperCol={{ xs: 24, md: 15 }}
                    required
                  />
                </Col>
                <Col xs={24} sm={24}>
                  <Row gutter={[0, 6]} align="middle">
                    <Col
                      xs={24}
                      sm={24}
                      lg={24}
                      className="group-input input_tag"
                    >
                      <RadioGroupField<FormValue>
                        label={t('notification_setting.publishing_settings')}
                        controller={{
                          name: 'postedFlg',
                          control,
                          defaultValue: '1',
                        }}
                        radioGroupProps={{
                          options: PUBLISH_SETTING(t),
                        }}
                        labelCol={{ xs: 24, md: 5 }}
                        wrapperCol={{ xs: 24, md: 19 }}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={8} sm={24} className="introduction">
              {t('notification_setting.introduction_1')}
              <br />
              <br />
              {t('notification_setting.introduction_2')}
            </Col>
          </Row>
          <Row className="mt-50">
            <Col xl={24} sm={24} span={24}>
              <Tabs
                tabBarGutter={0}
                tabBarExtraContent={{
                  left: (
                    <Typography.Text className="label_language title">
                      {t('notification_setting.language')}
                    </Typography.Text>
                  ),
                }}
                activeKey={activeTabKey}
                onChange={onChange}
                tabPosition={'top'}
              >
                {fields &&
                  fields?.map((language, index) => {
                    const notificationItem: any =
                      (notificationDetailData &&
                        notificationDetailData.rmOsiraseContents.filter(
                          (item) => item.gengoCd == language.gengoCd,
                        )[0]) ||
                      {};
                    return (
                      <TabPane tab={language.langNm} key={language.gengoCd}>
                        <LanguageItem
                          control={control}
                          index={index}
                          data={notificationItem}
                          id={id}
                          hyoujiBashoWatch={watch('hyoujiBasho')}
                        />
                      </TabPane>
                    );
                  })}
              </Tabs>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Divider className="mb-30" />
            <Button
              loading={mutation?.isLoading || mutationUpdate?.isLoading}
              className="button_submit_notification"
              htmlType="submit"
              type="primary"
            >
              {t('notification_setting.submit')}
            </Button>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ModalNotification;
