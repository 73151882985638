import { Button, Col, Form, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField, RadioGroupField } from 'src/components/form';
import modalConfirm from 'src/utils/modalConfirm';
import useForm, { FormArrValue } from './useForm';
import useBasicSetting from 'src/queries/useBasicSetting';
import { EMPLOYEE_INFO_DATA_ROLE } from 'src/constants/optionSelect/userRole';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import LocalStorage from 'src/utils/LocalStorage';

type Props = {
  employeeData: any;
};

const PersonResponsible: React.FC<Props> = ({ employeeData }) => {
  const [currentUser, setCurrentUser] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const {
    companyInfoData,
    fieldArray,
    control,
    setError,
    getValues,
    formState: { errors },
  } = useForm();
  const { fields, remove } = fieldArray;
  const { createEmployeeInfo, updateEmployeeInfo, deleteEmployeeInfo } =
    useBasicSetting({
      currentUser,
      setError,
    });

  const handleSave = (item, index) => {
    setLoadingBtn(undefined);
    //Validation form
    setCurrentUser(index);
    if (getValues(`responsible_person.${index}.name`) === '') {
      setError(`responsible_person.${index}.name`, {
        type: 'custom',
        message: t('message.E000003', {
          0: t('basic_setting.responsible_person'),
        }),
      });
    }
    if (getValues(`responsible_person.${index}.email`) === '') {
      setError(`responsible_person.${index}.email`, {
        type: 'custom',
        message: t('message.E000003', {
          0: t('basic_setting.email'),
        }),
      });
    }
    //
    const checkValidation = (i: number) => {
      if (errors?.responsible_person && errors?.responsible_person[i]) {
        return false;
      }
      return true;
    };

    const getObj = getValues().responsible_person[index];
    const responsiblePersonObj = {
      seqno: item?.seqno,
      userCd: item?.userCd,
      name: getObj?.name || item?.name,
      email: getObj?.email || item?.email,
      kengen: getObj?.kengen || item?.kengen || 1,
      gengoCd: LocalStorage.lang,
    };

    if (item?.action === 'save' && employeeData.length < 20) {
      if (getObj.name && getObj.email && checkValidation(index)) {
        createEmployeeInfo.mutate(responsiblePersonObj);
        setLoadingBtn(index);
      }
    } else if (item?.action === 'update') {
      if (getObj.name && getObj.email && checkValidation(index)) {
        updateEmployeeInfo.mutate(responsiblePersonObj);
        setLoadingBtn(index);
      }
    }
  };

  const handleDelete = (item, index) => {
    modalConfirm({
      t,
      title: t('basic_setting.message_confirm_delete'),
      okText: t('basic_setting.button_confirm'),
      cancelText: t('basic_setting.button_cancel'),
      onOk: () => {
        if (employeeData?.length > 1) {
          remove(index);
          deleteEmployeeInfo.mutate(item.seqno);
        }
      },
    });
  };

  useEffect(() => {
    const dataLength = employeeData?.length;
    if (dataLength < 20) {
      fieldArray.append(
        {
          name: '',
          email: '',
          kengen: '1',
          action: 'save',
        },
        { shouldFocus: false },
      );
    }
  }, [employeeData || fields]);

  return (
    <div className="form_person">
      {fields?.map((item, index) => {
        const condition =
          index < fields?.length - 1 || companyInfoData?.length === 20;
        return (
          <React.Fragment key={item.id}>
            <Form className="form_wrapper">
              <Row gutter={{ xs: 0, md: 15, lg: 15, xl: 20 }}>
                <Col
                  xs={24}
                  md={24}
                  lg={4}
                  xl={4}
                  xxl={4}
                  className="form_label_wrapper"
                >
                  <Typography.Title level={5} className="form_label">
                    {index === 0
                      ? t('basic_setting.responsible_person_name')
                      : ' '}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={6} lg={6} xl={4} xxl={4}>
                  {condition && (
                    <Row
                      gutter={{ xs: 0, md: 0, lg: 15, xl: 0 }}
                      className={
                        index === 0
                          ? 'form_responsible_person form_person_required'
                          : 'form_responsible_person'
                      }
                    >
                      <Col xs={6} md={9} lg={11} xl={9} xxl={11}>
                        <Typography.Title level={5} className="form_label">
                          {`${index + 1})`}
                        </Typography.Title>
                      </Col>
                      <Col xs={18} md={15} lg={13} xl={15} xxl={13}>
                        <InputField<FormArrValue>
                          controller={{
                            control: control,
                            name: `responsible_person.${index}.userCd`,
                          }}
                          disabled
                          className="field_disabled"
                          style={{ marginBottom: '16px' }}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col
                  xs={{ span: 18, offset: 6 }}
                  md={{ span: 8, offset: 0 }}
                  lg={5}
                  xl={4}
                  xxl={4}
                  className="form-name"
                >
                  <InputField<FormArrValue>
                    controller={{
                      control: control,
                      name: `responsible_person.${index}.name`,
                    }}
                    inputProps={{
                      placeholder: t('basic_setting.responsible_person_name'),
                      maxLength: 30,
                    }}
                    style={{ marginBottom: '16px' }}
                    required
                  />
                </Col>
                <Col
                  xs={{ span: 18, offset: 6 }}
                  md={{ span: 10, offset: 0 }}
                  lg={9}
                  xl={5}
                  xxl={5}
                  className="form_email"
                >
                  <InputField<FormArrValue>
                    controller={{
                      control: control,
                      name: `responsible_person.${index}.email`,
                    }}
                    inputProps={{
                      placeholder: t('basic_setting.email'),
                      maxLength: 80,
                    }}
                    style={{ marginBottom: '16px' }}
                    required
                  />
                </Col>
                <Col
                  xs={24}
                  md={9}
                  lg={12}
                  xl={4}
                  xxl={4}
                  className="form_checkbox_group"
                >
                  <RadioGroupField<FormArrValue>
                    controller={{
                      name: `responsible_person.${index}.kengen`,
                      control: control,
                      defaultValue: '1',
                    }}
                    radioGroupProps={{
                      options: EMPLOYEE_INFO_DATA_ROLE(t),
                    }}
                    required
                  />
                </Col>
                <Col
                  xs={{ span: 18, offset: 6 }}
                  md={{ span: 9, offset: 0 }}
                  lg={3}
                  xl={3}
                  xxl={3}
                >
                  <Row gutter={20}>
                    <Col>
                      <Button
                        icon={<SaveOutlined />}
                        danger
                        onClick={() => {
                          setCurrentUser(index);
                          setTimeout(() => {
                            handleSave(item, index);
                          }, 100);
                        }}
                        loading={
                          index === loadingBtn &&
                          (createEmployeeInfo.isLoading ||
                            updateEmployeeInfo.isLoading)
                        }
                      />
                    </Col>
                    <Col>
                      {condition && (
                        <Button
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() => handleDelete(item, index)}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default PersonResponsible;
