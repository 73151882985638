import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { PATH as PATH_CUSTOMER_SETTING } from 'src/pages/customerSetting';
import { PATH as PATH_CUSTOMER_REGISTRATION } from 'src/pages/customerRegistration';
import { PATH as PATH_USER_REGISTRATION_CHANGE } from 'src/pages/userRegistrationChange';
import { PATH as PATH_CUSTOMER_DELIVERY_SETTING } from 'src/pages/customerDeliverySetting';
import { PATH as PROFIT_MARGIN_SETTING } from 'src/pages/profitMarginSetting';
import LocalStorage from 'src/utils/LocalStorage';
import { ADMIN_ROLES } from 'src/constants/app';
import useCurrentAccount from 'src/queries/useCurrentAccount';

const useMenu = (type: string) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getSelectedKey = () => {
    const key = location.pathname.split('/')[1];
    // active main menu when access sub menu
    if (
      `/${key}` === PATH_CUSTOMER_REGISTRATION ||
      `/${key}` === PATH_CUSTOMER_DELIVERY_SETTING ||
      `/${key}` === PATH_USER_REGISTRATION_CHANGE ||
      `/${key}` === PROFIT_MARGIN_SETTING
    ) {
      return [`${PATH_CUSTOMER_SETTING}`];
    }
    return [`/${key}`];
  };

  // disable some path when role user is view only
  const [isOnlyView, setIsOnlyView] = useState(false);
  const { role } = useCurrentAccount();

  useEffect(() => {
    role && LocalStorage.saveUserRoles([role]);
    const isOnlyView = role === ADMIN_ROLES.viewAdmin;
    setIsOnlyView(isOnlyView);
  }, [role]);

  const menuItems: ItemType[] = [
    {
      key: '/',
      label: <Link to={'/'}>{t('menu_home.quotation_order_history')}</Link>,
    },
    {
      key: '/basic_setting',
      label: <Link to={'/basic_setting'}>{t('menu_home.basic_setting')}</Link>,
      disabled: isOnlyView,
    },
    {
      key: '/notification_setting',
      label: (
        <Link to={'/notification_setting'}>
          {t('menu_home.notification_setting')}
        </Link>
      ),
      disabled: isOnlyView,
    },
    {
      key: '/customer_setting',
      label: (
        <Link to={'/customer_setting'}>{t('menu_home.customer_setting')}</Link>
      ),
      disabled: isOnlyView,
    },
    {
      key: '/tax_exchange_setting',
      label: (
        <Link to={'/tax_exchange_setting'}>
          {t('menu_home.tax_exchange_setting')}
        </Link>
      ),
      disabled: isOnlyView,
    },
    {
      key: '/master_registration',
      label: (
        <Link to={'/master_registration'}>
          {t('menu_home.master_registration')}
        </Link>
      ),
      disabled: isOnlyView,
    },
    {
      key: '/pdf_registration',
      label: (
        <Link to={'/pdf_registration'}>{t('menu_home.pdf_registration')}</Link>
      ),
      disabled: isOnlyView,
    },
    {
      key: '/plugin_registration',
      label: (
        <Link to={'/plugin_registration'}>
          {t('menu_home.plugin_registration')}
        </Link>
      ),
      disabled: isOnlyView,
    },
  ];
  return {
    menuItems,
    getSelectedKey,
  };
};

export default useMenu;
