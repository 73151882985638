import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';

import ConfirmOTP from './ComfirmOTP';
import ResetPass from './ResetPass';
import useLogo from 'src/queries/useLogo';
import history from 'src/libs/history';
import { PATH } from 'src/pages/auth';
import { ENV } from 'src/constants/app';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [resetToken, setResetToken] = useState(false);
  const { data } = useLogo();

  const onConfirmOTP = (data) => {
    setIsVerified(true);
    setEmail(data.email);
    setResetToken(data.resetToken);
  };

  const handleRedirectToLogin = () => {
    history.push(PATH.SIGN_IN);
  };

  return (
    <React.Fragment>
      <Row justify="center" align="middle">
        <div className="reset_pass">
          <Row justify="center">
            <Col
              span={18}
              className="text-center"
              onClick={handleRedirectToLogin}
            >
              {data?.ecsiteLogoDir && (
                <Typography.Link href={ENV.FRONTSITE_URL}>
                  <img
                    src={data?.ecsiteLogoDir}
                    alt="logo"
                    className="reset_pass_logo"
                  />
                </Typography.Link>
              )}
            </Col>
          </Row>
          {!isVerified ? (
            <ConfirmOTP onConfirmOTP={onConfirmOTP} />
          ) : (
            <ResetPass resetToken={resetToken} email={email} />
          )}
        </div>
      </Row>
    </React.Fragment>
  );
};

export default ResetPassword;
