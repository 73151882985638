import { Button, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';

type Props = {
  onClick: () => void;
  waitSeconds: number;
  label: string;
  icon?: React.ReactNode;
  index?: number;
  currentButton?: number;
  isSuccess?: boolean;
  disable?: boolean;
};

const CountDownButton: React.FC<Props> = ({
  onClick,
  waitSeconds,
  label,
  icon,
  index,
  currentButton,
  isSuccess,
  disable,
}) => {
  const [isCountDown, setIsCountDown] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (isSuccess && index === currentButton) {
      setCountdown(Date.now() + 1000 * waitSeconds);
      setIsCountDown(true);
    }
  }, [isSuccess]);

  const onFinishCountDown = () => {
    setIsCountDown(false);
  };

  return (
    <Button
      className="button_countdown w-full"
      icon={isCountDown ? undefined : icon}
      onClick={onClick}
      disabled={disable || isCountDown}
    >
      {isCountDown ? (
        <Statistic.Countdown
          value={countdown}
          format={'ss'}
          onFinish={onFinishCountDown}
        />
      ) : (
        label
      )}
    </Button>
  );
};

export default CountDownButton;
