import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ImageUploadField,
  InputField,
  NumberOnlyField,
  RadioGroupField,
  TextAreaField,
  SelectField,
} from 'src/components/form';
import PhoneNumberField from 'src/components/form/PhoneNumberField';
import {
  DATA_NOTI_EMAIL,
  PDF_VERSION,
  SUGGESTION_CONDITION,
} from 'src/constants/optionSelect/basicSetting';
import useBasicSetting from 'src/queries/useBasicSetting';
import BottomNote from './BottomNote';
import PersonResponsible from './PersonResponsible';
import TopNote from './TopNote';
import useForm, { FormValue } from './useForm';
import { checkImageExt } from 'src/utils/helper';

const BasicSetting: React.FC = () => {
  const { t } = useTranslation();
  const { form } = useForm();
  const { control, handleSubmit, setValue, setError } = form;
  const {
    queryCompanyInfo,
    updateCompanyInfo,
    createCompanyInfo,
    dataApiList,
  } = useBasicSetting({ setError });

  const handleSubmitForm = async (value) => {
    const logoTypeValid = await checkImageExt(value.logo_img?.[0]);
    const qrTypeValid = await checkImageExt(value.qr_img?.[0]);

    let payload = {
      seqno: dataCompanyInfo?.seqno,
      netSbsName: value.front_site_name,
      kaisham: value.company_name,
      telno: value.phone_number,
      faxno: value.fax_number,
      jusho: value.address,
      eigyouJikanFrom: value.business_hours_start,
      eigyouJikanTo: value.business_hours_end,
      tokuteiKokyakuKbn: value.specific_customer,
      mailNoticFlg: value.notification_email,
      ecsiteLogoDir:
        (value.logo_img && logoTypeValid && value.logo_img[1].url) ||
        dataCompanyInfo?.ecsiteLogoDir,
      qrcodeDir:
        (value.qr_img && qrTypeValid && value.qr_img[1].url) ||
        dataCompanyInfo?.qrcodeDir,
      mitrYukoKigenb: parseInt(value.estimated_expiration_date),
      suggestYoukenType: value.suggestion_condition,
      seqnoApiList: value.preferred_suppliers,
      estPdfLayoutFlg: value.pdf_version,
      descriptioForQuotation: value.description,
    };
    if (dataCompanyInfo?.seqno) {
      updateCompanyInfo.mutate(payload);
    } else {
      createCompanyInfo.mutate(payload);
    }
  };
  const dataCompanyInfo = queryCompanyInfo?.data?.data.item;

  useEffect(() => {
    if (dataCompanyInfo) {
      setValue('front_site_name', dataCompanyInfo.netSbsName);
      setValue('company_name', dataCompanyInfo.kaisham);
      setValue('phone_number', dataCompanyInfo.telno);
      setValue('fax_number', dataCompanyInfo.faxno);
      setValue('address', dataCompanyInfo.jusho);
      setValue('business_hours_start', dataCompanyInfo.eigyouJikanFrom);
      setValue('business_hours_end', dataCompanyInfo.eigyouJikanTo);
      setValue('estimated_expiration_date', dataCompanyInfo.mitrYukoKigenb);
      setValue('specific_customer', dataCompanyInfo.tokuteiKokyakuKbn);
      setValue('notification_email', dataCompanyInfo.mailNoticFlg);
      setValue(
        'suggestion_condition',
        dataCompanyInfo.suggestYoukenType || SUGGESTION_CONDITION[0].value,
      );
      setValue('preferred_suppliers', dataCompanyInfo.seqnoApiDefault || 1);
      setValue('pdf_version', dataCompanyInfo.estPdfLayoutFlg || '1');
      setValue('description', dataCompanyInfo.descriptioForQuotation);
    }
  }, [dataCompanyInfo]);

  return (
    <div className="basic_setting_container">
      <TopNote />
      <div className="form">
        <InputField
          controller={{ control, name: 'front_site_name' }}
          inputProps={{
            placeholder: t('basic_setting.front_site_name_placeholder'),
            maxLength: 50,
          }}
          label={t('basic_setting.front_site_name')}
          labelCol={{ xs: 24, md: 7, xl: 4 }}
          wrapperCol={{ xs: 24, md: 17, xl: 20 }}
          style={{ marginBottom: '16px' }}
          required
        />
        <Row>
          <Col xs={24} md={7} xl={4}>
            <Typography.Title level={5} className="form_label">
              {t('basic_setting.login_screen_url')}
            </Typography.Title>
          </Col>
          <Col xs={24} md={17} xl={20} className="mb-20">
            <Typography.Link
              href={dataCompanyInfo?.urlDirName}
              className="form_link"
            >
              {dataCompanyInfo?.urlDirName}
            </Typography.Link>
          </Col>
        </Row>
        <InputField
          controller={{ control, name: 'company_name' }}
          inputProps={{
            placeholder: t('basic_setting.company_name'),
            maxLength: 120,
          }}
          label={t('basic_setting.company_name')}
          labelCol={{ xs: 24, md: 7, xl: 4 }}
          wrapperCol={{ xs: 24, md: 17, xl: 20 }}
          required
        />
        <Row justify="space-between">
          <Col xs={11} md={14} xl={12}>
            <PhoneNumberField
              controller={{ control, name: 'phone_number' }}
              inputProps={{
                placeholder: t('basic_setting.phone_number'),
                maxLength: 13,
              }}
              label={t('basic_setting.phone_number')}
              labelCol={{ xs: 24, md: 12, xl: 8 }}
              wrapperCol={{ xs: 24, md: 10, xl: 11 }}
              style={{ marginBottom: '16px' }}
              required
            />
          </Col>
          <Col xs={11} md={10} xl={12}>
            <PhoneNumberField
              controller={{ control, name: 'fax_number' }}
              inputProps={{
                placeholder: t('basic_setting.fax_number'),
                maxLength: 13,
              }}
              label={t('basic_setting.fax_number')}
              labelCol={{ xs: 24, md: 10, xl: 5 }}
              wrapperCol={{ xs: 24, md: 14, xl: 11 }}
              style={{ marginBottom: '16px' }}
            />
          </Col>
        </Row>
        <InputField
          controller={{ control, name: 'address' }}
          inputProps={{
            placeholder: t('basic_setting.address'),
            maxLength: 120,
          }}
          label={t('basic_setting.address')}
          labelCol={{ xs: 24, md: 7, xl: 4 }}
          wrapperCol={{ xs: 24, md: 17, xl: 20 }}
          className="input_any"
        />
        <Divider className="form_divider" />
        <PersonResponsible employeeData={dataCompanyInfo?.jishaTantos} />
        <Divider className="form_divider" />
        <Row className="form_hours mb-8">
          <Col xs={24} md={7} xl={5}>
            <Typography.Title level={5} className="form_label_any">
              {t('basic_setting.business_hours')}
            </Typography.Title>
          </Col>
          <Col xs={24} md={17} xl={19}>
            <Row>
              <Col xs={11} md={4} xl={4}>
                <InputField
                  controller={{ control, name: 'business_hours_start' }}
                  inputProps={{
                    placeholder: '00:00',
                    maxLength: 5,
                  }}
                />
              </Col>
              <Col xs={2} md={1} className="form_hours_middle">
                ~
              </Col>
              <Col xs={11} md={4} xl={4}>
                <InputField
                  controller={{ control, name: 'business_hours_end' }}
                  inputProps={{
                    placeholder: '00:00',
                    maxLength: 5,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="form_business_day">
          <Col xs={24} md={7} xl={5}>
            <Typography.Title level={5} className="form_label_required">
              {t('basic_setting.estimated_expiration_date')}
            </Typography.Title>
          </Col>
          <Col>
            <Row>
              <Col span={4}>
                <NumberOnlyField
                  isOnlyInteger={true}
                  controller={{ control, name: 'estimated_expiration_date' }}
                  inputProps={{
                    placeholder: '7',
                    maxLength: 2,
                  }}
                  required
                />
              </Col>
              <Col span={20} className="form_business_day_text">
                <Typography.Text className="form_label">
                  {t('basic_setting.business_day')}
                </Typography.Text>
                <Typography.Text className="form_business_day_note">
                  {t('basic_setting.business_day_note')}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className="form_divider" />
        <SelectField<FormValue>
          controller={{
            control,
            name: 'preferred_suppliers',
          }}
          selectProps={{
            options: dataApiList,
            placeholder: t('basic_setting.preferred_suppliers'),
          }}
          label={t('basic_setting.preferred_suppliers')}
          labelCol={{ xs: 24, md: 7, xl: 5 }}
          wrapperCol={{ xs: 24, md: 7, xl: 5 }}
          className="form_select"
        />
        <RadioGroupField<FormValue>
          label={t('basic_setting.suggestion_condition')}
          radioGroupProps={{
            options: SUGGESTION_CONDITION(t),
          }}
          controller={{
            name: 'suggestion_condition',
            control,
          }}
          labelCol={{ xs: 24, md: 7, xl: 5 }}
          wrapperCol={{ xs: 24, md: 17, xl: 19 }}
          className="form_radio_group suggestion_condition"
        />
        <Divider className="form_divider" />
        <InputField
          controller={{ control, name: 'specific_customer' }}
          inputProps={{
            placeholder: t(
              'basic_setting.specific_customer_flag_name_placeholder',
            ),
            maxLength: 30,
          }}
          label={t('basic_setting.specific_customer_flag_name')}
          labelCol={{ xs: 24, md: 7, xl: 5 }}
          wrapperCol={{ xs: 24, md: 17, xl: 19 }}
          style={{ marginBottom: '16px' }}
          className="input_any"
        />
        <RadioGroupField<FormValue>
          label={t('basic_setting.email_notification')}
          radioGroupProps={{
            options: DATA_NOTI_EMAIL(t),
          }}
          controller={{
            name: 'notification_email',
            control,
          }}
          labelCol={{ xs: 24, md: 7, xl: 5 }}
          wrapperCol={{ xs: 24, md: 17, xl: 19 }}
          className="form_radio_group"
        />
        <Row>
          <Col xs={24} md={7} xl={5} className="form_upload_label">
            <Typography.Title level={5} className="form_label">
              {t('basic_setting.ec_site_logo')}
            </Typography.Title>
          </Col>
          {dataCompanyInfo !== undefined &&
            (dataCompanyInfo?.ecsiteLogoDir ? (
              <Col xs={24} md={7} xl={7}>
                <ImageUploadField
                  uploadFileProps={{
                    defaultFileList: [
                      {
                        uid: '1',
                        name: 'logo',
                        status: 'done',
                        url: dataCompanyInfo?.ecsiteLogoDir,
                      },
                    ],
                  }}
                  controller={{
                    name: 'logo_img',
                    control: control,
                  }}
                  className="form_upload"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: false,
                  }}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    className="form_button"
                  >
                    {t('basic_setting.upload')}
                  </Button>
                </ImageUploadField>
              </Col>
            ) : (
              <Col xs={24} md={7} xl={7}>
                <ImageUploadField
                  controller={{
                    name: 'logo_img',
                    control: control,
                  }}
                  className="form_upload"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: false,
                  }}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    className="form_button"
                  >
                    {t('basic_setting.upload')}
                  </Button>
                </ImageUploadField>
              </Col>
            ))}
          <Col xs={24} md={3} xl={3} className="form_upload_label">
            <Typography.Title level={5} className="form_label">
              {t('basic_setting.qr_code')}
            </Typography.Title>
          </Col>
          {dataCompanyInfo !== undefined &&
            (dataCompanyInfo?.qrcodeDir ? (
              <Col xs={24} md={7} xl={7}>
                <ImageUploadField
                  uploadFileProps={{
                    defaultFileList: [
                      {
                        uid: '1',
                        name: 'logo',
                        status: 'done',
                        url: dataCompanyInfo?.qrcodeDir,
                      },
                    ],
                  }}
                  controller={{
                    name: 'qr_img',
                    control: control,
                  }}
                  className="form_upload"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: false,
                  }}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    className="form_button"
                  >
                    {t('basic_setting.upload')}
                  </Button>
                </ImageUploadField>
              </Col>
            ) : (
              <Col xs={24} md={7} xl={7}>
                <ImageUploadField
                  controller={{
                    name: 'qr_img',
                    control: control,
                  }}
                  className="form_upload"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: false,
                  }}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    className="form_button"
                  >
                    {t('basic_setting.upload')}
                  </Button>
                </ImageUploadField>
              </Col>
            ))}
        </Row>
        <RadioGroupField<FormValue>
          label={t('basic_setting.quotation_reply_pdf')}
          radioGroupProps={{
            options: PDF_VERSION(t),
          }}
          controller={{
            name: 'pdf_version',
            control,
          }}
          labelCol={{ xs: 24, md: 7, xl: 5 }}
          wrapperCol={{ xs: 24, md: 17, xl: 19 }}
          className="form_radio_group"
        />
        <TextAreaField<FormValue>
          label={t('basic_setting.description')}
          className="form_textarea input_any"
          controller={{
            control,
            name: 'description',
          }}
          labelCol={{ xs: 24, md: 7, xl: 5 }}
          wrapperCol={{ xs: 24, md: 17, xl: 19 }}
          textAreaProps={{
            maxLength: 1000,
          }}
        />
      </div>
      <BottomNote />
      <Divider className="form_divider" />
      <div className="submit_button">
        <Button
          type="primary"
          onClick={handleSubmit(handleSubmitForm)}
          className="form_button"
        >
          {t('basic_setting.setting')}
        </Button>
      </div>
    </div>
  );
};

export default BasicSetting;
