export const TYPE: App.OptionsFnc = (t) => [
  { label: t('plugin_management.note_calendar'), value: '1' },
  { label: t('plugin_management.note_faq'), value: '3' },
  { label: t('plugin_management.note_chat'), value: '2' },
  { label: t('plugin_management.note_qr_code'), value: '4' },
];

export const FLAG: App.OptionsFnc = (t) => [
  { label: t('plugin_management.in_operation'), value: '0' },
  { label: t('plugin_management.stop'), value: '1' },
];
