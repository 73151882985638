import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormItem = {
  status?: number;
  seqno: number;
};

export type FormValue = {
  quotation: FormItem[];
};

const useForm = () => {
  const schema = Yup.object().shape({
    status: Yup.string(),
  });

  const form = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const fieldArray = useFieldArray({
    name: 'quotation',
    control: form.control,
  });

  const { fields } = fieldArray;

  return { ...form, fields, fieldArray };
};

export default useForm;
