import { Button, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import Note from './Note';
import Profit from './Profit';
import Setting from './Setting';
import useProfitMarginSetting from 'src/queries/useProfitMarginSetting';
import useForm from './useForm';
import PriceCopyModal from './PriceCopyModal';
import Modal from 'src/components/custom/Modal';
import history from 'src/libs/history';
import { PATH as PATH_CUSTOMER_SETTING } from 'src/pages/customerSetting';
import LoadingOverlay from 'src/components/applications/LoadingOverlay';
import { ApiTaniType } from 'src/enums/profitMargin';
import { CATEGORIES_ID } from 'src/constants/app';

const ProfitMarginSetting: React.FC = () => {
  const { t } = useTranslation();

  const {
    formInfo,
    standardFieldArray,
    typeShapeFieldArray,
    cuttingMethodFieldArray,
    apiFieldArray,
  } = useForm();

  const breakPoint = useBreakpoint();
  const [hasErrorTaniType, setHasErrorTaniType] = useState<boolean>(false);
  const [productKeyword, setProductKeyword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [oldDataError, setOldDataError] = useState<string>();

  const { handleSubmit, setError } = formInfo;

  const {
    queryInitDataProfitMargin,
    kokyakuCd,
    saveProfitMargin,
    queryProductNames,
    dataProductNames,
    queryApiList,
    queryCurrentCurrencyUnit,
  } = useProfitMarginSetting({
    setError: setError,
    setOldDataError: setOldDataError,
    productKeyword: productKeyword,
  });

  useEffect(() => {
    if (queryInitDataProfitMargin?.isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [queryInitDataProfitMargin?.isFetching]);

  const dataProfitMargin = queryInitDataProfitMargin?.data?.data?.item;
  const datacurrentCurrencyUnit = queryCurrentCurrencyUnit?.data?.data.item;
  const dataApiList = queryApiList?.data?.data.items;

  const getValueApiSetting = (taniType, shiireKingaku) => {
    if (taniType === ApiTaniType.PURCHASE_AMOUNT && shiireKingaku) {
      return Number(shiireKingaku);
    }

    return undefined;
  };

  const handleSetting = (value) => {
    const nameMachiningReqs = value.cutting_method_setting
      .filter((i) => i?.product_select || i?.product)
      .map((item) => {
        const isHakudoProduct = item?.product_select?.includes('h'); // `h${product.hakudoShhnCd}` - 'h' means hakudoShhnCd
        return {
          taniType: item.tani_type,
          riekrate1: item.riekrate1 ? Number(item.riekrate1) : undefined,
          riekrate2: item.riekrate2 ? Number(item.riekrate2) : undefined,
          riekrate3: item.riekrate3 ? Number(item.riekrate3) : undefined,
          riekrate4: item.riekrate4 ? Number(item.riekrate4) : undefined,
          shhnCd:
            !item.hakudo_product_flg && !isHakudoProduct
              ? item.product_select?.slice(1)
              : undefined,
          hakudoShhnCd:
            item.hakudo_product_flg || isHakudoProduct
              ? item.product_select?.slice(1)
              : undefined,
          setdnKakoCd:
            item.product_category !== CATEGORIES_ID.TOOL_PRODUCTS
              ? item.processing_method_select
              : undefined,
          seqno: item.seqno && Number(item.seqno),
        };
      });
    const typeShapeReqs = value.type_shape_setting
      .filter((i) => (i.type_select || i.type) && (i.shape_select || i.shape))
      .map((item) => {
        return {
          seqno: item.seqno && Number(item.seqno),
          taniType: item.tani_type,
          riekrate1: item.riekrate1 ? Number(item.riekrate1) : undefined,
          riekrate2: item.riekrate2 ? Number(item.riekrate2) : undefined,
          riekrate3: item.riekrate3 ? Number(item.riekrate3) : undefined,
          riekrate4: item.riekrate4 ? Number(item.riekrate4) : undefined,
          hinshL3Cd: item.type_select,
          keijoL3Cd: item.shape_select,
        };
      });
    const apiReqs = value.api_setting?.map((item) => {
      return {
        seqno: item?.seqno && Number(item.seqno),
        apiSeqno: item?.api_select,
        apiTaniType: item?.tani_type_select,
        shiireKingakuFrom1: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_from_1,
        ),
        shiireKingakuTo1: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_to_1,
        ),
        shiireKingakuFrom2: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_from_2,
        ),
        shiireKingakuTo2: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_to_2,
        ),
        shiireKingakuFrom3: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_from_3,
        ),
        shiireKingakuTo3: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_to_3,
        ),
        shiireKingakuFrom4: getValueApiSetting(
          item?.tani_type_select,
          item?.shiire_kingaku_from_4,
        ),
      };
    });
    if (kokyakuCd) {
      const payload: Payload.SaveProfitMargin = {
        kokyakuCd: kokyakuCd,
        standardSettingReq: {
          taniType: value.standard_setting[0].tani_type,
          riekrate1: value.standard_setting[0].riekrate1
            ? Number(value.standard_setting[0].riekrate1)
            : undefined,
          riekrate2: value.standard_setting[0].riekrate2
            ? Number(value.standard_setting[0].riekrate2)
            : undefined,
          riekrate3: value.standard_setting[0].riekrate3
            ? Number(value.standard_setting[0].riekrate3)
            : undefined,
          riekrate4: value.standard_setting[0].riekrate4
            ? Number(value.standard_setting[0].riekrate4)
            : undefined,
          seqno: Number(value.standard_setting[0].seqno),
        },
        typeShapeReqs: typeShapeReqs?.length > 0 ? typeShapeReqs : undefined,
        nameMachiningReqs:
          nameMachiningReqs?.length > 0 ? nameMachiningReqs : undefined,
        rmApiRiekiSetteiReqs: apiReqs?.length > 0 ? apiReqs : undefined,
        minimumGrossReq: {
          // saiteiTankaReq: {
          //   saiteTanka: value.minimum_gross_profit
          //     ? Number(value.minimum_gross_profit)
          //     : null,
          //   seqno: value.saitei_tanka_seqno,
          // },
          hasuSetteiReq: {
            hasuTani: value.last_digit,
            marumeHoho: value.round_down,
            seqno: value.hasu_settei_seqno,
          },
        },
      };
      saveProfitMargin.mutate(payload);
    }
  };

  const handleBack = () => {
    history.push(PATH_CUSTOMER_SETTING);
  };

  return (
    <div className="mb-75 mt-20 profit_margin_setting">
      {loading && <LoadingOverlay />}
      <Note customer_name={dataProfitMargin?.rmKokyakuRes?.kokyakuMei} />
      <Setting
        type="api"
        apiFieldArray={apiFieldArray}
        formInfo={formInfo}
        setHasErrorTaniType={setHasErrorTaniType}
        dataApiList={dataApiList}
        datacurrentCurrencyUnit={datacurrentCurrencyUnit}
      />
      <Setting
        type="standard"
        standardFieldArray={standardFieldArray}
        formInfo={formInfo}
        setHasErrorTaniType={setHasErrorTaniType}
      />
      <Setting
        type="type_shape"
        typeShapeFieldArray={typeShapeFieldArray}
        formInfo={formInfo}
        setHasErrorTaniType={setHasErrorTaniType}
      />
      <Setting
        type="product_cutting_method"
        cuttingMethodFieldArray={cuttingMethodFieldArray}
        formInfo={formInfo}
        setHasErrorTaniType={setHasErrorTaniType}
        dataProductNames={dataProductNames}
        queryProductNames={queryProductNames}
        setProductKeyword={setProductKeyword}
      />
      <Profit formInfo={formInfo} />
      {oldDataError && (
        <Row>
          <Typography.Text
            className="ant-form-item-explain ant-form-item-explain-error"
            type="danger"
            ellipsis={{ tooltip: true }}
          >
            {oldDataError}
          </Typography.Text>
        </Row>
      )}
      <Row className="action">
        {kokyakuCd && (
          <Modal
            modalProps={{
              width:
                breakPoint.lg || breakPoint.xl || breakPoint.xxl ? 840 : '100%',
            }}
            title={t('price_copy_modal.title')}
            content={({ onClose }) => (
              <PriceCopyModal onClose={onClose} kokyakuCd={kokyakuCd} />
            )}
          >
            <Button type="primary">{t('profit_margin_setting.copy')}</Button>
          </Modal>
        )}
        <Button
          type="primary"
          onClick={
            hasErrorTaniType
              ? undefined
              : handleSubmit((val) => handleSetting(val))
          }
          loading={saveProfitMargin.isLoading}
        >
          {t('profit_margin_setting.setting')}
        </Button>
        <Button type="primary" onClick={handleBack}>
          {t('profit_margin_setting.back')}
        </Button>
      </Row>
    </div>
  );
};

export default ProfitMarginSetting;
