import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import basicSetting from 'src/repositories/basicSetting';
import _ from 'lodash';
import { STATUS_CODE } from 'src/constants/app';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { t } from 'i18next';

const apiPath = '/basic-setting';
const apiEmployeePath = '/basic-setting/employee';
const apiListPath = '/basic-setting/api-list';

type BasicSetting = {
  currentUser?: number;
  setError: UseFormSetError<any>;
};

const useBasicSetting = ({ currentUser, setError }: BasicSetting) => {
  const queryClient = useQueryClient();

  const queryCompanyInfo = useQuery<
    AxiosResponse<Response.BasicSetting>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath],
    queryFn: basicSetting.getCompanyInfo,
  });

  const updateCompanyInfo = useMutation<
    AxiosResponse<Response.UpdateCompanyInfo>,
    AxiosError,
    Payload.CreateAndUpdateCompanyInfo
  >({
    mutationKey: [apiPath],
    mutationFn: (args) =>
      basicSetting.updateCompanyInfo(`${apiPath}/${args.seqno}`, args),
    retry: 0,
    async onSuccess(res) {
      history.go(0);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const createCompanyInfo = useMutation<
    AxiosResponse<Response.CreateCompanyInfo>,
    AxiosError,
    Payload.CreateAndUpdateCompanyInfo
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => basicSetting.createCompanyInfo(apiPath, args),
    retry: 0,
    async onSuccess(res) {
      history.go(0);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const createEmployeeInfo = useMutation<
    AxiosResponse<Response.EmployeeEntity>,
    AxiosError,
    Payload.EmployeeEntity
  >({
    mutationKey: [apiEmployeePath],
    mutationFn: (args) =>
      basicSetting.createEmployeeInfo(apiEmployeePath, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPath]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      switch (errorCode) {
        case STATUS_CODE.EMAIL_ALREADY_USED:
          return setError(`responsible_person.${currentUser}.email`, {
            type: 'custom',
            message: t('message.E000072', { 0: t('label.email') }),
          });
      }
    },
  });

  const updateEmployeeInfo = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.EmployeeEntity
  >({
    mutationKey: [apiEmployeePath],
    mutationFn: (args) =>
      basicSetting.updateEmployeeInfo(`${apiEmployeePath}/${args.seqno}`, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPath]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      switch (errorCode) {
        case STATUS_CODE.EMAIL_ALREADY_USED:
          return setError(`responsible_person.${currentUser}.email`, {
            type: 'custom',
            message: t('message.E000072', { 0: t('label.email') }),
          });
      }
    },
  });

  const deleteEmployeeInfo = useMutation<AxiosResponse<any>, AxiosError>({
    mutationKey: [apiEmployeePath],
    mutationFn: (args) =>
      basicSetting.deleteEmployeeInfo(`${apiEmployeePath}/${args}`),
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPath]);
    },
  });

  const queryApiList = useQuery<
    AxiosResponse<Response.ApiList>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiListPath],
    queryFn: basicSetting.getApiList,
  });

  const dataApiList = queryApiList?.data?.data?.items?.map((item) => ({
    label: item?.name,
    value: item?.seqno,
  }));

  return {
    queryCompanyInfo,
    updateCompanyInfo,
    createCompanyInfo,
    createEmployeeInfo,
    updateEmployeeInfo,
    deleteEmployeeInfo,
    queryApiList,
    dataApiList,
  };
};

export default useBasicSetting;
