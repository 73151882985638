import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const listCustomerDelivery = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const updateCustomerDelivery = (url, data: Payload.updateCustomerDelivery) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const deleteCustomerDelivery = (url, data: Payload.deleteCustomerDelivery) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

export default {
  listCustomerDelivery,
  updateCustomerDelivery,
  deleteCustomerDelivery,
};
