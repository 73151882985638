import { Button, Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { SelectField } from 'src/components/form';
import useTaxExChangeSetting from 'src/queries/useTaxExChangeSetting';
import useForm from './useForm';

type Props = {
  symbols?: Response.SymbolItem[];
};

const CurrencyUnit: React.FC<Props> = ({ symbols }) => {
  const [firstSymbol, setFirstSymbol] = useState<string>();

  const { control, symbolOptions, setValue, getValues } = useForm({
    symbolsTaxExChange: symbols,
  });
  const {
    getCurrentCurrencyUnit,
    createCurrentCurrencyUnit,
    updateCurrentCurrencyUnit,
  } = useTaxExChangeSetting({});

  const currentCurrencyUnitData = getCurrentCurrencyUnit?.data?.data.item;

  useEffect(() => {
    symbols && setFirstSymbol(_.first(symbols)?.moneyType);
  }, [symbols]);

  useEffect(() => {
    if (currentCurrencyUnitData) {
      setValue('currency_unit', currentCurrencyUnitData.currentKawase);
    }
  }, [currentCurrencyUnitData]);

  useEffect(() => {
    if (!currentCurrencyUnitData && firstSymbol) {
      setValue('currency_unit', firstSymbol);
    }
  }, [currentCurrencyUnitData, firstSymbol]);

  const handleSetting = () => {
    if (!currentCurrencyUnitData) {
      createCurrentCurrencyUnit.mutate({
        currentKawase: getValues()?.currency_unit,
      });
    } else {
      const currentCurrencyUnitObj = {
        seqno: currentCurrencyUnitData.seqno,
        currentKawase: getValues()?.currency_unit,
      };
      updateCurrentCurrencyUnit.mutate(currentCurrencyUnitObj);
    }
  };

  return (
    <div className="currency_unit">
      <Typography.Text className="title_text mb-10">
        {t('tax_exchange_setting.set_the_unit_of_selling_price')}
      </Typography.Text>
      <Row gutter={20}>
        <Col xl={24} lg={24} md={24} sm={12} xs={12}>
          <SelectField
            label={t('tax_exchange_setting.selling_price_unit')}
            controller={{ name: 'currency_unit', control: control }}
            labelCol={{ xxl: 6, xl: 6, lg: 8, md: 9, sm: 10, xs: 10 }}
            wrapperCol={{ xxl: 5, xl: 6, lg: 7, md: 9, sm: 14, xs: 14 }}
            selectProps={{ options: symbolOptions[0] }}
          />
        </Col>
        <Col xl={24} lg={24} md={24} sm={12} xs={12}>
          <div className="btn_setting_wrapper justify-start">
            <Button
              size="large"
              onClick={handleSetting}
              loading={
                createCurrentCurrencyUnit.isLoading ||
                updateCurrentCurrencyUnit.isLoading
              }
              className="btn_setting_170"
            >
              {t('tax_exchange_setting.setting')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CurrencyUnit;
