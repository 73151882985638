import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { FormValue } from 'src/containers/NotificationSetting/NotificationSettingModal/useForm';
import updateNotification from '../repositories/notification';
import _ from 'lodash';

type UseUpdateNotification = {
  setError?: UseFormSetError<FormValue>;
  onSuccess?: (data) => void;
  id: number;
};

const useUpdateNotification = ({
  setError,
  onSuccess,
  id,
}: UseUpdateNotification) => {
  const apiKey = `/notifications/${id}`;
  const apiKeyNotificationList = '/notifications';
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<Payload.CreateOrUpdateNotification>,
    AxiosError,
    Payload.CreateOrUpdateNotification
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => updateNotification.updateNotification(apiKey, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiKeyNotificationList]);
    },
    onError(err) {
      const error = _.get(err, 'response.data');

      return error;
    },
  });
};

export default useUpdateNotification;
