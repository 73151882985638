import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { EnvironmentOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, TableProps } from 'antd';

import useDestination from 'src/queries/useDestination';
import { PER } from 'src/constants/app';

type Props = {
  onSelectDestination: (any) => void;
  searchParams?: SearchParams.Destination;
  setSearchParams?: Dispatch<SetStateAction<SearchParams.Destination>>;
};

const useTable = ({
  onSelectDestination,
  searchParams,
  setSearchParams,
}: Props) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    total: undefined,
    page: 1,
    offset: PER.DEFAULT,
  });

  const { queryDestinationList } = useDestination({
    ...pagination,
    ...searchParams,
  });

  const handleChangeTable = (pagination) => {
    setSearchParams &&
      setSearchParams({
        ...searchParams,
        page: pagination.current,
        offset: pagination.pageSize,
      });
  };

  const columns = [
    // row responsive mobile
    {
      title: 'responsive',
      key: 'mobile',
      dataIndex: 'code',
      responsive: ['xs'] as Breakpoint[],
      width: '50%',
      render: (text, record) => (
        <div className="row_responsive">
          <span className="dark_text">{record.kokyakuCd}</span>
          <span>{record.rmOtokesaki.otokesakiNm}</span>
          <div className="column_zip_code">
            <EnvironmentOutlined />
            <a className="dark_text">{record.rmOtokesaki.jushoCd}</a>
          </div>
          <span>{record.rmOtokesaki.otodokesakiJusho}</span>
        </div>
      ),
    },
    // rows responsive desktop
    {
      title: 'code',
      dataIndex: 'kokyakuCd',
      key: 'kokyakuCd',
      responsive: ['sm'] as Breakpoint[],
      width: '15%',
      render: (text) => <a className="dark_text">{text}</a>,
    },
    {
      title: 'name',
      dataIndex: 'rmOtokesaki',
      key: 'rmOtokesaki',
      responsive: ['sm'] as Breakpoint[],
      render: (text, record) => <p className="mb-0">{text.otokesakiNm}</p>,
    },
    {
      title: 'zip_code',
      dataIndex: 'rmOtokesaki',
      key: 'rmOtokesaki',
      width: '20%',
      responsive: ['sm'] as Breakpoint[],
      render: (text) => (
        <div className="column_zip_code">
          <EnvironmentOutlined />
          <a className="dark_text">{text.jushoCd}</a>
        </div>
      ),
    },
    {
      title: 'address',
      key: 'rmOtokesaki',
      dataIndex: 'rmOtokesaki',
      responsive: ['sm'] as Breakpoint[],
      render: (text, record) => <p className="mb-0">{text.otodokesakiJusho}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right' as const,
      width: '15%',
      render: (text, record) => (
        <Button
          onClick={() => onSelectDestination(record)}
          size="small"
          type="primary"
          className="w-full"
        >
          {t('destination_modal.select_button')}
        </Button>
      ),
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: queryDestinationList?.data?.data?.items,
    loading: {
      spinning: queryDestinationList.isLoading,
      indicator: <LoadingOutlined />,
    },
    rowKey: (record) => record.rmOtokesaki?.otokesakiCd,
    showHeader: false,
    size: 'middle',
    pagination: {
      position: ['bottomCenter'],
      total: queryDestinationList?.data?.data?.total,
      current: queryDestinationList?.data?.data?.page,
      pageSize: queryDestinationList?.data?.data?.offset,
    },
    onChange: handleChangeTable,
    scroll: { y: 600 },
    locale: {
      emptyText: t('message.E000024'),
    },
  };

  return {
    tableProps,
    pagination,
    setPagination,
  };
};

export default useTable;
