import React from 'react';

import ListMasterRegistration from './ListMasterRegistration';
import TableStatus from './TableStatus';

const MasterRegistration: React.FC = () => {
  return (
    <React.Fragment>
      <div className="master_registration">
        <ListMasterRegistration />
        <TableStatus />
      </div>
    </React.Fragment>
  );
};

export default MasterRegistration;
