export enum DisplayCondition {
  LOGIN_PAGE = '1',
  FRONT_SITE = '2',
  BOTH = '3',
}

export enum DisplayOrder {
  REGISTRATION_DATE = 'CREATED_DATE',
  PUBLISH_DATE = 'POSTED_FROM',
}
