import React, { useState } from 'react';
import { Row, Col, Button, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { InputField, SelectField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import useTable from './useTable';
import { PER } from 'src/constants/app';
import { PAGE_SIZE_OPTIONS } from 'src/constants/optionSelect/pageSize';

type Props = {
  onClose?: any;
  onSelect?: any;
};

const DestinationModal: React.FC<Props> = ({ onClose, onSelect }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, getValues } = useForm();

  const [searchParams, setSearchParams] = useState<SearchParams.Destination>({
    search: '',
    page: 1,
    offset: PER.DEFAULT,
  });

  const { tableProps } = useTable({
    onSelectDestination: (val) => onSelectDestination(val),
    searchParams,
    setSearchParams,
  });

  const handleSearch = (values) => {
    setSearchParams({
      page: 1,
      search: values.searchAddress,
      offset: getValues().offset,
    });
  };

  const handleSetOffset = () => {
    setSearchParams({
      ...searchParams,
      offset: getValues().offset,
      page: 1,
    });
  };

  const onSelectDestination = (destination) => {
    onSelect(destination);
    onClose();
  };

  return (
    <React.Fragment>
      <div className="destination_modal">
        <Row>
          <Col xs={24} md={11}>
            <Row>
              <Col span={20}>
                <InputField<FormValue>
                  controller={{ control, name: 'searchAddress' }}
                  inputProps={{
                    placeholder: t('destination_modal.search_placeholder'),
                  }}
                  required
                />
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  onClick={handleSubmit((val) => handleSearch(val))}
                  size="middle"
                  className="w-full"
                >
                  <SearchOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={13} className="page_size">
            <Row justify="center" align="middle">
              <Col span={19} className="page_size_col">
                <span className="page_size_col_text">
                  {t('destination_modal.page_size_text_before')}
                </span>
                <SelectField<FormValue>
                  controller={{
                    control,
                    name: 'offset',
                    defaultValue: searchParams.offset,
                  }}
                  selectProps={{
                    options: PAGE_SIZE_OPTIONS(t),
                  }}
                />
                <span className="page_size_col_text">
                  {t('destination_modal.page_size_text_after')}
                </span>
              </Col>
              <Col span={5}>
                <Button
                  onClick={handleSetOffset}
                  type="primary"
                  size="small"
                  className="w-full"
                >
                  {t('destination_modal.page_size_button')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="modal_table">
          <Col span={24}>
            <Table {...tableProps} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DestinationModal;
