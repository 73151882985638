import React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form/dist/types/form';

import { InputField, NumberOnlyField } from 'src/components/form';
import { FormValue } from 'src/containers/CustomerSetting/SearchComponent/useForm';
import PhoneNumberField from 'src/components/form/PhoneNumberField';

type CustomerSearchFormProps<T> = {
  control: Control<T>;
  onSubmit: () => void;
};

const CustomerSearchForm: React.FC<CustomerSearchFormProps<FormValue>> = (
  props: CustomerSearchFormProps<FormValue>,
) => {
  const { control, onSubmit } = props;
  const { t } = useTranslation();
  return (
    <div className="customer-search-form">
      <Typography.Title level={5} className="mb-20 text-darken form-title">
        {t('customer_search_form.header')}
      </Typography.Title>
      <Form onFinish={onSubmit}>
        <NumberOnlyField<FormValue>
          controller={{ control, name: 'customer_code' }}
          inputProps={{
            maxLength: 10,
            placeholder: t('customer_setting.customer_code'),
          }}
          label={t('customer_setting.customer_code')}
          labelCol={{ xs: 24, sm: 24, md: 6, lg: 4, xl: 4 }}
          wrapperCol={{ xs: 24, sm: 24, md: 18, lg: 20, xl: 20 }}
        />
        <Row className="mb-12">
          <Col xs={24} sm={24} md={6} lg={4} xl={4}>
            <Typography.Text className="form-item-label">
              {t('customer_setting.company_name')}
            </Typography.Text>
          </Col>
          <Col xs={17} sm={20} md={12} lg={16} xl={17}>
            <InputField<FormValue>
              controller={{ control, name: 'company_name' }}
              inputProps={{
                maxLength: 90,
                placeholder: t('customer_setting.company_name'),
              }}
            />
          </Col>
          <Col xs={7} sm={4} md={6} lg={4} xl={3}>
            <Typography.Text className="partial-label">
              {t('customer_setting.partial_match')}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mb-12">
          <Col xs={24} sm={24} md={6} lg={4} xl={4}>
            <Typography.Text className="form-item-label">
              {t('customer_setting.address_code')}
            </Typography.Text>
          </Col>
          <Col xs={17} sm={20} md={12} lg={16} xl={17}>
            <PhoneNumberField<FormValue>
              controller={{ control, name: 'address_code' }}
              inputProps={{
                maxLength: 10,
                placeholder: t('customer_setting.address_code'),
              }}
            />
          </Col>
          <Col xs={7} sm={4} md={6} lg={4} xl={3}>
            <Typography.Text className="partial-label">
              {t('customer_setting.partial_match')}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mb-12">
          <Col xs={24} sm={24} md={6} lg={4} xl={4}>
            <Typography.Text className="form-item-label">
              {t('customer_setting.address')}
            </Typography.Text>
          </Col>
          <Col xs={17} sm={20} md={12} lg={16} xl={17}>
            <InputField<FormValue>
              controller={{ control, name: 'address' }}
              inputProps={{
                maxLength: 100,
                placeholder: t('customer_setting.address'),
              }}
            />
          </Col>
          <Col xs={7} sm={4} md={6} lg={4} xl={3}>
            <Typography.Text className="partial-label">
              {t('customer_setting.partial_match')}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} className="phone-number">
            <PhoneNumberField<FormValue>
              controller={{ control, name: 'phone_number' }}
              inputProps={{
                maxLength: 13,
                placeholder: t('customer_setting.phone_number'),
              }}
              label={t('customer_setting.phone_number')}
              labelCol={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 8 }}
              wrapperCol={{ xs: 24, sm: 24, md: 12, lg: 16, xl: 16 }}
            />
          </Col>
          <Col xs={12} sm={12} md={12} className="fax-number">
            <PhoneNumberField<FormValue>
              controller={{ control, name: 'fax_number' }}
              inputProps={{
                maxLength: 13,
                placeholder: t('customer_setting.fax_number'),
              }}
              label={t('customer_setting.fax_number')}
              labelCol={{ xs: 24, sm: 24, md: 12, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 24, sm: 24, md: 12, lg: 16, xl: 18 }}
            />
          </Col>
        </Row>
        <InputField<FormValue>
          controller={{ control, name: 'email' }}
          inputProps={{
            maxLength: 80,
            placeholder: t('customer_setting.email_address'),
          }}
          label={t('customer_setting.email_address')}
          labelCol={{ xs: 24, sm: 24, md: 6, lg: 4, xl: 4 }}
          wrapperCol={{ xs: 24, sm: 24, md: 18, lg: 20, xl: 20 }}
        />
        <InputField<FormValue>
          controller={{ control, name: 'person_in_charge' }}
          inputProps={{
            maxLength: 30,
            placeholder: t('customer_setting.person_in_charge'),
          }}
          label={t('customer_setting.person_in_charge')}
          labelCol={{ xs: 24, sm: 24, md: 6, lg: 4, xl: 4 }}
          wrapperCol={{ xs: 24, sm: 24, md: 18, lg: 20, xl: 20 }}
        />
        <Row justify="center">
          <Button htmlType="submit" type="primary" className="btn-search">
            {t('customer_search_form.button_search')}
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default CustomerSearchForm;
