import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';

import { REGEX } from 'src/constants/app';
import { t } from 'src/libs/i18n';
import _ from 'lodash';
import useTaxExChangeSetting from 'src/queries/useTaxExChangeSetting';

type Props = {
  symbolsCurrencyTransfers?: Response.SymbolItem[];
};

export type currencyConversionField = {
  unit_0: string;
  tax_0: number;
  unit_1: string;
  tax_1: number;
};

export type FormValue = {
  currency_conversion: currencyConversionField[];
};

const useForm = ({ symbolsCurrencyTransfers }: Props) => {
  const { getCurrencyTransfers } = useTaxExChangeSetting({});
  const currencyTransfersData = getCurrencyTransfers?.data?.data.items;

  const symbolOptions = [
    symbolsCurrencyTransfers?.map((item) => {
      return {
        label: item.symbol,
        value: item.moneyType,
      };
    }),
  ];

  const currencyConversionFieldSchema = {
    tax_0: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_0_TO_99999999999999,
        t('message.E000006', {
          0: t('tax_exchange_setting.exchange_rate'),
          1: '0～99999999999999',
        }),
      ),
    tax_1: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_0_TO_99999999999999,
        t('message.E000006', {
          0: t('tax_exchange_setting.exchange_rate'),
          1: '0～99999999999999',
        }),
      ),
  };

  const schema = Yup.object().shape({
    currency_conversion: Yup.array().of(
      Yup.object().shape(currencyConversionFieldSchema),
    ),
  });

  const form = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const fieldArray = useFieldArray({
    name: 'currency_conversion',
    control: form.control,
  });

  const { append, remove } = fieldArray;
  const [firstSymbol, setFirstSymbol] = useState<string>();

  useEffect(() => {
    symbolsCurrencyTransfers &&
      setFirstSymbol(_.first(symbolsCurrencyTransfers)?.moneyType);
  }, [symbolsCurrencyTransfers]);

  useEffect(() => {
    currencyTransfersData?.map((item) => {
      append(
        {
          unit_0: item.kawaseKbCdFrom,
          tax_0: item.kawaseValueCdFrom,
          unit_1: item.kawaseKbCdTo,
          tax_1: item.kawaseValueCdTo,
        },
        { shouldFocus: false },
      );
    });

    if (currencyTransfersData?.length === 0) {
      append(
        {
          unit_0: firstSymbol,
          tax_0: undefined,
          unit_1: firstSymbol,
          tax_1: undefined,
        },
        { shouldFocus: false },
      );
    }

    return () => {
      remove();
    };
  }, [currencyTransfersData, symbolsCurrencyTransfers, firstSymbol]);

  return { ...form, fieldArray, symbolOptions };
};

export default useForm;
