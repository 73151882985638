import React from 'react';

import { PATH } from 'src/pages/customerRegistration';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { t } from 'src/libs/i18n';
import { SCREENS } from 'src/constants/screens';

const CustomerRegistrationRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/customerRegistration')),
        title: t('customer_registration.page_title'),
        screenId: SCREENS.R1514,
      },
    ],
  },
];

export default CustomerRegistrationRoute;
