import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import customerRegistration from 'src/repositories/customerRegistration';

const apiKey = '/customers/currency';

const useCustomerCurrency = () => {
  const { data, ...rest } = useQuery<
    AxiosResponse<Response.CustomerCurrency>,
    AxiosError<any>
  >([apiKey], customerRegistration.getCustomerCurrency, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });
  return {
    currency: data?.data?.item,
    ...rest,
  };
};

export default useCustomerCurrency;
