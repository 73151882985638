import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Tooltip, Typography } from 'antd';
import classNames from 'classnames';

import history from 'src/libs/history';
import { t } from 'src/libs/i18n';
import useTable from './useTable';
import useForm from './useForm';
import { PATH as CUSTOMER_SETTING_PATH } from 'src/pages/customerSetting';
import useQueryUrl from 'src/hooks/useQueryUrl';
import useCustomerSetting from 'src/queries/useCustomerSetting';
import useCustomerDeliverySetting from 'src/queries/useCustomerDeliverySetting';
import modalConfirm from 'src/utils/modalConfirm';
import CustomerDelivertyRegistrationModal from './CustomerDelivertyRegistrationModal';
import { Modal } from 'src/components/custom';
import { SCREENS } from 'src/constants/screens';
import { ellipsisByLength } from 'src/utils/helper';
import { DELIVERY_NUMBER_DEFAULT } from 'src/constants/app';

const CustomerDeliverySetting: React.FC = () => {
  const query = useQueryUrl();
  const [errorTable, setErrorTable] = useState<string>('');
  const { setError } = useForm({});
  const { querySearchCustomer } = useCustomerSetting({ setError });
  const getCustomerSetting = querySearchCustomer?.data?.data?.items[0];

  const { tableProps, rowSelected, setRowKeysSelected, dataSource } = useTable({
    setErrorTable: setErrorTable,
    errorTable: errorTable,
    customer_code: query?.customer_code,
  });

  const { deleteCustomerDelivery } = useCustomerDeliverySetting({
    setRowKeysSelected,
  });

  const checkOtokesakiCd = () => {
    return dataSource?.find(
      (item) => item.otokesakiCd === DELIVERY_NUMBER_DEFAULT,
    )?.seqno;
  };

  const handleDelete = () => {
    if (rowSelected.length > 0) {
      const customerDeliverySelect = {
        kokyakuCd: query?.customer_code,
        seqnos: rowSelected?.filter((item) => item !== checkOtokesakiCd()),
      };
      modalConfirm({
        t,
        title: t('customer_delivery_setting.message_select_delete'),
        okText: t('modal_confirm.button_confirm'),
        cancelText: t('modal_confirm.button_cancel'),
        maskClosable: false,
        onOk: async () => {
          await deleteCustomerDelivery.mutate(customerDeliverySelect);
        },
      });
    } else {
      setErrorTable(
        t('message.E000007', {
          0: t('customer_delivery_setting.addressee'),
        }),
      );
    }
  };

  return (
    <div className="customer_delivery_setting">
      <div className="description_text">
        <Typography.Text className="customer_name">
          <Tooltip placement="topLeft" title={getCustomerSetting?.kokyakuMei}>
            {getCustomerSetting &&
              ellipsisByLength(getCustomerSetting?.kokyakuMei, 20)}
          </Tooltip>
        </Typography.Text>
        &nbsp;
        <Typography.Text>
          {t('customer_delivery_setting.description_text')}
        </Typography.Text>
      </div>

      <div className="table_wrapper">
        <Row
          gutter={[0, { xs: 10 }]}
          justify="space-between"
          align="middle"
          className="table_head"
        >
          <Col className="table_title">
            <Typography.Text className="customer_name">
              <Tooltip
                placement="topLeft"
                title={getCustomerSetting?.kokyakuMei}
              >
                {getCustomerSetting &&
                  ellipsisByLength(getCustomerSetting?.kokyakuMei, 20)}
              </Tooltip>
            </Typography.Text>
            &nbsp;
            <Typography.Text>
              {t('customer_delivery_setting.table_title')}
            </Typography.Text>
          </Col>
          <Col>
            <Modal
              modalProps={{ width: 840 }}
              title={t('customer_delivery_registration_modal.title')}
              content={({ onClose }) => (
                <CustomerDelivertyRegistrationModal
                  customer_code={query?.customer_code}
                  onClose={onClose}
                  screenId={SCREENS.R2506}
                />
              )}
            >
              <Button size="large" className="w-170">
                {t(
                  'customer_delivery_setting.btn_delivery_address_registration',
                )}
              </Button>
            </Modal>
          </Col>
        </Row>
        {!!dataSource?.length && (
          <div className="list_table">
            <Table {...tableProps} />
            <Row>
              <Button
                size="large"
                icon={<DeleteOutlined />}
                onClick={handleDelete}
                className={classNames({ 'mt-20': !dataSource?.length })}
              >
                {t('customer_delivery_setting.btn_delete')}
              </Button>
            </Row>
          </div>
        )}
      </div>
      <Row justify="center">
        <Button
          size="large"
          onClick={() => history.push(CUSTOMER_SETTING_PATH)}
          className="w-170"
        >
          {t('customer_delivery_setting.btn_return')}
        </Button>
      </Row>
    </div>
  );
};

export default CustomerDeliverySetting;
