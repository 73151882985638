import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getCustomerType = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getCustomerCurrency = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const createCustomer = (url, data: Payload.CustomerRegistration) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const getOccupation = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getLocation = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

export default {
  getCustomerType,
  getCustomerCurrency,
  createCustomer,
  getOccupation,
  getLocation,
};
