import React, { useEffect } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import useForm, { FormValue } from './useForm';
import { t } from 'src/libs/i18n';

import {
  InputField,
  RadioGroupField,
  TextAreaField,
} from 'src/components/form';
import { TYPE } from 'src/constants/optionSelect/pluginManagement';
import { usePluginRegistration } from 'src/queries/usePluginRegistration';
import { STATUS_PLUGIN } from 'src/constants/app';
import { formatScript } from 'src/utils/helper';

const CreatePlugin: React.FC = () => {
  const { control, handleSubmit, setError, resetField, setValue } = useForm();

  const { registration } = usePluginRegistration({
    setError,
    setRowKeysSelected: undefined,
  });

  const handleSubmitForm = (value) => {
    registration.mutate({
      pluginNm: value.pluginNm,
      pluginType: value?.pluginType,
      script: formatScript(value?.script),
    });
  };

  useEffect(() => {
    if (registration?.data?.data) {
      resetField('pluginNm');
      setValue('pluginType', STATUS_PLUGIN.CALENDAR);
      resetField('script');
    }
  }, [registration?.data?.data]);

  return (
    <div className="create_plugin">
      <Row>
        <Col xs={24} md={24} xl={24}>
          <InputField<FormValue>
            label={t('plugin_management.name')}
            controller={{
              control,
              name: 'pluginNm',
            }}
            inputProps={{
              placeholder: t('plugin_management.name'),
            }}
            labelCol={{ xs: 24, md: 2 }}
            wrapperCol={{ xs: 24, md: 8 }}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} xl={24} className="col-type-plugin">
          <RadioGroupField<FormValue>
            label={t('plugin_management.type')}
            controller={{
              name: 'pluginType',
              control,
            }}
            radioGroupProps={{
              options: TYPE(t),
            }}
            labelCol={{ xs: 3, md: 2 }}
            wrapperCol={{ xs: 21, md: 22 }}
          />
        </Col>
      </Row>
      <Row className="mt-20" gutter={[0, 6]}>
        <Col xs={24} sm={24} lg={24} className="group-input">
          <TextAreaField<FormValue>
            label={t('plugin_management.fill_source_code')}
            className="pl-20 text-area"
            controller={{
              control,
              name: 'script',
            }}
            textAreaProps={{
              placeholder: t('plugin_management.fill_source_code'),
            }}
          />
        </Col>
      </Row>
      <Divider className="mb-30" />
      <div className="contain-btn-update">
        <Button
          className="btn-update"
          htmlType={'submit'}
          type="primary"
          onClick={handleSubmit((val) => handleSubmitForm(val))}
        >
          {t('plugin_management.register')}
        </Button>
      </div>
    </div>
  );
};

export default CreatePlugin;
