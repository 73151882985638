import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { REGEX } from 'src/constants/app';
import useQueryUrl from 'src/hooks/useQueryUrl';

export type FormValue = {
  customer_code: string;
  company_name: string;
  address_code: string;
  address: string;
  phone_number: string;
  fax_number: string;
  email: string;
  person_in_charge: string;
  customer_classification?: boolean;
  job_id?: number;
  region_id?: number;
};

const useForm = () => {
  const { t } = useTranslation();
  const query = useQueryUrl();

  const defaultValues: FormValue = {
    customer_code: query.customer_code,
    company_name: query.company_name,
    address_code: query.address_code,
    address: query.address,
    phone_number: query.phone_number,
    fax_number: query.fax_number,
    email: query.email,
    person_in_charge: query.person_in_charge,
    job_id: query.job_id ? +query.job_id : undefined,
    region_id: query.region_id ? +query.region_id : undefined,
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_EMAIL_MATCHES,
        t('message.E000033', { 0: t('customer_setting.email_address') }),
      ),
    phone_number: Yup.string().matches(
      REGEX.REGEX_PHONE_NUMBER,
      t('message.E000011', { 0: t(`customer_setting.phone_number`) }),
    ),
    fax: Yup.string().matches(
      REGEX.REGEX_PHONE_NUMBER,
      t('message.E000011', { 0: t(`customer_setting.phone_number`) }),
    ),
  });

  const formInfo = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { ...formInfo };
};

export default useForm;
