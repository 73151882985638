import { Typography } from 'antd';
import React from 'react';
import { t } from 'src/libs/i18n';

const Note: React.FC = () => {
  return (
    <div className="quotation_note">
      <Typography.Text>{t('quotation_page.note_1')}</Typography.Text>
      <Typography.Text>{t('quotation_page.note_2')}</Typography.Text>
      <Typography.Text>{t('quotation_page.note_3')}</Typography.Text>
      <Typography.Text>{t('quotation_page.note_4')}</Typography.Text>
    </div>
  );
};

export default Note;
