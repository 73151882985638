import {
  Table as AntTable,
  Typography,
  Card,
  Button,
  Row,
  Col,
  Space,
} from 'antd';
import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import Modal from 'src/components/custom/Modal';
import ModalNotification from '../NotificationSettingModal/index';

import useTable from './useTable';
import { t } from 'src/libs/i18n';
import useNotificationSetting from 'src/queries/useNotificationSetting';
import { SCREENS } from 'src/constants/screens';

const Table: React.FC = () => {
  const [searchMessageError, setSearchMessageError] = useState<string>('');
  const [actionMessageError, setActionMessageError] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { tableProps, rowSelected, setRowKeysSelected, notificationSelected } =
    useTable();
  const { updatePostFlg, deleteNotification } = useNotificationSetting({
    setRowKeysSelected,
    setActionMessageError,
  });

  const handleEditRegister = () => {
    setSearchMessageError('');
    switch (rowSelected.length) {
      case 0:
        setOpenModal(true);
        break;
      case 1:
        setOpenModal(true);
        break;
      default:
        setSearchMessageError(t('message.E000026'));
        break;
    }
  };

  const handleUpdate = async () => {
    // handle update post flag data
    // handle update publish status of selected row of notification seqno
    setActionMessageError('');
    await updatePostFlg.mutate({ seqnos: rowSelected });
  };

  const handleDelete = async () => {
    setActionMessageError('');
    // handle delete notification
    // just delete selected row of content seqno
    await deleteNotification.mutate({
      seqnos: rowSelected,
    });
  };

  return (
    <React.Fragment>
      <Row justify="center" className="search_error_container">
        {searchMessageError && (
          <Typography.Paragraph>{searchMessageError}</Typography.Paragraph>
        )}
      </Row>
      <div className="table">
        <Card
          title={
            <Row>
              <Col xs={24} md={12} xl={12} className="table_title">
                <Typography.Title level={2}>
                  {t('notification_setting.table_title')}
                </Typography.Title>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Space className="d-flex justify-end table_button">
                  <Modal
                    modalProps={{
                      width: 860,
                    }}
                    title={t('notification_setting.modal_title')}
                    content={({ onClose }) => (
                      <ModalNotification
                        setOpenModal={setOpenModal}
                        id={notificationSelected[0]}
                        mode={notificationSelected[0] ? 'UPDATE' : 'CREATE'}
                        onClose={onClose}
                        screenId={SCREENS.R2501}
                      />
                    )}
                    autoOpen={openModal}
                    setAutoOpen={setOpenModal}
                  />
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={handleEditRegister}
                    disabled={rowSelected.length > 1}
                  >
                    {t('button.register_edit')}
                  </Button>
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={handleUpdate}
                    loading={updatePostFlg.isLoading}
                    disabled={!rowSelected.length}
                  >
                    {t('notification_setting.btn_reload')}
                  </Button>
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={handleDelete}
                    loading={deleteNotification.isLoading}
                    disabled={!rowSelected.length}
                  >
                    {t('button.delete')}
                  </Button>
                </Space>
                <Row justify="end" className="action_error_container">
                  {actionMessageError && <span>{actionMessageError}</span>}
                </Row>
              </Col>
            </Row>
          }
          className="custom_card"
        >
          <AntTable {...tableProps} />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Table;
