import React from 'react';
import { TableProps, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import useForm from './useForm';
import useTableCommon from 'src/hooks/useTable';
import { I18n, t } from 'src/libs/i18n';
import { FormValue } from './useForm';
import { DateTimePickerField } from 'src/components/form';
import {
  DATE_TIME_HOUR,
  STATUS_MASTER_REGISTRATION,
  STATUS_UPLOAD_CSV,
} from 'src/constants/app';
import { useMasterRegistration } from 'src/queries/useMasterRegistration';
import {
  formatDate,
  getStatusUploadCsv,
  getNameTypeCsv,
} from 'src/utils/helper';

const useTable = () => {
  const { queryListHistory } = useMasterRegistration({
    setRowKeysSelected: undefined,
  });
  const { control, getValues } = useForm(queryListHistory);
  const { rowSelection, ...rest } = useTableCommon({
    pagination: false,
    hasSelect: true,
  });

  const columns: ColumnsType<any> = [
    {
      title: t('master_registration.item_number'),
      dataIndex: 'index',
      render(value, item, index) {
        return index + 1;
      },
    },
    {
      title: t('master_registration.classification'),
      dataIndex: 'kbnmCd',
      render(value, record) {
        return getNameTypeCsv(value);
      },
    },
    {
      title: t('master_registration.file_name'),
      dataIndex: 'fileNm',
    },
    {
      title: t('master_registration.status'),
      dataIndex: 'status',
      render(text, record) {
        return getStatusUploadCsv(record?.status);
      },
    },
    {
      title: t('master_registration.reason_for_error'),
      dataIndex: 'reason_for_error',
      render(text, record) {
        return record?.errorMsg ? I18n.t(`message.${record?.errorMsg}`) : '-';
      },
    },
    {
      title: t('master_registration.number_updated_lines'),
      dataIndex: 'kensu',
    },
    {
      title: t('master_registration.manager'),
      dataIndex: 'manager',
      render(text, record) {
        return record?.webUser?.smei;
      },
    },
    {
      title: t('master_registration.reflection_timing'),
      dataIndex: 'reflection_timing',
      render(text, record) {
        if (
          record?.haneiPattern == STATUS_MASTER_REGISTRATION.RESERVATION &&
          record?.status == STATUS_UPLOAD_CSV.UPLOADED
        ) {
          if (record.shiteiNichiji) {
            return formatDate(record?.shiteiNichiji, ' |');
          } else {
            return (
              <div className="datePicker">
                <DateTimePickerField<FormValue>
                  controller={{
                    control,
                    name: `dateTimeReflections.${record.seqno || 0}`,
                  }}
                  className="dateTimePicker"
                  style={{ width: '60%' }}
                  datePickerProps={{
                    placeholder: '2022-04-25 | 00:00',
                    format: DATE_TIME_HOUR,
                    showTime: { format: DATE_TIME_HOUR },
                  }}
                />
              </div>
            );
          }
        } else {
          return (
            <Typography.Text>
              {record?.status == STATUS_UPLOAD_CSV.WAITING_REFLECTION ||
              record?.haneiPattern == STATUS_MASTER_REGISTRATION.RESERVATION
                ? t('master_registration.status_reservation_reflection')
                : t('master_registration.immediately')}
            </Typography.Text>
          );
        }
      },
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: queryListHistory.data?.data.items,
    loading: {
      spinning: queryListHistory.isLoading,
      indicator: <LoadingOutlined />,
    },
    rowClassName: 'table_row_status',
    rowKey: 'seqno',
    size: 'middle',
    pagination: false,
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
  };

  return {
    tableProps,
    ...rest,
    queryListHistory,
    getValues,
  };
};

export default useTable;
