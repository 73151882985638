import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';

import internalPIC from 'src/repositories/internalPIC';

const apiPath = '/internalpic/initialization-info-popup';
const apiPathSearch = '/internalpic/search';
const apiPathUpdate = '/internalpic/setting-internal-pic';
const apiPathSearchCustomer = '/customers/company';

const useInternalPIC = (
  { name }: SearchParams.InternalPIC,
  screenParams: Payload.InternalPICInfo,
) => {
  const queryClient = useQueryClient();

  const queryInternalPICInfo = useQuery<
    AxiosResponse<Response.InternalPICInfo>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath, screenParams],
    queryFn: internalPIC.getInternalPIC,
  });

  const queryInternalPICList = useQuery<
    AxiosResponse<Response.InternalPIC>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathSearch, { name }],
    queryFn: internalPIC.getInternalPICList,
    enabled: typeof name !== 'undefined',
  });

  const updateInternalPIC = useMutation<
    AxiosResponse<Response.UpdateInternalPIC>,
    AxiosError,
    Payload.InternalPIC
  >({
    mutationKey: [apiPathUpdate],
    mutationFn: (args) => internalPIC.updateInternalPIC(apiPathUpdate, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPathSearchCustomer]);
    },
    onError(err) {
      const error = _.get(err, 'response.data');
      return error;
    },
  });

  return { queryInternalPICList, queryInternalPICInfo, updateInternalPIC };
};

export default useInternalPIC;
