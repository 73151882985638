export const DISPLAY_CONDITION: App.OptionsFnc = (t) => [
  { label: t('notification_setting.login_page'), value: '1' },
  { label: t('notification_setting.front_site'), value: '2' },
];

export const DISPLAY_ORDER: App.OptionsFnc = (t) => [
  {
    label: t('notification_setting.registration_date'),
    value: 'CREATED_DATE',
  },
  { label: t('notification_setting.publish_date'), value: 'POSTED_FROM' },
];

export const PUBLISH_SETTING: App.OptionsFnc = (t) => [
  { label: t('notification_setting.flag_public'), value: '1' },
  {
    label: t('notification_setting.flag_private'),
    value: '0',
  },
];
