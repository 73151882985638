import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getTokuteiKokyakuKbn = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getSearchCustomer = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;
  return axios({
    method: 'get',
    url,
    params,
  });
};

const updateCurrency = (url, data: Payload.UpdateCurrency) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const deleteCustomerSetting = (
  url,
  data: Payload.DeleteCustomerSettingItem,
) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

const updateOccupationAndLocation = (
  url,
  data: Payload.UpdateOccupationAndLocation,
) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

export default {
  getTokuteiKokyakuKbn,
  getSearchCustomer,
  updateCurrency,
  deleteCustomerSetting,
  updateOccupationAndLocation,
};
