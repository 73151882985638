import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import { t } from 'i18next';
import _ from 'lodash';
import { UseFormSetError } from 'react-hook-form';

import customerSetting from 'src/repositories/customerSetting';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { PER, STATUS_CODE } from 'src/constants/app';

const apiCustomSettingPath = '/customers';
const apiSearchCustomerPath = '/customers/company';
const apiUpdateOccupationAndLocationPath = '/customers/occupation-location';

type UseNotificationSetting = {
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
  setError: UseFormSetError<any>;
};

const useCustomerSetting = ({
  setRowKeysSelected,
  setError,
}: UseNotificationSetting) => {
  const queryClient = useQueryClient();

  const queryCustomer = useQuery<
    AxiosResponse<Response.CustomerClassification>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiCustomSettingPath],
    queryFn: customerSetting.getSearchCustomer,
  });

  const query = useQueryUrl<SearchParams.CustomerSetting>();
  const params = {
    page: query.page || 1,
    offset: query.offset || PER.DEFAULT,
    name: query.person_in_charge,
    email: query.email,
    faxNo: query.fax_number,
    telNo: query.phone_number,
    jusho: query.address,
    jushoCd: query.address_code,
    kokyakuKbnFlg: query.customer_classification ? 1 : undefined,
    kokyakuMei: query.company_name,
    kokyakuCd: query.customer_code,
    gyoushuId: query.job_id,
    shozaichiId: query.region_id,
  };

  const querySearchCustomer = useQuery<
    AxiosResponse<Response.CustomerSetting>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiSearchCustomerPath, params],
    queryFn: customerSetting.getSearchCustomer,
  });

  const updateCurrency = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.UpdateCurrency
  >({
    mutationKey: [apiCustomSettingPath],
    mutationFn: (args) =>
      customerSetting.updateCurrency(
        `${apiCustomSettingPath}/${args.seqno}`,
        args,
      ),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiSearchCustomerPath, params]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const deleteCustomerSetting = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.DeleteCustomerSettingItem
  >({
    mutationKey: [apiCustomSettingPath],
    mutationFn: (args) =>
      customerSetting.deleteCustomerSetting(apiCustomSettingPath, args),
    retry: 0,
    onSuccess: () => {
      queryClient.removeQueries([apiSearchCustomerPath]);
      setRowKeysSelected?.([]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      switch (errorCode) {
        case STATUS_CODE.DUPLICATE_DATA:
          return setError(t('message.E000060'), {
            type: 'custom',
          });
      }
    },
  });

  const updateOccupationAndLocation = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.UpdateOccupationAndLocation
  >({
    mutationKey: [apiUpdateOccupationAndLocationPath],
    mutationFn: (args) =>
      customerSetting.updateOccupationAndLocation(
        apiUpdateOccupationAndLocationPath,
        args,
      ),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiSearchCustomerPath, params]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  return {
    queryCustomer,
    querySearchCustomer,
    updateCurrency,
    deleteCustomerSetting,
    updateOccupationAndLocation,
  };
};

export default useCustomerSetting;
