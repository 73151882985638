import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import customerRegistration from 'src/repositories/customerRegistration';

export const apiKey = '/customers' as const;
const apiOccupationPath = '/customers/occupation';
const apiLocationPath = '/customers/location';

const useOccupationAndLocation = () => {
  const queryOccupation = useQuery<
    AxiosResponse<Response.Occupation>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiOccupationPath],
    queryFn: customerRegistration.getOccupation,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataOccupation = queryOccupation?.data?.data?.items?.map((item) => {
    return {
      label: item.name,
      value: item.seqno,
    };
  });

  const queryLocation = useQuery<
    AxiosResponse<Response.Location>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiLocationPath],
    queryFn: customerRegistration.getLocation,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataLocation = queryLocation?.data?.data?.items?.map((item) => {
    return {
      label: item.shozaichi,
      value: item.seqno,
    };
  });

  return {
    dataOccupation,
    dataLocation,
  };
};

export default useOccupationAndLocation;
