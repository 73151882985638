import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  customer_name?: string;
};

const Note: React.FC<Props> = ({ customer_name }) => {
  const { t } = useTranslation();

  return (
    <div className="note">
      <Typography.Text>{customer_name}</Typography.Text>
      <Typography.Text>{t('profit_margin_setting.note_1')}</Typography.Text>
      <Typography.Text className="note_block">
        {t('profit_margin_setting.note_2')}
      </Typography.Text>
      <Typography.Title level={4} className="note_title">
        {t('profit_margin_setting.title')}
      </Typography.Title>
      <Typography.Text className="note_block">
        {t('profit_margin_setting.note_3')}
      </Typography.Text>
      <Typography.Text>{t('profit_margin_setting.note_4')}</Typography.Text>
      <Typography.Text className="note_block">
        {t('profit_margin_setting.note_5')}
      </Typography.Text>
    </div>
  );
};

export default Note;
