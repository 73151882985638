import axiosBatch from 'src/libs/axiosBatch';
import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getListDownloadCsv = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axiosBatch({
    method: 'get',
    url,
    params,
  });
};

const downloadSingleCsv = (
  url: string,
  data: Payload.DownloadSingleCsvMasterRegistration,
) => {
  const formData = new FormData();
  formData.append('kbnmCd', data.kbnmCd);
  formData.append('locale', data.locale);
  return axiosBatch({
    responseType: 'blob',
    method: 'POST',
    url,
    data: formData,
  });
};

const getListHistory = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axiosBatch({
    method: 'get',
    url,
    params,
  });
};

const deleteMasterRegistration = (
  url: string,
  data: Payload.DeleteMasterRegistration,
) => {
  return axiosBatch({
    method: 'DELETE',
    url,
    data,
  });
};

const downloadCsv = (
  url: string,
  data: Payload.DownloadCsvMasterRegistration,
) => {
  const formData = new FormData();
  formData.append('seqnos', data.seqnos);
  return axiosBatch({
    responseType: 'blob',
    method: 'POST',
    url,
    data: formData,
  });
};

const scheduling = (
  url: string,
  data: Payload.schedulingMasterRegistration,
) => {
  return axiosBatch({
    method: 'POST',
    url,
    data,
  });
};

const uploadWork = (
  url: string,
  data: Payload.uploadWorkMasterRegistration,
) => {
  return axiosBatch({
    method: 'POST',
    url,
    data,
  });
};

const registration = (
  url: string,
  data: Payload.registrationMasterRegistration,
) => {
  return axiosBatch({
    method: 'POST',
    url,
    data,
  });
};

const runJob = (url: string, data: Payload.runJobMasterRegistration) => {
  const formData = new FormData();
  formData.append('seqno', data.seqno);
  return axiosBatch({
    method: 'POST',
    url,
    data: formData,
  });
};

const getTotalColumns = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const updateTotalColumns = (url, data: Payload.TotalColumns) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const createTotalColumns = (url, data: Payload.TotalColumns) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

export default {
  getListDownloadCsv,
  getListHistory,
  deleteMasterRegistration,
  downloadCsv,
  scheduling,
  uploadWork,
  registration,
  runJob,
  downloadSingleCsv,
  getTotalColumns,
  createTotalColumns,
  updateTotalColumns,
};
