import React from 'react';
import Error, { PATH } from 'src/pages/error';
import { IRouter } from 'src/interfaces/route';
import { t } from 'src/libs/i18n';

const routes: IRouter[] = [
  {
    children: [
      {
        index: true,
        path: PATH.NOT_FOUND,
        element: <Error.NotFound />,
        title: t('internal_pic.title'),
      },
    ],
  },
  {
    children: [
      {
        index: true,
        path: PATH.INTERNAL_SERVER,
        element: <Error.InternalServer />,
        title: t('internal_pic.title'),
      },
    ],
  },
  {
    children: [
      {
        index: true,
        path: PATH.FORBIDDEN,
        element: <Error.Forbidden />,
        title: t('internal_pic.title'),
      },
    ],
  },
  {
    children: [
      {
        index: true,
        path: '*',
        element: <Error.NotFound />,
        title: t('internal_pic.title'),
      },
    ],
  },
];

export default routes;
