import React from 'react';

import ProfitMarginSetting from 'src/containers/ProfitMarginSetting';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const ProfitMarginSettingPage: React.FC = () => {
  useTitle(t('profit_margin_setting.title'));
  return <ProfitMarginSetting />;
};

export const PATH = '/profit_margin_setting' as const;
export default ProfitMarginSettingPage;
