import { Button, Card, Col, Divider, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilePDFUploadField, SelectLanguageField } from 'src/components/form';
import { CATEGORY_PDF_REGISTER_CODE, LANGUAGE_CODE } from 'src/constants/app';
import usePDFRegistration from 'src/queries/usePDFRegistration';
import LocalStorage from 'src/utils/LocalStorage';
import modalConfirm from 'src/utils/modalConfirm';
import useForm, { FormValue } from './useForm';

type CategoryType = { file: any; gengoCd: string };

const RegistrationForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    languageOptions,
    setValue,
    watch,
    formState: { errors },
    setError,
    getLanguage,
  } = useForm();
  const { t } = useTranslation();
  const { actionPDFRegistration, queryListUpload } = usePDFRegistration({
    setError,
  });
  const termsOfServiceLanguage = 'terms_of_service.gengoCd';
  const termsOfServiceFile = 'terms_of_service.file';
  const forTheFirstTimeUsersLanguage = 'for_the_first_time_users.gengoCd';
  const forTheFirstTimeUsersFile = 'for_the_first_time_users.file';
  const electronicCatalogLanguage = 'electronic_catalog.gengoCd';
  const electronicCatalogFile = 'electronic_catalog.file';

  const [termsOfServiceFileNm, setTermsOfServiceFileNm] = useState('');
  const [forTheFirstTimeUsersFileNm, setForTheFirstTimeUsersFileNm] =
    useState('');
  const [electronicCatalogFileNm, setElectronicCatalogFileNm] = useState('');

  // Query list PDF
  const getListUpload = queryListUpload?.data?.data?.items;

  // Get list by kbnmCd
  const getByCategory = (categoryCode) => {
    let languageFieldName;
    let categoryName;
    const item = getListUpload?.filter((item) => item.kbnmCd === categoryCode);

    switch (categoryCode) {
      case CATEGORY_PDF_REGISTER_CODE.TERMS_OF_SERVICE:
        languageFieldName = termsOfServiceLanguage;
        categoryName = 'terms_of_service';
        break;
      case CATEGORY_PDF_REGISTER_CODE.FOR_THE_FIRST_TIME_USERS:
        languageFieldName = forTheFirstTimeUsersLanguage;
        categoryName = 'for_the_first_time_users';
        break;
      case CATEGORY_PDF_REGISTER_CODE.ELECTRONIC_CATALOG:
        languageFieldName = electronicCatalogLanguage;
        categoryName = 'electronic_catalog';
        break;
    }
    return { item, categoryName, languageFieldName };
  };

  // Get all Terms Of Service values
  const getTermsOfService = getByCategory(
    CATEGORY_PDF_REGISTER_CODE.TERMS_OF_SERVICE,
  );
  // Get all For The First Time Users values
  const getForTheFirstTimeUsers = getByCategory(
    CATEGORY_PDF_REGISTER_CODE.FOR_THE_FIRST_TIME_USERS,
  );
  // Get all Electronic Catalog values
  const getElectronicCatalog = getByCategory(
    CATEGORY_PDF_REGISTER_CODE.ELECTRONIC_CATALOG,
  );

  // Get language by priority
  const getLanguagePriority = (listCategory) => {
    const findByLanguageCode = (languageCode) => {
      return listCategory?.find((item) => item?.gengoCd === languageCode);
    };

    if (listCategory?.length === 0) {
      return LocalStorage.lang;
    } else if (listCategory) {
      if (findByLanguageCode(LocalStorage.lang)) {
        return findByLanguageCode(LocalStorage.lang)?.gengoCd;
      } else {
        if (
          findByLanguageCode(LANGUAGE_CODE.ENGLISH) ||
          findByLanguageCode(LANGUAGE_CODE.JAPAN)
        ) {
          return (
            findByLanguageCode(LANGUAGE_CODE.ENGLISH)?.gengoCd ||
            findByLanguageCode(LANGUAGE_CODE.JAPAN)?.gengoCd
          );
        } else {
          // Return list priority language code
          const getLanguageItem = getLanguage
            ?.map((language) => {
              // Find item that exists by language
              return listCategory?.find(
                (category) => category?.gengoCd === language?.gengoCd,
              )?.gengoCd;
            })
            ?.filter(Boolean);

          return getLanguageItem ? getLanguageItem[0] : LocalStorage.lang;
        }
      }
    }
  };

  // Set language by priority
  useEffect(() => {
    setValue(
      getTermsOfService.languageFieldName,
      getLanguagePriority(getTermsOfService.item),
    );
    setValue(
      getForTheFirstTimeUsers.languageFieldName,
      getLanguagePriority(getForTheFirstTimeUsers.item),
    );
    setValue(
      getElectronicCatalog.languageFieldName,
      getLanguagePriority(getElectronicCatalog.item),
    );
  }, [getListUpload, LocalStorage.lang]);

  // Set File name by language
  const findFileNmByLanguage = (listCategory, languageCode) => {
    const findByLanguage = listCategory?.find(
      (item) => item?.gengoCd === languageCode,
    );

    return findByLanguage ? findByLanguage?.fileNm : '';
  };

  const getLanguageTermsOfService = watch(termsOfServiceLanguage);
  const getLanguageForTheFirstTimeUsers = watch(forTheFirstTimeUsersLanguage);
  const getLanguageElectronicCatalog = watch(electronicCatalogLanguage);

  // Set the file name in the language of the checkbox
  useEffect(() => {
    setTermsOfServiceFileNm(
      findFileNmByLanguage(getTermsOfService.item, getLanguageTermsOfService),
    );
  }, [getLanguageTermsOfService]);

  useEffect(() => {
    setForTheFirstTimeUsersFileNm(
      findFileNmByLanguage(
        getForTheFirstTimeUsers.item,
        getLanguageForTheFirstTimeUsers,
      ),
    );
  }, [getLanguageForTheFirstTimeUsers]);

  useEffect(() => {
    setElectronicCatalogFileNm(
      findFileNmByLanguage(
        getElectronicCatalog.item,
        getLanguageElectronicCatalog,
      ),
    );
  }, [getLanguageElectronicCatalog]);

  // Handle registration
  const handleRegistration = (value: CategoryType) => {
    let registerArr = new Array();

    value &&
      Object.entries(value)?.filter((item) => {
        let categoryCode;
        const categoryName = item[0];
        const categoryValue = item[1];

        // Get category code by category name
        switch (categoryName) {
          case getTermsOfService.categoryName:
            categoryCode = CATEGORY_PDF_REGISTER_CODE.TERMS_OF_SERVICE;
            break;
          case getForTheFirstTimeUsers.categoryName:
            categoryCode = CATEGORY_PDF_REGISTER_CODE.FOR_THE_FIRST_TIME_USERS;
            break;
          case getElectronicCatalog.categoryName:
            categoryCode = CATEGORY_PDF_REGISTER_CODE.ELECTRONIC_CATALOG;
            break;
        }

        // Get category has file upload
        if (categoryValue?.file) {
          const registrationObj = {
            kbnmCd: categoryCode,
            gengoCd: categoryValue?.gengoCd,
            fileDir: categoryValue.file[1].id?.split('/tmp/').pop(),
            fileNm: categoryValue.file[1].displayName,
          };

          registerArr.push(registrationObj);
        }
      });
    // Check the category has file exist and handle the mutation
    if (registerArr.length > 0) {
      const checkCategoryHasFileExits = (listSubmit) => {
        const resultCheckArr = listSubmit?.map((itemSubmit) => {
          const getByLanguageAndCategoryCode = getListUpload?.find(
            (item) =>
              item.kbnmCd === itemSubmit.kbnmCd &&
              item.gengoCd === itemSubmit.gengoCd,
          );
          return getByLanguageAndCategoryCode?.fileDir ? true : false;
        });
        return resultCheckArr.includes(true) ? true : false;
      };

      // Show modal confirm when has file exist
      if (checkCategoryHasFileExits(registerArr)) {
        modalConfirm({
          t,
          title: t('pdf_registration.message_check_registration_exists'),
          okText: t('modal_confirm.button_confirm'),
          cancelText: t('modal_confirm.button_cancel'),
          maskClosable: false,
          onOk: () => {
            actionPDFRegistration.mutate(registerArr);
          },
        });
      } else {
        actionPDFRegistration.mutate(registerArr);
      }
    }
  };

  return (
    <React.Fragment>
      <Card className="upload_section">
        <Row gutter={[20, 10]} className="upload_wrapper" align="middle">
          <Col xs={12} md={8} lg={7} xl={6}>
            <SelectLanguageField<FormValue>
              controller={{
                name: termsOfServiceLanguage,
                control: control,
              }}
              selectProps={{
                options: languageOptions[0],
              }}
              label={t('pdf_registration.terms_of_service')}
              labelCol={{ xs: 24, md: 12, lg: 12, xl: 15 }}
              wrapperCol={{ xs: 24, md: 12, lg: 12, xl: 9 }}
            />
          </Col>
          <Col xs={12} md={6} lg={5} xl={4}>
            <FilePDFUploadField<FormValue>
              controller={{
                control: control,
                name: termsOfServiceFile,
              }}
              type="default"
              nameButton={t('pdf_registration.upload')}
              className="btn_upload"
              maxCount={1}
              uploadFileProps={{
                accept: 'application/pdf',
                beforeUpload: (file) => {
                  setTermsOfServiceFileNm(file?.name);
                },
              }}
            />
          </Col>
          {errors?.terms_of_service ? (
            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={12}
              xl={14}
            >
              <Typography.Text className="error_message">
                {errors?.terms_of_service?.file?.message}
              </Typography.Text>
            </Col>
          ) : (
            <Col
              xs={{ span: 12, offset: 12 }}
              md={{ span: 10, offset: 0 }}
              lg={12}
              xl={14}
            >
              <Typography.Text className="file_name_section">
                {termsOfServiceFileNm}
              </Typography.Text>
            </Col>
          )}
        </Row>
        <Row gutter={[20, 10]} className="upload_wrapper" align="middle">
          <Col xs={12} md={8} lg={7} xl={6}>
            <SelectLanguageField<FormValue>
              controller={{
                name: forTheFirstTimeUsersLanguage,
                control: control,
              }}
              selectProps={{
                options: languageOptions[0],
              }}
              label={t('pdf_registration.for_the_first_time_users')}
              labelCol={{ xs: 24, md: 12, lg: 12, xl: 15 }}
              wrapperCol={{ xs: 24, md: 12, lg: 12, xl: 9 }}
            />
          </Col>
          <Col xs={12} md={6} lg={5} xl={4}>
            <FilePDFUploadField<FormValue>
              controller={{
                control: control,
                name: forTheFirstTimeUsersFile,
              }}
              type="default"
              nameButton={t('pdf_registration.upload')}
              className="btn_upload"
              maxCount={1}
              uploadFileProps={{
                accept: 'application/pdf',
                beforeUpload: (file) => {
                  setForTheFirstTimeUsersFileNm(file?.name);
                },
              }}
            />
          </Col>
          {errors?.for_the_first_time_users ? (
            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={12}
              xl={14}
            >
              <Typography.Text className="error_message">
                {errors?.for_the_first_time_users?.file?.message}
              </Typography.Text>
            </Col>
          ) : (
            <Col
              xs={{ span: 12, offset: 12 }}
              md={{ span: 10, offset: 0 }}
              lg={12}
              xl={14}
            >
              <Typography.Text className="file_name_section">
                {forTheFirstTimeUsersFileNm}
              </Typography.Text>
            </Col>
          )}
        </Row>
        <Row gutter={[20, 10]} className="upload_wrapper" align="middle">
          <Col xs={12} md={8} lg={7} xl={6}>
            <SelectLanguageField<FormValue>
              controller={{
                name: electronicCatalogLanguage,
                control: control,
              }}
              selectProps={{
                options: languageOptions[0],
              }}
              label={t('pdf_registration.electronic_catalog')}
              labelCol={{ xs: 24, md: 12, lg: 12, xl: 15 }}
              wrapperCol={{ xs: 24, md: 12, lg: 12, xl: 9 }}
            />
          </Col>
          <Col xs={12} md={6} lg={5} xl={4}>
            <FilePDFUploadField<FormValue>
              controller={{
                control: control,
                name: electronicCatalogFile,
              }}
              type="default"
              nameButton={t('pdf_registration.upload')}
              className="btn_upload"
              maxCount={1}
              uploadFileProps={{
                accept: 'application/pdf',
                beforeUpload: (file) => {
                  setElectronicCatalogFileNm(file?.name);
                },
              }}
            />
          </Col>
          {errors?.electronic_catalog ? (
            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 10, offset: 0 }}
              lg={12}
              xl={14}
            >
              <Typography.Text className="error_message">
                {errors?.electronic_catalog?.file?.message}
              </Typography.Text>
            </Col>
          ) : (
            <Col
              xs={{ span: 12, offset: 12 }}
              md={{ span: 10, offset: 0 }}
              lg={12}
              xl={14}
            >
              <Typography.Text className="file_name_section">
                {electronicCatalogFileNm}
              </Typography.Text>
            </Col>
          )}
        </Row>
        <Divider className="divider" />
        <div className="btn_registration">
          <Button
            className="btn_submit"
            onClick={handleSubmit(handleRegistration)}
            loading={actionPDFRegistration.isLoading}
          >
            {t('pdf_registration.register')}
          </Button>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default RegistrationForm;
