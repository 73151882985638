import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import { useEffect } from 'react';

export type Calendar = {
  dateTimeReflection: string;
};

export type FormValue = {
  dateTimeReflections: Calendar[];
};

const useForm = (queryListHistory) => {
  const data = queryListHistory?.data?.data?.items;
  const formInfo = useHookForm<FormValue>({});
  const fieldArray = useFieldArray({
    name: 'dateTimeReflections',
    control: formInfo.control,
  });
  const { append, remove } = fieldArray;
  useEffect(() => {
    data?.forEach((item) => {
      append({
        dateTimeReflection: item.seqno,
      });
    });
    return () => {
      remove();
    };
  }, [data && data.length]);
  return { ...formInfo };
};

export default useForm;
