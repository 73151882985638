import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SettingType } from '../index';
import SpecifyUnitLabel from '../SpecifyUnitLabel';

type Props = {
  type: SettingType;
};
const SettingLabel: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className="setting_label">
        <Col xs={3} md={1}>
          <Typography.Text>
            {type === 'standard' ? '01' : type === 'type_shape' ? '02' : '03'}
          </Typography.Text>
        </Col>
        <Col xs={21} md={8}>
          <Typography.Text>
            {type === 'standard'
              ? t('profit_margin_setting.standard_setting')
              : type === 'type_shape'
              ? t('profit_margin_setting.setting_by_type_and_shape')
              : t('profit_margin_setting.setting_by_items_or_cutting_method')}
          </Typography.Text>
        </Col>
        <Col md={3} className="desktop">
          <SpecifyUnitLabel />
        </Col>
        <Col md={3} className="desktop">
          <Typography.Text>
            {t('profit_margin_setting.0_to_299kg')}
          </Typography.Text>
        </Col>
        <Col md={3} className="desktop">
          <Typography.Text>
            {t('profit_margin_setting.300_to_749kg')}
          </Typography.Text>
        </Col>
        <Col md={3} className="desktop">
          <Typography.Text>
            {t('profit_margin_setting.750_to_1499kg')}
          </Typography.Text>
        </Col>
        <Col md={3} className="desktop">
          <Typography.Text>
            {t('profit_margin_setting.more_than_1500kg')}
          </Typography.Text>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SettingLabel;
