import React, { useEffect, useState } from 'react';
import { TableProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { t } from 'src/libs/i18n';
import { formatPeriodDateTime } from 'src/utils/helper';
import { PER, POST_FLAG_PRIVATE } from 'src/constants/app';
import useTableCommon from 'src/hooks/useTable';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { DisplayCondition } from 'src/components/common';
import useNotificationSetting from 'src/queries/useNotificationSetting';

const useTable = () => {
  const query = useQueryUrl();
  const { page = 1, limit = PER.DEFAULT } = query;
  const [notificationSelected, setNotificationSelected] = useState<number[]>(
    [],
  );
  const { queryNotificationSetting } = useNotificationSetting({
    setRowKeysSelected: undefined,
  });

  const {
    pagination: paginationCommon,
    rowSelection,
    rowSelected,
    ...rest
  } = useTableCommon({
    hasSelect: true,
    maxPageSize: 500,
  });

  useEffect(() => {
    // get list id notification selected from rowSelected
    let notificationList = queryNotificationSetting?.data?.data?.items
      ?.filter((item) => rowSelected.includes(item?.seqno))
      .map((item) => item?.seqno);
    setNotificationSelected(notificationList || []);
  }, [queryNotificationSetting?.data?.data?.items, rowSelected.length]);

  const columns: ColumnsType<Response.NotificationEntity> = [
    {
      title: t('notification_setting.no'),
      dataIndex: 'index',
      render(value, item, index) {
        return (page - 1) * limit + index + 1;
      },
    },
    {
      title: t('notification_setting.title'),
      dataIndex: 'title',
      width: 400,
      render: (text, record) => {
        return record?.rmOsiraseContent?.title;
      },
    },
    {
      title: t('notification_setting.status'),
      dataIndex: 'hyoujiBasho',
      render: (text, record) => {
        return <DisplayCondition display={record?.hyoujiBasho?.toString()} />;
      },
    },
    {
      title: t('notification_setting.created_by'),
      render: (text, record) => {
        return record?.rmWebUser?.smei;
      },
    },
    {
      title: t('notification_setting.publish_date_time'),
      render: (text, record) => {
        return formatPeriodDateTime(record?.postedFrom, record?.postedTo);
      },
    },
    {
      title: t('notification_setting.flag'),
      dataIndex: 'postedFlg',
      render(text, record) {
        return record.postedFlg === POST_FLAG_PRIVATE
          ? t('notification_setting.flag_private')
          : t('notification_setting.flag_public');
      },
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    loading: {
      spinning: queryNotificationSetting.isFetching,
      indicator: <LoadingOutlined />,
    },
    dataSource: queryNotificationSetting.data?.data?.items,
    rowKey: 'seqno',
    size: 'middle',
    pagination: {
      ...paginationCommon,
      total: queryNotificationSetting?.data?.data?.total,
      current: queryNotificationSetting?.data?.data?.page,
      pageSize: queryNotificationSetting?.data?.data?.offset,
    },
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
    locale: {
      emptyText: t('message.E000025'),
    },
  };

  return {
    tableProps,
    ...rest,
    rowSelected,
    notificationSelected,
  };
};

export default useTable;
