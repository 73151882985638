import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import notificationSystem from 'antd/lib/notification';
import notification from 'src/repositories/notification';
import { useTranslation } from 'react-i18next';

export const apiKey = '/notifications' as const;

export const useDetailNotificationSetting = (
  id: number,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { t } = useTranslation();

  const apiPath = `/notifications/${id}`;
  const queryNotificationSettingDetail = useQuery<
    AxiosResponse<Response.rmOsiraseContentItem>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath],
    enabled: !!id,
    cacheTime: 0,
    queryFn: notification.getNotificationSettingDetail,
    onError(err) {
      setOpenModal(false);
      notificationSystem.error({ message: t('message.E000060') || 'Error' });
    },
  });

  return { queryNotificationSettingDetail };
};
