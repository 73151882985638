import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import customerSetting from 'src/repositories/customerSetting';
import { FormValue } from '../containers/CustomerSetting/SearchComponent/useForm';
import profitMargin from '../repositories/profitMargin';

const apiSearchCustomerPath: string = '/customers/company';
const apiCopyProfitMarginPath: string = '/profit-margins/copies';

export type SearchCustomerRequest = {
  form: FormValue;
  page?: number;
  offset?: number;
};

export function toSearchParams(
  form: FormValue,
  page?: number,
  offset?: number,
) {
  return {
    page: page || 1,
    offset: offset || 1,
    name: form?.person_in_charge,
    email: form?.email,
    faxNo: form?.fax_number,
    telNo: form?.phone_number,
    jusho: form?.address,
    jushoCd: form?.address_code,
    kokyakuMei: form?.company_name,
    kokyakuCd: form?.customer_code,
  };
}

const useCustomer = (args: SearchCustomerRequest) => {
  const searchCustomer = useQuery<
    AxiosResponse<Response.CustomerSetting>,
    AxiosError<Response.Error>
  >({
    queryKey: [
      apiSearchCustomerPath,
      toSearchParams(args.form, args.page, args.offset),
    ],
    queryFn: customerSetting.getSearchCustomer,
  });
  const copyProfitMargin = useMutation<
    AxiosResponse<{}>,
    AxiosError,
    Payload.CopyProfitMarginRequest
  >({
    mutationKey: [apiCopyProfitMarginPath],
    mutationFn: (payload: Payload.CopyProfitMarginRequest) =>
      profitMargin.copyProfitMargin(apiCopyProfitMarginPath, payload),
  });
  return {
    searchCustomer,
    copyProfitMargin,
  };
};

export default useCustomer;
