import * as Yup from 'yup';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { t } from 'src/libs/i18n';
import { REGEX } from 'src/constants/app';

export type FormValue = {
  customer_org_name: string;
  customer_address_code: string;
  customer_address: string;
  customer_phone: string;
  customer_fax: string;
  customer_staff: string;
  customer_type: string;
  order_limit_price: string;
  order_condition: string;
  note: string;
  address_recieve_name: string;
  address_recieve_code: string;
  address_recieve: string;
  address_recieve_phone: string;
  customer_email: string;
  customer_name: string;
  job_id: number;
  region_id: number;
};

const useForm = () => {
  const schema = Yup.object().shape({
    customer_org_name: Yup.string().required(
      t('message.E000003', {
        0: t('customer_registration.customer_org_name'),
      }),
    ),
    customer_address_code: Yup.string().required(
      t('message.E000003', {
        0: t('customer_registration.customer_address_code'),
      }),
    ),
    customer_address: Yup.string().required(
      t('message.E000003', {
        0: t('customer_registration.customer_address'),
      }),
    ),
    customer_phone: Yup.string()
      .required(
        t('message.E000003', {
          0: t('customer_registration.customer_phone'),
        }),
      )
      .matches(
        REGEX.REGEX_PHONE_NUMBER,
        t('message.E000011', {
          0: t('customer_registration.customer_phone'),
        }),
      ),
    customer_fax: Yup.string().matches(
      REGEX.REGEX_PHONE_NUMBER,
      t('message.E000011', {
        0: t('customer_registration.customer_fax'),
      }),
    ),
    customer_staff: Yup.string().required(
      t('message.E000003', {
        0: t('customer_registration.customer_staff_error'),
      }),
    ),
    order_limit_price: Yup.string(),
    order_condition: Yup.string(),
    note: Yup.string(),
    address_recieve_name: Yup.lazy(() =>
      Yup.string().when(
        ['address_recieve_code', 'address_recieve', 'address_recieve_phone'],
        {
          is: (address_recieve_code, address_recieve, address_recieve_phone) =>
            address_recieve_code || address_recieve || address_recieve_phone,
          then: Yup.string().required(t('message.E000084')),
        },
      ),
    ),
    address_recieve_code: Yup.lazy(() =>
      Yup.string().when(
        ['address_recieve_name', 'address_recieve', 'address_recieve_phone'],
        {
          is: (address_recieve_name, address_recieve, address_recieve_phone) =>
            address_recieve_name || address_recieve || address_recieve_phone,
          then: Yup.string().required(t('message.E000084')),
        },
      ),
    ),
    address_recieve: Yup.lazy(() =>
      Yup.string().when(
        [
          'address_recieve_name',
          'address_recieve_code',
          'address_recieve_phone',
        ],
        {
          is: (
            address_recieve_name,
            address_recieve_code,
            address_recieve_phone,
          ) =>
            address_recieve_name ||
            address_recieve_code ||
            address_recieve_phone,
          then: Yup.string().required(t('message.E000084')),
        },
      ),
    ),
    address_recieve_phone: Yup.lazy(() =>
      Yup.string().when(
        ['address_recieve_name', 'address_recieve_code', 'address_recieve'],
        {
          is: (address_recieve_name, address_recieve_code, address_recieve) =>
            address_recieve_name || address_recieve_code || address_recieve,
          then: Yup.string().required(t('message.E000084')),
        },
      ),
    ),
    customer_email: Yup.string()
      .required(
        t('message.E000003', {
          0: t('customer_registration.customer_email'),
        }),
      )
      .matches(
        REGEX.REGEX_EMAIL_MATCHES,
        t('message.E000012', { 0: t('customer_registration.customer_email') }),
      ),
    customer_name: Yup.string().required(
      t('message.E000003', {
        0: t('customer_registration.customer_name_error'),
      }),
    ),
  });

  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onSubmit',
  });

  return { formInfo };
};

export default useForm;
