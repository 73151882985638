import { QueryFunctionContext } from '@tanstack/react-query';

import axios from 'src/libs/axios';

const getSymbols = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getTaxSettings = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const createTaxSettings = (url, data: Payload.TaxExChangeSetting) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const updateTaxSettings = (url, data: Payload.TaxExChangeSetting) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const getCurrencyTransfers = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const createCurrencyTransfers = (url, data: Payload.CurrencyTransfers[]) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const getCurrentCurrencyUnit = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const createCurrentCurrencyUnit = (url, data: Payload.CurrentCurrencyUnit) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const updateCurrentCurrencyUnit = (url, data: Payload.CurrentCurrencyUnit) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const checkExistCurrencyTransferBySymbol = (
  url: string,
  params: Payload.CheckTaxSelectSymbol,
) => {
  return axios({
    method: 'get',
    url,
    params,
  });
};

const getApiList = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getKeijoList = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getHinshList = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

export default {
  getSymbols,
  getTaxSettings,
  createTaxSettings,
  updateTaxSettings,
  getCurrencyTransfers,
  createCurrencyTransfers,
  getCurrentCurrencyUnit,
  createCurrentCurrencyUnit,
  updateCurrentCurrencyUnit,
  checkExistCurrencyTransferBySymbol,
  getApiList,
  getHinshList,
  getKeijoList,
};
