export const INITIAL_PASSWORD: App.OptionsFnc = (t) => [
  { label: t('user_registration_change.unsent'), value: 'UNSENT' },
  { label: t('user_registration_change.sent'), value: 'SENT' },
];

export const LOCKED_STATE: App.OptionsFnc = (t) => [
  {
    label: t('user_registration_change.unlocked'),
    value: 'UNLOCK',
  },
  { label: t('user_registration_change.locked'), value: 'LOCK' },
];
