import { Modal, ModalFuncProps } from 'antd';
import { TFunction } from 'i18next';
import { createBrowserHistory } from 'history';

type Props = Omit<ModalFuncProps, 'type'> & { t: TFunction };

const modalConfirm = (props: Props) => {
  const browserHistory = createBrowserHistory();
  const {
    t,
    title,
    okText,
    cancelText,
    content,
    okButtonProps,
    cancelButtonProps,
    maskClosable,
    wrapClassName,
    ...rest
  } = props;

  browserHistory.listen(() => {
    Modal.destroyAll();
  });

  return Modal.confirm({
    ...rest,
    title,
    content,
    className: 'confirm_modal',
    maskClosable: maskClosable,
    okText: okText || t('button.confirm'),
    cancelText: cancelText || t('button.cancel'),
    icon: null,
    wrapClassName,
    cancelButtonProps: {
      type: 'ghost',
      block: true,
      danger: true,
      className: 'btn_cancel',
      ...cancelButtonProps,
    },
    okButtonProps: {
      block: true,
      ...okButtonProps,
    },
    centered: true,
    autoFocusButton: null,
  });
};

export default modalConfirm;
