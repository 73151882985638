import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAppContext } from 'src/store';
import { APP_ACTIONS } from 'src/constants/app';
import LocalStorage from 'src/utils/LocalStorage';
import useLanguage from 'src/queries/useLanguage';

const LangSelect: React.FC = () => {
  const { i18n } = useTranslation();
  const [, dispatch] = useAppContext();
  let siteInfo: any = LocalStorage.initSiteInfo;

  if (!siteInfo?.rmGengos) {
    const { data } = useLanguage();
    siteInfo = data;
  }

  const changeLang = async (lang) => {
    await i18n.changeLanguage(lang);
    await LocalStorage.changeLang(lang);
    dispatch({
      type: APP_ACTIONS.CHANGE_LANG,
      payload: lang,
    });
  };

  useEffect(() => {
    // setup multi font when change language
    document.documentElement.lang = LocalStorage.lang;
  }, [LocalStorage.lang]);

  return (
    <div className="lang-select">
      {siteInfo && (
        <Select size="small" onChange={changeLang} value={LocalStorage.lang}>
          {siteInfo?.map((item) => (
            <Select.Option
              className={classNames({
                'lang-ja': item.gengoCd === 'ja',
                'lang-en': item.gengoCd === 'en',
                'lang-vi': item.gengoCd === 'vi',
                'lang-ko': item.gengoCd === 'ko',
                'lang-th': item.gengoCd === 'th',
                'lang-zh': item.gengoCd === 'zh',
                'lang-tw': item.gengoCd === 'tw',
              })}
              key={item.gengoCd}
              value={item.gengoCd}
            >
              {item.gengoNm}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default LangSelect;
