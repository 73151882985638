import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Row, Space, TableProps, Tooltip } from 'antd';

import useTableCommon from 'src/hooks/useTable';
import { FormValue } from 'src/containers/CustomerSetting/SearchComponent/useForm';
import useCustomer from 'src/queries/useCustomer';
import { MAX_PAGE_SIZE_POPUP } from 'src/constants/app';
import { ellipsisByLength } from 'src/utils/helper';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

type Props = {
  form: FormValue;
  singleSelect?: boolean;
  clearErrorMessage: () => void;
};

const useTable = ({ form, singleSelect, clearErrorMessage }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const breakPoint = useBreakpoint();
  const stripLength =
    breakPoint.lg || breakPoint.xl || breakPoint.xxl ? 24 : 12;
  const {
    pagination: paginationCommon,
    rowSelection,
    setRowKeysSelected,
    rowSelected,
    ...rest
  } = useTableCommon({
    hasSelect: true,
  });

  const { searchCustomer, copyProfitMargin } = useCustomer({
    form,
    page,
    offset: MAX_PAGE_SIZE_POPUP,
  });

  const columns = [
    {
      title: t('customer_search_form.customer_no'),
      dataIndex: 'kokyakuCd',
      key: 'kokyakuCd',
      width: 100,
    },
    {
      title: t('customer_search_form.customer_name'),
      children: [
        {
          title: t('customer_setting.address'),
          dataIndex: 'kokyakuMei_jusho',
          key: 'kokyakuMei_jusho',
          width: 210,
          ellipsis: {
            showTitle: false,
          },
          render: (_, row, index) => {
            return (
              <React.Fragment>
                <Row justify="center" className="text-middle">
                  <Tooltip placement="topLeft" title={row.kokyakuMei}>
                    {row.kokyakuMei}
                  </Tooltip>
                </Row>
                <Divider />
                <Row justify="center" className="text-middle">
                  <Tooltip placement="topLeft" title={row.jusho}>
                    {ellipsisByLength(row.jusho, stripLength)}
                  </Tooltip>
                </Row>
              </React.Fragment>
            );
          },
        },
      ],
    },
    {
      title: t('customer_search_form.phone'),
      children: [
        {
          title: t('customer_search_form.fax'),
          dataIndex: 'telno_faxno',
          key: 'telno_faxno',
          width: 105,
          render: (_, row) => {
            return (
              <React.Fragment>
                <Row justify="center" className="text-middle phone-number">
                  {row.telno || '-'}
                </Row>
                <Divider />
                <Row justify="center" className="text-middle phone-number">
                  {row.faxno || '-'}
                </Row>
              </React.Fragment>
            );
          },
        },
      ],
    },
    {
      title: t('customer_search_form.person_in_charge'),
      dataIndex: 'name',
      key: 'name',
      width: 105,
      ellipsis: {
        showTitle: false,
      },
      render: (_, row) => {
        return (
          <Row className="mb-0 pt-12 pic-name" justify="center" align="middle">
            <Tooltip placement="topLeft" title={row.name}>
              {row.name}
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: searchCustomer?.data?.data?.items,
    loading: {
      spinning: searchCustomer.isLoading,
      indicator: <LoadingOutlined />,
    },
    bordered: false,
    rowKey: 'seqno',
    className: 'custom_table customer-search-table',
    rowSelection: {
      ...rowSelection,
      hideSelectAll: singleSelect,
      onSelect: (data, selected) => {
        clearErrorMessage();
        return singleSelect
          ? selected
            ? setRowKeysSelected([data.seqno])
            : setRowKeysSelected([])
          : // @ts-ignore
            rowSelection?.onSelect?.(data, selected);
      },
      getCheckboxProps: (record) => ({
        disabled:
          singleSelect && !!rowSelected[0] && record.seqno !== rowSelected[0],
      }),
    },
    scroll: { x: 'max-content' },
    size: 'middle',
    pagination: {
      ...paginationCommon,
      onChange: handlePageChange,
      showTotal(total) {
        return (
          <Space className="show_total_space_container">
            <span>
              {t('table.show_items', { total: total.toLocaleString() })}
            </span>
            <Divider type="vertical" />
          </Space>
        );
      },
      position: ['bottomRight'],
      total: searchCustomer?.data?.data?.total,
      current: searchCustomer?.data?.data?.page,
      pageSize: searchCustomer?.data?.data?.offset,
    },
    locale: {
      emptyText: t('message.E000024'),
    },
  };

  return {
    tableProps,
    searchCustomer,
    setPage,
    copyProfitMargin,
    rowSelected,
    setRowKeysSelected,
    ...rest,
  };
};

export default useTable;
