import React from 'react';

import { PATH } from 'src/pages/customerDeliverySetting';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { t } from 'src/libs/i18n';
import { SCREENS } from 'src/constants/screens';

const CustomerDeliverySettingRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/customerDeliverySetting')),
        title: t('menu_home.customer_setting'),
        screenId: SCREENS.R1507,
      },
    ],
  },
];

export default CustomerDeliverySettingRoute;
