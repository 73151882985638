import React from 'react';

import UserRegistrationChange from 'src/containers/UserRegistrationChange';
import { withRemountOnHistoryPush } from 'src/hocs/withRemountOnHistoryPush';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const UserRegistrationChangePage: React.FC = () => {
  useTitle(t('user_registration_change.title'));
  return <UserRegistrationChange />;
};

export const PATH = '/user_registration_change' as const;
export default withRemountOnHistoryPush(UserRegistrationChangePage);
