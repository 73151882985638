import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { t } from 'src/libs/i18n';

export type FormItem = {
  address_code: number;
  shipping_address: string;
};

export type FormValue = {
  customer_delivery_setting: FormItem[];
};

type Props = {
  dataSource?: Response.CustomerDeliverySettingsItem[];
};

const useForm = ({ dataSource }: Props) => {
  const tableInputSchema = {
    address_code: Yup.string().required(
      t('message.E000003', {
        0: t('customer_delivery_setting.address_code_col'),
      }),
    ),
    shiping_address: Yup.string().required(
      t('message.E000003', {
        0: t('customer_delivery_setting.shipping_address_col'),
      }),
    ),
  };
  const schema = Yup.object().shape({
    customer_delivery_setting: Yup.array().of(
      Yup.object().shape(tableInputSchema),
    ),
  });

  const form = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const fieldArray = useFieldArray({
    name: 'customer_delivery_setting',
    control: form.control,
  });

  const { fields, append, remove } = fieldArray;

  useEffect(() => {
    dataSource?.forEach((item, index) => {
      append(
        {
          address_code: item?.jushoCd,
          shipping_address: item?.otodokesakiJusho,
        },
        { shouldFocus: false },
      );
    });
    return () => {
      remove();
    };
  }, [dataSource]);

  return { ...form, fields, fieldArray };
};

export default useForm;
