import * as Yup from 'yup';
import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useQueryUrl from 'src/hooks/useQueryUrl';

export type FormValue = {
  email: string;
  name: string;
  initial_password: string;
  locked_state: string;
};

const useForm = () => {
  const query = useQueryUrl();

  const defaultValues: FormValue = {
    email: query.email,
    name: query.name,
    initial_password: query.initial_password,
    locked_state: query.locked_state,
  };

  const schema = Yup.object().shape({});

  const form = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  return { ...form };
};

export default useForm;
