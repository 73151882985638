export enum UnitPrices {
  WON = 'WON',
  BHT = 'BHT',
  USD = 'USD',
  TWD = 'TWD',
  YEN = 'YEN',
  VND = 'VND',
  PERCENT = 'PERCENT',
  CNY = 'CNY',
}

export enum SetdnKakom {
  STANDARD_CUTTING = '標準切断',
  LENGTH_CUTTING = '長さ切断',
  STANDARD_LENGTH = '定尺',
}

export enum ApiTaniType {
  PURCHASE_AMOUNT = 'PURCHASE_AMOUNT',
  WEIGHT = 'WEIGHT',
  EXCLUDED_PROFIT_RATE = 'EXCLUDED_PROFIT_RATE',
}

export enum ApiList {
  IS_DEFAULT = '1',
  NOT_DEFAULT = '0',
}
