import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import customerRegistration from 'src/repositories/customerRegistration';
import history from 'src/libs/history';
import { PATH as CUSTOMER_SETTING_PATH } from 'src/pages/customerSetting';

export const apiKey = '/customers' as const;

const useCustomerRegistration = (setError) => {
  const { t } = useTranslation();

  const saveCustomerRegistration = useMutation<
    AxiosResponse<Response.CustomerRegistration>,
    AxiosError,
    Payload.CustomerRegistration
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => customerRegistration.createCustomer(apiKey, args),
    retry: 0,
    onSuccess(data) {
      const errorList = _.get(data, 'data.item.errorList');
      errorList?.forEach((error) => {
        if (error.objectName == 'kokyaku') {
          setError('customer_address_code', {
            type: 'custom',
            message: t('message.E000015', {
              0: t('customer_registration.customer_address_code'),
            }),
          });
        }
        if (error.objectName == 'name') {
          setError('customer_staff', {
            type: 'custom',
            message: t('message.E000030', {
              0: t('customer_registration.customer_staff_error'),
            }),
          });
        }
        if (error.objectName == 'otokesaki') {
          setError('address_recieve_code', {
            type: 'custom',
            message: t('message.E000015', {
              0: t('customer_registration.address_recieve_code'),
            }),
          });
        }
        if (error.objectName == 'email') {
          setError('customer_email', {
            type: 'custom',
            message: t('message.E000072', {
              0: t('customer_registration.customer_email_error'),
            }),
          });
        }
      });
      if (errorList && errorList.length > 0) {
        return;
      }
      history.push(CUSTOMER_SETTING_PATH);
    },
    onError(err) {
      const error = _.get(err, 'response.data');
      return error;
    },
  });

  return {
    saveCustomerRegistration,
  };
};

export default useCustomerRegistration;
