import React from 'react';
import { Menu, Layout, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';

import LangSelect from 'src/components/layout/TopNav/LangSelect';
import useMenu from 'src/hooks/useMenu';
import LocalStorage from 'src/utils/LocalStorage';
import { PATH } from 'src/pages/auth';
import history from 'src/libs/history';
import useClickOutiside from 'src/hooks/useClickOutiside';

type Props = {
  setToggleMenuMobile: (data: boolean) => void;
};

const AppMenuMobile: React.FC<Props> = ({ setToggleMenuMobile }) => {
  const { menuItems, getSelectedKey } = useMenu('mobile');
  const { t } = useTranslation();

  const menuRef = useClickOutiside(() => {
    setToggleMenuMobile(false);
  });

  const onLogout = () => {
    LocalStorage.removeInfo();
    history.push(PATH.SIGN_IN);
  };

  return (
    <Layout className={'menu_mobile'} ref={menuRef}>
      <div className={'top-menu'}>
        <Menu
          style={{ width: 256 }}
          defaultSelectedKeys={getSelectedKey()}
          defaultOpenKeys={['categories']}
          mode="inline"
          items={menuItems}
          onClick={() => setToggleMenuMobile(false)}
        />
      </div>
      <div className="lang-select-mobile">
        <LangSelect />
        <Button
          type="primary"
          className="lang-select_logout"
          icon={<LogoutOutlined />}
          onClick={onLogout}
        >
          {t('header.logout')}
        </Button>
      </div>
    </Layout>
  );
};

export default AppMenuMobile;
