import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getAllFrontAccount = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const updateLockStatus = (url, data: Payload.UpdateLockStatus) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const deleteAccount = (url, data: Payload.DeleteAccount) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

const sendMailResetPassword = (url, data: Payload.SendMailResetPassword) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const insertAccountFrontsite = (url, data: Payload.InsertAccountFrontsite) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const updateAccountFrontsite = (url, data: Payload.UpdateAccountFrontsite) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

export default {
  getAllFrontAccount,
  updateLockStatus,
  deleteAccount,
  sendMailResetPassword,
  insertAccountFrontsite,
  updateAccountFrontsite,
};
