import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getQuotation = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const exportCSV = (url: string, data: Payload.ExportCSV) => {
  return axios({
    responseType: 'blob',
    method: 'post',
    url,
    data,
  });
};

const getLoyalCustomer = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const exportPDF = (url: string, data: Payload.ExportPDF) => {
  return axios({
    responseType: 'blob',
    method: 'post',
    url,
    data,
  });
};

const getCurrentCurrencyUnit = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getCurrencyTransfers = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const deleteQuotation = (url: string, data: Payload.DeleteQuotation) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

const updateOrderStatus = (url: string, data: Payload.UpdateOrderStatus) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const getOrderStatus = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

export default {
  getQuotation,
  exportCSV,
  getLoyalCustomer,
  exportPDF,
  getCurrencyTransfers,
  getCurrentCurrencyUnit,
  deleteQuotation,
  getOrderStatus,
  updateOrderStatus,
};
