export const HASUTANI_TYPE: App.OptionsFnc = (t) => [
  { label: t('profit_margin_setting.10th_place'), value: 'TENS_PLACE' },
  { label: t('profit_margin_setting.1st_place'), value: 'FIRST_PLACE' },
  {
    label: t('profit_margin_setting.first_digit'),
    value: 'FIRST_DECIMAL_PLACE',
  },
  {
    label: t('profit_margin_setting.2nd_digit'),
    value: 'SECOND_DECIMAL_PLACE',
  },
  { label: t('profit_margin_setting.3rd_digit'), value: 'THIRD_DECIMAL_PLACE' },
  {
    label: t('profit_margin_setting.4th_digit'),
    value: 'FOURTH_DECIMAL_PLACE',
  },
];

export const MARUME_HOHO: App.OptionsFnc = (t) => [
  {
    label: t('profit_margin_setting.truncation'),
    value: 'TRUNCATE',
  },
  { label: t('profit_margin_setting.round_up'), value: 'ROUND_UP' },
  { label: t('profit_margin_setting.rounding'), value: 'ROUNDING' },
];

export const TANI_TYPE: App.OptionsFnc = (t) => [
  {
    label: t('profit_margin_setting.purchase_amount'),
    value: 'PURCHASE_AMOUNT',
  },
  { label: t('profit_margin_setting.weight'), value: 'WEIGHT' },
  {
    label: t('profit_margin_setting.not_included'),
    value: 'EXCLUDED_PROFIT_RATE',
  },
];
