import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getListUpload = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const pdfRegistration = (url, data: Payload.PDFRegistration[]) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const deletePDF = (url, data: Payload.DeletePDFRegistration) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

export default {
  getListUpload,
  pdfRegistration,
  deletePDF,
};
