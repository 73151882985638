import dayjs from 'dayjs';
import _ from 'lodash';
import JSEncrypt from 'jsencrypt';
import { TypeFile } from 'get-real-file-type';

import {
  CATEGORIES_ID,
  DATE_FORMAT,
  DATE_FORMAT_JAPAN,
  DATE_TIME_HOUR_SECOND,
  LABEL,
  MACHINING_METHOD,
  REGEX,
  STATUS_PLUGIN,
  fileTypes,
} from 'src/constants/app';
import { DisplayCondition } from 'src/enums/notification';
import { t } from 'src/libs/i18n';
import LocalStorage from './LocalStorage';

type GetProductNameParams = {
  item;
  isHakudoShhnm: boolean;
  defaultName?: string;
};

export const formatPeriodDateTime = (
  dateFrom: string | null,
  dateTo: string | null,
  defaultValue: string = '',
) => {
  if (dateFrom && dateTo) {
    const formatFrom = DATE_TIME_HOUR_SECOND;
    const formatTo = DATE_FORMAT;
    return `${formatDateFromString(
      dateFrom,
      formatFrom,
      formatTo,
    )} ~ ${formatDateFromString(dateTo, formatFrom, formatTo)}`;
  }
  return defaultValue;
};

export const formatDateFromString = (
  dateString: string | null,
  formatFrom: string,
  formatTo: string,
) => {
  if (dateString) {
    return dayjs(dateString, formatFrom).format(formatTo);
  }
  return '';
};

export const mbStrWidth = (input) => {
  let len = 0;
  for (let i = 0; i < input?.length; i++) {
    let code = input.charCodeAt(i);
    if (
      (code >= 0x0020 && code <= 0x1fff) ||
      (code >= 0xff61 && code <= 0xff9f)
    ) {
      len += 1;
    } else if ((code >= 0x2000 && code <= 0xff60) || code >= 0xffa0) {
      len += 2;
    } else {
      len += 0;
    }
  }
  return len;
};

export const isValidPassword = (pwd) => {
  const containsNumber = REGEX.REGEX_NUMBER;
  const containsUpperCase = REGEX.REGEX_UPPER_CASE;
  const containsLowerCase = REGEX.REGEX_LOWER_CASE;
  const containsSymbol = REGEX.REGEX_SPECIAL_CHARACTER;

  let matches = 0;

  if (containsNumber.test(pwd)) {
    matches++;
  }

  if (containsUpperCase.test(pwd)) {
    matches++;
  }

  if (containsLowerCase.test(pwd)) {
    matches++;
  }

  if (containsSymbol.test(pwd)) {
    matches++;
  }

  return pwd && matches >= 2;
};

export const getDisplayCondition = (checkboxValues) => {
  switch (checkboxValues?.length) {
    case 1:
      return checkboxValues[0];
    case 2:
      return DisplayCondition.BOTH;
    case 0:
    default:
      return [];
  }
};

export const validateFormat = (date, format) => {
  return dayjs(date, format).format(format) === date;
};

export const parseNumDigit = (n, length: number) => {
  let len = length - ('' + n).length;
  return (len > 0 ? new Array(++len).join('0') : '') + n;
};

export const numberFormat = (number) => {
  return number?.toLocaleString('en-US');
};

export const optionSelect = (size: 100 | 200 | 500 = 200) => {
  const PAGE_SIZE_OPTIONS_100 = [
    { label: '30', value: 30 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  const PAGE_SIZE_OPTIONS_200 = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
  ];
  const PAGE_SIZE_OPTIONS_500 = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: '300', value: 300 },
    { label: '400', value: 400 },
    { label: '500', value: 500 },
  ];
  if (size === 100) {
    return PAGE_SIZE_OPTIONS_100;
  } else if (size === 500) {
    return PAGE_SIZE_OPTIONS_500;
  }
  return PAGE_SIZE_OPTIONS_200;
};
export const formatDeleteHistoryCsv = (param, queryListHistory) => {
  const listCsvDelete: Payload.DeleteMasterRegistrationEntity[] = [];
  param.forEach((seqno) => {
    const item = queryListHistory.data?.data.items.filter(
      (item) => item.seqno == seqno,
    )[0];
    const objCsv: Payload.DeleteMasterRegistrationEntity = {
      seqno: seqno,
      kbnmCd: item?.kbnmCd,
      status: item?.status,
    };
    listCsvDelete.push(objCsv);
  });

  return listCsvDelete;
};

export const formatDate = (date, character = '') => {
  const dateValue = dayjs.unix(date);
  const formattedDate = dateValue.format(`YYYY-MM-DD${character} HH:mm`);
  return formattedDate;
};

export const getCurrentDateTime = () => {
  const currentDate = new Date();
  return (
    currentDate.getFullYear() +
    '-' +
    (currentDate.getMonth() + 1) +
    '-' +
    currentDate.getDate() +
    ' ' +
    currentDate.getHours() +
    ':' +
    currentDate.getMinutes()
  );
};

export const getStatusUploadCsv = (statusCode) => {
  let status: string = '';
  switch (statusCode) {
    case '01':
      status = t('master_registration.status_upload.error');
      break;
    case '02':
      status = t('master_registration.status_upload.uploaded');
      break;
    case '03':
      status = t('master_registration.status_upload.running');
      break;
    case '04':
      status = t('master_registration.status_upload.update_failure');
      break;
    case '05':
      status = t('master_registration.status_upload.update_completed');
      break;
    case '06':
      status = t('master_registration.status_upload.waiting_for_reflection');
      break;
    default:
      status = t('master_registration.status_upload.error');
      break;
  }
  return status;
};

export const ellipsisByLength = (text: string, length: number) => {
  if (text?.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};

export const ellipsisContentTable = (text, length) => {
  const isFullwidth = mbStrWidth(text) > text?.length;
  const realLength = isFullwidth ? length : length * 2;
  return ellipsisByLength(text, realLength);
};

export const getNameTypeCsv = (codeType) => {
  let nameTypeCsv: string = '';
  switch (codeType) {
    case '01':
      nameTypeCsv = t('master_registration.item_size');
      break;
    case '02':
      nameTypeCsv = t('master_registration.processing_information');
      break;
    case '03':
      nameTypeCsv = t('master_registration.fare');
      break;
    case '04':
      nameTypeCsv = t('master_registration.inventory_information');
      break;
    case '05':
      nameTypeCsv = t('master_registration.basic_product_information');
      break;
    case '06':
      nameTypeCsv = t('master_registration.customer_information');
      break;
    case '07':
      nameTypeCsv = t('master_registration.product_details');
      break;
    default:
      nameTypeCsv = t('master_registration.item_size');
      break;
  }
  return nameTypeCsv;
};

export const getNameTypePlugin = (codeType) => {
  let nameTypePlugin: string = '';
  switch (codeType) {
    case STATUS_PLUGIN.CALENDAR:
      nameTypePlugin = t('plugin_management.calendar');
      break;
    case STATUS_PLUGIN.FAQ:
      nameTypePlugin = t('plugin_management.faq');
      break;
    case STATUS_PLUGIN.CHAT:
      nameTypePlugin = t('plugin_management.chat');
      break;
    case STATUS_PLUGIN.QR_CODE:
      nameTypePlugin = t('plugin_management.qr_code');
      break;
    default:
      nameTypePlugin = t('plugin_management.calendar');
      break;
  }
  return nameTypePlugin;
};

export const formatScript = (script) => {
  const afterScript = script.split('elfsight-app-')[1];
  if (afterScript) {
    return afterScript.substr(0, afterScript.indexOf('">'));
  }
  return '';
};

export const handleUnitPrices = (unitPrices) => {
  switch (unitPrices) {
    case 'WON':
      return '(KRW)';
    case 'BHT':
      return '(THB)';
    case 'USD':
      return '(USD)';
    case 'TWD':
      return '(TWD)';
    case 'YEN':
      return '(JPY)';
    case 'VND':
      return '(VNĐ)';
    case 'PERCENT':
      return '％';
    case 'CNY':
      return '(CNY)';
    default:
      return '(JPY)';
  }
};

export const formatFileName = (fileName) => {
  return fileName?.split('/').slice(-1)[0] || '';
};

export const openPDF = (pdf) => {
  window.open(pdf);
};

export const formatCurrency = (symbols) => {
  return (
    symbols &&
    symbols?.map((item) => {
      if (item?.symbol) {
        const symbol = item.symbol !== '%' ? `(${item?.symbol})` : item?.symbol;
        return { ...item, symbol };
      }
      const label = item.label !== '%' ? `(${item?.label})` : item?.label;
      return { ...item, label };
    })
  );
};

export const displayMultiLanguage = (
  productName,
  defaultName,
  data,
  isHakudoShhnm: boolean,
) => {
  // display product name in selected language
  if (!_.isEmpty(productName)) {
    return productName;
  }
  // english > japanese > first language of the list
  if (!_.isEmpty(isHakudoShhnm ? data?.hakudoShhnmEn : data?.shhnmEn)) {
    return isHakudoShhnm ? data?.hakudoShhnmEn : data?.shhnmEn;
  }
  if (!_.isEmpty(isHakudoShhnm ? data?.hakudoShhnmJp : data?.shhnmJp)) {
    return isHakudoShhnm ? data?.hakudoShhnmJp : data?.shhnmJp;
  }
  let listProductName: string[] = [];
  const listSortLanguage = sortLanguage();

  if (data) {
    for (const key in listSortLanguage) {
      switch (key) {
        case 'vi':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmVn
            : data?.shhnmVn;
          break;
        case 'ko':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmKr
            : data?.shhnmKr;
          break;
        case 'th':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmTh
            : data?.shhnmTh;
          break;
        case 'zh':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmCn
            : data?.shhnmCn;
          break;
        case 'tw':
          listSortLanguage[key] = isHakudoShhnm
            ? data?.hakudoShhnmTw
            : data?.shhnmTw;
          break;
        default:
          listSortLanguage[key] = '';
          break;
      }
      if (!_.isEmpty(listSortLanguage[key])) {
        listProductName.push(listSortLanguage[key]);
      }
    }
    if (listProductName.length > 0) {
      return _.get(listProductName, '0');
    }
    return defaultName;
  }
};

export const sortLanguage = () => {
  let siteInfo: any = LocalStorage.initSiteInfo;
  let listLanguage: any = [];
  const obj = {};
  siteInfo?.map((obj) => {
    if (Object.values(obj)[0] !== 'en' && Object.values(obj)[0] !== 'ja') {
      listLanguage.push(Object.values(obj)[0]);
    }
  });
  listLanguage.forEach((element) => {
    obj[element] = '';
  });
  return obj;
};

export const getProductName = (params: GetProductNameParams) => {
  const { item, isHakudoShhnm, defaultName = '' } = params;
  let productName: string = '';
  switch (LocalStorage.lang) {
    case 'en':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmEn : item?.shhnmEn,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'ja':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmJp : item?.shhnmJp,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'vi':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmVn : item?.shhnmVn,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'ko':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmKr : item?.shhnmKr,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'th':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmTh : item?.shhnmTh,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'zh':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmCn : item?.shhnmCn,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    case 'tw':
      productName = displayMultiLanguage(
        isHakudoShhnm ? item?.hakudoShhnmTw : item?.shhnmTw,
        defaultName,
        item,
        isHakudoShhnm,
      );
      break;
    default:
      productName = defaultName;
      break;
  }
  return productName;
};

export const formatDimensions = (item, method: string) => {
  // Example: shiagsnpoHyojiMojirets = "15x15x1000(厚さｘ巾ｘ長さ)"
  //          inputSunpo = "1.5inchx1.5ftx100inch"
  //          snpoGuide = "厚さｘ巾ｘ長さ"
  //          shiagsnpo = "15x15x1000"
  //          sunpoTaniKubun = "mm"
  if (item?.inputSunpo && item?.inputSunpo !== '' && item?.snpoGuide) {
    // If has convert size, display like this: 1.5inchx1.5ftx100inch (厚さｘ巾ｘ長さ) (参考 仕入先寸法15x15x1000mm)
    return `${item?.inputSunpo} (${item?.snpoGuide}) (${t(
      'quotation_page.reference_supplier_dimensions',
    )}${item?.shiagsnpo}${item?.sunpoTaniKubun})`;
  }
  if (item?.snpoGuide) {
    if (
      checkDimensionsWithSpaceBetween(item?.shiagsnpo) &&
      !isMachiningMethodFromH2ToH5(method)
    ) {
      return `${item?.shiagsnpo} (${item?.snpoGuide})`;
    }
    return formatSizeByMachiningMethod(item, method);
  }
  return item?.shiagsnpoHyojiMojirets;
};

export const getDeliveryDateExternal = (text) => {
  //text example: 船便：30～45日以内\n航空機便：7~11日以内
  if (text) {
    const parts = text.split('\n');

    //Split each element into subvalues
    const byShipDate = parts[0]?.split('：')[1]?.split('日以内')[0];
    const byAirplaneDate = parts[1]?.split('：')[1]?.split('日以内')[0];

    const obj = {
      byShip: byShipDate,
      byAirplane: byAirplaneDate,
    };
    return obj;
  }
};

export const renderDueDate = (item, nokiOrKiboNoki) => {
  if (item?.zaitokKbn === '1') {
    return displayDeliveryDate(nokiOrKiboNoki);
  } else {
    //noukiDisplay: "船便：30～45日以内\n航空機便：7~11日以内" OR "2024-04-25 10:53:37"
    if (item?.noukiDisplay?.includes('日以内')) {
      return (
        t('quotation_page.back_order_items') +
        t('quotation_page.by_sea', {
          0: getDeliveryDateExternal(item?.noukiDisplay)?.byShip,
        }) +
        t('quotation_page.airmail', {
          0: getDeliveryDateExternal(item?.noukiDisplay)?.byAirplane,
        })
      );
    } else {
      return displayDeliveryDate(item?.noukiDisplay);
    }
  }
};

export const displayDeliveryDate = (date) => {
  return (
    (LocalStorage.lang === 'ja'
      ? dayjs(date).format(DATE_FORMAT_JAPAN)
      : dayjs(date).format(DATE_FORMAT)) +
    ' ' +
    t('quotation_page.scheduled_to_be_delivered')
  );
};

export const checkDimensionsWithSpaceBetween = (dimensions) => {
  return dimensions?.split(' x ')?.length > 1;
};

export const encryptData = (data: string) => {
  if (process.env.REACT_APP_PUBLIC_KEY) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
    return encrypt.encrypt(data) || '';
  }
  return '';
};

export const checkFileExt = (file, extList) => {
  if (file) {
    const typeFile = new TypeFile(file);

    return new Promise((resolve) => {
      typeFile.onParseEnd = () => {
        if (typeFile.isType(extList)) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      typeFile.start();
    });
  }
};

export const checkImageExt = (file) => {
  return checkFileExt(file, fileTypes);
};

export const convertShiagsnpoHyojiMojiretsToArray = (
  shiagsnpoHyojiMojirets,
) => {
  const dimensions = shiagsnpoHyojiMojirets?.split('(')[0]?.split('x');
  if (checkDimensionsWithSpaceBetween(shiagsnpoHyojiMojirets)) {
    // Example: shiagsnpoHyojiMojirets = "15mmx15mmx1000mm(厚さｘ巾ｘ長さ)" => [15mm,15mm,1000mm]
    return dimensions;
  }
  // Example: shiagsnpoHyojiMojirets = "15mmx15mmx1000mm(厚さｘ巾ｘ長さ)" => [15,15,1000]
  return dimensions?.map((item) => parseFloat(item).toString());
};

export const isMachiningMethodFromH2ToH5 = (method: string) => {
  return MACHINING_METHOD.H2TOH5.includes(method);
};

export const roundDecimalPlaces = (size, decimalPlaces: number) => {
  return Number(parseFloat(size).toFixed(decimalPlaces));
};

export const formatSizeByMachiningMethod = (item, method) => {
  // Split the snpo guide to get the length dimension position
  // Then round the length dimension to 2 decimal places
  // Example:
  // 1. shiagsnpoHyojiMojirets = "12x1333.333(直径x長さ)" => [12,1333.333]
  // Index of Length: 1, value: 1333.333, round to 2 decimal places -> 1333.33
  // 2. shiagsnpoHyojiMojirets = "12x15x15x1000x3.333(厚さx孔径xピッチx巾x長さ)" => [12x15x15,1000,3.333]
  // Index of Length: 2, value: 3.333, round to 2 decimal places -> 3.33

  // If product has maching method from H2 to H5 then split the dimensions and round the length.
  if (item?.shiagsnpo && isMachiningMethodFromH2ToH5(method)) {
    //If product has unit type (mm, cm, inch,...)
    const separator = checkDimensionsWithSpaceBetween(item?.shiagsnpo)
      ? ' x '
      : 'x';
    // item.shiagsnpo = 12mm x 1333.333mm
    return `${convertShiagsnpoHyojiMojiretsToArray(item.shiagsnpo)
      // arr = [12mm, 1333.333mm]
      ?.map((dimension, index, arr) =>
        // Get last element = 1333.333mm
        arr.length > 1 && index === arr.length - 1
          ? // Round to 2 decimal places
            // arr[1] = 1333.33
            roundDecimalPlaces(parseFloat(dimension), 2) +
            // unit = mm
            dimension.replace(REGEX.REGEX_NUMERIC_CHARACTER, '').trim()
          : dimension,
      )
      // '12mm' + ' x ' + '1333.33' + 'mm' + ' ' + '(直径x長さ)'
      .join(separator)}${separator === ' x ' ? ' ' : ''}(${item.snpoGuide})`;
  }
  // If not, return original snpo guide
  return `${item?.shiagsnpo}(${item?.snpoGuide})`;
};

export const checkIsProductFinished = (data) => {
  return data === CATEGORIES_ID.TOOL_PRODUCTS;
};

export const getProductNameByPriority = (item, value, type) => {
  const label =
    getProductName({ item: item, isHakudoShhnm: false }) ||
    getProductName({ item: item, isHakudoShhnm: true });

  return {
    label,
    value,
    type,
  };
};

export const checkOrdered = (item) => {
  if (
    item.kessaiZumiFlg === '1' ||
    (item.chumonUketkeNo !== null && item.chumonUketkeNo !== undefined)
  ) {
    return true;
  }
  return false;
};

export const checkExpired = (value: string) => {
  const date = dayjs(value, DATE_FORMAT);
  if (date.isBefore(dayjs().format(DATE_FORMAT))) {
    return true;
  }
  return false;
};

export const checkQuotationLabel = (item) => {
  switch (true) {
    case (item.chumonUketkeNo === undefined || '') &&
      item.kokyakuCd !== '0000000000':
      return LABEL.QUOTATION;
    case item.kokyakuCd === '0000000000':
      return LABEL.TRIAL;
    default:
      return LABEL.ORDER;
  }
};
