import { Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArrayWithId, UseFormReturn } from 'react-hook-form';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { SelectField } from 'src/components/form';
import { checkIsProductFinished } from 'src/utils/helper';
import { FormValue } from 'src/containers/ProfitMarginSetting/useForm';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
  dataProductNames?: {
    label: string;
    value: string;
    type: string;
  }[];
  queryProductNames?: UseQueryResult<
    AxiosResponse<Response.ProductNames, any>,
    AxiosError<Response.Error, any>
  >;
  setProductKeyword?: React.Dispatch<React.SetStateAction<string | undefined>>;
  item: FieldArrayWithId<FormValue, 'cutting_method_setting', 'id'>;
  index: number;
  dataMachiningMethods?: {
    label: string;
    value: string;
  }[];
  setMethodKeyword?: React.Dispatch<React.SetStateAction<string | undefined>>;
  queryMachiningMethods?: UseQueryResult<
    AxiosResponse<Response.MachiningMethods, any>,
    AxiosError<Response.Error, any>
  >;
};

const CuttingMethodSelect: React.FC<Props> = ({
  dataMachiningMethods,
  formInfo,
  queryMachiningMethods,
  dataProductNames,
  queryProductNames,
  setProductKeyword,
  item,
  index,
  setMethodKeyword,
}) => {
  const { t } = useTranslation();
  const { control, setValue } = formInfo;
  const [disableProcessingMethodSelect, setDisableProcessingMethodSelect] =
    useState<boolean>(false);

  const handleSelectProcessingMethod = (data, index) => {
    setValue(`cutting_method_setting.${index}.product_category`, data?.type);
    const isProductFinished = checkIsProductFinished(data?.type);
    setDisableProcessingMethodSelect(isProductFinished);
  };

  const handleSearchProductOrMethod = (value, select: 'product' | 'method') => {
    if (select === 'product') {
      setProductKeyword && setProductKeyword(value);
    } else {
      setMethodKeyword && setMethodKeyword(value);
    }
  };

  return (
    <Col xs={22} md={8} xl={9} className="setting_input_title">
      {!item.product && !item.processing_method ? (
        <div className="setting_input_new">
          <Row justify="space-between">
            <SelectField<FormValue>
              controller={{
                control,
                name: `cutting_method_setting.${index}.product_select`,
              }}
              selectProps={{
                options: dataProductNames,
                showSearch: true,
                filterOption: false,
                onSearch: (value) =>
                  handleSearchProductOrMethod(value, 'product'),
                onSelect: (any, data) =>
                  handleSelectProcessingMethod(data, index),
                onFocus: () => handleSearchProductOrMethod('', 'product'),
                loading: queryProductNames?.isLoading,
                placeholder: t('profit_margin_setting.product'),
              }}
              label={t('profit_margin_setting.product')}
              labelCol={{ xs: 24, md: 10, xl: 7 }}
              wrapperCol={{ xs: 24, md: 14, xl: 14 }}
              className="auto_field"
            />
          </Row>
          {!disableProcessingMethodSelect ? (
            <Row justify="space-between">
              <SelectField<FormValue>
                controller={{
                  control,
                  name: `cutting_method_setting.${index}.processing_method_select`,
                }}
                selectProps={{
                  options: dataMachiningMethods,
                  showSearch: true,
                  filterOption: (inputValue, option) =>
                    ((option && option.label) || '')
                      .toString()
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1,
                  onSearch: (value) =>
                    handleSearchProductOrMethod(value, 'method'),
                  onFocus: () => handleSearchProductOrMethod('', 'method'),
                  loading: queryMachiningMethods?.isLoading,
                  placeholder: t('profit_margin_setting.processing_method'),
                }}
                label={t('profit_margin_setting.processing_method')}
                labelCol={{ xs: 24, md: 10, xl: 7 }}
                wrapperCol={{ xs: 24, md: 14, xl: 14 }}
                className="auto_field"
              />
            </Row>
          ) : (
            <React.Fragment />
          )}
        </div>
      ) : (
        <Typography.Text className="label">
          {item.processing_method
            ? `${item.product}・${item.processing_method}`
            : `${item.product}`}
        </Typography.Text>
      )}
    </Col>
  );
};

export default CuttingMethodSelect;
