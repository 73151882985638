import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { InputField } from 'src/components/form';
import { RowDetail } from 'src/components/common/index';
import useForm, { FormValue } from './useForm';
import useTable from './useTable';
import useInternalPIC from 'src/queries/useInternalPIC';
import { ADMIN_ROLES } from 'src/constants/app';
import LocalStorage from 'src/utils/LocalStorage';

type Props = {
  onClose?: () => void;
  onSelected?: (e) => void;
  screenId?: string;
  kokyakuCd?: string;
};

const InternalPICModal: React.FC<Props> = ({
  onClose,
  onSelected,
  screenId,
  kokyakuCd,
}) => {
  const { t } = useTranslation();
  const [nameSelected, setNameSelected] = useState('');
  const [userCd, setUserCd] = useState('');
  const [showError, setShowError] = useState(false);
  const { control, handleSubmit } = useForm();
  const [isOnlyView, setIsOnlyView] = useState(false);

  const [searchParams, setSearchParams] = useState<SearchParams.InternalPIC>({
    name: undefined,
  });

  const screenParams = {
    screenId: screenId,
    kokyakuCd: kokyakuCd,
  };

  const { queryInternalPICInfo, updateInternalPIC } = useInternalPIC(
    {
      ...searchParams,
    },
    screenParams,
  );

  useEffect(() => {
    if (updateInternalPIC.data?.status) {
      onClose && onClose();
    }
  }, [updateInternalPIC.data]);

  const { tableProps } = useTable({
    searchParams,
    setSearchParams,
    onSelectChange: (rowKeys, rowSelected) =>
      onSelectChange(rowKeys, rowSelected),
    nameSelected: nameSelected,
  });

  const handleSearch = (values) => {
    setSearchParams({
      name: values.searchKey,
    });
  };

  const handleSetting = () => {
    if (nameSelected) {
      setShowError(false);
      updateInternalPIC.mutate({
        screenId: screenId || '',
        kokyakuCd: kokyakuCd || '',
        userCd: userCd || '',
        name: nameSelected,
      });
    } else {
      setShowError(true);
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    rowSelected: any[],
  ) => {
    setNameSelected(rowSelected[0]?.name);
    setUserCd(rowSelected[0]?.userCd);
    onSelected && onSelected(rowSelected[0]);
  };

  useEffect(() => {
    if (LocalStorage.userRoles[0] === ADMIN_ROLES.viewAdmin) {
      setIsOnlyView(true);
    }
  }, [LocalStorage.userRoles[0]]);

  return (
    <React.Fragment>
      <div className="internal_pic_modal">
        <div className="p-20">
          <RowDetail
            title={t('internal_pic.customer_name')}
            value={queryInternalPICInfo.data?.data.item.kokyakuMei}
            layout={{
              titleCol: { xs: 6, sm: 6, md: 5, lg: 4, xl: 4 },
              valueCol: { xs: 16, sm: 15, md: 16, lg: 17, xl: 20 },
            }}
          />
          <RowDetail
            title={t('internal_pic.internal_person')}
            value={queryInternalPICInfo.data?.data.item.name}
            layout={{
              titleCol: { xs: 6, sm: 6, md: 5, lg: 4, xl: 4 },
              valueCol: { xs: 16, sm: 15, md: 16, lg: 17, xl: 20 },
            }}
          />
          <Divider />
          <Row className="mb-20">
            <Typography.Text>{t('internal_pic.internal_note')}</Typography.Text>
          </Row>
          <Row className="internal_pic_modal_input">
            <Col xs={24} md={6} lg={5} xl={5}>
              <Typography.Title level={5} className="internal_pic_modal_label">
                {t('internal_pic.search_person')}
              </Typography.Title>
            </Col>
            <Col xs={13} md={12} lg={14} xl={13}>
              <InputField<FormValue>
                controller={{
                  control,
                  name: 'searchKey',
                  defaultValue: '',
                }}
                inputProps={{
                  maxLength: 30,
                  placeholder: t('internal_pic.place_holdel_internal_person'),
                }}
              />
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 5, offset: 1 }}
              lg={{ span: 4, offset: 1 }}
              xl={{ span: 5, offset: 1 }}
            >
              <Button
                type="primary"
                onClick={handleSubmit((val) => handleSearch(val))}
                className="w-full internal_pic_modal_button_search"
              >
                {t('button.search')}
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <Table {...tableProps} />
          </Col>
          {showError && (
            <Typography.Text className="text-error ml-20">
              {t('message.E000007', { 0: t('internal_pic.company_name') })}
            </Typography.Text>
          )}
        </Row>
        <Row
          justify="center"
          className="mt-20 internal_pic_modal_button_setting"
        >
          <Button
            type="primary"
            onClick={handleSetting}
            disabled={isOnlyView}
            className="px-60"
          >
            {t('basic_setting.setting')}
          </Button>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default InternalPICModal;
