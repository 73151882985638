import { Button, Col, Row, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioGroupField } from 'src/components/form';
import { DISPLAY_ORDER_PDF_REGISTRATION } from 'src/constants/optionSelect/pdfRegistration';
import useTable from './useTable';
import useForm from './useForm';
import usePDFRegistration from 'src/queries/usePDFRegistration';

const UploadTable: React.FC = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm();
  const { queryListUpload, deletePDFRegistration } = usePDFRegistration({
    setError,
  });
  const [sort, setSort] = useState<string>('');
  const [errorCheckItemTable, setErrorCheckItemTable] = useState('');

  const { tableProps, rowSelected } = useTable({
    sortBy: sort,
    dataSource: queryListUpload,
    setErrorTable: errorCheckItemTable,
  });

  const handleDelete = async () => {
    const listItemDelete = {
      seqnos: rowSelected,
    };
    if (rowSelected.length === 0) {
      setErrorCheckItemTable(
        t('message.E000007', {
          0: t('pdf_registration.file_to_delete'),
        }),
      );
    } else {
      setErrorCheckItemTable('');
      await deletePDFRegistration.mutate(listItemDelete);
    }
  };

  const handleSortListTable = (value) => {
    // 01:新規順
    // 02:区分順
    // 03:言語順
    setSort(value.display_order_by);
  };

  return (
    <React.Fragment>
      <div className="display_order_section">
        <Row className="display_order_wrapper" align="middle">
          <Col flex="none">
            <Typography.Title level={5} className="display_order_title">
              {t('pdf_registration.display_order')}
            </Typography.Title>
          </Col>
          <Col flex="auto">
            <Row align="middle">
              <Col flex="none">
                <RadioGroupField
                  controller={{
                    name: 'display_order_by',
                    control: control,
                    defaultValue: '01',
                  }}
                  radioGroupProps={{
                    options: DISPLAY_ORDER_PDF_REGISTRATION(t),
                  }}
                />
              </Col>
              <Col flex="auto">
                <Button
                  className="btn_update_list"
                  size="small"
                  onClick={handleSubmit(handleSortListTable)}
                >
                  {t('pdf_registration.update_list')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="list_upload_section">
        <Table {...tableProps} />
        <div className="btn_delete_list">
          <Button
            className="btn_submit"
            onClick={handleDelete}
            loading={deletePDFRegistration.isLoading}
          >
            {t('pdf_registration.delete')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
export default UploadTable;
