import { Button, Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { NumberOnlyField, SelectField } from 'src/components/form';
import useTaxExChangeSetting from 'src/queries/useTaxExChangeSetting';
import useForm from './useForm';

type Props = {
  symbols?: Response.SymbolItem[];
};

const CurrencyConversion: React.FC<Props> = ({ symbols }) => {
  const [disabledAdd, setDisabledAdd] = useState(false);
  const [firstSymbol, setFirstSymbol] = useState<string>();

  const {
    control,
    handleSubmit,
    getValues,
    fieldArray,
    symbolOptions,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    symbolsCurrencyTransfers: symbols,
  });

  const { fields, append } = fieldArray;

  const onErrorDuplicateCurrencyTransfers = () => {
    setError(`currency_conversion.${fields.length - 1}.unit_1`, {
      type: 'custom',
      message: t('message.E000055'),
    });
  };

  const { getCurrencyTransfers, createCurrencyTransfers } =
    useTaxExChangeSetting({
      onErrorDuplicateCurrencyTransfers: onErrorDuplicateCurrencyTransfers,
    });
  const currencyTransfersData = getCurrencyTransfers?.data?.data.items;

  useEffect(() => {
    symbols && setFirstSymbol(_.first(symbols)?.moneyType);
  }, [symbols]);

  const handleAddField = () => {
    if (symbols && fields?.length < symbols?.length) {
      const getCurrencyConversionArr = getValues().currency_conversion;
      const getLastTaxObj = _.last(getCurrencyConversionArr);

      const validationTaxInput = (condition, textBox: 'tax_0' | 'tax_1') => {
        const getLastIndexTaxObj = `currency_conversion.${_.findLastIndex(
          getCurrencyConversionArr,
        )}` as const;
        if (condition !== 0 && !condition) {
          clearErrors(`${getLastIndexTaxObj}.unit_1`);
          setError(`${getLastIndexTaxObj}.${textBox}`, {
            type: 'custom',
            message: t('message.E000003', {
              0: t('tax_exchange_setting.exchange_rate'),
            }),
          });
        } else {
          clearErrors(`${getLastIndexTaxObj}.${textBox}`);
        }
      };

      validationTaxInput(getLastTaxObj?.tax_0, 'tax_0');
      validationTaxInput(getLastTaxObj?.tax_1, 'tax_1');

      if (
        (getLastTaxObj?.tax_0 === 0 || getLastTaxObj?.tax_0) &&
        (getLastTaxObj?.tax_1 === 0 || getLastTaxObj?.tax_1)
      ) {
        append(
          {
            unit_0: firstSymbol,
            tax_0: undefined,
            unit_1: firstSymbol,
            tax_1: undefined,
          },
          { shouldFocus: false },
        );
      }
    }
  };

  useEffect(() => {
    if (symbols) {
      if (fields?.length < symbols?.length) {
        setDisabledAdd(false);
      } else {
        setDisabledAdd(true);
      }
    }
  }, [fields]);

  const handleSetting = () => {
    const currencyConversion = getValues().currency_conversion;

    const checkSymbolDuplicate = currencyConversion
      .map((object, index) => {
        const findDuplicateUnit = currencyConversion.find((obj, i) => {
          if (index > i) {
            return _.isEqual(
              [object.unit_0, object.unit_1],
              [obj.unit_0, obj.unit_1],
            );
          }
        });

        if (object.unit_0 === object.unit_1 || findDuplicateUnit) {
          setError(`currency_conversion.${index}.unit_1`, {
            type: 'custom',
            message: t('message.E000055'),
          });
          return false;
        }
      })
      .find((obj) => obj === false);

    if (checkSymbolDuplicate !== false) {
      const currencyTranferObj = [
        getValues().currency_conversion?.map((item, index) => {
          const getSeqno = currencyTransfersData?.find(
            (obj, objIndex) => objIndex === index,
          )?.seqno;
          return {
            seqno: getSeqno,
            kawaseKbCdFrom: item.unit_0,
            kawaseKbCdTo: item.unit_1,
            kawaseValueCdFrom: item.tax_0,
            kawaseValueCdTo: item.tax_1,
          };
        }),
      ];

      const checkCurrencyConversionEmpty =
        getValues().currency_conversion?.find(
          (obj) =>
            (obj.tax_0 !== 0 && !obj.tax_0) || (obj.tax_1 !== 0 && !obj.tax_1),
        );

      if (!checkCurrencyConversionEmpty) {
        createCurrencyTransfers.mutate(currencyTranferObj[0]);
      }
    }
  };

  return (
    <div className="currency_transfers">
      <Typography.Text className="title_text mb-10">
        {t('tax_exchange_setting.title_register_the_exchange_rate')}
      </Typography.Text>
      <Row gutter={20}>
        <Col xl={4} lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={5} className="label_input">
            {t('tax_exchange_setting.exchange_rate')}
          </Typography.Title>
        </Col>
        <Col xl={20} lg={24} md={24} sm={24} xs={24}>
          {fields?.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Row gutter={20}>
                  <Col
                    xxl={8}
                    xl={7}
                    lg={7}
                    md={6}
                    sm={19}
                    xs={16}
                    className="divider_top"
                  >
                    <NumberOnlyField
                      controller={{
                        name: `currency_conversion.${index}.tax_0`,
                        control: control,
                      }}
                    />
                  </Col>
                  <Col
                    xxl={4}
                    xl={5}
                    lg={5}
                    md={6}
                    sm={5}
                    xs={8}
                    className="divider_top unit_options"
                  >
                    <SelectField
                      controller={{
                        name: `currency_conversion.${index}.unit_0`,
                        control: control,
                        defaultValue: symbolOptions[0]?.[0]?.value,
                      }}
                      selectProps={{ options: symbolOptions[0] }}
                    />
                  </Col>

                  <Col xxl={8} xl={7} lg={7} md={6} sm={19} xs={16}>
                    <NumberOnlyField
                      controller={{
                        name: `currency_conversion.${index}.tax_1`,
                        control: control,
                      }}
                    />
                  </Col>
                  <Col
                    xxl={4}
                    xl={5}
                    lg={5}
                    md={6}
                    sm={5}
                    xs={8}
                    className="unit_options"
                  >
                    <SelectField
                      controller={{
                        name: `currency_conversion.${index}.unit_1`,
                        control: control,
                      }}
                      selectProps={{ options: symbolOptions[0] }}
                    />
                  </Col>
                  {errors?.currency_conversion &&
                    errors?.currency_conversion[index]?.unit_1 && (
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography.Text className="message_error">
                          {errors?.currency_conversion[index]?.unit_1?.message}
                        </Typography.Text>
                      </Col>
                    )}
                </Row>
              </React.Fragment>
            );
          })}
        </Col>
      </Row>
      <div className="btn_setting_wrapper justify-start">
        <Button
          size="large"
          onClick={handleAddField}
          className="btn_add"
          disabled={disabledAdd}
        >
          {t('tax_exchange_setting.add_line')}
        </Button>
        <Button
          size="large"
          onClick={handleSubmit(handleSetting)}
          className="btn_setting"
          loading={createCurrencyTransfers.isLoading}
        >
          {t('tax_exchange_setting.setting')}
        </Button>
      </div>
    </div>
  );
};

export default CurrencyConversion;
