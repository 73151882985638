import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import listDownloadCsv from 'src/repositories/masterRegistration';
import React, { Dispatch, SetStateAction } from 'react';
import masterRegistration from 'src/repositories/masterRegistration';

export const apiKey = '/notifications' as const;
const apiHistory = '/csv/history';
const apiDownloadCsv = '/csv/downloads';
const apiDownloadSingleCsv = '/csv/download';
const apiScheduling = '/csv/scheduling';
const apiUploadWork = '/csv/upload-work';
const apiRunJob = '/csv/run-job';
const apiTotalColumns = '/csv/total-columns';

export type UseMasterRegistration = {
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
  rowSelected?: number[];
};

export const useMasterRegistration = ({
  setRowKeysSelected,
  rowSelected = [],
}: UseMasterRegistration) => {
  const queryClient = useQueryClient();

  const downloadSingleCSV = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DownloadSingleCsvMasterRegistration
  >({
    mutationKey: [apiDownloadSingleCsv],
    mutationFn: (args) => {
      return masterRegistration.downloadSingleCsv(apiDownloadSingleCsv, args);
    },
    onSuccess: (data) => {
      setRowKeysSelected?.([]);
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      const filename = data?.headers['content-disposition'].split('=')[1];
      link.href = url;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();
    },
  });

  const queryListHistory = useQuery<
    AxiosResponse<Response.listHistoryCsv>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiHistory],
    queryFn: listDownloadCsv.getListHistory,
  });

  const deleteMasterRegistration = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteMasterRegistration
  >({
    mutationKey: [apiHistory],
    mutationFn: (args) => {
      return masterRegistration.deleteMasterRegistration(apiHistory, args);
    },
    onSuccess: () => {
      queryClient.removeQueries([apiHistory]);
      setRowKeysSelected?.([]);
    },
  });

  const downloadCSV = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DownloadCsvMasterRegistration
  >({
    mutationKey: [apiDownloadCsv],
    mutationFn: (args) => {
      return masterRegistration.downloadCsv(apiDownloadCsv, args);
    },
    onSuccess: (data) => {
      setRowKeysSelected?.([]);
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'csv-masters.zip');
      document.body.appendChild(link);
      link.click();
    },
  });

  const scheduling = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.schedulingMasterRegistration
  >({
    mutationKey: [apiScheduling],
    mutationFn: (args) => {
      return masterRegistration.scheduling(apiScheduling, args);
    },
    onSuccess: (data) => {
      queryClient.removeQueries([apiHistory]);
      setRowKeysSelected?.([]);
    },
  });

  const uploadWork = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.uploadWorkMasterRegistration
  >({
    mutationKey: [apiUploadWork],
    mutationFn: (args) => {
      return masterRegistration.uploadWork(apiUploadWork, args);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([apiHistory]).then();
      setRowKeysSelected?.([]);
    },
  });

  const registration = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.registrationMasterRegistration
  >({
    mutationKey: [apiHistory],
    mutationFn: (args) => {
      return masterRegistration.registration(apiHistory, args);
    },
    onSuccess: (data) => {
      setRowKeysSelected?.([]);
    },
  });

  const runJob = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.runJobMasterRegistration
  >({
    mutationKey: [apiRunJob],
    mutationFn: (args) => {
      return masterRegistration.runJob(apiRunJob, args);
    },
    onSuccess: (data) => {
      setRowKeysSelected?.([]);
      queryClient.invalidateQueries([apiHistory]).then();
    },
  });

  const queryTotalColumns = useQuery<
    AxiosResponse<Response.TotalColumns>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiTotalColumns],
    queryFn: masterRegistration.getTotalColumns,
  });

  const updateTotalColumns = useMutation<
    AxiosResponse<Response.TotalColumns>,
    AxiosError,
    Payload.TotalColumns
  >({
    mutationKey: [apiTotalColumns],
    mutationFn: (args) =>
      masterRegistration.updateTotalColumns(
        `${apiTotalColumns}/${args.seqno}`,
        args,
      ),
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const createTotalColumns = useMutation<
    AxiosResponse<Response.TotalColumns>,
    AxiosError,
    Payload.TotalColumns
  >({
    mutationKey: [apiTotalColumns],
    mutationFn: (args) =>
      masterRegistration.createTotalColumns(apiTotalColumns, args),
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  return {
    queryListHistory,
    deleteMasterRegistration,
    downloadCSV,
    scheduling,
    uploadWork,
    registration,
    runJob,
    downloadSingleCSV,
    queryTotalColumns,
    updateTotalColumns,
    createTotalColumns,
  };
};
