import React from 'react';
import { Button } from 'antd';

import { t } from 'src/libs/i18n';
import useForm, { FormValue } from './useForm';
import {
  HalfWidthInputField,
  InputField,
  PhoneNumberField,
} from 'src/components/form';
import useCustomerDeliveryRegistration from 'src/queries/useCustomerDeliveryRegistration';

type Props = {
  customer_code: number;
  onClose?: () => void;
  screenId?: string;
};

const CustomerDelivertyRegistrationModal: React.FC<Props> = ({
  customer_code,
  onClose,
}) => {
  const { control, getValues, handleSubmit } = useForm();
  const { createCustomerDelivery } = useCustomerDeliveryRegistration(onClose);

  const handleRegistration = () => {
    if (customer_code) {
      const createCustomerDeliveryObj = {
        kokyakuCd: customer_code,
        jushoCd: getValues().address_code,
        otodokesakiJusho: getValues().shipping_address,
        otokesakiNm: getValues().delivery_name,
        telno: getValues().phone_number,
      };
      createCustomerDelivery.mutate(createCustomerDeliveryObj);
    }
  };

  return (
    <React.Fragment>
      <div className="customer_delivery_registration_modal">
        <div className="form_wrapper">
          <InputField<FormValue>
            label={t('customer_delivery_registration_modal.delivery_name')}
            labelCol={{ xl: 6, lg: 6, md: 6, sm: 8, xs: 24 }}
            wrapperCol={{ xl: 18, lg: 18, md: 18, sm: 16, xs: 24 }}
            controller={{ name: 'delivery_name', control: control }}
            required
            inputProps={{
              maxLength: 90,
              placeholder: t(
                'customer_delivery_registration_modal.delivery_name',
              ),
            }}
          />
          <HalfWidthInputField<FormValue>
            label={t(
              'customer_delivery_registration_modal.delivery_address_code',
            )}
            labelCol={{ xl: 6, lg: 6, md: 6, sm: 8, xs: 24 }}
            wrapperCol={{ xl: 18, lg: 18, md: 18, sm: 16, xs: 24 }}
            controller={{ name: 'address_code', control: control }}
            required
            inputProps={{
              maxLength: 10,
              placeholder: t(
                'customer_delivery_registration_modal.delivery_address_code',
              ),
            }}
          />
          <InputField<FormValue>
            label={t('customer_delivery_registration_modal.delivery_address')}
            labelCol={{ xl: 6, lg: 6, md: 6, sm: 8, xs: 24 }}
            wrapperCol={{ xl: 18, lg: 18, md: 18, sm: 16, xs: 24 }}
            controller={{ name: 'shipping_address', control: control }}
            required
            inputProps={{
              maxLength: 200,
              placeholder: t(
                'customer_delivery_registration_modal.delivery_address',
              ),
            }}
          />
          <PhoneNumberField<FormValue>
            label={t('customer_delivery_registration_modal.phone_number')}
            labelCol={{ xl: 6, lg: 6, md: 6, sm: 8, xs: 24 }}
            wrapperCol={{ xl: 18, lg: 18, md: 18, sm: 16, xs: 24 }}
            controller={{ name: 'phone_number', control: control }}
            required
            inputProps={{
              maxLength: 13,
              placeholder: t(
                'customer_delivery_registration_modal.phone_number',
              ),
            }}
          />
        </div>
        <div className="btn_wrapper">
          <Button
            size="large"
            onClick={handleSubmit(handleRegistration)}
            loading={createCustomerDelivery?.isLoading}
          >
            {t('customer_delivery_registration_modal.registration')}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDelivertyRegistrationModal;
