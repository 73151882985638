import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Table, Typography } from 'antd';

import CustomerSearchForm from 'src/components/common/CustomerSearchForm';
import useTable from 'src/components/common/CustomerSearchForm/useTable';
import useForm, {
  FormValue,
} from 'src/containers/CustomerSetting/SearchComponent/useForm';

type CustomerModalProps = {
  onClose: () => void;
  onSelectCustomer: (customer: Response.CustomerSettingItem) => void;
  screenId?: string;
};

const CustomerModal: React.FC<CustomerModalProps> = (
  props: CustomerModalProps,
) => {
  const [items, setItems] = useState<Response.CustomerSettingItem[]>([]);
  const { onSelectCustomer, onClose } = props;
  const { control, handleSubmit } = useForm();
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState<FormValue>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    tableProps,
    rowSelected,
    setRowKeysSelected,
    searchCustomer,
    setPage,
  } = useTable({
    form: formValue!,
    singleSelect: true,
    clearErrorMessage: () => setErrorMessage(''),
  });

  useEffect(() => {
    const customers = searchCustomer?.data?.data?.items;
    const newItems = [...items];
    customers?.forEach((customer) => {
      const item = items.find((e) => e.seqno === customer.seqno);
      if (!item) {
        newItems.push(customer);
      }
    });
    setItems(newItems);
  }, [searchCustomer]);

  const selectCustomer = () => {
    if (!rowSelected.length) {
      setErrorMessage(
        t('message.E000007', {
          0: t('customer_modal.customer'),
        }),
      );
      return;
    }
    const customer = items.find((item) => item.seqno === rowSelected[0]);
    onSelectCustomer(customer!);
    onClose();
  };

  const search = (form: FormValue) => {
    setPage(1);
    setFormValue(form);
    setErrorMessage('');
    setRowKeysSelected([]);
  };

  return (
    <div className="customer-modal">
      <CustomerSearchForm
        control={control}
        onSubmit={handleSubmit((val: any) => {
          setFormValue(val);
          search(val);
        })}
      />
      <Table {...tableProps} />
      {errorMessage && (
        <Row>
          <Typography.Text
            className="ant-form-item-explain ant-form-item-explain-error"
            type="danger"
          >
            {errorMessage}
          </Typography.Text>
        </Row>
      )}
      <Row justify="center">
        <Button onClick={selectCustomer} type="primary" className="btn-select">
          {t('customer_modal.button_select')}
        </Button>
      </Row>
    </div>
  );
};

export default CustomerModal;
