import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  LoadingOutlined,
  SaveOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Row,
  TableProps,
  Tooltip,
  Typography,
} from 'antd';
import qs from 'query-string';

import useTableCommon from 'src/hooks/useTable';
import { NumberOnlyField, SelectField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import useCustomerSetting from 'src/queries/useCustomerSetting';
import { ellipsisContentTable } from 'src/utils/helper';
import { InternalPICModal } from 'src/components/common';
import { Modal } from 'src/components/custom';
import history from 'src/libs/history';
import { PATH as PATH_CUSTOMER_DELIVERY_SETTING } from 'src/pages/customerDeliverySetting';
import { PATH as PATH_USER_REGISTRATION_CHANGE } from 'src/pages/userRegistrationChange';
import { PATH as PATH_PROFIT_MARGIN_SETTING } from 'src/pages/profitMarginSetting';
import useOccupationAndLocation from 'src/queries/useOccupationAndLocation';

type Props = {
  error?: string;
};

const useTable = ({ error }: Props) => {
  const { t } = useTranslation();
  const { control, getValues, setError, setValue } = useForm();
  const [itemEdit, setItemEdit] = useState(undefined);
  const { querySearchCustomer, updateCurrency, updateOccupationAndLocation } =
    useCustomerSetting({
      setRowKeysSelected: undefined,
      setError,
    });
  const { dataLocation, dataOccupation } = useOccupationAndLocation();

  const listSearchCustomer = querySearchCustomer.data?.data;

  const {
    pagination: paginationCommon,
    rowSelection,
    ...rest
  } = useTableCommon({
    hasSelect: true,
    maxPageSize: 500,
  });

  const handleUpdate = (seqno, index) => {
    const getValueItem = getValues()?.customer[index]?.price;
    const updateObj = {
      seqno: seqno,
      jogenKingaku: getValueItem ? +getValueItem : undefined,
    };
    updateCurrency.mutate(updateObj);
  };

  const handleUpdateJobAndRegion = (seqno, index) => {
    const payload = {
      seqno: +seqno,
      gyoushuId: getValues()?.customer[index]?.job_id,
      shozaichiId: getValues()?.customer[index]?.region_id,
    };
    updateOccupationAndLocation.mutate(payload);
    setItemEdit(undefined);
  };

  useEffect(() => {
    listSearchCustomer?.items?.forEach((item, index) => {
      setValue(
        `customer.${index}.price`,
        `${item?.jogenKingaku ? item?.jogenKingaku : ''}`,
      );
      setValue(`customer.${index}.job_id`, item?.gyoushuId);
      setValue(`customer.${index}.region_id`, item?.shozaichiId);
    });
  }, [listSearchCustomer]);

  useEffect(() => {
    // reset value when selecting another item
    listSearchCustomer?.items?.forEach((item, index) => {
      setValue(`customer.${index}.job_id`, item?.gyoushuId);
      setValue(`customer.${index}.region_id`, item?.shozaichiId);
    });
  }, [itemEdit]);

  const columns = [
    {
      title: t('customer_setting.customer_code'),
      dataIndex: 'kokyakuCd',
      key: 'kokyakuCd',
      width: 85,
    },
    {
      title: t('customer_setting.company_name_table'),
      children: [
        {
          title: t('customer_setting.address'),
          dataIndex: 'kokyakuMei_jusho',
          key: 'kokyakuMei_jusho',
          width: 210,
          ellipsis: {
            showTitle: false,
          },
          render: (_, row, index) => {
            return (
              <div className="cell_space-between">
                <Row justify="center" className="text-middle p-5">
                  <Tooltip placement="topLeft" title={row.kokyakuMei}>
                    {ellipsisContentTable(row.kokyakuMei, 12)}
                  </Tooltip>
                </Row>
                <Divider />
                <Row justify="center" className="text-middle">
                  <Tooltip placement="topLeft" title={row.jusho}>
                    {ellipsisContentTable(row.jusho, 12)}
                  </Tooltip>
                </Row>
              </div>
            );
          },
        },
      ],
    },
    {
      title: t('customer_setting.phone_number'),
      children: [
        {
          title: t('customer_setting.fax_number'),
          dataIndex: 'telno_faxno',
          key: 'telno_faxno',
          width: 105,
          render: (_, row) => {
            return (
              <div className="cell_space-between">
                <Row justify="center" className="text-middle p-5">
                  {row.telno ? row.telno : '-'}
                </Row>
                <Divider />
                <Row justify="center" className="text-middle">
                  {row.faxno ? row.faxno : '-'}
                </Row>
              </div>
            );
          },
        },
      ],
    },
    {
      title: t('customer_setting.person_in_charge_table'),
      dataIndex: 'name',
      key: 'name',
      width: 105,
      render: (_, row) => {
        return (
          <Row className="cell_space-between">
            <Row className="mb-0 pt-12 p-5" justify="center" align="middle">
              <Tooltip placement="topLeft" title={row.name}>
                {row.name ? ellipsisContentTable(row.name, 5) : '-'}
              </Tooltip>
            </Row>
            <Row justify="center">
              <Modal
                modalProps={{ width: 840 }}
                title={t('internal_pic.title')}
                content={({ onClose }) => (
                  <InternalPICModal
                    onClose={onClose}
                    screenId="R1512"
                    kokyakuCd={row.kokyakuCd}
                  />
                )}
              >
                <Button className="button_edit">
                  <EditOutlined /> {t('customer_setting.edit')}
                </Button>
              </Modal>
            </Row>
          </Row>
        );
      },
    },
    {
      title: t('customer_setting.addressee_table'),
      dataIndex: 'countOtokesaki',
      key: 'countOtokesaki',
      width: 150,
      render: (_, row) => {
        return (
          <Row className="cell_space-between">
            <Row className="mb-0 pt-12 p-5" justify="center" align="middle">
              {t('customer_setting.number_of_registrations')}
              {row.countOtokesaki}
              {t('customer_setting.address_shipping_unit')}
            </Row>
            <Row justify="center">
              <Button
                className="button_edit"
                onClick={() =>
                  history.push({
                    pathname: PATH_CUSTOMER_DELIVERY_SETTING,
                    search: qs.stringify({ customer_code: row.kokyakuCd }),
                  })
                }
              >
                <SettingOutlined /> {t('basic_setting.setting')}
              </Button>
            </Row>
          </Row>
        );
      },
    },
    {
      title: t('customer_setting.customer_user_table'),
      dataIndex: 'countKokyakuUser',
      key: 'countKokyakuUser',
      width: 120,
      render: (_, row) => {
        return (
          <Row className="cell_space-between">
            <Row className="mb-0 pt-12 p-5" justify="center" align="middle">
              {t('customer_setting.number_of_registrants')}
              {row.countKokyakuUser}
              {t('customer_setting.people_unit')}
            </Row>
            <Row justify="center">
              <Button
                className="button_edit"
                onClick={() =>
                  history.push({
                    pathname: PATH_USER_REGISTRATION_CHANGE,
                    search: qs.stringify({
                      customer_code: row.kokyakuCd,
                      customer_name: row.kokyakuMei,
                    }),
                  })
                }
              >
                <SettingOutlined /> {t('basic_setting.setting')}
              </Button>
            </Row>
          </Row>
        );
      },
    },
    {
      title: t('customer_registration.industry_classification'),
      children: [
        {
          title: t('customer_registration.region'),
          dataIndex: 'telno_faxno',
          key: 'telno_faxno',
          width: 132,
          render: (_, row, index) => {
            return (
              <Row className="job_and_region_table">
                {itemEdit === row.seqno ? (
                  <>
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: `customer.${index}.job_id`,
                      }}
                      selectProps={{
                        options: dataOccupation,
                        placeholder: t(
                          'customer_registration.industry_classification',
                        ),
                      }}
                      className="auto_field"
                    />
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: `customer.${index}.region_id`,
                      }}
                      selectProps={{
                        options: dataLocation,
                        placeholder: t('customer_registration.region'),
                      }}
                      className="auto_field"
                    />
                    <Button
                      className="button_edit"
                      onClick={() => handleUpdateJobAndRegion(row.seqno, index)}
                    >
                      <SaveOutlined /> {t('customer_setting.save')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Row justify="center">
                      <Tooltip placement="topLeft" title={row?.gyoushu}>
                        {ellipsisContentTable(
                          row.gyoushu ? row.gyoushu : '-',
                          5,
                        )}
                      </Tooltip>
                    </Row>
                    <Row justify="center">
                      <Tooltip placement="topLeft" title={row?.shozaichi}>
                        {ellipsisContentTable(
                          row.shozaichi ? row.shozaichi : '-',
                          5,
                        )}
                      </Tooltip>
                    </Row>
                    <Button
                      className="button_edit"
                      onClick={() => setItemEdit(row.seqno)}
                    >
                      <EditOutlined /> {t('customer_setting.edit')}
                    </Button>
                  </>
                )}
              </Row>
            );
          },
        },
      ],
    },
    {
      title: t('customer_setting.price_table'),
      dataIndex: 'profit_margin',
      key: 'profit_margin',
      width: 130,
      render: (_, row) => {
        return (
          <Row justify="center" className="cell_profit_margin">
            <Button
              className="button_edit"
              onClick={() =>
                history.push({
                  pathname: PATH_PROFIT_MARGIN_SETTING,
                  search: qs.stringify({ kokyaku_cd: row.kokyakuCd }),
                })
              }
            >
              {t('customer_setting.pricing')}
            </Button>
          </Row>
        );
      },
    },
    {
      title: t('customer_setting.max_amount_table'),
      dataIndex: 'currencySymbol',
      key: 'currencySymbol',
      width: 180,
      render: (_, row, index) => {
        return (
          <Row wrap={false} className="cell_price">
            <Col>
              <NumberOnlyField<FormValue>
                wrapperCol={{ span: 24 }}
                className="ml-12 mb-0 max_amount_input"
                controller={{
                  control,
                  name: `customer.${index}.price`,
                }}
                inputProps={{
                  maxLength: 14,
                  onBlur: () => handleUpdate(row.seqno, index),
                }}
              />
            </Col>
            <Col className="currency_symbol">{`(${row.currencySymbol})`}</Col>
          </Row>
        );
      },
    },
    {
      title: t('customer_setting.terms_of_settlement'),
      dataIndex: 'term',
      key: 'term',
      width: 105,
      render: (_, row) => {
        return (
          <Row className="cell_center">
            <Tooltip
              placement="topLeft"
              title={row.kessaiJoken}
              className="wrap-text p-5"
            >
              {ellipsisContentTable(row.kessaiJoken, 25)}
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: t('customer_setting.general_purpose_column'),
      dataIndex: 'genaral',
      key: 'genaral',
      width: 95,
      render: (_, row) => {
        return (
          <Row className="cell_center">
            <Tooltip
              placement="topLeft"
              title={row.hanyomRan}
              className="wrap-text p-5"
            >
              {ellipsisContentTable(row.hanyomRan, 25)}
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: listSearchCustomer?.items,
    loading: {
      spinning: querySearchCustomer.isFetching,
      indicator: <LoadingOutlined />,
    },
    // bordered: true,
    rowKey: 'seqno',
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
    size: 'middle',
    pagination: {
      ...paginationCommon,
      total: listSearchCustomer?.total,
      current: listSearchCustomer?.page,
      pageSize: listSearchCustomer?.offset,
    },
    locale: {
      emptyText: t('message.E000024'),
    },
    footer: () => (
      <React.Fragment>
        {error && (
          <Typography.Text className="error_message">{error}</Typography.Text>
        )}
      </React.Fragment>
    ),
  };

  return {
    tableProps,
    ...rest,
  };
};

export default useTable;
