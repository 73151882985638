import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import useQueryUrl from 'src/hooks/useQueryUrl';
import quotation from 'src/repositories/quotation';

const apiPath = '/order-history';
const csvPath = '/order-history/csv';
const loyalCustomerPath = '/order-history/loyal-customer';
const pdfPath = '/order-history/pdf';
const orderStatusPath = '/order-history/order-status';

type UseQuotation = {
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
  setError?: React.Dispatch<React.SetStateAction<string>>;
};

const useQuotation = ({ setRowKeysSelected, setError }: UseQuotation) => {
  const query = useQueryUrl<SearchParams.Quotation>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const params = {
    orderType:
      query.quote_order_flag &&
      query.quote_order_flag.length >= 1 &&
      query.quote_order_flag.length < 3
        ? query.quote_order_flag?.toString().replaceAll(',', '-')
        : null,
    page: query.page || 1,
    offset: query.offset || 30,
    limit: query.maximum_search_count || 100,
    kokyakuKbnFlg: query.customer_distinction === true ? '1' : null,
    nokiFrom: query.delivery_date_from?.concat(' 00:00:00'),
    nokiTo: query.delivery_date_to?.concat(' 23:59:59'),
    zaitokKbn:
      query.product_classification && query.product_classification.length === 1
        ? query.product_classification[0]
        : null,
    webMitrNoFrom:
      query.quotation_number_from && `W${query.quotation_number_from}`,
    webMitrNoTo: query.quotation_number_to && `W${query.quotation_number_to}`,
    webTrokubFrom: query.order_date_from?.concat(' 00:00:00'),
    webTrokubTo: query.order_date_to?.concat(' 23:59:59'),
    chumonUketkeNoFrom: query.order_number_from,
    chumonUketkeNoTo: query.order_number_to,
    kokyakum: query.customer_name,
    nonyusm: query.delivery_address,
    hakudoShhnm: query.product_name,
    shiagsnpoNyurk: query.finished_dimensions,
    shiagsnpo: query.finished_dimensions,
    kokyakuTanto: query.internal_person,
    sogakuFrom: query.selling_price_from,
    sogakuTo: query.selling_price_to,
    juryFrom: query.weight_from,
    juryTo: query.weight_to,
    sortColumn: 'webMitrNo',
    sortType: query.quotation_number_express || '0',
    tantoMei: query.customer,
    sttCd: query.status,
  };

  const queryQuotation = useQuery<
    AxiosResponse<Response.Quotation>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath, params],
    queryFn: quotation.getQuotation,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const queryLoyalCustomer = useQuery<
    AxiosResponse<Response.LoyalCustomer>,
    AxiosError<Response.Error>
  >({
    queryKey: [loyalCustomerPath, params],
    queryFn: quotation.getLoyalCustomer,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const exportCSV = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.ExportCSV
  >({
    mutationKey: [csvPath],
    mutationFn: (args) => {
      return quotation.exportCSV(csvPath, args);
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Quotation.csv');
      document.body.appendChild(link);
      link.click();
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const exportPDF = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.ExportPDF
  >({
    mutationKey: [pdfPath],
    mutationFn: (args) => {
      return quotation.exportPDF(pdfPath, args);
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Quotation.pdf');
      document.body.appendChild(link);
      link.click();
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const deleteQuotation = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteQuotation
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => {
      return quotation.deleteQuotation(apiPath, args);
    },
    onSuccess: (data) => {
      queryClient.removeQueries([apiPath]);
      setRowKeysSelected?.([]);
      if (data?.data?.length > 0) {
        setError?.(
          t('message.E000089', {
            0: t('quotation_page.order_item'),
          }),
        );
      }
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const updateOrderStatus = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.UpdateOrderStatus
  >({
    mutationKey: [orderStatusPath],
    mutationFn: (args) => {
      return quotation.updateOrderStatus(orderStatusPath, args);
    },
    onSuccess: () => {
      queryClient.removeQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const queryOrderStatus = useQuery<
    AxiosResponse<Response.OrderStatus>,
    AxiosError<Response.Error>
  >({
    queryKey: [orderStatusPath],
    queryFn: quotation.getOrderStatus,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataOrderStatus = queryOrderStatus?.data?.data?.items?.map((item) => ({
    label: item?.sttm,
    value: item?.sttCd,
  }));

  return {
    queryQuotation,
    exportCSV,
    queryLoyalCustomer,
    exportPDF,
    deleteQuotation,
    dataOrderStatus,
    updateOrderStatus,
  };
};

export default useQuotation;
