import { Col, Checkbox, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import {
  CheckBoxField,
  CheckboxGroupField,
  NegativeNumberField,
  InputField,
  SelectField,
} from 'src/components/form';
import { t } from 'src/libs/i18n';

import { TaxtSettingType } from 'src/enums/taxSetting';
import { FormValue } from '../useForm';
import { CATEGORIES_ID, SHAPE } from 'src/constants/app';

type Props = {
  index: number;
  handleSelectRow: (event, index, seqno) => void;
  item;
  form;
  checkTaxSelectSymbol;
  symbolOptions;
  dataOccupation;
  dataLocation;
  dataApiList;
  dataHinshList;
  dataKeijoList;
};

const TaxExchangeItem: React.FC<Props> = ({
  index,
  item,
  form,
  checkTaxSelectSymbol,
  handleSelectRow,
  symbolOptions,
  dataOccupation,
  dataLocation,
  dataApiList,
  dataHinshList,
  dataKeijoList,
}) => {
  const { control, watch, getValues, setValue } = form;

  const [disabledCheckboxAll, setDisabledCheckboxAll] =
    useState<boolean>(false);
  const [disabledOthersCheckbox, setDisabledOthersCheckbox] =
    useState<boolean>(false);
  const [disabledShapeCheckbox, setDisabledShapeCheckbox] =
    useState<boolean>(false);

  const watchTaxSettingType = watch(`tax_setting.${index}.type`);
  const watchTaxSettingHinshCd = watch(`tax_setting.${index}.hinshCd`);

  useEffect(() => {
    if (watchTaxSettingType?.length > 0) {
      if (watchTaxSettingType?.includes(TaxtSettingType.ALL)) {
        setDisabledOthersCheckbox(true);
        setDisabledCheckboxAll(false);
      } else {
        setDisabledCheckboxAll(true);
        setDisabledOthersCheckbox(false);
      }
    } else {
      setDisabledCheckboxAll(false);
      setDisabledOthersCheckbox(false);
    }
  }, [watchTaxSettingType?.length]);

  useEffect(() => {
    if (watchTaxSettingType?.includes(TaxtSettingType.CATEGORY)) {
      if (watchTaxSettingHinshCd === CATEGORIES_ID.TOOL_PRODUCTS) {
        const listChecked = getValues(`tax_setting.${index}.type`);
        const newListChecked = listChecked?.filter(
          (element) => element !== TaxtSettingType.SHAPE,
        );
        setValue(`tax_setting.${index}.type`, newListChecked);
        setDisabledShapeCheckbox(true);
      } else {
        setDisabledShapeCheckbox(false);
      }
    } else {
      setDisabledShapeCheckbox(false);
    }
  }, [watchTaxSettingType?.length, watchTaxSettingHinshCd]);

  const onChangeUnit = (index, value) => {
    setValue(`tax_setting.${index}.taxValue`, null);
    checkTaxSelectSymbol.mutate({ param: value, index: index });
  };

  return (
    <Row gutter={10} className="tax_setting_item">
      <Col xs={2} md={1} xl={1}>
        <CheckBoxField<FormValue>
          controller={{
            name: `tax_setting.${index}.checkbox`,
            control: control,
          }}
          checkboxProps={{
            onClick: (event) => handleSelectRow(event, index, item.seqno),
          }}
        />
      </Col>
      <Col xs={10} md={3} xl={3} className="tax_setting_name">
        <InputField
          controller={{
            name: `tax_setting.${index}.taxName`,
            control: control,
          }}
          inputProps={{
            maxLength: 50,
          }}
        />
      </Col>
      <Col xs={6} md={2} xl={2} className="tax_setting_rate">
        <NegativeNumberField<FormValue>
          controller={{
            name: `tax_setting.${index}.taxValue`,
            control: control,
          }}
        />
      </Col>
      <Col xs={6} md={2} xl={2} className="tax_setting_unit">
        <SelectField<FormValue>
          controller={{
            name: `tax_setting.${index}.taxUnit`,
            control: control,
          }}
          selectProps={{
            options: symbolOptions[0],
            onChange: (value) => onChangeUnit(index, value),
          }}
          className="select-currency-unit"
        />
      </Col>
      <Col
        xs={{ span: 22, offset: 2 }}
        md={{ span: 16, offset: 0 }}
        xl={16}
        className="tax_setting_checkbox"
      >
        <CheckboxGroupField<FormValue>
          controller={{
            name: `tax_setting.${index}.type`,
            control,
          }}
        >
          <Checkbox
            value={TaxtSettingType.ALL}
            className="tax_setting_checkbox_normal"
            disabled={disabledCheckboxAll}
          >
            <Typography.Text>{t('tax_exchange_setting.all')}</Typography.Text>
          </Checkbox>
          <Row className="tax_setting_option">
            <Checkbox
              value={TaxtSettingType.BACKORDER_ITEM}
              disabled={disabledOthersCheckbox}
            >
              <Typography.Text>
                {t('tax_exchange_setting.backorder_only')}
              </Typography.Text>
            </Checkbox>
            {watchTaxSettingType?.includes(TaxtSettingType.BACKORDER_ITEM) && (
              <SelectField
                controller={{
                  control,
                  name: `tax_setting.${index}.apiSeqno`,
                  defaultValue: 1,
                }}
                selectProps={{
                  options: dataApiList,
                }}
                className="tax_setting_checkbox_item_select"
              />
            )}
          </Row>
          <Row className="tax_setting_option">
            <Checkbox
              value={TaxtSettingType.CATEGORY}
              disabled={disabledOthersCheckbox}
            >
              <Typography.Text>{t('product.category')}</Typography.Text>
            </Checkbox>
            {watchTaxSettingType?.includes(TaxtSettingType.CATEGORY) && (
              <SelectField
                controller={{
                  control,
                  name: `tax_setting.${index}.hinshCd`,
                  defaultValue: CATEGORIES_ID.ALUMNINUM,
                }}
                selectProps={{
                  options: dataHinshList,
                }}
                className="tax_setting_checkbox_item_select"
              />
            )}
          </Row>
          <Row className="tax_setting_option">
            <Checkbox
              value={TaxtSettingType.SHAPE}
              disabled={disabledOthersCheckbox || disabledShapeCheckbox}
            >
              <Typography.Text>{t('product.shape')}</Typography.Text>
            </Checkbox>
            {watchTaxSettingType?.includes(TaxtSettingType.SHAPE) && (
              <SelectField
                controller={{
                  control,
                  name: `tax_setting.${index}.keijoCd`,
                  defaultValue: SHAPE.PLATE,
                }}
                selectProps={{
                  options: dataKeijoList,
                }}
                className="tax_setting_checkbox_item_select"
              />
            )}
          </Row>
          <Row className="tax_setting_option">
            <Checkbox
              value={TaxtSettingType.DESIGNATE_INDUSTRY}
              disabled={disabledOthersCheckbox}
            >
              <Typography.Text>
                {t('tax_exchange_setting.specify_industry')}
              </Typography.Text>
            </Checkbox>
            {watchTaxSettingType?.includes(
              TaxtSettingType.DESIGNATE_INDUSTRY,
            ) && (
              <SelectField
                controller={{
                  control,
                  name: `tax_setting.${index}.gyoushuId`,
                  defaultValue: 1,
                }}
                selectProps={{
                  options: dataOccupation,
                }}
                className="tax_setting_checkbox_item_select"
              />
            )}
          </Row>
          <Row className="tax_setting_option">
            <Checkbox
              value={TaxtSettingType.DESIGNATE_REGION}
              disabled={disabledOthersCheckbox}
            >
              <Typography.Text>
                {t('tax_exchange_setting.specify_region')}
              </Typography.Text>
            </Checkbox>
            {watchTaxSettingType?.includes(
              TaxtSettingType.DESIGNATE_REGION,
            ) && (
              <SelectField
                controller={{
                  control,
                  name: `tax_setting.${index}.shozaichiId`,
                  defaultValue: 1,
                }}
                selectProps={{
                  options: dataLocation,
                }}
                className="tax_setting_checkbox_item_select"
              />
            )}
          </Row>
        </CheckboxGroupField>
      </Col>
    </Row>
  );
};

export default TaxExchangeItem;
