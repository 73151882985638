import { Button, Card, Col, Divider, Row, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import SearchComponent from './SearchComponent';
import useTable from './ListCustomer/useTable';
import useCustomerSetting from 'src/queries/useCustomerSetting';
import history from 'src/libs/history';
import { PATH as PATH_CUSTOMER_REGISTRATION } from 'src/pages/customerRegistration';
import modalConfirm from 'src/utils/modalConfirm';

const CustomerSetting: React.FC = () => {
  const [errorTable, setErrorTable] = useState('');
  const { tableProps, rowSelected, setRowKeysSelected } = useTable({
    error: errorTable,
  });
  const { t } = useTranslation();
  const { deleteCustomerSetting } = useCustomerSetting({
    setRowKeysSelected,
    setError: setErrorTable,
  });

  const handleDelete = () => {
    if (rowSelected.length > 0) {
      setErrorTable('');
      const listItemDelete = {
        seqnos: rowSelected,
      };
      modalConfirm({
        t,
        title: t('customer_setting.message_modal_delete'),
        okText: t('modal_confirm.button_confirm'),
        cancelText: t('modal_confirm.button_cancel'),
        maskClosable: false,
        onOk: async () => {
          await deleteCustomerSetting.mutate(listItemDelete);
        },
      });
    } else {
      setErrorTable(
        t('message.E000007', {
          0: t('customer_setting.client'),
        }),
      );
    }
  };

  const handleAddCustomer = () => {
    history.push(PATH_CUSTOMER_REGISTRATION);
  };
  return (
    <div className="customer_setting">
      <SearchComponent />
      <div className="list_customer">
        <Card
          title={
            <React.Fragment>
              <Divider className="mb-11 customer_setting_display_mobile border-gray-1" />
              <Row>
                <Col xs={24} xl={17} className="customer_setting_title">
                  <Typography.Text className="customer_setting_list">
                    {t('customer_setting.customer_list')}
                  </Typography.Text>
                </Col>
                <Col xs={24} xl={7}>
                  <Row gutter={[10, 0]}>
                    <Col xs={12} xl={14}>
                      <Button
                        type="primary"
                        className="w-full h-44 fs-16"
                        onClick={handleAddCustomer}
                      >
                        {t('customer_setting.add_customer')}
                      </Button>
                    </Col>
                    <Col xs={12} xl={10}>
                      <Button
                        type="primary"
                        className="w-full h-44 d-flex align-center justify-center"
                        onClick={handleDelete}
                      >
                        <DeleteOutlined /> {t('button.delete')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Divider className="mt-10 border-gray-1" />
              </Row>
            </React.Fragment>
          }
          className="custom_card"
        >
          <Table {...tableProps} />
        </Card>
      </div>
    </div>
  );
};

export default CustomerSetting;
