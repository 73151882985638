import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Row, Typography, Form } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { t } from 'src/libs/i18n';
import { RadioGroupField, SelectField } from 'src/components/form';
import useForm, { FormValue } from '../useForm';
import { STATUS_UPLOAD_CSV } from 'src/constants/optionSelect/master_registration';
import FileUploadCsvField from 'src/components/form/FileUploadCsvField';
import { useMasterRegistration } from 'src/queries/useMasterRegistration';
import { STATUS_MASTER_REGISTRATION } from 'src/constants/app';
import LocalStorage from 'src/utils/LocalStorage';

type Props = {
  control: any;
  uploadFileName:
    | 'upload_file_item_size'
    | 'upload_file_process_info'
    | 'upload_file_fare'
    | 'upload_file_inventory_info'
    | 'upload_file_product_detail'
    | 'upload_file_customer_info'
    | 'upload_file_product_dimension_info';
  nameStatus:
    | 'status_item_size'
    | 'status_process_info'
    | 'status_fare'
    | 'status_inventory_info'
    | 'status_product_detail'
    | 'status_customer_info'
    | 'status_product_dimension_info';
  col?: number;
  title: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  kbnmCd: string;
  hasSelectColumn?: boolean;
  listColumn?: {
    label: string;
    value: string;
  }[];
};

const ItemUploadFile: React.FC<Props> = (props) => {
  const {
    uploadFileName,
    nameStatus,
    col,
    title,
    kbnmCd,
    listColumn,
    hasSelectColumn = false,
  } = props;
  const { control, handleSubmit, resetField, setValue, getValues } =
    useForm(uploadFileName);

  const {
    uploadWork,
    registration,
    runJob,
    queryTotalColumns,
    createTotalColumns,
    updateTotalColumns,
  } = useMasterRegistration({
    setRowKeysSelected: undefined,
  });

  useEffect(() => {
    setValue(
      'number_of_columns',
      queryTotalColumns?.data?.data?.item?.shhnAddInfoDispno,
    );
  }, [queryTotalColumns.data?.data?.item?.shhnAddInfoDispno]);

  const infoFile = {
    uid: kbnmCd,
    name: '',
  };

  let listShowFile = {
    upload_file_item_size: infoFile,
    upload_file_process_info: infoFile,
    upload_file_fare: infoFile,
    upload_file_inventory_info: infoFile,
    upload_file_product_detail: infoFile,
    upload_file_customer_info: infoFile,
    upload_file_product_dimension_info: infoFile,
  };
  const [fileLists, setFileLists] = useState(listShowFile);

  const [valueSubmit, setValueSubmit] =
    useState<Payload.registrationMasterRegistration>();
  const [loading, setLoading] = useState<boolean>(false);

  const { downloadSingleCSV } = useMasterRegistration({
    setRowKeysSelected: undefined,
  });

  const handleSubmitForm = (values, uploadFileName) => {
    registration.mutate({
      kbnmCd: kbnmCd,
      fileNm: values[uploadFileName][1]?.displayName,
      haneiPattern: values[nameStatus],
      totalRecord: values[uploadFileName][1]?.totalRecord,
      errorMsg: values[uploadFileName][1]?.errorMsg,
      userCd: LocalStorage?.authInfo?.userCd,
    });
    setValueSubmit(values);
  };

  const getListFileField = (value) => {
    listShowFile[uploadFileName] = {
      name: value?.file?.name,
      uid: kbnmCd,
    };
    return listShowFile;
  };

  useEffect(() => {
    if (registration?.data) {
      if (valueSubmit) {
        uploadWork.mutate({
          seqno: registration?.data?.data?.item?.seqno,
          kbnmCd: kbnmCd,
          totalRecord: registration?.data?.data?.item?.totalRecord,
          fileNm: valueSubmit[uploadFileName][1]?.fileNm,
          fileDir: valueSubmit[uploadFileName][1]?.id,
          errorMsg: valueSubmit[uploadFileName][1]?.errorMsg,
        });
      }
      resetField(uploadFileName);
      let newFileLists = fileLists;
      newFileLists[uploadFileName] = infoFile;
      setFileLists(newFileLists);
      setValue(nameStatus, STATUS_MASTER_REGISTRATION.TIMER);
    }
  }, [registration.data]);

  useEffect(() => {
    if (valueSubmit) {
      if (
        uploadWork?.data &&
        valueSubmit[nameStatus] == STATUS_MASTER_REGISTRATION.TIMER &&
        !valueSubmit[uploadFileName][1]?.errorMsg
      ) {
        runJob.mutate({
          seqno: uploadWork?.data?.data?.item?.seqno,
        });
      }
    }
  }, [uploadWork.data]);

  const downloadCsv = (kbnmCd) => {
    downloadSingleCSV.mutate({
      kbnmCd: kbnmCd,
      locale: LocalStorage?.lang,
    });
  };

  const handleTotalColumns = () => {
    const shhnAddInfoDispno = getValues('number_of_columns');
    if (shhnAddInfoDispno !== undefined) {
      const payload = {
        seqno: queryTotalColumns?.data?.data?.item?.seqno,
        shhnAddInfoDispno: shhnAddInfoDispno,
      };
      if (queryTotalColumns?.data?.data?.item?.seqno) {
        updateTotalColumns.mutate(payload);
      } else {
        createTotalColumns.mutate(payload);
      }
    }
  };

  const renderSettingColumn = () => {
    return (
      <>
        {hasSelectColumn ? (
          <Row className="setting_column">
            <Col xs={12} md={8} lg={7} xl={9} className="setting_column_title">
              {t('master_registration.number_of_displayed_items')}
            </Col>
            <Col xs={6} md={6} lg={8} xl={6} className="setting_column_select">
              <SelectField<FormValue>
                controller={{
                  control,
                  name: 'number_of_columns',
                  defaultValue: '0',
                }}
                selectProps={{
                  options: listColumn,
                }}
              />
            </Col>
            <Col className="setting_column_btn" xs={6} md={7} lg={8} xl={7}>
              <Button
                type="primary"
                onClick={handleTotalColumns}
                loading={
                  createTotalColumns?.isLoading || updateTotalColumns?.isLoading
                }
              >
                {t('master_registration.setting')}
              </Button>
            </Col>
          </Row>
        ) : (
          <Typography.Paragraph className="text-title">
            {!col ? title : ''}
          </Typography.Paragraph>
        )}
      </>
    );
  };

  return (
    <Form
      onFinish={handleSubmit((val) => handleSubmitForm(val, uploadFileName))}
    >
      <Row>
        <Col
          className={col ? 'title-upload' : 'title-upload hidden-max'}
          xs={6}
          sm={8}
          md={3}
        >
          <Typography.Paragraph className="text-title">
            {title}
          </Typography.Paragraph>
        </Col>

        <Col xs={18} md={21} sm={16} lg={col || 24}>
          <Row>
            <Col className="col-item-upload" sm={24} md={24} lg={16}>
              <Row
                className={`item-upload ${col ? 'not-left' : 'left'} ${
                  hasSelectColumn ? 'has-select-column' : ''
                }`}
              >
                <Col
                  xs={24}
                  xl={9}
                  className={`${hasSelectColumn ? '' : 'contain-text-title'}`}
                >
                  {renderSettingColumn()}
                </Col>
                <Col
                  className="contain-file"
                  xs={24}
                  sm={24}
                  md={14}
                  lg={14}
                  xl={9}
                >
                  <FileUploadCsvField<FormValue>
                    className="mb-0 ml-10"
                    nameButton={t('master_registration.btn_upload')}
                    type={'default'}
                    typeUploadCsv={kbnmCd}
                    setLoadingParent={setLoading}
                    controller={{
                      control,
                      name: uploadFileName,
                    }}
                    uploadFileProps={{
                      fileList: [fileLists[uploadFileName]],
                      onChange: (value) => {
                        setFileLists(getListFileField(value));
                      },
                    }}
                  />
                </Col>
                <Col
                  sm={24}
                  xs={24}
                  md={6}
                  lg={6}
                  xl={6}
                  className="btn-download"
                >
                  <Button
                    onClick={() => downloadCsv(kbnmCd)}
                    icon={<DownloadOutlined />}
                  >
                    {t('master_registration.btn_download')}
                  </Button>
                </Col>
              </Row>
              <Divider className="mt-5 mb-5 divider-mobile" />
            </Col>
            <Col xs={24} md={24} lg={8}>
              <Row className="status_row">
                <Col xs={18} md={16} className="parent_status">
                  <RadioGroupField<FormValue>
                    className={'status'}
                    controller={{
                      name: nameStatus,
                      control,
                      defaultValue: '01',
                    }}
                    radioGroupProps={{ options: STATUS_UPLOAD_CSV(t) }}
                  />
                </Col>
                <Col className="button_register" xs={6} md={8}>
                  <Button loading={loading} htmlType="submit" type="primary">
                    {t('master_registration.button_registration')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default ItemUploadFile;
