import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Space,
  Button,
  Divider,
  Table as AntTable,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import useTable from './useTable';
import { useMasterRegistration } from 'src/queries/useMasterRegistration';
import modalConfirm from 'src/utils/modalConfirm';
import {
  STATUS_MASTER_REGISTRATION,
  STATUS_UPLOAD_CSV,
} from 'src/constants/app';
import {
  formatDeleteHistoryCsv,
  formatDate,
  getCurrentDateTime,
} from 'src/utils/helper';

const TableStatus: React.FC = () => {
  const {
    tableProps,
    rowSelected,
    queryListHistory,
    setRowKeysSelected,
    getValues,
  } = useTable();
  const { t } = useTranslation();
  const [checkReservationDate, setCheckCheckReservationDate] =
    useState<boolean>(false);
  const [checkDeleteHistoryCsv, setCheckCheckDeleteHistoryCsv] =
    useState<boolean>(false);
  const [checkDownloadCsv, setCheckDownloadCsv] = useState<boolean>(false);

  // Check disable, enable button delete Reservation date registration , delete and download file zip csv after click checkbox table
  useEffect(() => {
    // Check enable
    queryListHistory.data?.data.items &&
      queryListHistory.data?.data.items.forEach((item, i) => {
        if (
          item.status == STATUS_UPLOAD_CSV.UPLOADED &&
          !item.shiteiNichiji &&
          item.haneiPattern == STATUS_MASTER_REGISTRATION.RESERVATION &&
          rowSelected.includes(Number(item.seqno)) &&
          rowSelected.length == 1
        ) {
          setCheckCheckReservationDate(true);
        }
        if (
          item.status == STATUS_UPLOAD_CSV.UPLOADED ||
          (item.haneiPattern == STATUS_MASTER_REGISTRATION.RESERVATION &&
            rowSelected.includes(Number(item.seqno)))
        ) {
          setCheckDownloadCsv(true);
        }
        if (
          (item.status == STATUS_UPLOAD_CSV.FAILURE ||
            item.status == STATUS_UPLOAD_CSV.ERROR) &&
          rowSelected.includes(Number(item.seqno))
        ) {
          setCheckCheckDeleteHistoryCsv(true);
        }
      });
    // Check disable
    queryListHistory.data?.data.items.forEach((item, i) => {
      if (
        ((item.status !== STATUS_UPLOAD_CSV.UPLOADED ||
          item.haneiPattern != STATUS_MASTER_REGISTRATION.RESERVATION ||
          item.shiteiNichiji) &&
          rowSelected.includes(Number(item.seqno))) ||
        rowSelected.length == 0 ||
        rowSelected.length > 1
      ) {
        setCheckCheckReservationDate(false);
      }
      if (
        (item.status !== STATUS_UPLOAD_CSV.UPLOADED &&
          rowSelected.includes(Number(item.seqno))) ||
        rowSelected.length == 0
      ) {
        setCheckDownloadCsv(false);
      }
      if (
        (item.status !== STATUS_UPLOAD_CSV.FAILURE &&
          item.status !== STATUS_UPLOAD_CSV.ERROR &&
          rowSelected.includes(Number(item.seqno))) ||
        rowSelected.length == 0
      ) {
        setCheckCheckDeleteHistoryCsv(false);
      }
    });
  }, [rowSelected]);

  const { deleteMasterRegistration, downloadCSV, scheduling } =
    useMasterRegistration({
      setRowKeysSelected,
      rowSelected,
    });

  const handleDelete = async () => {
    const listCsvDelete = formatDeleteHistoryCsv(rowSelected, queryListHistory);
    await deleteMasterRegistration.mutate(listCsvDelete);
  };

  const downloadCsv = async () => {
    const listCsvDownload = formatDownloadCsv(rowSelected);
    downloadCSV.mutate({
      seqnos: listCsvDownload,
    });
  };

  const formatDownloadCsv = (param) => {
    let listCsvDownload = '';

    param.forEach((seqno, index) => {
      if (index === param.length - 1) {
        listCsvDownload += seqno;
      } else {
        listCsvDownload += seqno + ',';
      }
    });

    return listCsvDownload;
  };

  const handleSchedule = (valueDateTime, isReplace = false) => {
    const item =
      (rowSelected &&
        queryListHistory.data?.data.items.filter(
          (item) => item.seqno == rowSelected[0].toString(),
        )[0]) ||
      null;
    item &&
      scheduling.mutate({
        seqno: Number(item?.seqno),
        kbnmCd: item?.kbnmCd,
        date: valueDateTime ? new Date(valueDateTime).toISOString() : '',
        flgReplace: isReplace,
      });
  };

  const handleRegister = () => {
    const dateTime = getValues(`dateTimeReflections.${rowSelected[0] || 0}`);
    if (typeof dateTime == 'undefined') {
      const title = t('master_registration.pop_up.past_date');
      showModalConfirm(title, false, dateTime, false);
    } else {
      const appointmentDateTime = dateTime.toString();
      const currentDateTime = getCurrentDateTime();

      const checkAppointmentDateTimeExist =
        (rowSelected &&
          queryListHistory.data?.data.items.filter((item) => {
            if (item?.shiteiNichiji) {
              if (
                new Date(formatDate(item.shiteiNichiji)) <=
                  new Date(appointmentDateTime) &&
                new Date(formatDate(item.shiteiNichiji)) >=
                  new Date(appointmentDateTime)
              ) {
                return item;
              }
            }
          })[0]) ||
        null;

      //If the previous appointment date and time already exist, display popup d1
      if (checkAppointmentDateTimeExist) {
        const title = t('master_registration.pop_up.override');
        showModalConfirm(title, true, appointmentDateTime, true);
      } else if (
        new Date(appointmentDateTime) > new Date(currentDateTime) &&
        new Date(appointmentDateTime).getDate() ==
          new Date(currentDateTime).getDate()
      ) {
        // if appointment date time > current date time and current date == appointment date => show popup d2
        const title = t('master_registration.pop_up.today_specified');
        showModalConfirm(title, true, appointmentDateTime, false);
      } else if (
        new Date(appointmentDateTime) > new Date(currentDateTime) &&
        !checkAppointmentDateTimeExist
      ) {
        // if appointment date time > current date time => submit and not show popup
        handleSchedule(appointmentDateTime, false);
      } else if (
        new Date(appointmentDateTime) < new Date(currentDateTime) ||
        (new Date(appointmentDateTime) <= new Date(currentDateTime) &&
          new Date(appointmentDateTime) >= new Date(currentDateTime))
      ) {
        // if appointment date time < current date time or appointment date time = current date time => show popup d3
        const title = t('master_registration.pop_up.past_date');
        showModalConfirm(title, false, appointmentDateTime, true);
      }
    }
  };

  const showModalConfirm = (title, isAction, valueDateTime, isReplace) => {
    modalConfirm({
      t,
      title: title,
      okText: t('basic_setting.button_confirm'),
      cancelText: t('basic_setting.button_cancel'),
      okButtonProps: {
        type: 'primary',
      },
      wrapClassName: 'master-registration-modal',
      cancelButtonProps: {
        style: { display: isAction ? 'default' : 'none' },
      },
      onOk: async () => {
        if (isAction) {
          handleSchedule(valueDateTime, isReplace);
        }
      },
    });
  };

  return (
    <React.Fragment>
      <Divider />
      <div className="table">
        <Card
          title={
            <Row>
              <Col xs={24} md={12} xl={12} className="table_title">
                <Typography.Title level={2}>
                  {t('master_registration.upload_status')}
                </Typography.Title>
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Space className="d-flex justify-end table_button">
                  <Button
                    disabled={!checkReservationDate}
                    type="primary"
                    onClick={() => handleRegister()}
                    icon={<CheckCircleOutlined />}
                  >
                    {t('master_registration.reservation_date_registration')}
                  </Button>
                </Space>
              </Col>
            </Row>
          }
          className="custom_card"
        />
        <AntTable {...tableProps} />
        <Row className="contain-button">
          <Button
            type="primary"
            onClick={handleDelete}
            disabled={!checkDeleteHistoryCsv}
            icon={<DeleteOutlined />}
          >
            {t('button.delete')}
          </Button>
          <Button
            disabled={!checkDownloadCsv}
            onClick={downloadCsv}
            type="primary"
            icon={<DownloadOutlined />}
          >
            {t('master_registration.confirmation_of_reservation_details')}
          </Button>
        </Row>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default TableStatus;
