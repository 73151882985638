import React from 'react';

import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { t } from 'src/libs/i18n';
import Layout from 'src/components/layout';
import { PATH } from 'src/pages/profitMarginSetting';
import { SCREENS } from 'src/constants/screens';

const ProfitMarginSettingRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        index: true,
        path: PATH,
        element: loadable(() => import('src/pages/profitMarginSetting')),
        title: t('profit_margin_setting.title'),
        screenId: SCREENS.R1504,
      },
    ],
  },
];

export default ProfitMarginSettingRoute;
