import { Button, Col, Divider, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { DeleteOutlined } from '@ant-design/icons';

import { RadioGroupField, SelectField } from 'src/components/form';
import { DISPLAY_OF_TOTAL_AMOUNT } from 'src/constants/app';
import { TAX_EXCHANGE_OPTION } from 'src/constants/optionSelect/taxExchangeSetting';
import { t } from 'src/libs/i18n';
import useTaxExChangeSetting from 'src/queries/useTaxExChangeSetting';
import useForm, { FormValue } from './useForm';
import {
  HASUTANI_TYPE,
  MARUME_HOHO,
} from 'src/constants/optionSelect/profitMarginSetting';
import { UnitPrices } from 'src/enums/profitMargin';
import { TaxtSettingType } from 'src/enums/taxSetting';
import useSelectRow from 'src/hooks/useSelectRow';
import TaxExchangeItem from './TaxExchangeItem';
import useOccupationAndLocation from 'src/queries/useOccupationAndLocation';

type Props = {
  symbols?: Response.SymbolItem[];
};

const TaxExchange: React.FC<Props> = ({ symbols }) => {
  const [errorsTax, setErrorsTax] = useState<string[]>([]);
  const [hasErrorsTax, setHasErrorsTax] = useState<boolean>(false);
  const [fieldArrayState, setFieldArrayState] = useState<
    Response.TaxSettingsEntity[]
  >([]);

  const {
    handleSelectRow,
    selectedRecord,
    selectedSeqno,
    setSelectedRecord,
    setSelectedSeqno,
  } = useSelectRow();

  const { dataLocation, dataOccupation } = useOccupationAndLocation();

  const handleSuccessCheckSymbol = (
    res: AxiosResponse<Response.CheckExistCurrencyTransferBySymbol, any>,
    args: Payload.CheckTaxSelectSymbol,
  ) => {
    const checkTaxSelect = res?.data?.item;
    if (checkTaxSelect === false) {
      setError(`tax_setting.${args?.index}.taxUnit`, {
        type: 'custom',
        message: t('message.E000067'),
      });
    } else {
      clearErrors(`tax_setting.${args?.index}.taxUnit`);
    }
  };

  const { symbolOptions, fieldArray, form } = useForm({
    symbolsTaxExChange: symbols,
  });

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = form;

  const {
    getTaxSettings,
    saveTaxSettings,
    getCurrentCurrencyUnit,
    getCurrencyTransfers,
    checkTaxSelectSymbol,
    dataApiList,
    dataHinshList,
    dataKeijoList,
  } = useTaxExChangeSetting({
    handleSuccess: handleSuccessCheckSymbol,
    setError: setError,
  });
  const { fields, append, remove } = fieldArray;

  const taxExchangeData = getTaxSettings?.data?.data?.item;

  const checkTaxType = (item) => {
    const resultArray: number[] = [];

    const propertyToEnumMapping = {
      keijoCd: 'SHAPE',
      hinshCd: 'CATEGORY',
      apiSeqno: 'BACKORDER_ITEM',
      gyoushuId: 'DESIGNATE_INDUSTRY',
      shozaichiId: 'DESIGNATE_REGION',
    };

    for (const property in propertyToEnumMapping) {
      if (item[property] !== undefined && item[property] !== null) {
        const enumValue = +TaxtSettingType[propertyToEnumMapping[property]];
        resultArray.push(enumValue);
      }
    }
    if (resultArray?.length === 0) {
      resultArray.push(TaxtSettingType.ALL);
    }
    return resultArray;
  };

  // Show data
  useEffect(() => {
    if (taxExchangeData?.taxSettings) {
      if (taxExchangeData?.taxSettings?.length > 0) {
        taxExchangeData?.taxSettings?.map((item) => {
          append(
            {
              checkbox: false,
              gyoushuId: item?.gyoushuId ? +item?.gyoushuId : undefined,
              taxName: item?.taxName,
              taxValue: item?.taxValue || undefined,
              shozaichiId: item?.shozaichiId ? +item?.shozaichiId : undefined,
              seqno: item?.seqno,
              taxUnit: item?.taxUnit || UnitPrices.PERCENT,
              type: checkTaxType(item),
              isDeleted: item?.isDeleted || false,
              apiSeqno: item?.apiSeqno ? item?.apiSeqno : undefined,
              hinshCd: item?.hinshCd ? item?.hinshCd : undefined,
              keijoCd: item?.keijoCd ? item?.keijoCd : undefined,
            },
            { shouldFocus: false },
          );
        });
      }
      setFieldArrayState(taxExchangeData?.taxSettings);
    }
    setValue(
      'last_digit',
      taxExchangeData?.hasuTani || HASUTANI_TYPE(t)[0].value,
    );
    setValue(
      'round_down',
      taxExchangeData?.marumeHoho || MARUME_HOHO(t)[0].value,
    );
    setValue(
      'radio_tax',
      taxExchangeData?.kingakuHyoji || DISPLAY_OF_TOTAL_AMOUNT.TAX_EXCLUDED,
    );

    return () => {
      remove();
    };
  }, [taxExchangeData]);

  // Show note below radio tax when checked [合計金額税込 表示]
  const [showNoteRadioCheck, setNoteRadioCheck] = useState<string>();
  useEffect(() => {
    clearErrors('radio_tax');
    setNoteRadioCheck(
      watch('radio_tax') === DISPLAY_OF_TOTAL_AMOUNT.TAX_INCLUDED
        ? t('tax_exchange_setting.message_check_total_amount_including_tax')
        : '',
    );
  }, [watch('radio_tax')]);

  // Fetch API check symbol when currency transfers section or current unit change value
  useEffect(() => {
    if (
      !getCurrentCurrencyUnit.isFetching &&
      !getCurrencyTransfers.isFetching
    ) {
      fields.map((item, index) => {
        const unit = watch(`tax_setting.${index}.taxUnit`);
        if (unit) {
          checkTaxSelectSymbol.mutate({
            param: unit,
            index: index,
          });
        }
      });
    }
  }, [getCurrentCurrencyUnit.isFetching, getCurrencyTransfers.isFetching]);

  // Validate data
  useEffect(() => {
    let newArr: string[] = [];
    errors?.tax_setting?.map((item) => {
      if (item) {
        Object.values(item).map((mess) => {
          mess?.message && newArr.push(mess.message);
        });
      }
    });
    const uniqueErrors = [...new Set(newArr)];
    setErrorsTax(uniqueErrors);
  }, [errors?.tax_setting]);

  useEffect(() => {
    setHasErrorsTax(errorsTax?.length !== 0);
  }, [errorsTax?.length]);

  // Handle Row
  const handleAddRow = () => {
    append(
      {
        checkbox: false,
        taxName: undefined,
        taxValue: undefined,
        seqno: undefined,
        taxUnit: UnitPrices.PERCENT,
        type: [],
        isDeleted: false,
      },
      { shouldFocus: false },
    );
  };

  const handleRemoveRow = () => {
    selectedRecord?.map((item) => {
      setValue(`tax_setting.${item}.checkbox`, false);
      setValue(`tax_setting.${item}.isDeleted`, true);
      clearErrors(`tax_setting.${item}`);
    });
    remove(selectedRecord);
    if (selectedSeqno.length > 0) {
      setSelectedSeqno([]);
    }
    setSelectedRecord([]);
  };

  const getValueByItemType = (item, type, itemIdProperty) => {
    if (item?.type?.includes(type)) {
      return item?.[itemIdProperty];
    } else {
      return null;
    }
  };

  // Submit data
  const handleSetting = (val) => {
    const taxSettingSeqnoFromApi = fieldArrayState?.map((item) => item.seqno);
    const taxSettingSeqnoFromFieldArray = val?.tax_setting?.map(
      (item) => item.seqno,
    );
    const taxSettingSeqnoIsDeleted = taxSettingSeqnoFromApi?.filter(
      (item) => !taxSettingSeqnoFromFieldArray?.includes(item),
    );
    const taxSettingItemIsDeleted = fieldArrayState
      ?.filter((item) => taxSettingSeqnoIsDeleted?.includes(item?.seqno))
      ?.map((tax) => ({ ...tax, isDeleted: true }));

    const taxSettingArray = val?.tax_setting?.concat(taxSettingItemIsDeleted);

    const taxSetting = taxSettingArray?.map((item) => {
      return {
        seqno: item?.seqno && Number(item?.seqno),
        gyoushuId: getValueByItemType(
          item,
          TaxtSettingType.DESIGNATE_INDUSTRY,
          'gyoushuId',
        ),
        shozaichiId: getValueByItemType(
          item,
          TaxtSettingType.DESIGNATE_REGION,
          'shozaichiId',
        ),
        taxName: item?.taxName || null,
        taxUnit: item?.taxUnit,
        taxValue: item?.taxValue || null,
        isDeleted: item?.isDeleted,
        apiSeqno: getValueByItemType(
          item,
          TaxtSettingType.BACKORDER_ITEM,
          'apiSeqno',
        ),
        hinshCd: getValueByItemType(item, TaxtSettingType.CATEGORY, 'hinshCd'),
        keijoCd: getValueByItemType(item, TaxtSettingType.SHAPE, 'keijoCd'),
      };
    });
    const taxExchangeObj: Payload.TaxExChangeSetting = {
      hasuTani: val?.last_digit,
      marumeHoho: val?.round_down,
      taxType: val?.radio_tax,
      taxSettings: taxSetting,
    };

    saveTaxSettings.mutate(taxExchangeObj);
  };

  return (
    <>
      <Row className="radio_tax">
        <RadioGroupField<FormValue>
          controller={{
            name: 'radio_tax',
            control: control,
          }}
          radioGroupProps={{
            options: TAX_EXCHANGE_OPTION(t),
          }}
        />
        <span className="note_tax">{showNoteRadioCheck}</span>
      </Row>
      <div className="tax_exchange">
        <Typography.Title level={5} className="tax_exchange_title">
          {t('tax_exchange_setting.please_select_a_condition')}
        </Typography.Title>
        <div className="tax_exchange_list">
          {fields.map((item, index) => (
            <TaxExchangeItem
              checkTaxSelectSymbol={checkTaxSelectSymbol}
              form={form}
              handleSelectRow={handleSelectRow}
              index={index}
              item={item}
              key={item.id}
              symbolOptions={symbolOptions}
              dataApiList={dataApiList}
              dataHinshList={dataHinshList}
              dataKeijoList={dataKeijoList}
              dataLocation={dataLocation}
              dataOccupation={dataOccupation}
            />
          ))}
        </div>
        {errorsTax &&
          errorsTax.map((error, index) => (
            <Row key={index}>
              <Typography.Text
                className="ant-form-item-explain ant-form-item-explain-error message_error"
                type="danger"
                ellipsis={{ tooltip: true }}
              >
                {error}
              </Typography.Text>
            </Row>
          ))}
        <Divider className="divider_desktop" />
        <Row className="setting_action">
          <Button
            className="setting_action_add"
            type="primary"
            onClick={handleAddRow}
          >
            {t('tax_exchange_setting.add_line')}
          </Button>
          <Button
            className="setting_action_delete"
            type="primary"
            icon={<DeleteOutlined />}
            onClick={handleRemoveRow}
          >
            {t('tax_exchange_setting.delete')}
          </Button>
        </Row>
        <Divider />
        <Row className="fractional_tax" gutter={20}>
          <Col className="fractional_tax_title">
            <Typography.Title level={5}>
              {t('tax_exchange_setting.fractional_tax_rate_setting')}
            </Typography.Title>
          </Col>
          <Col className="fractional_tax_select">
            <SelectField<FormValue>
              controller={{
                control,
                name: 'last_digit',
              }}
              selectProps={{
                options: HASUTANI_TYPE(t),
              }}
            />
          </Col>
          <Col className="fractional_tax_select">
            <SelectField<FormValue>
              controller={{
                control,
                name: 'round_down',
              }}
              selectProps={{
                options: MARUME_HOHO(t),
              }}
            />
          </Col>
        </Row>
        <Divider />
        <div className="btn_setting_wrapper">
          <Button
            size="large"
            onClick={
              hasErrorsTax
                ? undefined
                : handleSubmit((val) => handleSetting(val))
            }
            loading={saveTaxSettings.isLoading}
            className="btn_setting_170"
          >
            {t('tax_exchange_setting.setting')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TaxExchange;
