import { useEffect } from 'react';
import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'src/libs/i18n';

import { CATEGORIES_ID, REGEX } from 'src/constants/app';
import { ApiTaniType, UnitPrices } from 'src/enums/profitMargin';
import useProfitMarginSetting from 'src/queries/useProfitMarginSetting';
import { getProductName } from 'src/utils/helper';
import LocalStorage from 'src/utils/LocalStorage';

export type StandardSetting = {
  tani_type: string;
  riekrate1: number | null;
  riekrate2: number | null;
  riekrate3: number | null;
  riekrate4: number | null;
  seqno: number;
};

export type TypeShapeSetting = {
  tani_type: string;
  riekrate1: number | null;
  riekrate2: number | null;
  riekrate3: number | null;
  riekrate4: number | null;
  seqno: number;
  type: string;
  shape: string;
  type_select: string;
  shape_select: string;
  checkbox: boolean;
};

export type CuttingMethodSetting = {
  tani_type: string;
  riekrate1: number | null;
  riekrate2: number | null;
  riekrate3: number | null;
  riekrate4: number | null;
  product: string;
  product_select: string;
  seqno: number;
  processing_method: string;
  processing_method_select: string;
  hakudo_product_flg: boolean;
  checkbox: boolean;
  product_category?: string;
};

export type ApiSetting = {
  api_select: number;
  tani_type_select: string;
  shiire_kingaku_from_1: number | null;
  shiire_kingaku_to_1: number | null;
  shiire_kingaku_from_2: number | null;
  shiire_kingaku_to_2: number | null;
  shiire_kingaku_from_3: number | null;
  shiire_kingaku_to_3: number | null;
  shiire_kingaku_from_4: number | null;
  seqno: number;
};

export type FormValue = {
  // minimum_gross_profit: number | null;
  last_digit: string;
  round_down: string;
  saitei_tanka_seqno: number;
  hasu_settei_seqno: number;
  standard_setting: StandardSetting[];
  type_shape_setting: TypeShapeSetting[];
  cutting_method_setting: CuttingMethodSetting[];
  api_setting: ApiSetting[];
};

const validateRiekratePercentValue = (tani_type, riekrate) => {
  return (
    tani_type === UnitPrices.PERCENT &&
    (Number(riekrate) < -0.1 ||
      Number(riekrate) > 99.9 ||
      !REGEX.REGEX_PERCENT.test(riekrate))
  );
};

const validateRiekrateProfitRateValue = (tani_type, riekrate) => {
  return (
    tani_type !== UnitPrices.PERCENT &&
    (Number(riekrate) < -1.111 ||
      Number(riekrate) > 9999999.999 ||
      !REGEX.REGEX_PROFIT_RATE.test(riekrate))
  );
};

const useForm = () => {
  const validateRiekrateStandard = (tani_type, riekrate, createError) => {
    if (riekrate !== null && riekrate !== undefined) {
      if (validateRiekratePercentValue(tani_type, riekrate)) {
        return createError({
          message: t('message.E000010', {
            0: t('profit_margin_setting.profit_rate'),
            1: '-0.1～99.9',
          }),
        });
      } else if (validateRiekrateProfitRateValue(tani_type, riekrate)) {
        return createError({
          message: t('message.E000006', {
            0: t('profit_margin_setting.profit_margin_error'),
            1: '-1.111～9999999.999',
          }),
        });
      }
    }
    return true;
  };

  const standardSettingSchema = {
    riekrate1: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type', (tani_type, schema) => {
        return schema.test({
          test: function (riekrate1, { createError }) {
            return validateRiekrateStandard(tani_type, riekrate1, createError);
          },
        });
      }),
    riekrate2: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type', (tani_type, schema) => {
        return schema.test({
          test: function (riekrate2, { createError }) {
            return validateRiekrateStandard(tani_type, riekrate2, createError);
          },
        });
      }),
    riekrate3: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type', (tani_type, schema) => {
        return schema.test({
          test: function (riekrate3, { createError }) {
            return validateRiekrateStandard(tani_type, riekrate3, createError);
          },
        });
      }),
    riekrate4: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type', (tani_type, schema) => {
        return schema.test({
          test: function (riekrate4, { createError }) {
            return validateRiekrateStandard(tani_type, riekrate4, createError);
          },
        });
      }),
  };

  const validateRiekrateTypeShape = (
    riekrate,
    tani_type,
    type,
    type_select,
    shape,
    shape_select,
    createError,
  ) => {
    if (riekrate !== null && riekrate !== undefined) {
      if (validateRiekratePercentValue(tani_type, riekrate)) {
        return createError({
          message: t('message.E000010', {
            0: t('profit_margin_setting.profit_rate'),
            1: '-0.1～99.9',
          }),
        });
      } else if (validateRiekrateProfitRateValue(tani_type, riekrate)) {
        return createError({
          message: t('message.E000006', {
            0: t('profit_margin_setting.profit_margin_error'),
            1: '-1.111～9999999.999',
          }),
        });
      } else if (!type && !shape && !type_select && !shape_select) {
        return createError({
          message: t('message.E000007', {
            0: t('profit_margin_setting.type_shape'),
          }),
        });
      }
    }
    return true;
  };

  const typeShapeSettingSchema = {
    riekrate1: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        ['tani_type', 'type', 'type_select', 'shape', 'shape_select'],
        // @ts-ignore
        (tani_type, type, type_select, shape, shape_select, schema) => {
          return schema.test({
            test: function (riekrate1, { createError }) {
              return validateRiekrateTypeShape(
                riekrate1,
                tani_type,
                type,
                type_select,
                shape,
                shape_select,
                createError,
              );
            },
          });
        },
      ),
    riekrate2: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        ['tani_type', 'type', 'type_select', 'shape', 'shape_select'],
        // @ts-ignore
        (tani_type, type, type_select, shape, shape_select, schema) => {
          return schema.test({
            test: function (riekrate2, { createError }) {
              return validateRiekrateTypeShape(
                riekrate2,
                tani_type,
                type,
                type_select,
                shape,
                shape_select,
                createError,
              );
            },
          });
        },
      ),
    riekrate3: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        ['tani_type', 'type', 'type_select', 'shape', 'shape_select'],
        // @ts-ignore
        (tani_type, type, type_select, shape, shape_select, schema) => {
          return schema.test({
            test: function (riekrate3, { createError }) {
              return validateRiekrateTypeShape(
                riekrate3,
                tani_type,
                type,
                type_select,
                shape,
                shape_select,
                createError,
              );
            },
          });
        },
      ),
    riekrate4: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        ['tani_type', 'type', 'type_select', 'shape', 'shape_select'],
        // @ts-ignore
        (tani_type, type, type_select, shape, shape_select, schema) => {
          return schema.test({
            test: function (riekrate4, { createError }) {
              return validateRiekrateTypeShape(
                riekrate4,
                tani_type,
                type,
                type_select,
                shape,
                shape_select,
                createError,
              );
            },
          });
        },
      ),
    shape: Yup.string().when(
      ['type', 'type_select', 'shape_select'],
      // @ts-ignore
      (type, type_select, shape_select, schema) => {
        return schema.test({
          test: function (shape) {
            if (!type && !type_select && (shape || shape_select)) {
              return this.createError({
                message: t('message.E000007', {
                  0: t('profit_margin_setting.type'),
                }),
              });
            } else if ((type || type_select) && !shape && !shape_select) {
              return this.createError({
                message: t('message.E000007', {
                  0: t('profit_margin_setting.shape'),
                }),
              });
            }
            return true;
          },
        });
      },
    ),
  };

  const validateRiekrateCuttingMethod = (
    riekrate,
    tani_type,
    product,
    product_select,
    processing_method,
    processing_method_select,
    createError,
  ) => {
    if (riekrate !== null && riekrate !== undefined) {
      if (validateRiekratePercentValue(tani_type, riekrate)) {
        return createError({
          message: t('message.E000010', {
            0: t('profit_margin_setting.profit_rate'),
            1: '-0.1～99.9',
          }),
        });
      } else if (validateRiekrateProfitRateValue(tani_type, riekrate)) {
        return createError({
          message: t('message.E000006', {
            0: t('profit_margin_setting.profit_margin_error'),
            1: '-1.111～9999999.999',
          }),
        });
      } else if (
        !product &&
        !processing_method &&
        !product_select &&
        !processing_method_select
      ) {
        return createError({
          message: t('message.E000007', {
            0: t('profit_margin_setting.product_processing_method'),
          }),
        });
      }
    }
    return true;
  };

  const cuttingMethodSettingSchema = {
    riekrate1: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        [
          'tani_type',
          'product',
          'product_select',
          'processing_method',
          'processing_method_select',
        ],
        // @ts-ignore
        (
          tani_type,
          product,
          product_select,
          processing_method,
          processing_method_select,
          schema,
        ) => {
          return schema.test({
            test: function (riekrate1, { createError }) {
              return validateRiekrateCuttingMethod(
                riekrate1,
                tani_type,
                product,
                product_select,
                processing_method,
                processing_method_select,
                createError,
              );
            },
          });
        },
      ),
    riekrate2: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        [
          'tani_type',
          'product',
          'product_select',
          'processing_method',
          'processing_method_select',
        ],
        // @ts-ignore
        (
          tani_type,
          product,
          product_select,
          processing_method,
          processing_method_select,
          schema,
        ) => {
          return schema.test({
            test: function (riekrate2, { createError }) {
              return validateRiekrateCuttingMethod(
                riekrate2,
                tani_type,
                product,
                product_select,
                processing_method,
                processing_method_select,
                createError,
              );
            },
          });
        },
      ),
    riekrate3: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        [
          'tani_type',
          'product',
          'product_select',
          'processing_method',
          'processing_method_select',
        ],
        // @ts-ignore
        (
          tani_type,
          product,
          product_select,
          processing_method,
          processing_method_select,
          schema,
        ) => {
          return schema.test({
            test: function (riekrate3, { createError }) {
              return validateRiekrateCuttingMethod(
                riekrate3,
                tani_type,
                product,
                product_select,
                processing_method,
                processing_method_select,
                createError,
              );
            },
          });
        },
      ),
    riekrate4: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when(
        [
          'tani_type',
          'product',
          'product_select',
          'processing_method',
          'processing_method_select',
        ],
        // @ts-ignore
        (
          tani_type,
          product,
          product_select,
          processing_method,
          processing_method_select,
          schema,
        ) => {
          return schema.test({
            test: function (riekrate4, { createError }) {
              return validateRiekrateCuttingMethod(
                riekrate4,
                tani_type,
                product,
                product_select,
                processing_method,
                processing_method_select,
                createError,
              );
            },
          });
        },
      ),
    processing_method: Yup.string().when(
      [
        'product',
        'product_select',
        'processing_method_select',
        'product_category',
      ],
      // @ts-ignore
      (product, product_select, processing_method_select, product_category) => {
        return Yup.string().test({
          test: function (processing_method) {
            if (
              !product &&
              !product_select &&
              (processing_method || processing_method_select)
            ) {
              return this.createError({
                message: t('message.E000007', {
                  0: t('profit_margin_setting.product'),
                }),
              });
            } else if (
              (product || product_select) &&
              !processing_method &&
              !processing_method_select &&
              product_category !== CATEGORIES_ID.TOOL_PRODUCTS
            ) {
              return this.createError({
                message: t('message.E000007', {
                  0: t('profit_margin_setting.processing_method'),
                }),
              });
            }
            return true;
          },
        });
      },
    ),
  };

  const validateShiireKingaku = (tani_type, shiire_kingaku, createError) => {
    if (tani_type === ApiTaniType.PURCHASE_AMOUNT) {
      if (!shiire_kingaku) {
        return createError({
          message: t('message.E000003', {
            0: t('profit_margin_setting.purchase_amount'),
          }),
        });
      } else {
        if (!REGEX.REGEX_1_TO_9999999.test(shiire_kingaku)) {
          return createError({
            message: t('message.E000006', {
              0: t('profit_margin_setting.profit_margin_error'),
              1: '1~9999999',
            }),
          });
        }
      }
    }
    return true;
  };

  const apiSettingSchema = {
    tani_type_select: Yup.string().required(t('message.E000090')),
    shiire_kingaku_from_1: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_from_1, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_from_1,
              createError,
            );
          },
        });
      }),
    shiire_kingaku_to_1: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_to_1, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_to_1,
              createError,
            );
          },
        });
      }),
    shiire_kingaku_from_2: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_from_2, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_from_2,
              createError,
            );
          },
        });
      }),
    shiire_kingaku_to_2: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_to_2, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_to_2,
              createError,
            );
          },
        });
      }),
    shiire_kingaku_from_3: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_from_3, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_from_3,
              createError,
            );
          },
        });
      }),
    shiire_kingaku_to_3: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_to_3, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_to_3,
              createError,
            );
          },
        });
      }),
    shiire_kingaku_from_4: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('tani_type_select', (tani_type, schema) => {
        return schema.test({
          test: function (shiire_kingaku_from_4, { createError }) {
            return validateShiireKingaku(
              tani_type,
              shiire_kingaku_from_4,
              createError,
            );
          },
        });
      }),
  };
  const schema = Yup.object().shape({
    standard_setting: Yup.array().of(Yup.object().shape(standardSettingSchema)),
    type_shape_setting: Yup.array().of(
      Yup.object().shape(typeShapeSettingSchema),
    ),
    cutting_method_setting: Yup.array().of(
      Yup.object().shape(cuttingMethodSettingSchema),
    ),
    api_setting: Yup.array().of(Yup.object().shape(apiSettingSchema)),
    // minimum_gross_profit: Yup.string()
    //   .nullable(true)
    //   .transform((curr, orig) => (orig === '' ? null : curr))
    //   .matches(
    //     REGEX.REGEX_1_TO_9999999,
    //     t('message.E000062', {
    //       0: t('profit_margin_setting.minimum_gross_profit'),
    //     }),
    //   ),
  });

  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const standardFieldArray = useFieldArray({
    name: 'standard_setting',
    control: formInfo.control,
  });
  const typeShapeFieldArray = useFieldArray({
    name: 'type_shape_setting',
    control: formInfo.control,
  });
  const cuttingMethodFieldArray = useFieldArray({
    name: 'cutting_method_setting',
    control: formInfo.control,
  });
  const apiFieldArray = useFieldArray({
    name: 'api_setting',
    control: formInfo.control,
  });

  const { queryInitDataProfitMargin } = useProfitMarginSetting({});
  const { append, remove } = typeShapeFieldArray;
  const { append: appendMethod, remove: removeMethod } =
    cuttingMethodFieldArray;
  const { append: appendApi, remove: removeApi } = apiFieldArray;
  const dataProfitMargin = queryInitDataProfitMargin?.data?.data?.item;

  useEffect(() => {
    dataProfitMargin?.typeShapeRes &&
      dataProfitMargin?.typeShapeRes?.map((item) => {
        append(
          {
            seqno: item.seqno,
            tani_type: item.taniType,
            riekrate1: item.riekrate1,
            riekrate2: item.riekrate2,
            riekrate3: item.riekrate3,
            riekrate4: item.riekrate4,
            type: item.hinshL3M ? item.hinshL3M : '',
            shape: item.keijoL3M ? item.keijoL3M : '',
            checkbox: false,
            type_select: item.hinshL3Cd,
            shape_select: item.keijoL3Cd,
          },
          { shouldFocus: false },
        );
      });
    return () => {
      remove();
    };
  }, [dataProfitMargin?.typeShapeRes]);

  useEffect(() => {
    dataProfitMargin?.nameMachiningRes &&
      dataProfitMargin?.nameMachiningRes?.map((item) => {
        appendMethod(
          {
            seqno: item.seqno,
            tani_type: item.taniType,
            riekrate1: item.riekrate1,
            riekrate2: item.riekrate2,
            riekrate3: item.riekrate3,
            riekrate4: item.riekrate4,
            product:
              getProductName({ item: item, isHakudoShhnm: false }) ||
              getProductName({ item: item, isHakudoShhnm: true }) ||
              '',
            processing_method: item.setdnKakom,
            checkbox: false,
            product_select: item.shhnCd
              ? `s${item.shhnCd}`
              : item.hakudoShhnCd
              ? `h${item.hakudoShhnCd}`
              : undefined, // 's' means shhnCd -'h' means hakudoShhnCd
            processing_method_select: item.setdnKakoCd,
            hakudo_product_flg: item.shhnCd ? false : true,
            product_category: item?.hinshL3CdShhn || item?.hinshL3CdHakudoShhn,
          },
          { shouldFocus: false },
        );
      });

    return () => {
      removeMethod();
    };
  }, [dataProfitMargin?.nameMachiningRes, LocalStorage.lang]);

  useEffect(() => {
    dataProfitMargin?.rmApiRiekiSetteiReses &&
      dataProfitMargin?.rmApiRiekiSetteiReses?.map((item) => {
        appendApi(
          {
            seqno: item?.seqno,
            api_select: item?.apiSeqno,
            tani_type_select: item?.apiTaniType,
            shiire_kingaku_from_1: item?.shiireKingakuFrom1,
            shiire_kingaku_to_1: item?.shiireKingakuTo1,
            shiire_kingaku_from_2: item?.shiireKingakuFrom2,
            shiire_kingaku_to_2: item?.shiireKingakuTo2,
            shiire_kingaku_from_3: item?.shiireKingakuFrom3,
            shiire_kingaku_to_3: item?.shiireKingakuTo3,
            shiire_kingaku_from_4: item?.shiireKingakuFrom4,
          },
          { shouldFocus: false },
        );
      });

    return () => {
      removeApi();
    };
  }, [dataProfitMargin?.rmApiRiekiSetteiReses]);

  return {
    formInfo,
    standardFieldArray,
    typeShapeFieldArray,
    cuttingMethodFieldArray,
    apiFieldArray,
  };
};

export default useForm;
