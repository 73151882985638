import React from 'react';
import { Row, Col, Image, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { openPDF } from 'src/utils/helper';

type Props = {
  product: Response.ProductEntity;
};

const ProductTooltip: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  return (
    <Row>
      {product.shhnImageDir && (
        <Col>
          {product.shhnPdfDir ? (
            <a href={product.shhnPdfDir} target="_blank" rel="noreferrer">
              <Image preview={false} width={90} src={product.shhnImageDir} />
            </a>
          ) : (
            <Image preview={false} width={90} src={product.shhnImageDir} />
          )}
        </Col>
      )}
      <Col>
        {product.catchCopy && (
          <Row className="tooltip_description">
            <Typography.Text className="product_tooltip_des">
              {product.catchCopy}
            </Typography.Text>
          </Row>
        )}
        {product.shhnPdfDir && (
          <Row>
            <Button
              type="link"
              className="category_link_product"
              onClick={() => product.shhnPdfDir && openPDF(product.shhnPdfDir)}
            >
              {t('product.open_product_info')}
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ProductTooltip;
