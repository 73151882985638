import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import RegistrationForm from './RegistrationForm';
import UploadTable from './UploadTable';

const PDFRegistration: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="pdf_registration_container">
      <div className="description_text">
        <Typography.Text>
          {t('pdf_registration.description_text')}
        </Typography.Text>
      </div>
      <RegistrationForm />
      <UploadTable />
    </div>
  );
};

export default PDFRegistration;
