import { Button, Col, Divider, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import useQueryUrl from 'src/hooks/useQueryUrl';
import useQuotation from 'src/queries/useQuotation';
import LocalStorage from 'src/utils/LocalStorage';
import Note from './Note';
import Search from './Search';
import useTable from './useTable';
import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import quotation from 'src/repositories/quotation';
import { ADMIN_ROLES, LABEL } from 'src/constants/app';
import { checkExpired, checkQuotationLabel } from 'src/utils/helper';

const QuotationContainer: React.FC = () => {
  const {
    tableProps,
    dataSource,
    rowSelected,
    setPageSize,
    setRowKeysSelected,
    getValues,
  } = useTable();
  const { t } = useTranslation();
  const query = useQueryUrl();
  const client = useQueryClient();
  const roles = LocalStorage.userRoles;

  const apiPathCurrentCurrencyUnit = '/currency/current-unit';
  const apiPathCurrencyTransfers = '/currency/transfers';

  const [error, setError] = useState<string>('');
  const [disablePdfButton, setDisablePdfButton] = useState<boolean>(false);
  const [disableRegisStatusButton, setDisableRegisStatusButton] =
    useState<boolean>(false);
  const [prevRowSelected, setPrevRowSelected] = useState<number[]>([]);
  const [itemSelected, setItemSelected] = useState<Response.QuotationEntity[]>(
    [],
  );

  const { exportCSV, exportPDF, deleteQuotation, updateOrderStatus } =
    useQuotation({
      setRowKeysSelected,
      setError,
    });

  const handleExportCSV = async () => {
    setError('');
    if (rowSelected.length > 0) {
      await exportCSV.mutate({
        seqnos: rowSelected,
        direction: query.quotation_number_express || 'desc',
        gengoCd: LocalStorage.lang,
      });
    } else {
      setError(
        t('message.E000007', {
          0: t('quotation_page.detail'),
        }),
      );
    }
  };

  const checkCurrencyTransfer = async (record) => {
    // check if quote currency is different from system currency and currency conversion pair is set on screen R1509
    const currentCurrencyRes = await client.fetchQuery<
      AxiosResponse<Response.CurrentCurrencyUnit>
    >({
      queryKey: [apiPathCurrentCurrencyUnit],
      queryFn: quotation.getCurrentCurrencyUnit,
    });

    const currencyTransfersRes = await client.fetchQuery<
      AxiosResponse<Response.CurrencyTransfers>
    >({
      queryKey: [apiPathCurrencyTransfers],
      queryFn: quotation.getCurrencyTransfers,
    });

    const currentCurrency = currentCurrencyRes?.data?.item;
    const currencyTransfers = currencyTransfersRes?.data?.items;
    const currency = record.map((item) => item.sogakuTani);

    if (currentCurrency && currencyTransfers) {
      const currentCurrencyTransfers = currencyTransfers
        ?.filter(
          (item) => item?.kawaseKbCdTo === currentCurrency?.currentKawase,
        ) // filter items have kawaseKbCdTo = currentKawase
        ?.map((currency) => currency?.kawaseKbCdFromSymbol) // get kawaseKbCdFromSymbol of item => array
        ?.concat(currentCurrency?.currentKawaseSymbol); // add currentKawaseSymbol to array
      return _.every(currency, (item) =>
        currentCurrencyTransfers?.includes(item),
      );
    }
  };

  const handleExportPDF = async () => {
    setError('');
    if (rowSelected.length > 0) {
      if (await checkCurrencyTransfer(itemSelected)) {
        await exportPDF.mutate({
          seqnos: rowSelected,
          gengoCd: LocalStorage.lang,
          kokyakuCd: itemSelected[0]?.kokyakuCd,
        });
      } else {
        setError(t('message.E000024'));
      }
    } else {
      setError(
        t('message.E000021', {
          0: t('quotation_page.detail'),
        }),
      );
    }
  };

  const handleDelete = async () => {
    setError('');
    if (rowSelected.length > 0) {
      const checkAllItemsAreOrder = itemSelected?.every(
        (item) => checkQuotationLabel(item) === LABEL.ORDER,
      );
      if (checkAllItemsAreOrder) {
        setError(
          t('message.E000089', {
            0: t('quotation_page.order_item'),
          }),
        );
      } else {
        await deleteQuotation.mutate({
          seqnos: rowSelected,
        });
      }
    } else {
      setError(
        t('message.E000021', {
          0: t('quotation_page.detail'),
        }),
      );
    }
  };

  const handleUpdateStatus = async () => {
    setError('');
    if (rowSelected.length > 0) {
      const selectedOrder = getValues().quotation?.filter((item) =>
        rowSelected?.includes(item?.seqno),
      );

      const checkEmptyStatus = selectedOrder?.some((item) => !item?.status);

      if (checkEmptyStatus) {
        setError(
          t('message.E000007', {
            0: t('quotation_page.order_item'),
          }),
        );
      } else {
        await updateOrderStatus.mutate({
          orderStatusReqs: selectedOrder?.map((item) => ({
            seqnos: item?.seqno,
            sttCd: item?.status,
          })),
        });
      }
    } else {
      setError(
        t('message.E000021', {
          0: t('quotation_page.order_item'),
        }),
      );
    }
  };

  useEffect(() => {
    if (query.offset) {
      setPageSize(query.offset);
    }
  }, [query.offset]);

  useEffect(() => {
    // save selected items when click checkbox
    setPrevRowSelected(rowSelected);
    if (dataSource && dataSource?.length > 0) {
      if (rowSelected.length > 0) {
        let listItem: Response.QuotationEntity[] = [];
        // if check more item (rowSelected > prevRowSelected) => add new item selected
        if (rowSelected.length > prevRowSelected.length) {
          listItem = dataSource?.filter((item) =>
            rowSelected.includes(item.seqno),
          );
          const newItemSelected = listItem.filter(
            (item) => !prevRowSelected.includes(item.seqno),
          );
          setItemSelected([...itemSelected, ...newItemSelected]);
        } else {
          // else (uncheck item) => filter out the items being checked
          listItem = itemSelected.filter((item) =>
            rowSelected.includes(item.seqno),
          );
          setItemSelected(listItem);
        }
      } else {
        setItemSelected([]);
      }
    }
  }, [rowSelected?.length, dataSource?.length]);

  useEffect(() => {
    //check if records have same kokyakuCd
    const checKokyakuCd = itemSelected?.every(
      (obj) => obj?.kokyakuCd === itemSelected[0]?.kokyakuCd,
    );

    //check if records have expired date
    const hasExpiredRecord = itemSelected?.some((obj) =>
      checkExpired(obj?.mitrYukoKigenb),
    );

    const isNotOrderLabel = itemSelected?.some(
      (obj) => checkQuotationLabel(obj) !== LABEL.ORDER,
    );

    if (dataSource) {
      setDisablePdfButton(!checKokyakuCd || hasExpiredRecord);
      setDisableRegisStatusButton(isNotOrderLabel);
    }
  }, [itemSelected.length, rowSelected.length]);

  return (
    <div className="mb-20 mt-20 quotation">
      <Note />
      <Search />
      <Row className="quotation_action">
        <Button
          type="primary"
          className="quotation_action_button"
          onClick={handleExportCSV}
        >
          {t('quotation_page.csv_output')}
        </Button>
        <Button
          type="primary"
          className="quotation_action_button"
          icon={<FilePdfOutlined />}
          onClick={handleExportPDF}
          disabled={disablePdfButton}
        >
          {t('quotation_page.pdf_output')}
        </Button>
        <Button
          type="primary"
          className="quotation_action_button"
          icon={<DeleteOutlined />}
          onClick={handleDelete}
          disabled={roles[0] === ADMIN_ROLES.viewAdmin}
        >
          {t('button.delete')}
        </Button>
        <Button
          type="primary"
          className="quotation_action_button"
          onClick={handleUpdateStatus}
          disabled={disableRegisStatusButton}
        >
          {t('quotation_page.status_registration')}
        </Button>
      </Row>
      {error && (
        <Col span={24} className="quotation_error">
          <Typography.Text
            className="ant-form-item-explain ant-form-item-explain-error"
            type="danger"
          >
            {error}
          </Typography.Text>
        </Col>
      )}
      <Divider className="search_divider" />
      {dataSource && dataSource.length > 0 ? (
        <React.Fragment>
          <Typography.Title level={2} className="quotation_title">
            {t('quotation_page.table_title')}
          </Typography.Title>
          <Table {...tableProps} />
        </React.Fragment>
      ) : (
        <Typography.Title level={5} className="quotation_text">
          {t('message.E000024')}
        </Typography.Title>
      )}
    </div>
  );
};

export default QuotationContainer;
