import React, { useState } from 'react';
import { Button, Col, Form, FormItemProps, Row, Typography } from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import { UploadProps } from 'antd/es/upload';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadFileCSV } from 'src/utils/uploadImage';

type Props<T> = {
  uploadFileProps?: UploadProps;
  controller: UseControllerProps<T>;
  errorCol?: 12 | 24;
  maxCount?: number;
  type?:
    | 'primary'
    | 'link'
    | 'text'
    | 'dashed'
    | 'default'
    | 'ghost'
    | undefined;
  nameButton?: string;
  className?: string;
  typeUploadFile?: string;
  setLoadingParent?: React.Dispatch<React.SetStateAction<boolean>>;
  typeUploadCsv: string;
} & Omit<FormItemProps, 'children'>;

function FileUploadCsvField<T extends Record<string, any>>(props: Props<T>) {
  const {
    uploadFileProps,
    controller,
    errorCol = 24,
    maxCount,
    label,
    type = 'primary',
    className = 'w-full upload_file',
    nameButton,
    setLoadingParent,
    typeUploadCsv,
    ...rest
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;
  const [loading, setLoading] = useState(false);

  const uploadProps: UploadProps = {
    beforeUpload(file) {
      const isCSV = file.type === 'text/csv';
      const isLimitFile = file.size / 1024 / 1024 < 50;
      field.onChange([
        file,
        {
          displayName: file.name,
        },
      ]);
      field.onChange([
        file,
        {
          displayName: file.name,
        },
      ]);
      if (isCSV && isLimitFile) {
        return true;
      } else {
        return false;
      }
    },
    async action(file) {
      try {
        setLoading(true);
        if (setLoadingParent) {
          setLoadingParent(true);
        }
        const response = await uploadFileCSV(file, typeUploadCsv);
        if (response?.status == 201) {
          setLoading(false);
          if (setLoadingParent) {
            setLoadingParent(false);
          }
        }
        field.onChange([
          file,
          {
            displayName: file.name,
            id: response?.data?.item?.fileDir,
            fileNm: response?.data?.item?.fileNm,
            totalRecord: response?.data?.item?.totalRecord,
            errorMsg: response?.data?.item?.errorMsg,
          },
        ]);
      } catch (err) {
        field.onChange([
          file,
          field,
          {
            displayName: file.name,
          },
        ]);
      }
      return '';
    },
    maxCount: maxCount,
  };

  return (
    <Form.Item
      colon={false}
      label={label}
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={error ? 'error' : ''}
      {...rest}
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={16}>
            <Col span={24} className="file_name">
              <Upload
                {...uploadProps}
                maxCount={maxCount ?? 1}
                {...uploadFileProps}
                name={field.name}
                ref={field.ref}
              >
                <Button
                  loading={loading}
                  type={type}
                  disabled={uploadFileProps?.disabled}
                  className={className}
                  icon={<UploadOutlined />}
                >
                  {nameButton}
                </Button>
              </Upload>
            </Col>
          </Row>
        </Col>
        <Col span={errorCol} className="col-error">
          {error && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
              style={{ lineHeight: errorCol === 24 ? '16px' : '42px' }}
            >
              {error.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
}

export default FileUploadCsvField;
