export const DISPLAY_ORDER_PDF_REGISTRATION: App.OptionsFnc = (t) => [
  { label: t('pdf_registration.new_order'), value: '01' },
  {
    label: t('pdf_registration.classification_order'),
    value: '02',
  },
  { label: t('pdf_registration.language_order'), value: '03' },
];

export const CATEGORY_PDF_REGISTRATION: App.OptionsFnc = (t) => [
  { label: t('pdf_registration.terms_of_service'), value: '01' },
  {
    label: t('pdf_registration.for_the_first_time_users'),
    value: '02',
  },
  { label: t('pdf_registration.electronic_catalog'), value: '03' },
];
