import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getInitDataProfitMargin = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getProductProperties = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;
  return axios({
    method: 'get',
    url,
    params,
  });
};

const getUnitPrices = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const saveProfitMargin = (url, data: Payload.SaveProfitMargin) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const deleteProductProfitMargin = (
  url: string,
  data: Payload.DeleteProductProfitMargin,
) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

const checkExistCurrency = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getApiList = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getCurrentCurrencyUnit = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

export default {
  getInitDataProfitMargin,
  getProductProperties,
  getUnitPrices,
  saveProfitMargin,
  deleteProductProfitMargin,
  checkExistCurrency,
  getApiList,
  getCurrentCurrencyUnit,
};
