import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Table, Typography } from 'antd';

import CustomerSearchForm from 'src/components/common/CustomerSearchForm';
import useForm, {
  FormValue,
} from 'src/containers/CustomerSetting/SearchComponent/useForm';
import useTable from 'src/components/common/CustomerSearchForm/useTable';
import modalConfirm from 'src/utils/modalConfirm';

type PriceCopyModalProps = {
  onClose: () => void;
  kokyakuCd: string;
};

const PriceCopyModal: React.FC<PriceCopyModalProps> = (
  props: PriceCopyModalProps,
) => {
  const { kokyakuCd } = props;
  const [items, setItems] = useState<Response.CustomerSettingItem[]>([]);
  const { control, handleSubmit } = useForm();
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState<FormValue>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    tableProps,
    rowSelected,
    setRowKeysSelected,
    setPage,
    searchCustomer,
    copyProfitMargin,
  } = useTable({
    form: formValue!,
    clearErrorMessage: () => setErrorMessage(''),
  });

  useEffect(() => {
    const customers = searchCustomer?.data?.data?.items;
    const newItems = [...items];
    customers?.forEach((customer) => {
      const item = items.find((e) => e.seqno === customer.seqno);
      if (!item) {
        newItems.push(customer);
      }
    });
    setItems(newItems);
  }, [searchCustomer]);
  const copy = () => {
    if (!rowSelected.length) {
      setErrorMessage(
        t('message.E000007', {
          0: t('customer_modal.customer'),
        }),
      );
      return;
    }

    modalConfirm({
      t,
      title: t('price_copy_modal.confirmation'),
      okText: t('price_copy_modal.button.ok'),
      cancelText: t('price_copy_modal.button.cancel'),
      onOk: () => {
        copyProfitMargin.mutate({
          from: kokyakuCd,
          to: items
            .filter((e) => rowSelected.includes(e.seqno))
            .map((e) => e.kokyakuCd),
        });
      },
    });
  };

  const search = (form: FormValue) => {
    setPage(1);
    setFormValue(form);
  };

  return (
    <div className="price-copy-modal">
      <Typography.Text className="description mb-20">
        {t('price_copy_modal.description')}
      </Typography.Text>
      <CustomerSearchForm
        control={control}
        onSubmit={handleSubmit((val: any) => {
          setFormValue(val);
          search(val);
          // Reset error message
          setErrorMessage('');
          // Reset selected element on table
          setRowKeysSelected([]);
        })}
      />
      <Table {...tableProps} />
      {errorMessage && (
        <Row>
          <Typography.Text
            className="ant-form-item-explain ant-form-item-explain-error"
            type="danger"
          >
            {errorMessage}
          </Typography.Text>
        </Row>
      )}
      <Row justify="center">
        <Button
          onClick={copy}
          loading={copyProfitMargin.isLoading}
          type="primary"
          className="btn-search"
        >
          {t('price_copy_modal.button.copy')}
        </Button>
      </Row>
    </div>
  );
};

export default PriceCopyModal;
