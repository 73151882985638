import React from 'react';

import CustomerSettingContainer from 'src/containers/CustomerSetting';
import { withRemountOnHistoryPush } from 'src/hocs/withRemountOnHistoryPush';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const CustomerSettingPage: React.FC = () => {
  useTitle(t('customer_setting.title'));
  return <CustomerSettingContainer />;
};

export const PATH = '/customer_setting' as const;
export default withRemountOnHistoryPush(CustomerSettingPage);
