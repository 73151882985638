import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Note: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="user_registration_note">
      <Typography.Title level={5}>
        {t('user_registration_change.title')}
      </Typography.Title>
      <Typography.Paragraph>
        {t('user_registration_change.note_1')}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t('user_registration_change.note_2')}
      </Typography.Paragraph>
    </div>
  );
};

export default Note;
