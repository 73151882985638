import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

import notification from 'src/repositories/notification';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { PER } from 'src/constants/app';
import { I18n } from 'src/libs/i18n';
import { getDisplayCondition } from 'src/utils/helper';
import { DisplayOrder } from 'src/enums/notification';

const apiPath = '/notifications';
const apiPostFlg = '/notifications/posted-flg';

type UseNotificationSetting = {
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
  setActionMessageError?: Dispatch<SetStateAction<string>>;
};

const useNotificationSetting = ({
  setRowKeysSelected,
  setActionMessageError,
}: UseNotificationSetting) => {
  const queryClient = useQueryClient();

  const query = useQueryUrl<SearchParams.NotificationSetting>();
  const params = {
    postedFrom: query.publish_date_from?.concat(' 00:00:00'),
    postedTo: query.publish_date_to?.concat(' 23:59:59'),
    title: query.title,
    hyoujiBasho: getDisplayCondition(query.display_condition),
    displayOrder: query.display_order || DisplayOrder.PUBLISH_DATE,
    page: query.page || 1,
    offset: query.offset || PER.DEFAULT,
  };

  const queryNotificationSetting = useQuery<
    AxiosResponse<Response.NotificationSetting>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath, params],
    queryFn: notification.getNotificationSetting,
  });

  const updatePostFlg = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.UpdatePostFlg
  >({
    mutationKey: [apiPostFlg],
    mutationFn: (args) => {
      return notification.updatePostFlg(apiPostFlg, args);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      const errorCode = _.get(error, 'response.data.code');
      setActionMessageError?.(I18n.t(`message.${errorCode}`) || 'Error');
    },
  });

  const deleteNotification = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteNotificationSetting
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => {
      return notification.deleteNotificationSetting(apiPath, args);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      const errorCode = _.get(error, 'response.data.code');
      setActionMessageError?.(I18n.t(`message.${errorCode}`) || 'Error');
    },
  });

  return {
    queryNotificationSetting,
    updatePostFlg,
    deleteNotification,
  };
};

export default useNotificationSetting;
