import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const useForm = () => {
  const schema = Yup.object().shape({});

  const form = useHookForm<any>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { ...form };
};

export default useForm;
