import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Note: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="note">
      <Typography.Paragraph className="mt-15 mb-15">
        {t('plugin_management.note_1')}
      </Typography.Paragraph>
    </div>
  );
};

export default Note;
