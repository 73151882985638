import { Button, Col, Divider, Row, Typography } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import {
  CheckBoxField,
  NegativeNumberField,
  SelectField,
} from 'src/components/form';
import { FormValue } from '../../useForm';
import useProfitMarginSetting from 'src/queries/useProfitMarginSetting';
import { formatCurrency, handleUnitPrices } from 'src/utils/helper';
import SelectCurrencyField from 'src/components/form/SelectCurrencyField';
import { UnitPrices } from 'src/enums/profitMargin';
import useSelectRow from 'src/hooks/useSelectRow';
import SpecifyUnitLabel from '../SpecifyUnitLabel';

type Props = {
  typeShapeFieldArray: UseFieldArrayReturn<
    FormValue,
    'type_shape_setting',
    'id'
  >;
  formInfo: UseFormReturn<FormValue, any>;
  setHasErrorTaniType: React.Dispatch<React.SetStateAction<boolean>>;
};

const TypeShapeSetting: React.FC<Props> = ({
  typeShapeFieldArray,
  formInfo,
  setHasErrorTaniType,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = formInfo;
  const { fields, append, remove } = typeShapeFieldArray;

  const [errorsSetting, setErrorsSetting] = useState<string[]>([]);
  const [typeKeyword, setTypeKeyword] = useState<string>();
  const [shapeKeyword, setShapeKeyword] = useState<string>();
  const [unitPrice, setUnitPrice] = useState<string>();
  const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState<number>();

  const {
    dataProductShapes,
    dataProductTypes,
    dataUnitPrices,
    deleteProductTypeAndShape,
    kokyakuCd,
    queryProductTypes,
    queryProductShapes,
    checkExistCurrency,
  } = useProfitMarginSetting({
    typeKeyword: typeKeyword,
    shapeKeyword: shapeKeyword,
    unitPrice: unitPrice,
  });
  const {
    handleSelectRow,
    selectedRecord,
    selectedSeqno,
    setSelectedRecord,
    setSelectedSeqno,
  } = useSelectRow();

  const handleAddRow = () => {
    append({
      tani_type: UnitPrices.PERCENT,
      riekrate1: null,
      riekrate2: null,
      riekrate3: null,
      riekrate4: null,
      seqno: undefined,
      type: '',
      shape: '',
      checkbox: false,
    });
  };

  const handleSelectUnitPrice = (value, index) => {
    setValue(`type_shape_setting.${index}.riekrate1`, null);
    setValue(`type_shape_setting.${index}.riekrate2`, null);
    setValue(`type_shape_setting.${index}.riekrate3`, null);
    setValue(`type_shape_setting.${index}.riekrate4`, null);
    if (value !== UnitPrices.PERCENT) {
      setSelectedCurrencyIndex(index);
      setUnitPrice(value);
    } else {
      clearErrors(`type_shape_setting.${index}.tani_type`);
    }
  };

  useEffect(() => {
    let newArr: string[] = [];
    errors?.type_shape_setting?.map((item) => {
      if (item) {
        Object.values(item).map((mess) => {
          mess?.message && newArr.push(mess.message);
        });
      }
    });
    const uniqueErrors = [...new Set(newArr)];
    setErrorsSetting(uniqueErrors);
  }, [errors?.type_shape_setting]);

  useEffect(() => {
    if (
      checkExistCurrency?.data?.data?.item !== undefined &&
      selectedCurrencyIndex !== undefined
    ) {
      if (checkExistCurrency?.data?.data?.item === false) {
        setError(`type_shape_setting.${selectedCurrencyIndex}.tani_type`, {
          type: 'custom',
          message: t('message.E000067'),
        });
      } else if (checkExistCurrency?.data?.data?.item === true) {
        clearErrors(`type_shape_setting.${selectedCurrencyIndex}.tani_type`);
      }
    }
  }, [checkExistCurrency?.data?.data?.item, selectedCurrencyIndex]);

  const handleRemoveRow = () => {
    selectedRecord?.map((item) => {
      setValue(`type_shape_setting.${item}.checkbox`, false);
      clearErrors(`type_shape_setting.${item}`);
    });
    remove(selectedRecord);
    if (kokyakuCd && selectedSeqno.length > 0) {
      const payloadDelete = {
        seqnos: selectedSeqno,
        kokyakuCd: kokyakuCd,
      };
      deleteProductTypeAndShape.mutate(payloadDelete);
      setSelectedSeqno([]);
    }
    setSelectedRecord([]);
  };

  const handleSearchTypeOrShape = (value, select: 'type' | 'shape') => {
    if (select === 'type') {
      setTypeKeyword(value);
    } else {
      setShapeKeyword(value);
    }
  };

  useEffect(() => {
    setHasErrorTaniType(errorsSetting.includes(t('message.E000067')));
  }, [errorsSetting]);

  const handleShowNameSymbol = (index) => {
    const taniType = watch(`type_shape_setting.${index}.tani_type`);
    return handleUnitPrices(taniType);
  };

  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Row
          className={classNames('setting_input', {
            new: !item.type && !item.shape,
          })}
          key={item.id}
        >
          <React.Fragment>
            <Col xs={2} md={1} xl={1}>
              <CheckBoxField<FormValue>
                controller={{
                  name: `type_shape_setting.${index}.checkbox`,
                  control: control,
                }}
                checkboxProps={{
                  onClick: (event) => handleSelectRow(event, index, item.seqno),
                }}
              />
            </Col>
            <Col xs={22} md={8} xl={9} className="setting_input_title">
              {!item.type && !item.shape ? (
                <div className="setting_input_new">
                  <Row justify="space-between">
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: `type_shape_setting.${index}.type_select`,
                      }}
                      selectProps={{
                        options: dataProductTypes,
                        showSearch: true,
                        filterOption: (inputValue, option) =>
                          ((option && option.label) || '')
                            .toString()
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1,
                        onSearch: (value) =>
                          handleSearchTypeOrShape(value, 'type'),
                        onFocus: () => handleSearchTypeOrShape('', 'type'),
                        loading: queryProductTypes.isLoading,
                        placeholder: t('profit_margin_setting.type'),
                      }}
                      label={t('profit_margin_setting.type')}
                      labelCol={{ xs: 24, md: 10, xl: 7 }}
                      wrapperCol={{ xs: 24, md: 14, xl: 14 }}
                      className="auto_field"
                    />
                  </Row>
                  <Row justify="space-between">
                    <SelectField<FormValue>
                      controller={{
                        control,
                        name: `type_shape_setting.${index}.shape_select`,
                      }}
                      selectProps={{
                        options: dataProductShapes,
                        showSearch: true,
                        filterOption: (inputValue, option) =>
                          ((option && option.label) || '')
                            .toString()
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1,
                        onSearch: (value) =>
                          handleSearchTypeOrShape(value, 'shape'),
                        onFocus: () => handleSearchTypeOrShape('', 'shape'),
                        loading: queryProductShapes.isLoading,
                        placeholder: t('profit_margin_setting.shape'),
                      }}
                      label={t('profit_margin_setting.shape')}
                      labelCol={{ xs: 24, md: 10, xl: 7 }}
                      wrapperCol={{ xs: 24, md: 14, xl: 14 }}
                      className="auto_field"
                    />
                  </Row>
                </div>
              ) : (
                <Typography.Text className="label">
                  {`${item.type}・${item.shape}`}
                </Typography.Text>
              )}
            </Col>
          </React.Fragment>
          <Col xs={24} className="new_divider mobile">
            <Divider />
          </Col>
          <Col xs={24} md={3} xl={2} className="setting_input_select">
            <SelectCurrencyField<FormValue>
              controller={{
                control,
                name: `type_shape_setting.${index}.tani_type`,
                defaultValue: item.tani_type,
              }}
              selectProps={{
                options: formatCurrency(dataUnitPrices),
                onSelect: (value) => handleSelectUnitPrice(value, index),
              }}
              label={<SpecifyUnitLabel />}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              className="select_unit_price"
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `type_shape_setting.${index}.riekrate1`,
              }}
              label={t('profit_margin_setting.0_to_299kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`type_shape_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `type_shape_setting.${index}.riekrate2`,
              }}
              label={t('profit_margin_setting.300_to_749kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`type_shape_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `type_shape_setting.${index}.riekrate3`,
              }}
              label={t('profit_margin_setting.750_to_1499kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`type_shape_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `type_shape_setting.${index}.riekrate4`,
              }}
              label={t('profit_margin_setting.more_than_1500kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol(index)}
              className={classNames({
                'font-vi':
                  watch(`type_shape_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
        </Row>
      ))}
      {errorsSetting &&
        errorsSetting.map((error, index) => (
          <Row key={index}>
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {error}
            </Typography.Text>
          </Row>
        ))}
      <Row className="setting_action">
        <Button
          className="setting_action_add"
          type="primary"
          onClick={handleAddRow}
          icon={<PlusSquareOutlined />}
        >
          {t('profit_margin_setting.add_line')}
        </Button>
        <Button
          className="setting_action_delete"
          type="primary"
          icon={<DeleteOutlined />}
          onClick={handleRemoveRow}
        >
          {t('profit_margin_setting.delete')}
        </Button>
      </Row>
    </React.Fragment>
  );
};

export default TypeShapeSetting;
