import axios from 'src/libs/axios';

const createCustomerDelivery = (url, data: Payload.createCustomerDelivery) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

export default {
  createCustomerDelivery,
};
