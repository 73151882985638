import { Button, Col, Row } from 'antd';
import React from 'react';

import Note from './Note';
import Search from './Search';
import Table from './Table';
import { PATH as PATH_CUSTOMER_SETTING } from 'src/pages/customerSetting';
import history from 'src/libs/history';
import { useTranslation } from 'react-i18next';

const UserRegistrationChange: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="mb-75 mt-20 user_registration">
      <Note />
      <Search />
      <Table />
      <Row justify="center" className="mt-12">
        <Col xs={12} md={3}>
          <Button
            type="primary"
            className="w-full button"
            onClick={() => history.push(PATH_CUSTOMER_SETTING)}
          >
            {t('user_registration_change.back')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserRegistrationChange;
