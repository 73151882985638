import React from 'react';
import {
  Col,
  Form,
  FormItemProps,
  Row,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { ShowUploadListInterface } from 'antd/lib/upload/interface';
import { useController, UseControllerProps } from 'react-hook-form';

import defaultUploadSrc from 'src/assets/defaultUpload.png';
import { uploadFile } from 'src/utils/uploadImage';
import { checkImageExt } from 'src/utils/helper';

type Props<T> = {
  controller: UseControllerProps<T>;
  errorCol?: 12 | 24;
  helper?: React.ReactNode;
  children?: React.ReactElement;
  showUploadList?: boolean | ShowUploadListInterface;
  uploadFileProps?: UploadProps;
} & Omit<FormItemProps, 'children'>;

function ImageUploadField<T extends Record<string, any>>(props: Props<T>) {
  const {
    controller,
    errorCol = 24,
    label,
    helper,
    children,
    showUploadList,
    uploadFileProps,
    ...rest
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;

  const uploadProps: UploadProps = {
    async beforeUpload(file) {
      const fileSizeError = file && file.size / 1024 > 25;

      try {
        const fileTypeValid = await checkImageExt(file);

        field.onChange([
          file,
          {
            displayName: file.name,
          },
        ]);

        if (fileTypeValid) {
          if (!fileSizeError) {
            return true;
          } else {
            return false;
          }
        } else {
          return Upload.LIST_IGNORE;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async action(file) {
      try {
        const response = await uploadFile(file);
        field.onChange([
          file,
          {
            displayName: file.name,
            url: response.data.fileUrl,
          },
        ]);
      } catch (err) {
        field.onChange([
          field,
          file,
          {
            displayName: file.name,
          },
        ]);
      }
      return '';
    },
    maxCount: 1,
    showUploadList: showUploadList,
  };

  return (
    <Form.Item
      className={'upload_image'}
      colon={false}
      label={label}
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={error ? 'error' : ''}
      {...rest}
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={8}>
            <Col span={24}>
              <Upload
                {...uploadProps}
                {...uploadFileProps}
                listType="picture-card"
                ref={field.ref}
                name={field.name}
              >
                {children || (
                  <img src={defaultUploadSrc} width={150} height={150} />
                )}
              </Upload>
            </Col>
          </Row>
        </Col>
        <Col span={errorCol}>
          {error && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
              style={{ lineHeight: errorCol === 24 ? '16px' : '42px' }}
            >
              {error.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
      <span className="text-secondary">{helper}</span>
    </Form.Item>
  );
}

export default ImageUploadField;
