import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { t } from 'i18next';

import taxExchangeSetting from 'src/repositories/taxExChangeSetting';
import { STATUS_CODE } from 'src/constants/app';

const apiPathSymbolsHanyomCd1 = '/tax-settings/symbols?hanyomCd=1';
const apiPathSymbolsHanyomCd2 = '/tax-settings/symbols?hanyomCd=2';
const apiPathTaxSetting = '/tax-settings';
const apiPathCurrencyTransfers = '/tax-settings/currency-transfers';
const apiPathCurrentCurrencyUnit = '/tax-settings/current-currency-unit';
const apiPathCheckExistCurrencyTransferBySymbol =
  '/tax-settings/currency-transfers/check';
const apiPathApiList = '/tax-settings/api-list';
const apiPathKeijoList = '/tax-settings/keijo-list';
const apiPathHishList = '/tax-settings/hinsh-list';

type Props = {
  onErrorDuplicateCurrencyTransfers?: () => void;
  handleSuccess?: (
    res: AxiosResponse<Response.CheckExistCurrencyTransferBySymbol, any>,
    args: Payload.CheckTaxSelectSymbol,
  ) => void;
  setError?;
};

const useTaxExChangeSetting = ({
  handleSuccess,
  onErrorDuplicateCurrencyTransfers,
  setError,
}: Props) => {
  const queryClient = useQueryClient();

  const getSymbolsHanyomCd1 = useQuery<
    AxiosResponse<Response.Symbols>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathSymbolsHanyomCd1],
    queryFn: taxExchangeSetting.getSymbols,
  });

  const getSymbolsHanyomCd2 = useQuery<
    AxiosResponse<Response.Symbols>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathSymbolsHanyomCd2],
    queryFn: taxExchangeSetting.getSymbols,
  });

  const getTaxSettings = useQuery<
    AxiosResponse<Response.TaxSettings>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathTaxSetting],
    queryFn: taxExchangeSetting.getTaxSettings,
  });

  const saveTaxSettings = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.TaxExChangeSetting
  >({
    mutationKey: [apiPathTaxSetting],
    mutationFn: (args) =>
      taxExchangeSetting.createTaxSettings(apiPathTaxSetting, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPathTaxSetting]);
    },
    onError(error) {
      const errorCode = _.get(error, 'response.data.code');
      switch (errorCode) {
        case STATUS_CODE.NOT_YET_CURRENCY_TRANSFERS:
          return setError('tax_exchange_error', {
            type: 'custom',
            message: t('message.E000067'),
          });
      }
    },
  });

  const getCurrencyTransfers = useQuery<
    AxiosResponse<Response.CurrencyTransfers>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathCurrencyTransfers],
    queryFn: taxExchangeSetting.getCurrencyTransfers,
  });

  const createCurrencyTransfers = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.CurrencyTransfers[]
  >({
    mutationKey: [apiPathCurrencyTransfers],
    mutationFn: (args) =>
      taxExchangeSetting.createCurrencyTransfers(
        apiPathCurrencyTransfers,
        args,
      ),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPathCurrencyTransfers]);
    },
    onError(error) {
      const errorCode = _.get(error, 'response.data.code');
      switch (errorCode) {
        case STATUS_CODE.ALREADY_USED:
          return (
            onErrorDuplicateCurrencyTransfers &&
            onErrorDuplicateCurrencyTransfers()
          );
      }
    },
  });

  const getCurrentCurrencyUnit = useQuery<
    AxiosResponse<Response.CurrentCurrencyUnit>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathCurrentCurrencyUnit],
    queryFn: taxExchangeSetting.getCurrentCurrencyUnit,
  });

  const createCurrentCurrencyUnit = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.CurrentCurrencyUnit
  >({
    mutationKey: [apiPathCurrentCurrencyUnit],
    mutationFn: (args) =>
      taxExchangeSetting.createCurrentCurrencyUnit(
        apiPathCurrentCurrencyUnit,
        args,
      ),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPathCurrentCurrencyUnit]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const updateCurrentCurrencyUnit = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.CurrentCurrencyUnit
  >({
    mutationKey: [apiPathCurrentCurrencyUnit],
    mutationFn: (args) =>
      taxExchangeSetting.updateCurrentCurrencyUnit(
        `${apiPathCurrentCurrencyUnit}/${args.seqno}`,
        args,
      ),
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPathCurrentCurrencyUnit]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const checkTaxSelectSymbol = useMutation<
    AxiosResponse<Response.CheckExistCurrencyTransferBySymbol>,
    AxiosError<Response.Error>,
    Payload.CheckTaxSelectSymbol
  >({
    mutationKey: [apiPathCheckExistCurrencyTransferBySymbol],
    mutationFn: (args) => {
      return taxExchangeSetting.checkExistCurrencyTransferBySymbol(
        apiPathCheckExistCurrencyTransferBySymbol,
        args,
      );
    },
    onSuccess: (res, args) => handleSuccess && handleSuccess(res, args),
    onError: (error) => {
      console.log(error.response);
    },
  });

  const getApiList = useQuery<
    AxiosResponse<Response.ApiList>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathApiList],
    queryFn: taxExchangeSetting.getApiList,
  });

  const getKeijoList = useQuery<
    AxiosResponse<Response.ProductShapes>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathKeijoList],
    queryFn: taxExchangeSetting.getKeijoList,
  });

  const getHinshList = useQuery<
    AxiosResponse<Response.ProductTypes>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathHishList],
    queryFn: taxExchangeSetting.getHinshList,
  });

  const dataApiList = getApiList?.data?.data?.items?.map((item) => {
    return {
      label: item.name,
      value: item.seqno,
    };
  });

  const dataKeijoList = getKeijoList?.data?.data?.items?.map((item) => {
    return {
      label: item.keijoL3M,
      value: item.keijoL3Cd,
    };
  });

  const dataHinshList = getHinshList?.data?.data?.items?.map((item) => {
    return {
      label: item.hinshL3M,
      value: item.hinshL3Cd,
    };
  });

  return {
    getSymbolsHanyomCd1,
    getSymbolsHanyomCd2,
    getTaxSettings,
    saveTaxSettings,
    getCurrencyTransfers,
    createCurrencyTransfers,
    getCurrentCurrencyUnit,
    createCurrentCurrencyUnit,
    updateCurrentCurrencyUnit,
    checkTaxSelectSymbol,
    dataApiList,
    dataKeijoList,
    dataHinshList,
  };
};

export default useTaxExChangeSetting;
