import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import Modal from 'src/components/custom/Modal';
import {
  CheckBoxField,
  CheckboxGroupField,
  DateRangePickerField,
  HalfWidthInputField,
  InputField,
  NumberOnlyRangeField,
  RadioGroupField,
  SelectField,
} from 'src/components/form';
import useForm, { FormValue } from './useForm';
import { DestinationModal, InternalPICModal } from 'src/components/common';
import {
  EXPRESS,
  MAXIMUM_SEARCH_COUNT,
  PAGE_SIZE,
  PRODUCT_CLASSIFICATION,
  QUOTE_ORDER_FLAG,
} from 'src/constants/optionSelect/quotation';
import ProductModal from '../ProductModal';
import history from 'src/libs/history';
import useQueryUrl from 'src/hooks/useQueryUrl';
import useQuotation from 'src/queries/useQuotation';
import CustomerModal from '../CustomerModal';
import { SCREENS } from 'src/constants/screens';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'src/constants/app';

const Search: React.FC = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue, watch, getValues, reset } =
    useForm();
  const query = useQueryUrl();
  const { queryLoyalCustomer, dataOrderStatus } = useQuotation({
    setRowKeysSelected: undefined,
  });
  const [disabledDeliveryDate, setDisabledDeliveryDate] =
    useState<boolean>(false);
  const [disabledOrderDate, setDisabledOrderDate] = useState<boolean>(false);

  const onSelectDestination = (destination: Response.DestinationItem) => {
    setValue('delivery_address', destination?.rmOtokesaki?.otokesakiNm);
  };

  const onSelectProduct = (product) => {
    setValue('product_name', product);
  };

  const onSelectPIC = (value) => {
    setValue('internal_person', value?.name);
  };

  const loyalCustomer = queryLoyalCustomer?.data?.data?.item?.tokuteiKokyakuKbn;
  const currentCurrency = queryLoyalCustomer?.data?.data?.item?.currentCurrency;

  const handleClearParam = () => {
    reset({
      quote_order_flag: '',
      customer_distinction: false,
      customer_name: '',
      delivery_address: '',
      delivery_date_from: '',
      delivery_date_to: '',
      quotation_number_express: 'desc',
      product_name: '',
      order_date_from: '',
      order_date_to: '',
      selling_price_from: '',
      selling_price_to: '',
      order_number_from: '',
      order_number_to: '',
      finished_dimensions: '',
      customer: '',
      weight_from: '',
      weight_to: '',
      maximum_search_count: '100',
      quotation_number_from: '',
      quotation_number_to: '',
      internal_person: '',
      product_classification: '',
      offset: '30',
      delivery_today: false,
      order_today: false,
    });

    history.push({
      pathname: '/',
    });
  };

  const handleSearch = (values) => {
    history.push({
      search: qs.stringify(
        {
          ...query,
          ...values,
          delivery_date_from: getValues().delivery_date_from,
          delivery_date_to: getValues().delivery_date_to,
          order_date_from: getValues().order_date_from,
          order_date_to: getValues().order_date_to,
          search: true,
          page: 1,
        },
        {
          skipNull: true,
          skipEmptyString: true,
          arrayFormatSeparator: '|',
          arrayFormat: 'bracket-separator',
        },
      ),
    });
  };

  const handleSelectCustomer = (customer: Response.CustomerSettingItem) =>
    setValue('customer_name', customer.kokyakuMei);
  useEffect(() => {
    if (watch('delivery_today') === true) {
      setValue('delivery_date_from', dayjs().format(DATE_FORMAT));
      setValue('delivery_date_to', dayjs().format(DATE_FORMAT));
      setDisabledDeliveryDate(true);
    } else {
      setDisabledDeliveryDate(false);
    }
  }, [watch('delivery_today')]);

  useEffect(() => {
    if (watch('order_today') === true) {
      setValue('order_date_from', dayjs().format(DATE_FORMAT));
      setValue('order_date_to', dayjs().format(DATE_FORMAT));
      setDisabledOrderDate(true);
    } else {
      setDisabledOrderDate(false);
    }
  }, [watch('order_today')]);

  useEffect(() => {
    if (query.offset) {
      setValue('offset', query.offset);
    }
    if (query.status) {
      setValue('status', Number(query.status));
    }
  }, [query]);

  const breakPoint = useBreakpoint();

  return (
    <div className="search">
      <Row justify="space-between" className="search_group">
        <Col xs={24} md={12} xl={12}>
          <Row className="search_row">
            <Col xs={12} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.quote_order_flag')}
                  </Typography.Title>
                </Col>
                <Col
                  xs={24}
                  md={17}
                  xl={16}
                  className="search_column search_flag"
                >
                  <CheckboxGroupField<FormValue>
                    className="mb-0"
                    controller={{
                      name: 'quote_order_flag',
                      control,
                    }}
                    checkboxGroupProps={{
                      options: QUOTE_ORDER_FLAG(t),
                    }}
                  />
                  <Typography.Title
                    level={5}
                    className="search_title_customer_desktop"
                  >
                    {t('quotation_page.specific_customer_flag')}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Col xs={11} md={5} xl={4} className="specific_customer_flag">
              <Typography.Title
                level={5}
                className="search_title_customer_mobile"
              >
                {t('quotation_page.specific_customer_flag')}
              </Typography.Title>
              {loyalCustomer && (
                <CheckBoxField<FormValue>
                  className="mb-0"
                  label={loyalCustomer}
                  controller={{
                    name: 'customer_distinction',
                    control,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('master_registration.status')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <SelectField<FormValue>
                    controller={{
                      control,
                      name: 'status',
                    }}
                    selectProps={{
                      allowClear: true,
                      options: dataOrderStatus,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.customer_name')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16} className="search_column">
                  <InputField<FormValue>
                    controller={{
                      control,
                      name: 'customer_name',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.customer_name'),
                      maxLength: 90,
                    }}
                    wrapperCol={{ span: 22 }}
                    className="search_input"
                  />
                  <Modal
                    modalProps={{
                      className: 'search_customer_modal',
                      width:
                        breakPoint.lg || breakPoint.xl || breakPoint.xxl
                          ? 840
                          : '100%',
                    }}
                    title={t('customer_modal.title')}
                    content={({ onClose }) => (
                      <CustomerModal
                        onSelectCustomer={handleSelectCustomer}
                        onClose={onClose}
                        screenId={SCREENS.R2507}
                      />
                    )}
                  >
                    <Button type="primary" className="destination_button">
                      {t('quotation_page.selection')}
                    </Button>
                  </Modal>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={5} xl={4} className="search_label">
              <Typography.Text>
                {t('quotation_page.partially_consistent')}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.delivery_address')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16} className="search_column">
                  <InputField<FormValue>
                    controller={{
                      control,
                      name: 'delivery_address',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.delivery_address'),
                      maxLength: 90,
                    }}
                    wrapperCol={{ span: 22 }}
                    className="search_input"
                  />
                  <Modal
                    modalProps={{ width: 840 }}
                    title={t('destination_modal.title')}
                    content={({ onClose }) => (
                      <DestinationModal
                        onClose={onClose}
                        onSelect={onSelectDestination}
                      />
                    )}
                  >
                    <Button type="primary" className="destination_button">
                      {t('quotation_page.selection')}
                    </Button>
                  </Modal>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={5} xl={4} className="search_label">
              <Typography.Text>
                {t('quotation_page.partially_consistent')}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={14} md={6} xl={7}>
                  <Typography.Title level={5} className="form_label">
                    {t('quotation_page.delivery_date')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <DateRangePickerField<FormValue>
                    controllerFrom={{
                      name: 'delivery_date_from',
                      control: control,
                    }}
                    controllerTo={{
                      name: 'delivery_date_to',
                      control: control,
                    }}
                    datePickerPropsFrom={{
                      placeholder: t('quotation_page.start'),
                      disabled: disabledDeliveryDate,
                    }}
                    datePickerPropsTo={{
                      placeholder: t('quotation_page.end'),
                      disabled: disabledDeliveryDate,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={5} xl={4} className="search_checkbox_customer">
              <CheckBoxField<FormValue>
                label={t('quotation_page.for_the_day')}
                controller={{
                  name: 'delivery_today',
                  control,
                }}
              />
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.product_name')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16} className="search_column">
                  <InputField<FormValue>
                    controller={{
                      control,
                      name: 'product_name',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.product_name'),
                      maxLength: 80,
                    }}
                    wrapperCol={{ span: 22 }}
                    className="search_input"
                  />
                  <Modal
                    modalProps={{
                      width: '100%',
                      style: { padding: 50, margin: 0 },
                      bodyStyle: { height: 'calc( 100vh - 100px )' },
                      className: 'product_modal',
                    }}
                    title={t('product.product_search')}
                    content={({ onClose }) => (
                      <ProductModal
                        onClose={onClose}
                        onSelectProduct={onSelectProduct}
                      />
                    )}
                  >
                    <Button type="primary" className="destination_button">
                      {t('quotation_page.selection')}
                    </Button>
                  </Modal>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={5} xl={4} className="search_label">
              <Typography.Text>
                {t('quotation_page.partially_consistent')}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.internal_person')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16} className="search_column">
                  <InputField<FormValue>
                    controller={{
                      control,
                      name: 'internal_person',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.internal_person'),
                      maxLength: 30,
                    }}
                    wrapperCol={{ span: 22 }}
                    className="search_input"
                  />
                  <Modal
                    modalProps={{ width: 840 }}
                    title={t('internal_pic.title')}
                    content={({ onClose }) => (
                      <InternalPICModal
                        onClose={onClose}
                        screenId="R1502"
                        onSelected={onSelectPIC}
                      />
                    )}
                  >
                    <Button type="primary" className="destination_button">
                      {t('quotation_page.selection')}
                    </Button>
                  </Modal>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={5} xl={4} className="search_label">
              <Typography.Text>
                {t('quotation_page.partially_consistent')}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.selling_price')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <NumberOnlyRangeField<FormValue>
                    controllerFrom={{
                      name: 'selling_price_from',
                      control: control,
                    }}
                    controllerTo={{
                      name: 'selling_price_to',
                      control: control,
                    }}
                    inputPropsFrom={{
                      placeholder: '00.00',
                    }}
                    inputPropsTo={{
                      placeholder: '00.00',
                    }}
                    suffix={currentCurrency && `(${currentCurrency})`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <CheckboxGroupField<FormValue>
            className="search_checkbox_product"
            label={t('quotation_page.product_classification')}
            controller={{
              name: 'product_classification',
              control,
            }}
            checkboxGroupProps={{
              options: PRODUCT_CLASSIFICATION(t),
            }}
            labelCol={{ xs: 24, md: 5, xl: 6 }}
            wrapperCol={{ xs: 24, md: 19, xl: 18 }}
          />
          <Row className="search_row mt-20">
            <Col xs={24} md={4} xl={6}>
              <Typography.Title level={5}>
                {t('quotation_page.express')}
              </Typography.Title>
            </Col>
            <Col xs={24} md={19} xl={18}>
              <RadioGroupField<FormValue>
                className="search_radio_quotation"
                label={t('quotation_page.quotation_number')}
                controller={{
                  name: 'quotation_number_express',
                  control,
                }}
                radioGroupProps={{
                  options: EXPRESS(t),
                }}
                labelCol={{ xs: 9, md: 9, xl: 7 }}
                wrapperCol={{ xs: 15, md: 15, xl: 17 }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} xl={12} className="search_right">
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={14} md={6} xl={7}>
                  <Typography.Title level={5} className="form_label">
                    {t('quotation_page.order_date')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <DateRangePickerField<FormValue>
                    controllerFrom={{
                      name: 'order_date_from',
                      control: control,
                    }}
                    controllerTo={{
                      name: 'order_date_to',
                      control: control,
                    }}
                    datePickerPropsFrom={{
                      placeholder: t('quotation_page.start'),
                      disabled: disabledOrderDate,
                    }}
                    datePickerPropsTo={{
                      placeholder: t('quotation_page.end'),
                      disabled: disabledOrderDate,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={5} xl={4} className="search_checkbox_customer">
              <CheckBoxField<FormValue>
                label={t('quotation_page.for_the_day')}
                controller={{
                  name: 'order_today',
                  control,
                }}
              />
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.quotation_number')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <NumberOnlyRangeField<FormValue>
                    controllerFrom={{
                      name: 'quotation_number_from',
                      control: control,
                    }}
                    controllerTo={{
                      name: 'quotation_number_to',
                      control: control,
                    }}
                    inputPropsFrom={{
                      maxLength: 8,
                    }}
                    inputPropsTo={{
                      maxLength: 8,
                    }}
                    prefix="W"
                    isOnlyInteger
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.order_number')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <NumberOnlyRangeField<FormValue>
                    controllerFrom={{
                      name: 'order_number_from',
                      control: control,
                    }}
                    controllerTo={{
                      name: 'order_number_to',
                      control: control,
                    }}
                    inputPropsFrom={{
                      maxLength: 7,
                    }}
                    inputPropsTo={{
                      maxLength: 7,
                    }}
                    isOnlyInteger
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={19} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.finished_dimensions')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16} className="search_column">
                  <HalfWidthInputField<FormValue>
                    controller={{
                      control,
                      name: 'finished_dimensions',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.finished_dimensions'),
                      maxLength: 150,
                    }}
                    className="search_input"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={5} md={5} xl={4} className="search_label-right">
              <Typography.Text>
                {t('quotation_page.partially_consistent')}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={19} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.customer')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16} className="search_column">
                  <InputField<FormValue>
                    controller={{
                      control,
                      name: 'customer',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.customer_placeholder'),
                      maxLength: 30,
                    }}
                    className="search_input"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={5} md={5} xl={4} className="search_label-right">
              <Typography.Text>
                {t('quotation_page.partially_consistent')}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={18} xl={19}>
              <Row className="search_row_sub">
                <Col xs={24} md={6} xl={7}>
                  <Typography.Title level={5}>
                    {t('quotation_page.weight')}
                  </Typography.Title>
                </Col>
                <Col xs={24} md={17} xl={16}>
                  <NumberOnlyRangeField<FormValue>
                    controllerFrom={{
                      name: 'weight_from',
                      control: control,
                    }}
                    controllerTo={{
                      name: 'weight_to',
                      control: control,
                    }}
                    inputPropsFrom={{
                      placeholder: '00.00',
                    }}
                    inputPropsTo={{
                      placeholder: '00.00',
                    }}
                    suffix="kg"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="search_row page_size">
            <Col xs={24} md={4} xl={6}>
              <Typography.Title level={5}>
                {t('quotation_page.displayed_items_per_page')}
              </Typography.Title>
            </Col>
            <Col xs={24} md={19} xl={18}>
              <RadioGroupField<FormValue>
                className="search_radio_quotation"
                controller={{
                  name: 'offset',
                  control,
                }}
                radioGroupProps={{
                  options: PAGE_SIZE(t),
                }}
              />
            </Col>
          </Row>
          <Row className="search_row">
            <Col xs={24} md={4} xl={6}>
              <Typography.Title level={5}>
                {t('quotation_page.maximum_search_count')}
              </Typography.Title>
            </Col>
            <Col xs={24} md={19} xl={18}>
              <RadioGroupField<FormValue>
                className="search_radio_quotation"
                controller={{
                  name: 'maximum_search_count',
                  control,
                }}
                radioGroupProps={{
                  options: MAXIMUM_SEARCH_COUNT(t),
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider className="search_divider" />
      <div className="search_button">
        <Button type="primary" onClick={handleSubmit(handleSearch)}>
          {t('quotation_page.search')}
        </Button>
        <Button
          type="primary"
          className="ml-5 clear_search"
          onClick={handleClearParam}
        >
          {t('quotation_page.btn_reset')}
        </Button>
      </div>
    </div>
  );
};

export default Search;
