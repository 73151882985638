import React from 'react';

import MasterRegistration from 'src/containers/MasterRegistration';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const MasterRegistrationPage: React.FC = () => {
  useTitle(t('master_registration.title'));
  return <MasterRegistration />;
};

export const PATH = '/master_registration' as const;

export default MasterRegistrationPage;
