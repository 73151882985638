import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, TableProps, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';

import useTableCommon from 'src/hooks/useTable';
import useQuotation from 'src/queries/useQuotation';
import { DATE_FORMAT, LABEL } from 'src/constants/app';
import {
  checkQuotationLabel,
  ellipsisContentTable,
  formatDimensions,
  getProductName,
  numberFormat,
  renderDueDate,
} from 'src/utils/helper';
import { SelectField } from 'src/components/form';
import useForm, { FormValue } from './useForm';

const useTable = () => {
  const { t } = useTranslation();

  const {
    pagination: paginationCommon,
    rowSelection,
    setRowKeysSelected,
    ...rest
  } = useTableCommon({
    hasSelect: true,
    maxPageSize: 100,
    perDefault: 30,
  });

  const { queryQuotation, dataOrderStatus } = useQuotation({
    setRowKeysSelected,
  });
  const dataSource = queryQuotation?.data?.data?.items;

  const { control, setValue, getValues } = useForm();

  useEffect(() => {
    dataSource?.forEach((item, index) => {
      setValue(`quotation.${index}.seqno`, item.seqno);
      item?.sttCd && setValue(`quotation.${index}.status`, item?.sttCd);
    });
  }, [dataSource]);

  const columns = [
    {
      title: t('quotation_page.type_status'),
      width: 150,
      render: (_, record, index) => {
        switch (checkQuotationLabel(record)) {
          case LABEL.QUOTATION:
            return <Row>{t('quotation_page.quotation_label')}</Row>;
          case LABEL.TRIAL:
            return <Row>{t('quotation_page.trial')}</Row>;
          default:
            return (
              <Row>
                <Col span={24}>
                  <Typography.Text>
                    {t('quotation_page.order_label')}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <SelectField<FormValue>
                    controller={{
                      control,
                      name: `quotation.${index}.status`,
                    }}
                    selectProps={{
                      options: dataOrderStatus,
                    }}
                    className="select_status"
                  />
                </Col>
              </Row>
            );
        }
      },
    },
    {
      title: t('quotation_page.customer_name_table'),
      children: [
        {
          title: t('quotation_page.internal_person'),
          children: [
            {
              title: t('quotation_page.delivery_name'),
              width: 224,
              render: (record) => {
                return (
                  <React.Fragment>
                    <Row className="row_item flex-column">
                      <Tooltip placement="topLeft" title={record.kokyakum}>
                        {ellipsisContentTable(record.kokyakum, 25)}
                      </Tooltip>

                      {record.kokyakuCd === '0000000000' && (
                        <a
                          href={`mailto:${record.mail}`}
                          className="row_item_link"
                        >
                          {record.mail}
                        </a>
                      )}
                    </Row>
                    <Row className="row_item">
                      <Tooltip placement="topLeft" title={record.kokyakuTanto}>
                        {ellipsisContentTable(record.kokyakuTanto, 25)}
                      </Tooltip>
                    </Row>
                    <Row className="row_item">
                      {record.kokyakuCd !== '0000000000' && (
                        <Tooltip placement="topLeft" title={record.nonyusm}>
                          {ellipsisContentTable(record.nonyusm, 25)}
                        </Tooltip>
                      )}
                    </Row>
                  </React.Fragment>
                );
              },
            },
          ],
        },
      ],
    },
    {
      title: t('quotation_page.quotation_registration_date'),
      children: [
        {
          title: t('quotation_page.quotation_expiration_date'),
          children: [
            {
              title: t('quotation_page.quotation_number'),
              width: 150,
              render: (record) => {
                return (
                  <React.Fragment>
                    <Row className="row_item">
                      {record.webMitrTrokub
                        ? dayjs(record.webMitrTrokub).format(DATE_FORMAT)
                        : ''}
                    </Row>
                    <Row className="row_item">
                      {record.kokyakuCd !== '0000000000' &&
                        (record.mitrYukoKigenb
                          ? dayjs(record.mitrYukoKigenb).format(DATE_FORMAT)
                          : '')}
                    </Row>
                    <Row className="row_item">{record.webMitrNo}</Row>
                  </React.Fragment>
                );
              },
            },
          ],
        },
      ],
    },
    {
      title: t('quotation_page.order_acceptance_date'),
      children: [
        {
          title: t('quotation_page.order_receipt_number'),
          children: [
            {
              title: t('quotation_page.order_number_table'),
              width: 150,
              render: (record) => {
                return (
                  <React.Fragment>
                    <Row className="row_item">
                      {record.webChumonTrokub
                        ? dayjs(record.webChumonTrokub).format(DATE_FORMAT)
                        : ''}
                    </Row>
                    <Row className="row_item">{record.chumonUketkeNo}</Row>
                    <Row className="row_item"></Row>
                  </React.Fragment>
                );
              },
            },
          ],
        },
      ],
    },
    {
      title: t('quotation_page.product_details'),
      width: 200,
      render: (record) => {
        const productName = getProductName({
          item: record.productName,
          isHakudoShhnm: false,
          defaultName: record.hakudoShhnm,
        });

        return (
          <Row>
            <Typography.Text>
              {(
                (productName ? productName + '／' : '') +
                ((record.shiagsnpo && record.snpoGuide) ||
                record.shiagsnpoHyojiMojirets
                  ? formatDimensions(record, record.setdnKakoCd) + '／'
                  : '') +
                (record.setdnkako ? record.setdnkako + '／' : '') +
                (record.fraiseKakoPtnm ? record.fraiseKakoPtnm + '／' : '') +
                (record.biko ? record.biko + '／' : '') +
                (record.tantoMei ? record.tantoMei + '／' : '')
              ).slice(0, -1)}
            </Typography.Text>
          </Row>
        );
      },
    },
    {
      title: t('quotation_page.number_of_members'),
      width: 65,
      render: (record) => (
        <React.Fragment>
          <Row className="row_item member">
            {numberFormat(record.inzu) + record.inzuTani}
          </Row>
          <Row className="row_item member">
            {`${numberFormat(record.jury)} (${record.juryTani}) `}
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t('quotation_page.purchase_unit_price'),
      children: [
        {
          title: t('quotation_page.purchase_amount'),
          width: 150,
          render: (record) => {
            return (
              <React.Fragment>
                <Row className="row_item member">
                  {record.shirTanka
                    ? `${numberFormat(record.shirTanka)}${record.tankaTani}`
                    : ''}
                </Row>
                <Row className="row_item member">
                  {record.shirSogaku
                    ? `${numberFormat(record.shirSogaku)}(${record.sogakuTani})`
                    : ''}
                </Row>
              </React.Fragment>
            );
          },
        },
      ],
    },
    {
      title: t('quotation_page.unit_selling_price'),
      children: [
        {
          title: t('quotation_page.price'),
          children: [
            {
              title: t('quotation_page.tax_breakdown'),
              width: 150,
              render: (record) => {
                return (
                  <React.Fragment>
                    <Row className="row_item">
                      {record.tanka
                        ? `${numberFormat(record?.tanka)}${record?.tankaTani}`
                        : ''}
                    </Row>
                    <Row className="row_item">
                      {record.sogaku
                        ? `${numberFormat(record?.sogaku)}(${
                            record?.sogakuTani
                          })`
                        : ''}
                    </Row>
                    <Row className="row_item">
                      {record?.mitsumoriTaxResList?.map(
                        (tax) =>
                          `${tax?.taxName}: ${tax?.zeikin} (${tax?.sogakuTani})` +
                          '　',
                      )}
                    </Row>
                  </React.Fragment>
                );
              },
            },
          ],
        },
      ],
    },
    {
      title: t('quotation_page.due_date'),
      width: 150,
      render: (record) => (
        <Row>
          {checkQuotationLabel(record) !== LABEL.TRIAL &&
            (record.chumonUketkeNo === undefined || ''
              ? renderDueDate(record, record?.noki)
              : renderDueDate(record, record?.kiboNoki))}
        </Row>
      ),
    },
    {
      title: t('quotation_page.remarks'),
      width: 200,
      render: (record) => (
        <Row>
          <Typography.Text>
            {record?.remarkAdminOrderHistory?.apiName &&
              `${t('quotation_page.supplier_name')}: ${
                record?.remarkAdminOrderHistory?.apiName
              }`}
          </Typography.Text>
          <Typography.Text>
            {record?.remarkAdminOrderHistory?.isConvertSize &&
              t('quotation_page.remarks_note')}
          </Typography.Text>
        </Row>
      ),
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: dataSource,
    loading: {
      spinning: queryQuotation.isFetching,
      indicator: <LoadingOutlined />,
    },
    rowKey: 'seqno',
    pagination: {
      ...paginationCommon,
      total: queryQuotation?.data?.data?.total,
      current: queryQuotation?.data?.data?.page,
      pageSize: queryQuotation?.data?.data?.offset,
    },
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
    size: 'middle',
    locale: {
      emptyText: t('message.E000024'),
    },
  };

  return {
    tableProps,
    dataSource,
    setRowKeysSelected,
    getValues,
    ...rest,
  };
};

export default useTable;
