import React from 'react';

import PluginManagement from 'src/containers/PluginManagement';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const PluginManagementPage: React.FC = () => {
  useTitle(t('menu_home.plugin_registration'));
  return <PluginManagement />;
};

export const PATH = '/plugin_registration' as const;

export default PluginManagementPage;
