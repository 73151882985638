import React from 'react';
import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';

import AppMenu from 'src/components/layout/Menu';
import AppFooter from 'src/components/layout/Footer';
import TopNav from 'src/components/layout/TopNav';
import LocalStorage from 'src/utils/LocalStorage';
import { PATH } from 'src/pages/auth';
import { PATH as PATH_ERROR } from 'src/pages/error';
import { ADMIN_ROLES } from 'src/constants/app';

type Props = {
  hasHeader?: boolean;
  hasMenu?: boolean;
  headerClass?: string;
  requireAuth?: boolean;
};

const AppLayout: React.FC<Props> = ({
  hasHeader = true,
  hasMenu = true,
  headerClass = '',
  requireAuth = true,
}) => {
  if (requireAuth) {
    const accessToken = LocalStorage.accessToken;
    const roles = LocalStorage.userRoles;
    const pathKey = location.pathname.split('/')[1];
    if (!accessToken) {
      return <Navigate to={PATH.SIGN_IN} replace />;
    }
    // Admin View role only can view home page and erorr page
    const PAGE_FOR_ADMIN_VIEW = [''];
    if (roles[0] === ADMIN_ROLES.viewAdmin) {
      if (!PAGE_FOR_ADMIN_VIEW.includes(pathKey)) {
        return <Navigate to={PATH_ERROR.FORBIDDEN} replace />;
      }
    }
  }
  return (
    <Layout>
      {hasHeader && <TopNav />}
      {hasMenu && <AppMenu />}
      <Layout className={'layout-container'}>
        <div className={'container-lg min-h-100' + headerClass}>
          <Outlet />
        </div>
      </Layout>
      <AppFooter />
    </Layout>
  );
};

export default AppLayout;
