export const QUOTE_ORDER_FLAG: App.OptionsFnc = (t) => [
  { label: t('quotation_page.quotation'), value: 'NOT_ORDERED_YET_TYPE' },
  { label: t('quotation_page.order'), value: 'ORDERED_TYPE' },
  { label: t('quotation_page.trial'), value: 'TRIAL_TYPE' },
];

export const PRODUCT_CLASSIFICATION: App.OptionsFnc = (t) => [
  { label: t('quotation_page.only_in_our_stock'), value: '1' },
  {
    label: t('quotation_page.white_bronze_net_service_ordered_product'),
    value: '2',
  },
];

export const EXPRESS: App.OptionsFnc = (t) => [
  { label: t('quotation_page.descending_order'), value: 'desc' },
  { label: t('quotation_page.ascending_order'), value: 'asc' },
];

export const MAXIMUM_SEARCH_COUNT: App.OptionsFnc = (t) => [
  { label: t('quotation_page.pieces', { 0: 100 }), value: '100' },
  { label: t('quotation_page.pieces', { 0: 300 }), value: '300' },
  { label: t('quotation_page.pieces', { 0: 500 }), value: '500' },
  { label: t('quotation_page.pieces', { 0: 1000 }), value: '1000' },
];

export const PAGE_SIZE: App.OptionsFnc = (t) => [
  { label: t('quotation_page.pieces', { 0: 30 }), value: '30' },
  { label: t('quotation_page.pieces', { 0: 50 }), value: '50' },
  { label: t('quotation_page.pieces', { 0: 100 }), value: '100' },
];
