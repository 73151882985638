import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { TableProps } from 'antd';

import useInternalPIC from 'src/queries/useInternalPIC';

type Props = {
  searchParams?: SearchParams.InternalPIC;
  setSearchParams?: Dispatch<SetStateAction<SearchParams.InternalPIC>>;
  onSelectChange?: (rowKeys: React.Key[], rowSelected: any[]) => void;
  nameSelected?: string;
};

const useTable = ({ searchParams, onSelectChange, nameSelected }: Props) => {
  const { t } = useTranslation();

  const { queryInternalPICList } = useInternalPIC(
    {
      ...searchParams,
    },
    {},
  );

  const columns = [
    {
      title: t('internal_pic.company_name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('internal_pic.email_address'),
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: queryInternalPICList.data?.data.items,
    loading: {
      spinning: queryInternalPICList.isFetching,
      indicator: <LoadingOutlined />,
    },
    rowKey: 'seqno',
    scroll: { y: 470 },
    rowSelection: {
      type: 'checkbox',
      onChange: onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: !!nameSelected && record.name !== nameSelected,
      }),
    },
    size: 'middle',
    pagination: false,
    locale: {
      emptyText: t('message.E000024'),
    },
  };

  return {
    tableProps,
  };
};

export default useTable;
