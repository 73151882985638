import { LoadingOutlined } from '@ant-design/icons';
import { TableProps, Typography } from 'antd';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';

import useTableCommon from 'src/hooks/useTable';
import { CATEGORY_PDF_REGISTRATION } from 'src/constants/optionSelect/pdfRegistration';

type Props = {
  onSelectChange?: (rowKeys: React.Key[], rowSelected: any[]) => void;
  sortBy?: string;
  dataSource: any;
  setErrorTable?: string;
};
const useTable = ({
  onSelectChange,
  sortBy,
  dataSource,
  setErrorTable,
}: Props) => {
  const client = useQueryClient();
  const [listTable, setListTable] = useState<any>();
  const listFileUpload = dataSource.data?.data.items;
  const getLanguage = client.getQueryData<AxiosResponse<Response.ListLanguage>>(
    ['/common/language/list'],
  )?.data.items;

  const { rowSelection, ...rest } = useTableCommon({
    hasSelect: true,
  });

  const columns = [
    {
      key: 'item_number',
      title: t('pdf_registration.item_number'),
      dataIndex: 'item_number',
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      key: 'kbnmCd',
      title: t('pdf_registration.classification'),
      dataIndex: 'kbnmCd',
      render: (item, record, index) =>
        CATEGORY_PDF_REGISTRATION(t).find((obj) => obj.value === item)?.label,
    },
    {
      key: 'fileNm',
      title: t('pdf_registration.file_name'),
      dataIndex: 'fileNm',
    },
    {
      key: 'gengoCd',
      title: t('pdf_registration.language'),
      dataIndex: 'gengoCd',
      render: (item, record, index) => (
        <div
          className={classNames({
            'lang-ja': item === 'ja',
            'lang-en': item === 'en',
            'lang-vi': item === 'vi',
            'lang-ko': item === 'ko',
            'lang-th': item === 'th',
            'lang-zh': item === 'zh',
            'lang-tw': item === 'tw',
          })}
        >
          {getLanguage?.find((obj) => obj.gengoCd === item)?.gengoNm}
        </div>
      ),
    },
    {
      key: 'userNm',
      title: t('pdf_registration.person_in_charge'),
      dataIndex: 'userNm',
    },
    {
      key: 'updDate',
      title: t('pdf_registration.update_date'),
      dataIndex: 'updDate',
    },
  ];
  // Soft list
  useEffect(() => {
    // 01:新規順
    // 02:区分順
    // 03:言語順
    if (sortBy === '01') {
      setListTable(_.orderBy(listFileUpload, ['updDate'], ['desc']));
    } else if (sortBy === '02') {
      setListTable(_.orderBy(listFileUpload, ['kbnmCd'], ['asc']));
    } else if (sortBy === '03') {
      setListTable(_.orderBy(listFileUpload, ['gengoCd'], ['asc']));
    }
  }, [sortBy, dataSource]);

  const tableProps: TableProps<any> = {
    columns: columns,
    loading: {
      spinning: dataSource.isFetching,
      indicator: <LoadingOutlined />,
    },
    dataSource: listTable || listFileUpload,
    rowKey: 'seqno',
    pagination: false,
    locale: {
      emptyText: t('message.E000024'),
    },
    scroll: { x: 'max-content' },
    rowSelection: rowSelection,
    footer: () => (
      <React.Fragment>
        {setErrorTable && (
          <Typography.Text className="error_message">
            {setErrorTable}
          </Typography.Text>
        )}
      </React.Fragment>
    ),
  };

  return {
    tableProps,
    ...rest,
  };
};

export default useTable;
