import React from 'react';
import { getProductNameByPriority } from 'src/utils/helper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';

import useQueryUrl from 'src/hooks/useQueryUrl';
import profitMarginSetting from 'src/repositories/profitMarginSetting';
import { STATUS_CODE, STATUS_FIELD } from 'src/constants/app';
import { FormValue } from 'src/containers/ProfitMarginSetting/useForm';

const apiPath = '/profit-margins';
const apiProductShapesPath = '/profit-margins/product-shapes';
const apiMachiningMethodsPath = '/profit-margins/machining-methods';
const apiProductTypesPath = '/profit-margins/product-types';
const apiProductNamesPath = '/profit-margins/product-names';
const apiUnitPricesPath = '/profit-margins/unit-prices';
const apiDeleteTypeAndShapePath = '/profit-margins/type-shapes';
const apiDeleteNameAndMethodPath =
  '/profit-margins/product-names-machining-methods';
const apiCheckExistCurrency = '/tax-settings/currency-transfers/check';
const apiListPath = '/basic-setting/api-list';
const apiPathCurrentCurrencyUnit = '/currency/current-unit';

type UseProfitMarginSetting = {
  typeKeyword?: string;
  shapeKeyword?: string;
  productKeyword?: string;
  methodKeyword?: string;
  unitPrice?: string;
  setError?: UseFormSetError<FormValue>;
  setOldDataError?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const useProfitMarginSetting = ({
  typeKeyword,
  shapeKeyword,
  productKeyword,
  methodKeyword,
  unitPrice,
  setError,
  setOldDataError,
}: UseProfitMarginSetting) => {
  const query = useQueryUrl<{ kokyaku_cd: string }>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const kokyakuCd = query.kokyaku_cd;

  const queryInitDataProfitMargin = useQuery<
    AxiosResponse<Response.InitDateProfitMargin>,
    AxiosError<Response.Error>
  >({
    queryKey: [`${apiPath}/${kokyakuCd}`],
    queryFn: profitMarginSetting.getInitDataProfitMargin,
  });

  const paramProductShape = {
    keyword: shapeKeyword,
  };

  const queryProductShapes = useQuery<
    AxiosResponse<Response.ProductShapes>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiProductShapesPath, paramProductShape],
    queryFn: profitMarginSetting.getProductProperties,
  });

  const dataProductShapes = queryProductShapes?.data?.data?.items?.map(
    (item) => {
      return {
        label: item.keijoL3M,
        value: item.keijoL3Cd,
      };
    },
  );

  const paramProductMethod = {
    keyword: methodKeyword,
  };

  const queryMachiningMethods = useQuery<
    AxiosResponse<Response.MachiningMethods>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiMachiningMethodsPath, paramProductMethod],
    queryFn: profitMarginSetting.getProductProperties,
  });

  const dataMachiningMethods = queryMachiningMethods?.data?.data?.items?.map(
    (item) => {
      return {
        label: item.setdnKakom,
        value: item.setdnKakoCd,
      };
    },
  );

  const paramProductType = {
    keyword: typeKeyword,
  };

  const queryProductTypes = useQuery<
    AxiosResponse<Response.ProductTypes>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiProductTypesPath, paramProductType],
    queryFn: profitMarginSetting.getProductProperties,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataProductTypes = queryProductTypes?.data?.data?.items?.map((item) => {
    return {
      label: item.hinshL3M,
      value: item.hinshL3Cd,
    };
  });

  const paramProductName = {
    keyword: productKeyword,
  };

  const queryProductNames = useQuery<
    AxiosResponse<Response.ProductNames>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiProductNamesPath, paramProductName],
    queryFn: profitMarginSetting.getProductProperties,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataProductNames = queryProductNames?.data?.data?.items?.map((item) => {
    let code = '',
      type = '';
    switch (true) {
      case !_.isNil(item.shhnCd):
        code = `s${item.shhnCd}`;
        type = item.hinshL3Cd;
        break;
      case !_.isNil(item.hakudoShhnCd):
        code = `h${item.hakudoShhnCd}`;
        type = item.hinshL3Cd;
        break;
      default:
        code = '';
    }
    return getProductNameByPriority(item, code, type);
  });

  const queryUnitPrices = useQuery<
    AxiosResponse<Response.UnitPrices>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiUnitPricesPath],
    queryFn: profitMarginSetting.getUnitPrices,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataUnitPrices = queryUnitPrices?.data?.data?.items?.map((item) => {
    return {
      label: item.symbol,
      value: item.taniType,
    };
  });

  const saveProfitMargin = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.SaveProfitMargin
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => profitMarginSetting.saveProfitMargin(apiPath, args),
    retry: 0,
    async onSuccess() {
      await queryClient.invalidateQueries([`${apiPath}/${kokyakuCd}`]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      const errorField = _.get(err, 'response.data.field');
      if (errorCode === STATUS_CODE.ALREADY_USED) {
        if (errorField === STATUS_FIELD.TYPE_SHAPE) {
          return (
            setError &&
            setError('type_shape_setting.0.type', {
              type: 'custom',
              message: t('message.E000055'),
            })
          );
        } else {
          return (
            setError &&
            setError('cutting_method_setting.0.product', {
              type: 'custom',
              message: t('message.E000055'),
            })
          );
        }
      }
      if (errorCode === STATUS_CODE.DUPLICATE_API_SEQNO) {
        return (
          setError &&
          setError('api_setting.0.api_select', {
            type: 'custom',
            message: t('message.E000090'),
          })
        );
      }
      if (errorCode === STATUS_CODE.OBJECT_NOT_FOUND) {
        return setOldDataError && setOldDataError(t('message.E000101'));
      }
    },
  });

  const deleteProductTypeAndShape = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteProductProfitMargin
  >({
    mutationKey: [apiDeleteTypeAndShapePath],
    mutationFn: (args) => {
      return profitMarginSetting.deleteProductProfitMargin(
        apiDeleteTypeAndShapePath,
        args,
      );
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const deleteProductNameAndMethod = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteProductProfitMargin
  >({
    mutationKey: [apiDeleteNameAndMethodPath],
    mutationFn: (args) => {
      return profitMarginSetting.deleteProductProfitMargin(
        apiDeleteNameAndMethodPath,
        args,
      );
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const paramCheckCurrency = {
    param: unitPrice,
  };

  const checkExistCurrency = useQuery<
    AxiosResponse<Response.CheckExistCurrencyTransferBySymbol>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiCheckExistCurrency, paramCheckCurrency],
    queryFn: profitMarginSetting.checkExistCurrency,
    enabled: !!unitPrice,
  });

  const queryApiList = useQuery<
    AxiosResponse<Response.ApiList>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiListPath],
    queryFn: profitMarginSetting.getApiList,
  });

  const queryCurrentCurrencyUnit = useQuery<
    AxiosResponse<Response.CurrentCurrencyUnit>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathCurrentCurrencyUnit],
    queryFn: profitMarginSetting.getCurrentCurrencyUnit,
  });

  return {
    queryInitDataProfitMargin,
    dataProductShapes,
    dataMachiningMethods,
    dataProductTypes,
    dataProductNames,
    dataUnitPrices,
    saveProfitMargin,
    kokyakuCd,
    deleteProductTypeAndShape,
    deleteProductNameAndMethod,
    queryProductTypes,
    queryMachiningMethods,
    queryProductNames,
    queryProductShapes,
    checkExistCurrency,
    queryApiList,
    queryCurrentCurrencyUnit,
  };
};

export default useProfitMarginSetting;
