export const DATA_NOTI_EMAIL: App.OptionsFnc = (t) => [
  { label: t('basic_setting.notify'), value: '1' },
  { label: t('basic_setting.not_notify'), value: '0' },
];

export const SUGGESTION_CONDITION: App.OptionsFnc = (t) => [
  {
    label: t('basic_setting.do_not_display_suggestions'),
    value: 'NOT_DISPLAY',
  },
  { label: t('basic_setting.if_the_price_is_low'), value: 'PRICE_LOW' },
  { label: t('basic_setting.fast_delivery'), value: 'FAST_DELIVERY' },
  {
    label: t('basic_setting.both_price_and_delivery'),
    value: 'PRICE_LOW_AND_FAST_DELIVERY',
  },
];

export const PDF_VERSION: App.OptionsFnc = (t) => [
  { label: t('basic_setting.landscape_version'), value: '1' },
  { label: t('basic_setting.portrait_version'), value: '0' },
];
