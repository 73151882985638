import { Button, Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import {
  CheckBoxField,
  InputField,
  NumberOnlyField,
  SelectField,
} from 'src/components/form';
import useCustomerSetting from 'src/queries/useCustomerSetting';
import useForm, { FormValue } from './useForm';
import history from 'src/libs/history';
import useQueryUrl from 'src/hooks/useQueryUrl';
import PhoneNumberField from 'src/components/form/PhoneNumberField';
import useOccupationAndLocation from 'src/queries/useOccupationAndLocation';

const SearchComponent: React.FC = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm();

  const { queryCustomer } = useCustomerSetting({
    setRowKeysSelected: undefined,
    setError,
  });
  const query = useQueryUrl();

  const { dataLocation, dataOccupation } = useOccupationAndLocation();

  const customer = queryCustomer.data?.data?.item?.tokuteiKokyakuKbn;
  const handleSearch = (values) => {
    history.push({
      search: qs.stringify(
        {
          ...query,
          ...values,
          page: 1,
          offset: 10,
          customer_code: values?.customer_code,
          customer_classification: values?.customer_classification
            ? 1
            : undefined,
          company_name: values?.company_name,
          address_code: values?.address_code,
          address: values?.address,
          phone_number: values?.phone_number,
          fax_number: values.fax_number,
          email: values?.email,
          person_in_charge: values?.person_in_charge,
          job_id: values?.job_id,
          region_id: values?.region_id,
          search: true,
        },
        {
          skipNull: true,
          skipEmptyString: true,
          arrayFormatSeparator: '|',
          arrayFormat: 'bracket-separator',
        },
      ),
    });
  };
  return (
    <div className="customer_setting_card_search">
      <Row className="mb-20 mb-md-0">
        <Col xs={24} sm={24} xl={3} className="mt-12 customer_setting_label">
          {t('customer_setting.customer_code')}
        </Col>
        <Col xs={24} sm={24} xl={14} className="mb-md-20">
          <NumberOnlyField<FormValue>
            wrapperCol={{ span: 23 }}
            controller={{
              control,
              name: 'customer_code',
            }}
            inputProps={{
              maxLength: 10,
              placeholder: t('customer_setting.customer_code'),
            }}
          />
        </Col>
        <Col xs={24} sm={24} xl={7}>
          {customer && (
            <Row className="customer_classification">
              <Col xl={16} className="customer_setting_label">
                {t('customer_setting.customer_classification')}
              </Col>
              <Col xl={8}>
                <CheckBoxField<FormValue>
                  label={customer}
                  controller={{
                    control,
                    name: 'customer_classification',
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row className="mb-20">
        <Col xs={24} sm={24} xl={3} className="mt-12 customer_setting_label">
          {t('customer_setting.company_name')}
        </Col>
        <Col xs={18} sm={20} xl={19}>
          <InputField<FormValue>
            wrapperCol={{ span: 23 }}
            controller={{
              control,
              name: 'company_name',
            }}
            inputProps={{
              maxLength: 90,
              placeholder: t('customer_setting.company_name'),
            }}
          />
        </Col>
        <Col xs={6} sm={4} xl={2} className="mt-12">
          <Typography.Text>
            {t('customer_setting.partial_match')}
          </Typography.Text>
        </Col>
      </Row>

      <Row className="mb-20">
        <Col xs={24} sm={24} xl={3} className="mt-12 customer_setting_label">
          {t('customer_setting.address_code')}
        </Col>
        <Col xs={18} sm={20} xl={19}>
          <PhoneNumberField<FormValue>
            wrapperCol={{ span: 23 }}
            controller={{
              control,
              name: 'address_code',
            }}
            inputProps={{
              maxLength: 10,
              placeholder: t('customer_setting.address_code'),
            }}
          />
        </Col>
        <Col xs={6} sm={4} xl={2} className="mt-12">
          <Typography.Text>
            {t('customer_setting.partial_match')}
          </Typography.Text>
        </Col>
      </Row>

      <Row className="mb-20">
        <Col xs={24} sm={24} xl={3} className="mt-12 customer_setting_label">
          {t('customer_setting.address')}
        </Col>
        <Col xs={18} sm={20} xl={19}>
          <InputField<FormValue>
            wrapperCol={{ span: 23 }}
            controller={{
              control,
              name: 'address',
            }}
            inputProps={{
              maxLength: 20,
              placeholder: t('customer_setting.address'),
            }}
          />
        </Col>
        <Col xs={6} sm={4} xl={2} className="mt-12">
          <Typography.Text>
            {t('customer_setting.partial_match')}
          </Typography.Text>
        </Col>
      </Row>
      <Row className="job_and_region">
        <Col xs={24} xl={3}>
          <Typography.Title level={5} className="label">
            {t('customer_registration.customer_category')}
          </Typography.Title>
        </Col>
        <Col xs={24} xl={8}>
          <SelectField<FormValue>
            controller={{
              control,
              name: `job_id`,
            }}
            selectProps={{
              options: dataOccupation,
              placeholder: t('customer_registration.industry_classification'),
            }}
            label={t('customer_registration.industry_classification')}
            labelCol={{ xs: 24, xl: 10 }}
            wrapperCol={{ xs: 24, sm: 23, xl: 13 }}
            className="auto_field"
          />
        </Col>
        <Col xs={24} xl={{ span: 11, offset: 1 }}>
          <SelectField<FormValue>
            controller={{
              control,
              name: `region_id`,
            }}
            selectProps={{
              options: dataLocation,
              placeholder: t('customer_registration.region'),
            }}
            label={t('customer_registration.region')}
            labelCol={{ xs: 24, xl: 5 }}
            wrapperCol={{ xs: 24, sm: 23, xl: 12 }}
            className="auto_field"
          />
        </Col>
      </Row>
      <Row className="mb-20">
        <Col xs={12} sm={12} xl={12}>
          <Row>
            <Col xl={6} className="mt-12 customer_setting_label">
              {t('customer_setting.phone_number')}
            </Col>
            <Col xs={24} sm={24} xl={16}>
              <PhoneNumberField<FormValue>
                wrapperCol={{ span: 23 }}
                controller={{
                  control,
                  name: 'phone_number',
                }}
                inputProps={{
                  maxLength: 13,
                  placeholder: t('customer_setting.phone_number'),
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} xl={11}>
          <Row>
            <Col
              xs={24}
              sm={24}
              xl={5}
              className="mt-12 customer_setting_label"
            >
              {t('customer_setting.fax_number')}
            </Col>
            <Col xs={24} sm={24} xl={16}>
              <PhoneNumberField<FormValue>
                wrapperCol={{ xs: 24, sm: 22, xl: 18 }}
                controller={{
                  control,
                  name: 'fax_number',
                }}
                inputProps={{
                  maxLength: 13,
                  placeholder: t('customer_setting.fax_number'),
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-20">
        <Col xs={24} sm={24} xl={3} className="mt-12 customer_setting_label">
          {t('customer_setting.email_address')}
        </Col>
        <Col xs={24} sm={24} xl={19}>
          <InputField<FormValue>
            wrapperCol={{ xs: 24, sm: 23, xl: 23 }}
            controller={{
              control,
              name: 'email',
            }}
            inputProps={{
              maxLength: 80,
              placeholder: t('customer_setting.email_address'),
            }}
          />
        </Col>
      </Row>
      <Row className="mb-20">
        <Col xs={24} sm={24} xl={3} className="mt-12 customer_setting_label">
          {t('customer_setting.person_in_charge')}
        </Col>
        <Col xs={24} sm={24} xl={19}>
          <InputField<FormValue>
            wrapperCol={{ xs: 24, sm: 23, xl: 23 }}
            controller={{
              control,
              name: 'person_in_charge',
            }}
            inputProps={{
              maxLength: 30,
              placeholder: t('customer_setting.person_in_charge'),
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Row justify="center" align="middle">
        <Button
          type="primary"
          onClick={handleSubmit((val) => handleSearch(val))}
          className="w-170 h-44 fs-16"
        >
          {t('button.search')}
        </Button>
      </Row>
    </div>
  );
};

export default SearchComponent;
