import { Table as AntTable, Typography, Button, Row, Col, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  LockOutlined,
  PlusSquareOutlined,
  UndoOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Modal from 'src/components/custom/Modal';
import useTable from './useTable';
import UserRegistrationChangeModal from '../UserRegistrationChangeModal';
import useUserRegistrationChange from 'src/queries/useUserRegistrationChange';
import LocalStorage from 'src/utils/LocalStorage';
import { INIT_PASS_SEND_FLAG, LOCK_FLAG } from 'src/constants/app';
import { SCREENS } from 'src/constants/screens';
import useQueryUrl from 'src/hooks/useQueryUrl';

const Table: React.FC = () => {
  const { t } = useTranslation();
  const { tableProps, rowSelected, setRowKeysSelected, errorResetPass } =
    useTable();
  const [error, setError] = useState<string>('');
  const {
    updateLockStatus,
    deleteAccount,
    sendMailResetPassword,
    queryAllFrontAccount,
  } = useUserRegistrationChange({
    setRowKeysSelected: setRowKeysSelected,
    setErrorTable: setError,
  });
  const query = useQueryUrl();

  const allFrontAccountData = queryAllFrontAccount?.data?.data?.items;

  useEffect(() => {
    setError(errorResetPass);
  }, [errorResetPass]);

  const handleDelete = () => {
    setError('');
    if (rowSelected.length > 0) {
      deleteAccount.mutate({
        seqnos: rowSelected,
      });
    } else {
      setError(
        t('message.E000007', {
          0: t('user_registration_change.user'),
        }),
      );
    }
  };

  const handleSendEmail = () => {
    setError('');
    if (rowSelected.length > 0) {
      const initPassSendFlg = allFrontAccountData
        ?.filter((row) => {
          return rowSelected.includes(row.seqno);
        })
        ?.map((item) => {
          return item.initPassSendFlg;
        });
      if (
        initPassSendFlg &&
        initPassSendFlg.includes(INIT_PASS_SEND_FLAG.FALSE)
      ) {
        setError(
          t('message.E000007', {
            0: t('user_registration_change.only_unsent_users'),
          }),
        );
      } else {
        const emails = allFrontAccountData
          ?.filter((row) => {
            return rowSelected.includes(row.seqno);
          })
          ?.map((item) => {
            return item.email;
          });
        sendMailResetPassword.mutate({
          emails: emails,
          gengoCd: LocalStorage.lang,
          customerMailType: 'INIT_PASSWORD',
        });
      }
    } else {
      setError(
        t('message.E000007', {
          0: t('user_registration_change.user'),
        }),
      );
    }
  };

  const handleLock = (lock: boolean) => {
    setError('');
    if (rowSelected.length > 0) {
      const lockFlg = allFrontAccountData
        ?.filter((row) => {
          return rowSelected.includes(row.seqno);
        })
        ?.map((item) => {
          return item.lockFlg;
        });
      if (
        lockFlg &&
        lockFlg.includes(lock ? LOCK_FLAG.LOCK : LOCK_FLAG.UNLOCK)
      ) {
        setError(
          t('message.E000007', {
            0: lock
              ? t('user_registration_change.only_unlocked_users')
              : t('user_registration_change.only_locked_users'),
          }),
        );
      } else {
        updateLockStatus.mutate({
          seqnos: rowSelected,
          lockFlgType: lock ? 'LOCK' : 'UNLOCK',
        });
      }
    } else {
      setError(
        t('message.E000007', {
          0: t('user_registration_change.user'),
        }),
      );
    }
  };

  const handleOpenModal = () => {
    setError('');
  };

  return (
    <div className="table">
      <Row className="table_header">
        <Col xs={0} md={24}>
          <Divider className="divider mt-0 mb-10" />
        </Col>
        <Col xs={24} md={4} lg={6} xl={12} className="table_title">
          <Typography.Text>{query?.customer_name}</Typography.Text>
          <Typography.Text>
            {t('user_registration_change.user_list')}
          </Typography.Text>
        </Col>
        <Col xs={24} md={0}>
          <Divider className="divider mt-20 mb-10" />
        </Col>
        <Col xs={24} md={20} lg={18} xl={12} className="table_action">
          <div className="table_button">
            <Modal
              modalProps={{ width: 525 }}
              title={t('user_registration_change.title')}
              content={({ onClose }) => (
                <UserRegistrationChangeModal
                  onClose={onClose}
                  screenId={SCREENS.R2505}
                />
              )}
            >
              <Button
                type="primary"
                icon={<PlusSquareOutlined />}
                onClick={handleOpenModal}
              >
                {t('user_registration_change.new_registration')}
              </Button>
            </Modal>
            <Button
              type="primary"
              icon={<UndoOutlined />}
              onClick={handleSendEmail}
              loading={sendMailResetPassword.isLoading}
            >
              {t('user_registration_change.send_initial_password_mail')}
            </Button>
          </div>
          <div className="table_button">
            <Button
              type="primary"
              icon={<LockOutlined />}
              onClick={() => handleLock(true)}
            >
              {t('user_registration_change.lock')}
            </Button>
            <Button
              type="primary"
              icon={<UnlockOutlined />}
              onClick={() => handleLock(false)}
            >
              {t('user_registration_change.unlock')}
            </Button>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={handleDelete}
            >
              {t('user_registration_change.delete')}
            </Button>
          </div>
        </Col>
      </Row>
      <Divider className="divider mt-10 mb-0" />
      <AntTable {...tableProps} />
      <Row>
        {error && (
          <Typography.Text
            className="ant-form-item-explain ant-form-item-explain-error"
            type="danger"
            ellipsis={{ tooltip: true }}
          >
            {error}
          </Typography.Text>
        )}
      </Row>
    </div>
  );
};

export default Table;
