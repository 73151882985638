export const SCREENS = {
  R1001: 'ログインページ',
  R1010: '確認コード入力',
  R1011: 'パスワード再設定',
  R1502: '見積・受注履歴',
  R1503: 'お知らせ設定',
  R1504: '利益率設定',
  R1505: '基本設定',
  R1506: '顧客設定',
  R1507: '顧客別納入先設定',
  R1508: 'マスタ登録',
  R1509: '税金・為替設定',
  R1510: 'PDF登録',
  R1512: 'ユーザ登録変更',
  R1513: 'プラグイン登録',
  R1514: '顧客登録',
  R2501: 'お知らせ登録ポップアップ',
  R2504: '顧客ごと担当者変更ポップアップ',
  R2505: 'ユーザ登録変更ポップアップ',
  R2506: '顧客別納入先設定',
  R2507: '顧客検索ポップアップ',
  R2508: '価格コピーポップアップ',
};
