import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { useForm as useHookForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export type UploadItem = {
  gengoCd: string;
  file: string;
};

export type FormValue = {
  terms_of_service: UploadItem;
  for_the_first_time_users: UploadItem;
  electronic_catalog: UploadItem;
};

const useForm = () => {
  const client = useQueryClient();
  const { t } = useTranslation();

  const getLanguage = client.getQueryData<AxiosResponse<Response.ListLanguage>>(
    ['/common/language/list'],
  )?.data.items;

  const languageOptions = [
    getLanguage?.map((item, index) => {
      return {
        label: item.gengoNm,
        value: item.gengoCd,
      };
    }),
  ];

  const validationPDFRegistration = {
    file: Yup.mixed()
      .test('fileSize', t('message.E000075'), (file) => {
        if (file) {
          return (
            file[0].size <= 5 * 1024 * 1024 || file[1].size <= 5 * 1024 * 1024
          );
        } else {
          return true;
        }
      })
      .test('typeFile', t('message.E000059'), (file) => {
        if (file) {
          return (
            file[0].type === 'application/pdf' ||
            file[1].type === 'application/pdf'
          );
        } else {
          return true;
        }
      }),
  };

  const schema = Yup.object().shape({
    terms_of_service: Yup.object().shape(validationPDFRegistration),
    for_the_first_time_users: Yup.object().shape(validationPDFRegistration),
    electronic_catalog: Yup.object().shape(validationPDFRegistration),
  });

  const form = useHookForm<any>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  return { ...form, languageOptions, getLanguage };
};

export default useForm;
