import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getNotificationSetting = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const updatePostFlg = (url: string, data: Payload.UpdatePostFlg) => {
  return axios({
    method: 'put',
    url,
    data,
  });
};

const deleteNotificationSetting = (
  url: string,
  data: Payload.DeleteNotificationSetting,
) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

const createNotification = (url, data: Payload.CreateOrUpdateNotification) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const getNotificationSettingDetail = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;

  return axios({
    method: 'get',
    url,
  });
};

const updateNotification = (url, data: Payload.CreateOrUpdateNotification) => {
  console.log(data);
  return axios({
    method: 'put',
    url,
    data,
  });
};

export default {
  getNotificationSetting,
  updatePostFlg,
  deleteNotificationSetting,
  createNotification,
  getNotificationSettingDetail,
  updateNotification,
};
