import { DISPLAY_OF_TOTAL_AMOUNT } from '../app';

export const TAX_EXCHANGE_OPTION: App.OptionsFnc = (t) => [
  {
    label: t('tax_exchange_setting.total_amount_including_tax'),
    value: DISPLAY_OF_TOTAL_AMOUNT.TAX_INCLUDED,
  },
  {
    label: t('tax_exchange_setting.total_amount_excluding_tax'),
    value: DISPLAY_OF_TOTAL_AMOUNT.TAX_EXCLUDED,
  },
];
