import React from 'react';
import CreatePlugin from './CreatePlugin';
import Note from './Note';
import PluginTable from './PluginTable';

const MasterRegistration: React.FC = () => {
  return (
    <div className="plugin_management">
      <Note />
      <CreatePlugin />
      <PluginTable />
    </div>
  );
};

export default MasterRegistration;
