import React from 'react';

import QuotationContainer from 'src/containers/Quotation';
import { withRemountOnHistoryPush } from 'src/hocs/withRemountOnHistoryPush';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const QuotationPage: React.FC = () => {
  useTitle(t('menu_home.quotation_order_history'));
  return <QuotationContainer />;
};

export const PATH = '/' as const;
export default withRemountOnHistoryPush(QuotationPage);
