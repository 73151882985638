import React from 'react';
import {
  Col,
  Form,
  FormItemProps,
  DatePickerProps,
  Row,
  Typography,
  Button,
  DatePicker,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'src/constants/app';
import { validateFormat } from 'src/utils/helper';

type Props<T> = {
  datePickerProps?: DatePickerProps;
  controller: UseControllerProps<T>;
  errorCol?: 12 | 24;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
} & Omit<FormItemProps, 'children'>;

function DatePickerField<T extends Record<string, any>>(props: Props<T>) {
  const { t } = useTranslation();
  const { datePickerProps, controller, errorCol = 24, label, ...rest } = props;
  const { fieldState, field } = useController<T>(controller);
  const { isTouched, error } = fieldState;

  const format = datePickerProps?.format?.toString() || DATE_FORMAT;
  const firstDateOfMonth = dayjs().startOf('month').format(format);

  const handleBlur = (e, field) => {
    const value = e.currentTarget.value;
    if (value && !validateFormat(value, format)) {
      setTimeout(() => {
        field.onChange(firstDateOfMonth);
      });
    } else {
      field.onChange(value);
    }
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      handleBlur(e, field);
    }
  };

  return (
    <Form.Item
      colon={false}
      label={label}
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={isTouched && error ? 'error' : ''}
      {...rest}
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={8}>
            <Col span={24}>
              <DatePicker
                style={{ width: '100%' }}
                dateRender={(current) => {
                  let className = 'ant-picker-cell-inner';
                  if (current.day() === 0) {
                    className = 'ant-picker-cell-inner_red';
                  }
                  if (current.day() === 6) {
                    className = 'ant-picker-cell-inner_blue';
                  }
                  return <div className={className}>{current.date()}</div>;
                }}
                {...datePickerProps}
                {...field}
                onBlur={(event) => handleBlur(event, field)}
                onKeyDown={(event) => handleKeyDown(event, field)}
                onChange={(e, dateString) => {
                  field.onChange(dateString);
                }}
                value={field.value ? dayjs(field.value) : undefined}
                renderExtraFooter={
                  // @ts-ignore
                  datePickerProps?.showToday === false
                    ? undefined
                    : () => (
                        <Button
                          className="my-custom-footer"
                          type="link"
                          onClick={() => {
                            field.onChange(dayjs().format(format));
                          }}
                        >
                          {t('button.today')}
                        </Button>
                      )
                }
                // @ts-ignore
                showToday={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={errorCol}>
          {error && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {error.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
}

export default DatePickerField;
