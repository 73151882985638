import React, { useState } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import useForm from './useForm';
import ItemUploadFile from './ItemUploadFile';
import Note from './Note';

const ListMasterRegistration: React.FC = () => {
  const [name, setName] = useState<string>('');
  const { control } = useForm(name);
  const { t } = useTranslation();

  const listColumn = [...Array(51)].map((_, i) => ({
    label: i.toString(),
    value: i.toString(),
  }));

  return (
    <React.Fragment>
      <Note />

      <div className="list_master_registration">
        <Row>
          <Col className="price_calculation" xs={24} md={24} lg={3}>
            <Typography.Paragraph className="text-title">
              {t('master_registration.price_calculation_factor')}
            </Typography.Paragraph>
          </Col>
          <Col xs={24} md={24} lg={21}>
            <ItemUploadFile
              kbnmCd={'01'}
              setName={setName}
              title={t('master_registration.item_size')}
              control={control}
              uploadFileName={'upload_file_item_size'}
              nameStatus={'status_item_size'}
            />
            <ItemUploadFile
              kbnmCd={'02'}
              setName={setName}
              title={t('master_registration.processing_information')}
              control={control}
              uploadFileName={'upload_file_process_info'}
              nameStatus={'status_process_info'}
            />
            <ItemUploadFile
              kbnmCd={'03'}
              setName={setName}
              title={t('master_registration.fare')}
              control={control}
              uploadFileName={'upload_file_fare'}
              nameStatus={'status_fare'}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <ItemUploadFile
              kbnmCd={'04'}
              setName={setName}
              title={t('master_registration.inventory_information')}
              col={21}
              control={control}
              uploadFileName={'upload_file_inventory_info'}
              nameStatus={'status_inventory_info'}
            />
            <ItemUploadFile
              kbnmCd={'06'}
              setName={setName}
              title={t('master_registration.customer_information')}
              col={21}
              control={control}
              uploadFileName={'upload_file_customer_info'}
              nameStatus={'status_customer_info'}
            />
            <ItemUploadFile
              kbnmCd={'05'}
              setName={setName}
              title={t('master_registration.basic_product_information')}
              col={21}
              control={control}
              uploadFileName={'upload_file_product_detail'}
              nameStatus={'status_product_detail'}
            />
            <ItemUploadFile
              kbnmCd={'07'}
              setName={setName}
              title={t('master_registration.product_details')}
              col={21}
              control={control}
              uploadFileName={'upload_file_product_dimension_info'}
              nameStatus={'status_product_dimension_info'}
              hasSelectColumn
              listColumn={listColumn}
            />
          </Col>
        </Row>
        <Divider className="mt-0 mb-30" />
      </div>
    </React.Fragment>
  );
};

export default ListMasterRegistration;
