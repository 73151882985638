import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useQueryUrl from 'src/hooks/useQueryUrl';
import { PER, STATUS_CODE } from 'src/constants/app';
import userRegistrationChange from 'src/repositories/userRegistrationChange';
import { FormValue } from 'src/containers/UserRegistrationChange/UserRegistrationChangeModal/useForm';

const apiPath = '/customers/accounts';
const insertAccountApiPath = '/customers/accounts-popup';
const updateAccountApiPath = '/customers/accounts-popup';

type UseUserRegistrationChange = {
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
  setErrorModal?: UseFormSetError<FormValue>;
  setErrorTable?: React.Dispatch<React.SetStateAction<string>>;
};

const useUserRegistrationChange = ({
  setRowKeysSelected,
  setErrorModal,
  setErrorTable,
}: UseUserRegistrationChange) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const query = useQueryUrl<SearchParams.UserRegistrationChange>();

  const params = {
    kokyakuCd: query.customer_code,
    lockFlg:
      query.locked_state?.length !== 1
        ? 'UNLOCK_AND_LOCK'
        : query.locked_state.toString(),
    initPassSendFlg:
      query.initial_password?.length !== 1
        ? 'TRUE_AND_FALSE'
        : query.initial_password == 'SENT'
        ? 'TRUE'
        : 'FALSE',
    email: query.email,
    smei: query.name,
    page: query.page || 1,
    offset: query.offset || PER.DEFAULT,
  };

  const queryAllFrontAccount = useQuery<
    AxiosResponse<Response.AllFrontAccount>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath, params],
    queryFn: userRegistrationChange.getAllFrontAccount,
  });

  const updateLockStatus = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.UpdateLockStatus
  >({
    mutationKey: [apiPath],
    mutationFn: (args) =>
      userRegistrationChange.updateLockStatus(apiPath, args),
    retry: 0,
    async onSuccess() {
      await queryClient.invalidateQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError(err) {
      console.log(err);
    },
  });

  const deleteAccount = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteAccount
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => userRegistrationChange.deleteAccount(apiPath, args),
    async onSuccess() {
      await queryClient.invalidateQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      if (errorCode === STATUS_CODE.DUPLICATE_DATA) {
        return setErrorTable && setErrorTable(t('message.E000060'));
      }
    },
  });

  const sendMailResetPassword = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.SendMailResetPassword
  >({
    mutationKey: [apiPath],
    mutationFn: (args) =>
      userRegistrationChange.sendMailResetPassword(apiPath, args),
    retry: 0,
    async onSuccess() {
      await queryClient.invalidateQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      if (errorCode === STATUS_CODE.DUPLICATE_DATA) {
        return setErrorTable && setErrorTable(t('message.E000060'));
      }
    },
  });

  const insertAccountFrontsite = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.InsertAccountFrontsite
  >({
    mutationKey: [insertAccountApiPath],
    mutationFn: (args) =>
      userRegistrationChange.insertAccountFrontsite(insertAccountApiPath, args),
    retry: 0,
    async onSuccess() {
      await queryClient.invalidateQueries([apiPath]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      if (errorCode === STATUS_CODE.EMAIL_ALREADY_USED) {
        return (
          setErrorModal &&
          setErrorModal('email', {
            type: 'custom',
            message: t('message.E000072', {
              0: t('user_registration_change.this_email_address'),
            }),
          })
        );
      }
    },
  });

  const updateAccountFrontsite = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.UpdateAccountFrontsite
  >({
    mutationKey: [updateAccountApiPath],
    mutationFn: (args) =>
      userRegistrationChange.updateAccountFrontsite(updateAccountApiPath, args),
    retry: 0,
    async onSuccess() {
      await queryClient.invalidateQueries([apiPath]);
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      if (errorCode === STATUS_CODE.EMAIL_ALREADY_USED) {
        return (
          setErrorModal &&
          setErrorModal('email', {
            type: 'custom',
            message: t('message.E000072', {
              0: t('user_registration_change.this_email_address'),
            }),
          })
        );
      }
    },
  });

  return {
    queryAllFrontAccount,
    updateLockStatus,
    deleteAccount,
    sendMailResetPassword,
    insertAccountFrontsite,
    updateAccountFrontsite,
  };
};

export default useUserRegistrationChange;
