import axios from 'src/libs/axios';
import axiosBath from 'src/libs/axiosBatch';
import axiosBase from 'axios';
import { AxiosPromise } from 'axios';
import { UploadFile } from 'antd/es/upload/interface';
import { STATUS_MASTER_REGISTRATION } from '../constants/app';

export const preSignedImage = (file: File | UploadFile) => {
  return axios<any>({
    method: 'post',
    url: '/files/presigned',
    data: {
      mime_type: file.type,
      content_length: file.size,
    },
  }) as AxiosPromise<any>;
};

export type Data = {
  form_attributes: {
    action: string;
    enctype: string;
    method: string;
  };
  form_inputs: {
    'Content-Type': string;
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
    key: string;
  };
};

export const uploadImage = (data: Data, file: File | UploadFile) => {
  const formdata = new FormData();

  for (const key in data.form_inputs) {
    formdata.append(key, data.form_inputs[key]);
  }
  formdata.append('file', file as File, file.name);

  return axiosBase({
    baseURL: data.form_attributes.action,
    method: 'post',
    data: formdata,
    headers: {
      enctype: data.form_attributes.enctype,
    },
  });
};

export const uploadFile = (
  file: File | UploadFile,
  typeUploadFile = 'NOTIFICATION',
) => {
  const formData = new FormData();
  formData.append('file', file as File, file.name);
  formData.append('type', typeUploadFile);
  return axios<any>({
    url: '/files/upload',
    method: 'post',
    data: formData,
    headers: {
      enctype: 'multipart/form-data',
    },
  });
};

export const uploadFileCSV = (
  file: File | UploadFile,
  kbnmCd = STATUS_MASTER_REGISTRATION.TIMER,
) => {
  const formData = new FormData();
  formData.append('file', file as File, file.name);
  formData.append('kbnmCd', kbnmCd);
  return axiosBath<any>({
    url: '/csv/upload-tmp',
    method: 'post',
    data: formData,
    headers: {
      enctype: 'multipart/form-data',
    },
  });
};

export const uploadPDFFile = (
  file: File | UploadFile,
  typeUploadFile = 'PDF',
) => {
  const formData = new FormData();
  formData.append('file', file as File, file.name);
  formData.append('type', typeUploadFile);
  return axios<any>({
    url: '/pdf/upload',
    method: 'post',
    data: formData,
    headers: {
      enctype: 'multipart/form-data',
    },
  });
};
