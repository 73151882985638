import { Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { NegativeNumberField } from 'src/components/form';
import { FormValue } from '../../useForm';
import useProfitMarginSetting from 'src/queries/useProfitMarginSetting';
import { formatCurrency, handleUnitPrices } from 'src/utils/helper';
import SelectCurrencyField from 'src/components/form/SelectCurrencyField';
import { UnitPrices } from 'src/enums/profitMargin';
import SpecifyUnitLabel from '../SpecifyUnitLabel';

type Props = {
  standardFieldArray: UseFieldArrayReturn<FormValue, 'standard_setting', 'id'>;
  formInfo: UseFormReturn<FormValue, any>;
  setHasErrorTaniType: React.Dispatch<React.SetStateAction<boolean>>;
};

const StandardSetting: React.FC<Props> = ({
  standardFieldArray,
  formInfo,
  setHasErrorTaniType,
}) => {
  const { t } = useTranslation();

  const [unitPrice, setUnitPrice] = useState<string>();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    resetField,
  } = formInfo;
  const { fields, append, remove } = standardFieldArray;
  const { queryInitDataProfitMargin, dataUnitPrices, checkExistCurrency } =
    useProfitMarginSetting({
      unitPrice: unitPrice,
    });
  const [errorsSetting, setErrorsSetting] = useState<string[]>([]);

  const dataProfitMargin = queryInitDataProfitMargin?.data?.data?.item;

  useEffect(() => {
    let newArr: string[] = [];
    errors?.standard_setting?.map((item) => {
      if (item) {
        Object.values(item).map((mess) => {
          mess?.message && newArr.push(mess.message);
        });
      }
    });
    const uniqueErrors = [...new Set(newArr)];
    setErrorsSetting(uniqueErrors);
  }, [errors?.standard_setting]);

  useEffect(() => {
    if (checkExistCurrency?.data?.data?.item !== undefined) {
      if (checkExistCurrency?.data?.data?.item === false) {
        setError('standard_setting.0.tani_type', {
          type: 'custom',
          message: t('message.E000067'),
        });
      } else if (checkExistCurrency?.data?.data?.item === true) {
        clearErrors('standard_setting');
      }
    }
  }, [checkExistCurrency?.data?.data?.item]);

  const handleSelectUnitPrice = (value, index) => {
    resetField(`standard_setting.${index}.riekrate1`);
    resetField(`standard_setting.${index}.riekrate2`);
    resetField(`standard_setting.${index}.riekrate3`);
    resetField(`standard_setting.${index}.riekrate4`);
    if (value !== UnitPrices.PERCENT) {
      setUnitPrice(value);
    } else {
      clearErrors();
    }
  };

  useEffect(() => {
    append(
      {
        seqno: undefined,
        tani_type: UnitPrices.PERCENT,
        riekrate1: undefined,
        riekrate2: undefined,
        riekrate3: undefined,
        riekrate4: undefined,
      },
      { shouldFocus: false },
    );
    if (dataProfitMargin?.standardSettingRes) {
      setValue(
        `standard_setting.0.seqno`,
        dataProfitMargin?.standardSettingRes?.seqno,
      );
      setValue(
        `standard_setting.0.tani_type`,
        dataProfitMargin?.standardSettingRes?.taniType,
      );
      setValue(
        `standard_setting.0.riekrate1`,
        dataProfitMargin?.standardSettingRes?.riekrate1,
      );
      setValue(
        `standard_setting.0.riekrate2`,
        dataProfitMargin?.standardSettingRes?.riekrate2,
      );
      setValue(
        `standard_setting.0.riekrate3`,
        dataProfitMargin?.standardSettingRes?.riekrate3,
      );
      setValue(
        `standard_setting.0.riekrate4`,
        dataProfitMargin?.standardSettingRes?.riekrate4,
      );
    }
    return () => {
      remove();
    };
  }, [dataProfitMargin?.standardSettingRes]);

  useEffect(() => {
    setHasErrorTaniType(errorsSetting.includes(t('message.E000067')));
  }, [errorsSetting]);

  const handleShowNameSymbol = () => {
    const taniType = watch(`standard_setting.0.tani_type`);
    return handleUnitPrices(taniType);
  };

  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Row className="setting_input" key={index}>
          <Col md={8} xl={9} className="desktop label" offset={1}>
            {t('profit_margin_setting.standard_setting')}
          </Col>
          <Col xs={24} md={3} xl={2} className="setting_input_select">
            <SelectCurrencyField<FormValue>
              controller={{
                control,
                name: `standard_setting.${index}.tani_type`,
                defaultValue: item.tani_type,
              }}
              selectProps={{
                options: formatCurrency(dataUnitPrices),
                onSelect: (value) => handleSelectUnitPrice(value, index),
              }}
              label={<SpecifyUnitLabel />}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              className="select_unit_price"
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `standard_setting.${index}.riekrate1`,
              }}
              label={t('profit_margin_setting.0_to_299kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol()}
              className={classNames({
                'font-vi':
                  watch(`standard_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `standard_setting.${index}.riekrate2`,
              }}
              label={t('profit_margin_setting.300_to_749kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol()}
              className={classNames({
                'font-vi':
                  watch(`standard_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `standard_setting.${index}.riekrate3`,
              }}
              label={t('profit_margin_setting.750_to_1499kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol()}
              className={classNames({
                'font-vi':
                  watch(`standard_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
          <Col xs={24} md={3} xl={3}>
            <NegativeNumberField<FormValue>
              controller={{
                control,
                name: `standard_setting.${index}.riekrate4`,
              }}
              label={t('profit_margin_setting.more_than_1500kg')}
              labelCol={{ xs: 12, md: 0 }}
              wrapperCol={{ xs: 12, md: 24 }}
              suffix={handleShowNameSymbol()}
              className={classNames({
                'font-vi':
                  watch(`standard_setting.${index}.tani_type`) ===
                  UnitPrices.VND,
              })}
            />
          </Col>
        </Row>
      ))}
      {errorsSetting &&
        errorsSetting.map((error, index) => (
          <Row key={index}>
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {error}
            </Typography.Text>
          </Row>
        ))}
    </React.Fragment>
  );
};

export default StandardSetting;
