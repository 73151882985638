import React from 'react';
import { Col, Row } from 'antd';
import { t } from 'src/libs/i18n';

import { EditorField, FileUploadField, InputField } from 'src/components/form';
import { FormValue } from '../useForm';
import { Control } from 'react-hook-form/dist/types/form';

import { DisplayCondition } from 'src/enums/notification';

type Props = {
  control: Control<FormValue, any>;
  index: number;
  data: any;
  id: number;
  hyoujiBashoWatch: string[];
};

const LanguageItem: React.FC<Props> = (props) => {
  const { control, index, data, id, hyoujiBashoWatch } = props;

  return (
    <>
      <Row className="mt-20" gutter={[0, 6]}>
        <Col xs={24} sm={24} lg={24} className="group-input input-textarea">
          <InputField<FormValue>
            label={t('notification_setting.title')}
            inputProps={{
              maxLength: 200,
              placeholder: t('notification_setting.title'),
            }}
            controller={{
              control,
              name: `rmOsiraseContents.${index}.title`,
            }}
            labelCol={{ xs: 24, md: 3 }}
            wrapperCol={{ xs: 24, md: 21 }}
            required
          />
        </Col>
      </Row>
      <Row className="mt-20" gutter={[0, 6]}>
        <Col xs={24} sm={24} lg={24} className="group-input">
          <EditorField<FormValue>
            placeholder={t('notification_setting.text')}
            className="pl-20"
            controller={{
              defaultValue: data?.osirase,
              control,
              name: `rmOsiraseContents.${index}.osirase`,
            }}
            label={t('notification_setting.text')}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4} md={4} className="mt-20 mb-25">
          <span className="mt-10 title">
            {t('notification_setting.attachment')}
          </span>
        </Col>
        {((data?.seqno || !id) && (
          <Col xs={20} md={20} xl={20}>
            <FileUploadField<FormValue>
              className="mb-0 upload-file"
              controller={{
                control,
                name: `rmOsiraseContents.${index}.upload_file`,
              }}
              uploadFileProps={{
                disabled: hyoujiBashoWatch
                  ? hyoujiBashoWatch[0] == DisplayCondition.LOGIN_PAGE
                  : false,
                defaultFileList: [
                  {
                    uid: `${data.seqno}`,
                    name: data.dispFileName,
                    status: 'done',
                    url: data.fileName,
                  },
                ],
              }}
            />
          </Col>
        )) || (
          <FileUploadField<FormValue>
            className="mb-0 upload-file"
            controller={{
              control,
              name: `rmOsiraseContents.${index}.upload_file`,
            }}
            uploadFileProps={{
              disabled: hyoujiBashoWatch
                ? hyoujiBashoWatch[0] == DisplayCondition.LOGIN_PAGE
                : false,
            }}
          />
        )}
      </Row>
    </>
  );
};
export default LanguageItem;
