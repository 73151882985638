import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { REGEX } from 'src/constants/app';
import useBasicSetting from 'src/queries/useBasicSetting';
import { useEffect } from 'react';
// import { ValidationError } from 'yup';

export type responsiblePersonItem = {
  userCd: string;
  name: string;
  email: string;
  kengen: string;
};

export type FormArrValue = {
  responsible_person: responsiblePersonItem[];
};

const useForm = () => {
  const responsiblePersonSchema = {
    name: Yup.string().required(
      t('message.E000003', { 0: t('basic_setting.responsible_person') }),
    ),
    email: Yup.string()
      .required(t('message.E000003', { 0: t('basic_setting.email') }))
      .matches(
        REGEX.REGEX_EMAIL_MATCHES,
        t('message.E000012', {
          0: t('basic_setting.email'),
        }),
      )
      .when('name', (name, schema) => {
        return schema.test({
          test: function (email) {
            if (email && !name) {
              return this.createError({
                message: t('message.E000003', {
                  0: t('basic_setting.responsible_person_name'),
                }),
                path: 'responsible_person.name',
              });
            } else {
              if (!email && name) {
                return this.createError({
                  message: t('message.E000003', {
                    0: t('basic_setting.email'),
                  }),
                  path: 'responsible_person.email',
                });
              }
            }
            return true;
          },
        });
      }),
  };
  const schema = Yup.object().shape({
    responsible_person: Yup.array().of(
      Yup.object().shape(responsiblePersonSchema),
    ),
  });

  const form = useHookForm<any>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { setError } = form;
  const { queryCompanyInfo } = useBasicSetting({ setError });
  const companyInfoData = queryCompanyInfo.data?.data.item.jishaTantos;

  const fieldArray = useFieldArray({
    name: 'responsible_person',
    control: form.control,
  });

  const { fields, append, remove } = fieldArray;

  useEffect(() => {
    companyInfoData?.forEach((item) => {
      append(
        {
          seqno: item.seqno,
          userCd: item.userCd,
          name: item.name,
          email: item.email,
          kengen: item.kengen,
          action: 'update',
        },
        { shouldFocus: false },
      );
    });
    return () => {
      remove();
    };
  }, [companyInfoData]);

  return { ...form, fieldArray, fields, companyInfoData };
};

export default useForm;
