import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import { FormValue } from 'src/containers/NotificationSetting/NotificationSettingModal/useForm';
import createNotification from '../repositories/notification';
import _ from 'lodash';

export const apiKey = '/notifications' as const;

type UseCreateNotification = {
  setError?: UseFormSetError<FormValue>;
  onSuccess?: (data) => void;
};

const useCreateNotification = ({
  setError,
  onSuccess,
}: UseCreateNotification) => {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<Payload.CreateOrUpdateNotification>,
    AxiosError,
    Payload.CreateOrUpdateNotification
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => createNotification.createNotification(apiKey, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiKey]);
    },
    onError(err) {
      const error = _.get(err, 'response.data');
      return error;
    },
  });
};

export default useCreateNotification;
