import { useFieldArray, useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { t } from 'src/libs/i18n';
import { DISPLAY_OF_TOTAL_AMOUNT, REGEX } from 'src/constants/app';
import { UnitPrices } from 'src/enums/profitMargin';

type Props = {
  symbolsTaxExChange?: Response.SymbolItem[];
};

export type TaxSetting = {
  taxValue?: string;
  taxUnit?: string;
  checkbox: boolean;
  taxName?: string;
  seqno?: number;
  gyoushuId?: number;
  shozaichiId?: number;
  type?: any;
  isDeleted: boolean;
  keijoCd?: string;
  hinshCd?: string;
  apiSeqno?: string;
};

export type FormValue = {
  tax_setting: TaxSetting[];
  radio_tax: string;
  tax_exchange_error: string;
  last_digit: string;
  round_down: string;
};

export type CheckRadioTax = {
  value;
  context;
  condition: 'name' | 'value' | 'all';
};

const useForm = ({ symbolsTaxExChange }: Props) => {
  const symbolOptions = [
    symbolsTaxExChange?.map((item) => {
      return {
        label: item.symbol,
        value: item.moneyType,
      };
    }),
  ];

  const taxSettingSchema = {
    taxValue: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('taxUnit', (taxUnit, schema) => {
        return schema.test({
          test: function (taxValue) {
            if (taxUnit === UnitPrices.PERCENT) {
              if (
                taxValue !== null &&
                taxValue !== undefined &&
                (Number(taxValue) < -0.1 ||
                  Number(taxValue) > 99.9 ||
                  !REGEX.REGEX_PERCENT.test(taxValue))
              ) {
                return this.createError({
                  message: t('message.E000010', {
                    0: t('tax_exchange_setting.tax_rate'),
                    1: '-0.1～99.9',
                  }),
                });
              }
              return true;
            } else {
              if (
                taxValue !== null &&
                taxValue !== undefined &&
                (Number(taxValue) < -1.111 ||
                  Number(taxValue) > 9999999.999 ||
                  !REGEX.REGEX_PROFIT_RATE.test(taxValue))
              ) {
                return this.createError({
                  message: t('message.E000006', {
                    0: t('tax_exchange_setting.tax_rate'),
                    1: '-1.111～9999999.999',
                  }),
                });
              }
              return true;
            }
          },
        });
      }),
  };

  const checkRadioTax = (param: CheckRadioTax) => {
    const { value, context, condition } = param;
    const hasNameTaxInvalid = !context.parent.tax_setting?.every((item) =>
      Boolean(item?.taxName),
    );
    const hasValueTaxInvalid = !context.parent.tax_setting?.every((item) =>
      Boolean(item?.taxValue),
    );
    const isAllInvalid =
      context.parent.tax_setting?.length === 0 ||
      (hasNameTaxInvalid && hasValueTaxInvalid);

    const checkConditionTest = () => {
      switch (condition) {
        case 'name':
          return hasNameTaxInvalid;
        case 'value':
          return hasValueTaxInvalid;
        case 'all':
          return isAllInvalid;
        default:
          return;
      }
    };
    if (
      value === DISPLAY_OF_TOTAL_AMOUNT.TAX_INCLUDED &&
      checkConditionTest()
    ) {
      return false;
    }
    return true;
  };

  const schema = Yup.object().shape({
    tax_setting: Yup.array().of(Yup.object().shape(taxSettingSchema)),

    radio_tax: Yup.string()
      .nullable(true)
      .test('radio_tax', t('message.E000083'), (value, context) => {
        return checkRadioTax({
          value: value,
          context: context,
          condition: 'all',
        });
      })
      .test(
        'radio_tax',
        t('message.E000003', { 0: t('tax_exchange_setting.tax_name') }),
        (value, context) => {
          return checkRadioTax({
            value: value,
            context: context,
            condition: 'name',
          });
        },
      )
      .test(
        'radio_tax',
        t('message.E000003', { 0: t('tax_exchange_setting.tax_value') }),
        (value, context) => {
          return checkRadioTax({
            value: value,
            context: context,
            condition: 'value',
          });
        },
      )
      .test('radio_tax', t('message.E000100'), (value, context) => {
        const hasCheckTaxType = context?.parent?.tax_setting?.every((item) => {
          return item?.type?.length > 0;
        });
        return hasCheckTaxType;
      }),
  });

  const form = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const fieldArray = useFieldArray({
    name: 'tax_setting',
    control: form.control,
  });

  return { form, symbolOptions, fieldArray };
};

export default useForm;
