import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export type FormValue = {
  pluginNm: string;
  pluginType: string;
  script: string;
};

const useForm = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    pluginType: Yup.string().required(
      t('message.E000008', { 0: t('plugin_management.plugin_type') }),
    ),
    pluginNm: Yup.string()
      .required(
        t('message.E000008', {
          0: t('plugin_management.registerable_plugin_names'),
        }),
      )
      .max(
        50,
        t('message.E000002', {
          0: t('plugin_management.name'),
          1: 50,
        }),
      ),
    script: Yup.string().required(
      t('message.E000066', { 0: t('plugin_management.fill') }),
    ),
  });
  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  return { ...formInfo };
};

export default useForm;
