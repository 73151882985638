import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getListPlugins = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'GET',
    url,
    params,
  });
};

const registration = (url: string, data: Payload.registrationPlugin) => {
  return axios({
    method: 'POST',
    url,
    data,
  });
};
const update = (url: string, data: Payload.updatePlugin) => {
  return axios({
    method: 'PUT',
    url,
    data,
  });
};

const deletePlugin = (url, data: Payload.DeletePlugin) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

export default {
  getListPlugins,
  registration,
  update,
  deletePlugin,
};
