import { Button, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField } from 'src/components/form';
import useQueryUrl from 'src/hooks/useQueryUrl';
import useUserRegistrationChange from 'src/queries/useUserRegistrationChange';
import useForm, { FormValue } from './useForm';

type Props = {
  onClose: () => void;
  screenId?: string;
  userInfo?: {
    email: string;
    smei: string;
    seqno?: number | undefined;
  };
};

const UserRegistrationChangeModal: React.FC<Props> = ({
  onClose,
  userInfo,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm();
  const { insertAccountFrontsite, updateAccountFrontsite } =
    useUserRegistrationChange({
      setRowKeysSelected: undefined,
      setErrorModal: setError,
    });
  const query = useQueryUrl();
  const handleInsert = (value) => {
    if (!userInfo?.seqno) {
      insertAccountFrontsite.mutate({
        kokyakuCd: query.customer_code,
        email: value.email,
        smei: value.name,
      });
    } else {
      updateAccountFrontsite.mutate({
        kokyakuCd: query.customer_code,
        email: value.email,
        smei: value.name,
        seqno: userInfo?.seqno,
      });
    }
  };

  useEffect(() => {
    insertAccountFrontsite.isSuccess && onClose();
    updateAccountFrontsite.isSuccess && onClose();
  }, [insertAccountFrontsite.isSuccess, updateAccountFrontsite.isSuccess]);

  return (
    <div className="user_registration_modal">
      <InputField<FormValue>
        controller={{
          control,
          name: 'email',
          defaultValue: userInfo?.email && userInfo?.email,
        }}
        label={t('user_registration_change.email')}
        inputProps={{
          placeholder: t('user_registration_change.email_address'),
          maxLength: 80,
        }}
        required
        labelCol={{ xs: 24, md: 8 }}
        wrapperCol={{ xs: 24, md: 16 }}
      />
      <InputField<FormValue>
        controller={{
          control,
          name: 'name',
          defaultValue: userInfo?.smei && userInfo?.smei,
        }}
        label={t('user_registration_change.name')}
        required
        inputProps={{
          placeholder: t('user_registration_change.name'),
          maxLength: 30,
        }}
        labelCol={{ xs: 24, md: 8 }}
        wrapperCol={{ xs: 24, md: 16 }}
      />
      <Row justify="center">
        <Button type="primary" onClick={handleSubmit(handleInsert)}>
          {userInfo?.seqno
            ? t('modal_confirm.button_update')
            : t('user_registration_change.register')}
          &nbsp;
        </Button>
      </Row>
    </div>
  );
};

export default UserRegistrationChangeModal;
