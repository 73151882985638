import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { FormValue } from '../useForm';
import CuttingMethodSetting from './CuttingMethodSetting';
import SettingLabel from './SettingLabel';
import StandardSetting from './StandardSetting';
import TypeShapeSetting from './TypeShapeSetting';
import ApiSetting from './ApiSetting';

export type SettingType =
  | 'standard'
  | 'type_shape'
  | 'product_cutting_method'
  | 'api';

type Props = {
  type: SettingType;
  standardFieldArray?: UseFieldArrayReturn<FormValue, 'standard_setting', 'id'>;
  typeShapeFieldArray?: UseFieldArrayReturn<
    FormValue,
    'type_shape_setting',
    'id'
  >;
  cuttingMethodFieldArray?: UseFieldArrayReturn<
    FormValue,
    'cutting_method_setting',
    'id'
  >;
  apiFieldArray?: UseFieldArrayReturn<FormValue, 'api_setting', 'id'>;
  formInfo: UseFormReturn<FormValue, any>;
  setHasErrorTaniType: React.Dispatch<React.SetStateAction<boolean>>;
  dataProductNames?: {
    label: string;
    value: string;
    type: string;
  }[];
  queryProductNames?: UseQueryResult<
    AxiosResponse<Response.ProductNames, any>,
    AxiosError<Response.Error, any>
  >;
  setProductKeyword?: React.Dispatch<React.SetStateAction<string | undefined>>;
  dataApiList?;
  datacurrentCurrencyUnit?;
};

const Setting: React.FC<Props> = ({
  type,
  standardFieldArray,
  typeShapeFieldArray,
  cuttingMethodFieldArray,
  apiFieldArray,
  formInfo,
  setHasErrorTaniType,
  dataProductNames,
  queryProductNames,
  setProductKeyword,
  dataApiList,
  datacurrentCurrencyUnit,
}) => {
  return (
    <div className="setting">
      {type !== 'api' && <SettingLabel type={type} />}
      {type === 'standard' && standardFieldArray ? (
        <StandardSetting
          standardFieldArray={standardFieldArray}
          formInfo={formInfo}
          setHasErrorTaniType={setHasErrorTaniType}
        />
      ) : type === 'type_shape' && typeShapeFieldArray ? (
        <TypeShapeSetting
          typeShapeFieldArray={typeShapeFieldArray}
          formInfo={formInfo}
          setHasErrorTaniType={setHasErrorTaniType}
        />
      ) : type === 'product_cutting_method' && cuttingMethodFieldArray ? (
        <CuttingMethodSetting
          cuttingMethodFieldArray={cuttingMethodFieldArray}
          formInfo={formInfo}
          setHasErrorTaniType={setHasErrorTaniType}
          dataProductNames={dataProductNames}
          queryProductNames={queryProductNames}
          setProductKeyword={setProductKeyword}
        />
      ) : (
        apiFieldArray && (
          <ApiSetting
            apiFieldArray={apiFieldArray}
            formInfo={formInfo}
            setHasErrorTaniType={setHasErrorTaniType}
            dataApiList={dataApiList}
            datacurrentCurrencyUnit={datacurrentCurrencyUnit}
          />
        )
      )}
    </div>
  );
};

export default Setting;
