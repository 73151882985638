import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Note: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="note">
      <Typography.Paragraph>
        {t('master_registration.note_1')}
        <br />
        {t('master_registration.note_2')}
        <br />
        {t('master_registration.note_3')}
      </Typography.Paragraph>
    </div>
  );
};

export default Note;
