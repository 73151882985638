import { Button, Col, Divider, Row, Typography, Radio, Popover } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductTooltip from 'src/components/common/ProductTooltip';
import { RadioGroupField } from 'src/components/form';
import useProductSearch from 'src/queries/useProductSearch';
import { checkIsProductFinished, getProductName } from 'src/utils/helper';
import useForm, { FormValue } from './useForm';

type Props = {
  onClose: () => void;
  onSelectProduct: (product) => void;
};

const ProductModal: React.FC<Props> = ({ onClose, onSelectProduct }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm();
  const [product, setProduct] = useState('');

  const { dataCategories, dataSubCategories, dataProduct } = useProductSearch(
    watch('product_category'),
    watch('product_shape'),
  );
  const handleSearch = () => {
    const productInfo = dataProduct?.find((item) => {
      return item.productId === product;
    });
    onSelectProduct(getProductName({ item: productInfo, isHakudoShhnm: true }));
    onClose();
  };

  useEffect(() => {
    setValue('product_category', _.get(dataCategories, '[0].value'));
  }, [_.get(dataCategories, '[0].value')]);

  useEffect(() => {
    if (dataSubCategories) {
      setValue('product_shape', _.get(dataSubCategories, '[0].value'));
    }
  }, [watch('product_category')]);

  useEffect(() => {
    setProduct(_.get(dataProduct, '[0].productId'));
  }, [_.get(dataProduct, '[0].productId')]);

  const _onChangeProduct = (e) => {
    setProduct(e.target.value);
  };

  return (
    <div className="p-20 product_search">
      <Row>
        <Col span={24}>
          <RadioGroupField<FormValue>
            label={t('product.category')}
            radioGroupProps={{
              options: dataCategories,
            }}
            controller={{
              name: 'product_category',
              control,
            }}
            labelCol={{ xs: 4, md: 2 }}
            wrapperCol={{ xs: 20, md: 22 }}
            className="product_search_category"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <RadioGroupField<FormValue>
            label={
              checkIsProductFinished(watch('product_category'))
                ? t('product.type')
                : t('product.shape')
            }
            radioGroupProps={{
              options: dataSubCategories,
            }}
            controller={{
              name: 'product_shape',
              control,
            }}
            labelCol={{ xs: 4, md: 2 }}
            wrapperCol={{ xs: 20, md: 22 }}
            className="product_search_shape"
          />
        </Col>
      </Row>
      <Typography.Text className="product_search_title">
        {t('product.product_name')}
      </Typography.Text>
      <Divider className="mt-14 mb-16" />
      <Row className="product_search_list">
        <Col span={24}>
          <Radio.Group
            className="w-full"
            onChange={_onChangeProduct}
            value={product}
          >
            {dataProduct?.map((item, index) => {
              return (
                <Popover
                  placement="bottomLeft"
                  key={index}
                  content={
                    (item.shhnImageDir ||
                      item.shhnPdfDir ||
                      item.catchCopy) && <ProductTooltip product={item} />
                  }
                  trigger="hover"
                >
                  <Radio
                    className="mr-0 mb-24 category_product_item"
                    value={item.productId}
                  >
                    {getProductName({ item: item, isHakudoShhnm: true })}
                  </Radio>
                </Popover>
              );
            })}
          </Radio.Group>
        </Col>
      </Row>
      <Divider className="mt-0 mb-20" />
      <Row align="middle" justify="center">
        <Button
          className="product_search_button"
          type="primary"
          onClick={handleSubmit(handleSearch)}
        >
          {t('product.decide')}
        </Button>
      </Row>
    </div>
  );
};

export default ProductModal;
