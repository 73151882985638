import React from 'react';

import CustomerDeliverySetting from 'src/containers/CustomerDeliverySetting';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const CustomerDeliverySettingPage: React.FC = () => {
  useTitle(t('customer_registration.page_title'));
  return <CustomerDeliverySetting />;
};

export const PATH = '/customer_specific_delivery_destination_settings' as const;
export default CustomerDeliverySettingPage;
