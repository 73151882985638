import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SpecifyUnitLabel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography.Text className="mr-10">
        {t('profit_margin_setting.specify_unit')}
      </Typography.Text>
      <Typography.Text className="text-center">
        {`${t('profit_margin_setting.purchase_amount')}\n${t(
          'profit_margin_setting.weight',
        )}`}
      </Typography.Text>
    </React.Fragment>
  );
};

export default SpecifyUnitLabel;
