import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UseFormSetError } from 'react-hook-form/dist/types/form';

import pdfRegistration from 'src/repositories/pdfRegistration';
import { STATUS_CODE } from 'src/constants/app';
import _ from 'lodash';
import { t } from 'i18next';

const apiListUploadPath = '/pdf/list';
const apiPDFRegistrationPath = '/pdf';

type PDFRegistration = {
  setError: UseFormSetError<any>;
};

const usePDFRegistration = ({ setError }: PDFRegistration) => {
  const queryClient = useQueryClient();

  const queryListUpload = useQuery<
    AxiosResponse<Response.PDFRegistrationList>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiListUploadPath],
    queryFn: pdfRegistration.getListUpload,
  });

  const actionPDFRegistration = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.PDFRegistration[]
  >({
    mutationKey: [apiPDFRegistrationPath],
    mutationFn: (args) =>
      pdfRegistration.pdfRegistration(apiPDFRegistrationPath, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiListUploadPath]);
    },
    onError(error) {
      const errorCode = _.get(error, 'response.data.code');
      const getParamKbnmCd = _.get(error, 'response.data.params')[0];
      switch (errorCode) {
        case STATUS_CODE.FILE_COPY_FAILED:
          if (getParamKbnmCd === '01') {
            return setError(`terms_of_service.file`, {
              type: 'custom',
              message: t('message.E000059'),
            });
          }
          if (getParamKbnmCd === '02') {
            return setError(`for_the_first_time_users.file`, {
              type: 'custom',
              message: t('message.E000059'),
            });
          }
          if (getParamKbnmCd === '03') {
            return setError(`electronic_catalog.file`, {
              type: 'custom',
              message: t('message.E000059'),
            });
          }
      }
    },
  });

  const deletePDFRegistration = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.DeletePDFRegistration
  >({
    mutationKey: [apiPDFRegistrationPath],
    mutationFn: (args) =>
      pdfRegistration.deletePDF(apiPDFRegistrationPath, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiListUploadPath]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  return {
    queryListUpload,
    actionPDFRegistration,
    deletePDFRegistration,
  };
};

export default usePDFRegistration;
