import React from 'react';

import PDFRegistrationContainer from 'src/containers/PDFRegistration';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const PDFRegistrationPage: React.FC = () => {
  useTitle(t('menu_home.pdf_registration'));
  return <PDFRegistrationContainer />;
};

export const PATH = '/pdf_registration' as const;
export default PDFRegistrationPage;
