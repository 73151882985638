import { AxiosError, AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import useQueryUrl from 'src/hooks/useQueryUrl';
import customerDeliverySetting from 'src/repositories/customerDeliverySetting';

const apiPath = '/delivery/address';

type UseCustomerDeliverySetting = {
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
};

const useCustomerDeliverySetting = ({
  setRowKeysSelected,
}: UseCustomerDeliverySetting) => {
  const queryClient = useQueryClient();

  const query = useQueryUrl<SearchParams.CustomerDeliverySettings>();

  const apiPathCustomerCode = `${apiPath}/${query.customer_code}`;

  const queryListCustomerDelivery = useQuery<
    AxiosResponse<Response.CustomerDeliverySettings>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPathCustomerCode],
    queryFn: customerDeliverySetting.listCustomerDelivery,
  });

  const updateCustomerDelivery = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.updateCustomerDelivery
  >({
    mutationKey: [apiPath],
    mutationFn: (args) =>
      customerDeliverySetting.updateCustomerDelivery(apiPath, args),
    retry: 0,
    async onSuccess(res) {
      await queryClient.invalidateQueries([apiPathCustomerCode]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  const deleteCustomerDelivery = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.deleteCustomerDelivery
  >({
    mutationKey: [apiPath],
    mutationFn: (args) =>
      customerDeliverySetting.deleteCustomerDelivery(apiPath, args),
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries([apiPathCustomerCode]);
      setRowKeysSelected?.([]);
    },
    onError(error) {
      console.log(error.response);
    },
  });

  return {
    queryListCustomerDelivery,
    updateCustomerDelivery,
    deleteCustomerDelivery,
  };
};

export default useCustomerDeliverySetting;
