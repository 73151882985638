import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { REGEX } from 'src/constants/app';

export type FormValue = {
  email: string;
  name: string;
};

const useForm = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(
        t('message.E000003', {
          0: t('user_registration_change.email_error'),
        }),
      )
      .matches(
        REGEX.REGEX_EMAIL_MATCHES,
        t('message.E000012', { 0: t('user_registration_change.email_error') }),
      ),
    name: Yup.string().required(
      t('message.E000003', {
        0: t('user_registration_change.user_name'),
      }),
    ),
  });
  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { ...formInfo };
};

export default useForm;
