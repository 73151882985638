import { Col, Row, Typography } from 'antd';
import React from 'react';

import { InputField } from 'src/components/form';
import { useTranslation } from 'react-i18next';
import { FormValue } from '../useForm';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
};

const CustomerInfo: React.FC<Props> = ({ formInfo }) => {
  const { t } = useTranslation();
  const { control } = formInfo;

  return (
    <React.Fragment>
      <Row className="mt-20 section_title">
        <Col xs={24} md={24} xl={24}>
          <Typography.Title level={5}>
            {t('customer_registration.customer_info_title')}
          </Typography.Title>
        </Col>
      </Row>
      <div className="infomation_input">
        <Row>
          <Col xs={24} md={24} xl={24}>
            <InputField
              className="input_email"
              controller={{ control, name: 'customer_email' }}
              inputProps={{
                placeholder: t(
                  'customer_registration.customer_email_placeholder',
                ),
                maxLength: 80,
              }}
              label={t('customer_registration.customer_email')}
              labelCol={{ xs: 24, md: 7, xl: 4 }}
              wrapperCol={{ xs: 24, md: 17, xl: 20 }}
              required
            />
          </Col>
          <Col xs={24} md={24} xl={24}>
            <InputField
              controller={{ control, name: 'customer_name' }}
              inputProps={{
                placeholder: t('customer_registration.customer_name'),
                maxLength: 30,
              }}
              label={t('customer_registration.customer_name')}
              labelCol={{ xs: 24, md: 7, xl: 4 }}
              wrapperCol={{ xs: 24, md: 17, xl: 20 }}
              required
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CustomerInfo;
