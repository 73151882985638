import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { REGEX } from 'src/constants/app';
import { checkImageExt } from 'src/utils/helper';

export type FormValue = {
  front_site_name: string;
  company_name: string;
  phone_number: string;
  fax_number: string;
  address: string;
  business_hours_start: string;
  business_hours_end: string;
  estimated_expiration_date: string;
  specific_customer: string;
  notification_email: string;
  logo_img: string;
  qr_img: string;
  description: string;
  preferred_suppliers: string;
  suggestion_condition: string;
  pdf_version: string;
};

const useForm = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    front_site_name: Yup.string().required(
      t('message.E000003', {
        0: t('basic_setting.front_site_name_error'),
      }),
    ),
    company_name: Yup.string().required(
      t('message.E000003', {
        0: t('basic_setting.company_name'),
      }),
    ),
    phone_number: Yup.string()
      .required(
        t('message.E000003', {
          0: t('basic_setting.phone_number'),
        }),
      )
      .matches(
        REGEX.REGEX_PHONE_NUMBER,
        t('message.E000011', {
          0: t('basic_setting.phone_number'),
        }),
      ),
    fax_number: Yup.string().matches(
      REGEX.REGEX_PHONE_NUMBER,
      t('message.E000011', {
        0: t('basic_setting.fax_number'),
      }),
    ),
    business_hours_start: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(REGEX.REGEX_HOUR_MIN, t('message.E000014')),
    business_hours_end: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(REGEX.REGEX_HOUR_MIN, t('message.E000014'))
      .when('business_hours_start', (start, schema) => {
        return schema.test({
          test: function (end) {
            if (end && !start) {
              return this.createError({
                message: t('message.E000003', {
                  0: t('basic_setting.business_hours_error'),
                }),
                path: 'business_hours_start',
              });
            } else if (!end && start) {
              return this.createError({
                message: t('message.E000003', {
                  0: t('basic_setting.business_hours_error'),
                }),
                path: 'business_hours_end',
              });
            } else if (start && end) {
              if (REGEX.REGEX_HOUR_MIN.test(start) && start >= end) {
                return this.createError({
                  message: t('message.E000015', {
                    0: t('basic_setting.business_hours_error'),
                  }),
                  path: 'business_hours_end',
                });
              }
            }
            return true;
          },
        });
      }),
    estimated_expiration_date: Yup.string()
      .required(
        t('message.E000003', {
          0: t('basic_setting.estimated_expiration_date'),
        }),
      )
      .matches(
        REGEX.REGEX_0_TO_99,
        t('message.E000006', {
          0: t('basic_setting.estimated_expiration_date'),
          1: t('basic_setting.number_0-99'),
        }),
      ),
    logo_img: Yup.mixed().test(
      'fileSize',
      t('message.E000028'),
      async (file) => {
        const fileTypeValid = await checkImageExt(file?.[0]);
        if (fileTypeValid) {
          return file?.[0].size < 25 * 1024;
        } else {
          return true;
        }
      },
    ),
    qr_img: Yup.mixed().test('fileSize', t('message.E000028'), async (file) => {
      const fileTypeValid = await checkImageExt(file?.[0]);
      if (fileTypeValid) {
        return file?.[0].size < 25 * 1024;
      } else {
        return true;
      }
    }),
  });

  const form = useHookForm<any>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { form };
};

export default useForm;
