import React, { useEffect } from 'react';
import { TableProps, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { t } from 'src/libs/i18n';
import useTableCommon from 'src/hooks/useTable';
import { HalfWidthInputField, InputField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import useCustomerDeliverySetting from 'src/queries/useCustomerDeliverySetting';
import { DELIVERY_NUMBER_DEFAULT } from 'src/constants/app';

type Props = {
  setErrorTable?: React.Dispatch<React.SetStateAction<string>>;
  errorTable?: string;
  customer_code: number;
};

const useTable = ({ setErrorTable, errorTable, customer_code }: Props) => {
  const { queryListCustomerDelivery, updateCustomerDelivery } =
    useCustomerDeliverySetting({
      setRowKeysSelected: undefined,
    });
  const { rowSelection, ...rest } = useTableCommon({
    pagination: false,
    hasSelect: true,
  });

  const dataSource = queryListCustomerDelivery?.data?.data?.items;

  const { control, setError, clearErrors, getValues, fields } = useForm({
    dataSource,
  });

  // clear error when add new row.
  useEffect(() => {
    setErrorTable && setErrorTable('');
  }, [dataSource?.length]);

  const handleUpdate = (index) => {
    const fieldAddressCode = getValues(
      `customer_delivery_setting.${index}.address_code`,
    );
    const fieldShippingAddress = getValues(
      `customer_delivery_setting.${index}.shipping_address`,
    );

    if (!fieldAddressCode) {
      setError(`customer_delivery_setting.${index}.address_code`, {
        type: 'custom',
        message: t('message.E000003', {
          0: t('customer_delivery_setting.address_code_col'),
        }),
      });
    } else {
      clearErrors(`customer_delivery_setting.${index}.address_code`);
    }

    if (!fieldShippingAddress) {
      setError(`customer_delivery_setting.${index}.shipping_address`, {
        type: 'custom',
        message: t('message.E000003', {
          0: t('customer_delivery_setting.shipping_address_col'),
        }),
      });
    } else {
      clearErrors(`customer_delivery_setting.${index}.shipping_address`);
    }

    if (fieldAddressCode && fieldShippingAddress && dataSource) {
      const customerDelivery = {
        kokyakuCd: customer_code,
        seqno: dataSource[index].seqno,
        jushoCd: fieldAddressCode,
        otodokesakiJusho: fieldShippingAddress,
      };
      updateCustomerDelivery.mutate(customerDelivery);
    }
  };

  const columns: ColumnsType<Response.CustomerDeliverySettingsItem> = [
    // rows responsive desktop
    {
      title: t('customer_delivery_setting.delivery_number_col'),
      dataIndex: 'otokesakiCd',
      key: 'otokesakiCd',
      width: 180,
      render: (text) => {
        return (
          <Typography.Text className={`otokesakiCd_${text}`}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: t('customer_delivery_setting.delivery_name_col'),
      dataIndex: 'otokesakiNm',
      key: 'otokesakiNm',
      width: 315,
    },
    {
      title: t('customer_delivery_setting.address_code_col'),
      dataIndex: 'jushoCd',
      key: 'jushoCd',
      width: 215,
      render: (text, record, index) => {
        return record?.otokesakiCd === DELIVERY_NUMBER_DEFAULT ? (
          record.jushoCd
        ) : (
          <HalfWidthInputField<FormValue>
            controller={{
              name: `customer_delivery_setting.${index}.address_code`,
              control: control,
              defaultValue: record.jushoCd,
            }}
            inputProps={{
              maxLength: 10,
              onBlur: () => handleUpdate(index),
            }}
            className="d-flex"
          />
        );
      },
    },
    {
      title: t('customer_delivery_setting.shipping_address_col'),
      dataIndex: 'otodokesakiJusho',
      key: 'otodokesakiJusho',
      width: 315,
      render: (text, record, index) => {
        return record?.otokesakiCd === DELIVERY_NUMBER_DEFAULT ? (
          record.otodokesakiJusho
        ) : (
          <InputField<FormValue>
            controller={{
              name: `customer_delivery_setting.${index}.shipping_address`,
              control: control,
              defaultValue: record.otodokesakiJusho,
            }}
            inputProps={{
              maxLength: 200,
              onBlur: () => handleUpdate(index),
            }}
          />
        );
      },
    },
    {
      title: t('customer_delivery_setting.delivery_phone_number_col'),
      dataIndex: 'telno',
      key: 'telno',
      width: 180,
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: dataSource?.length == fields.length ? dataSource : [],
    loading: {
      spinning: queryListCustomerDelivery.isFetching,
      indicator: <LoadingOutlined />,
    },
    rowKey: 'seqno',
    size: 'middle',
    pagination: false,
    className: 'custom_table',
    rowSelection: rowSelection,
    scroll: { x: 'max-content' },
    locale: {
      emptyText: t('message.E000025'),
    },
    footer: () => (
      <React.Fragment>
        {errorTable && (
          <Typography.Text className="error_message">
            {errorTable}
          </Typography.Text>
        )}
      </React.Fragment>
    ),
  };

  return {
    tableProps,
    dataSource,
    ...rest,
  };
};

export default useTable;
