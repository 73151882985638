import { useForm as useHookForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

export type FormValue = {
  status_item_size: string;
  status_process_info: string;
  status_fare: string;
  status_inventory_info: string;
  status_product_detail: string;
  status_customer_info: string;
  status_product_dimension_info: string;
  upload_file_item_size: string;
  upload_file_process_info: string;
  upload_file_fare: string;
  upload_file_inventory_info: string;
  upload_file_product_detail: string;
  upload_file_customer_info: string;
  upload_file_product_dimension_info: string;
  number_of_columns?: string;
};

const useForm = (name) => {
  const { t } = useTranslation();
  const validateUploadFile = (nameField: string) => {
    return function (value) {
      if (name == nameField) {
        if (!value) {
          return new ValidationError(
            t('message.E000007', {
              0: t('master_registration.required_error'),
            }),
            nameField,
            nameField,
          );
        } else {
          const SUPPORTED_FORMATS = ['text/csv'];
          if (!SUPPORTED_FORMATS.includes(value[0].type)) {
            return new ValidationError(
              t('message.E000059'),
              nameField,
              nameField,
            );
          }
          if (
            !(
              value[0].size <= 50 * 1024 * 1024 ||
              value[1].size <= 50 * 1024 * 1024
            )
          ) {
            return new ValidationError(
              t('message.E000077'),
              nameField,
              nameField,
            );
          }
        }
      }
      return true;
    };
  };

  const schema = Yup.object().shape({
    upload_file_item_size: Yup.mixed().test(
      validateUploadFile('upload_file_item_size'),
    ),
    upload_file_process_info: Yup.mixed().test(
      'uploadFileSize',
      'Unsupported File Format',
      validateUploadFile('upload_file_process_info'),
    ),
    upload_file_fare: Yup.mixed().test(
      'uploadFileSize',
      t('message.E000059'),
      validateUploadFile('upload_file_fare'),
    ),
    upload_file_inventory_info: Yup.mixed().test(
      'uploadFileSize',
      t('message.E000059'),
      validateUploadFile('upload_file_inventory_info'),
    ),
    upload_file_product_detail: Yup.mixed().test(
      'uploadFileSize',
      t('message.E000059'),
      validateUploadFile('upload_file_product_detail'),
    ),
    upload_file_customer_info: Yup.mixed().test(
      'uploadFileSize',
      t('message.E000059'),
      validateUploadFile('upload_file_customer_info'),
    ),
    upload_file_product_dimension_info: Yup.mixed().test(
      'uploadFileSize',
      t('message.E000059'),
      validateUploadFile('upload_file_product_dimension_info'),
    ),
  });
  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { ...formInfo };
};

export default useForm;
